import React, { useEffect,useRef } from "react";
import {
  add,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfMonth,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import Modal from "@mui/material/Modal";
import colors from "app/style/colors";
import moment, { isMoment } from "moment";
import { da } from "date-fns/locale";
import { getMonth } from "app/utils";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import { AiOutlinePlus, AiOutlineFolderView } from "react-icons/ai";
import AddJobDrawer from "../Drawer/AddJobDrawer";
import { Service } from "app/config/service";
import EditJobDrawer from "../Drawer/EditJobDrawer";
import { makeStyles } from '@mui/material';
import { BsBoxArrowDown, BsBoxArrowUpRight } from "react-icons/bs";



const Calendar = ({ value = new Date() }) => {
  const [anchorElViewMore, setAnchorElViewMore] = useState(null);
  const openViewMoreJob = Boolean(anchorElViewMore);
  const [showAddJob, setShowAddJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const [showEditJob, setShowEditJob] = useState(false);
  const [anchorElOptionAddJob, setAnchorElOptionAddJob] = useState(false);
  const [jobCount, setJobCount] = useState({});
  const [month, setMonth] = useState(moment().toDate());
  const [jobs, setJobs] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const startDate = startOfMonth(month);
  const endDate = endOfMonth(month);
  const numDays = differenceInDays(endDate, startDate) + 1;
  const [openViewAllJobsDialog, setOpenViewALlJobsDialog] = useState(false);
  const [modelList, setModelList] = useState({});
  const prefixDays = startDate.getDay() - 1;

  const divRef= useRef(null);
  const suffixDays = 7 - endDate.getDay();
  let dates = 0;
  let totalWeeks = 0;

  const calculateRow = () => {
    let totalDays = numDays;
    let startDiff = prefixDays;
    let endDiff = suffixDays;

    if (startDiff === -1) {
      totalWeeks += 1;
    }

    for (let index = 1; index < totalDays; index++) {
      startDiff++;
      if (startDiff === 7) {
        totalWeeks += 1;
        startDiff = 0;
      } else if (startDiff === 7) {
        totalWeeks += 1;
        startDiff = 0;
      }
    }
    if (endDiff > 0) {
      totalWeeks += 1;
    }

    console.log(
      "🚀 ~ file: Calendar.js ~ line 39 ~ Calendar ~ totalWeeks",
      totalWeeks
    );
  };

  calculateRow();

  const handleCalender = (action, val) => {
    let month = getMonth(action, val);
    setMonth(month);
    getJob(month);
  };
  const getJob = async (month) => {
    try {
      let monthStartDate = startOfMonth(month);
      let monthEndDate = endOfMonth(month);
      let startdate = moment(monthStartDate).format("YYYY-MM-DD");
      let enddate = moment(monthEndDate).format("YYYY-MM-DD");
      const { status, responseCode, message, data } =
        await Service.getJobByMonth(startdate, enddate);
      if (status === true && responseCode === 200) {
        setJobs(data.result);
      }
    } catch (error) {
      console.log("🚀 ~ file: Calendar.js ~ line 80 ~ getJob ~ error", error);
    }
  };

  const handleViewMoreMenu = (event, value, date) => {
    try {
      setAnchorElViewMore(event.currentTarget);
      // const list = value.assignDetails.filter(
      //   (val) => moment(val.startDate).format("dddd D") === date
      // );
      // setModelList({
      //   id: value._id,
      //   name: value.name,
      //   totalHrs: Math.ceil(value.totalHrs),
      //   date: list[0].startDate,
      //   assignWork: list,
      // });
    } catch (error) {
      console.log(
        "🚀 ~ file: WeeklyTable.js ~ line 77 ~ handleViewMoreModal ~ error",
        error
      );
    }
  };

  
  useEffect(() => {
    setMonth(getMonth());
    getJob(month);
  }, []);

  return (
    <>
    <Dialog
    open={openViewAllJobsDialog}
    fullWidth={true}
    maxWidth="xs"
  >
    <DialogTitle id="alert-dialog-title">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        All Jobs
        <IconButton
          onClick={() => {
            setOpenViewALlJobsDialog(false);
          }}
        >
          <MdClose size={24} />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent>
      {jobs
        .filter(
          (val) =>
            moment(val.startDate).format(
              "DD"
            ) == dates
        )
        .map((job, index) => {
          return (
            <>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {" "}
                ASDASD
              </Typography>
            </>
          );
        })}
    </DialogContent>
  </Dialog>
      {selectedJob && (
        <EditJobDrawer
          handleEditJob={() => setShowEditJob(!showEditJob)}
          open={showEditJob}
          selectedJobId={selectedJob}
          setSelectedJobId={setSelectedJob}
          getAssignJob={getJob}
          date={month}
        />
      )}
      <AddJobDrawer
        handleAddJob={() => setShowAddJob(!showAddJob)}
        open={showAddJob}
      />
      <Box sx={{ p: 3, borderRadius: "10px" }}>
        <TableContainer
          component={Paper}
          sx={{ borderBottomLeftRadius: 15, bottom: 0 }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "10px 10px 0 0",
              color: `${colors.textSecondary}`,
              fontWeight: 600,
            }}
          >
            <RiArrowLeftSFill
              fontSize={22}
              cursor="pointer"
              onClick={() => handleCalender("previous", month)}
            />
            {moment(month).format("MMMM YYYY")}
            <RiArrowRightSFill
              fontSize={22}
              cursor="pointer"
              onClick={() => handleCalender("next", month)}
            />
          </Typography>
          {jobs.length === 0 && (
            <Box sx={{ textAlign: "center", width: "100%", my: 20 }}>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 25, fontWeight: 600 }}
              >
                Scheduler
              </Typography>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 15, fontWeight: 400 }}
              >
                No jobs scheduled for this month
              </Typography>
            </Box>
          )}
          {jobs.length !== 0 && (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Monday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Tuesday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Wednesday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Thursday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Friday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Saturday
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 500 }}
                  >
                    Sunday
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: totalWeeks }).map((week, index) => {

                  return (
                    <>
                      {index === 0 && (
                        <TableRow>
                          {Array.from({
                            length: prefixDays === -1 ? 6 : prefixDays,
                          }).map((_, index) => (
                            <TableCell
                              key={index}
                              align="center"
                              sx={{
                                width: "150px",
                                height: 100,
                                border: "1px solid rgba(224,224,224,1)",
                              }}
                            ></TableCell>
                          ))}
                          {prefixDays === -1 &&
                            Array.from({ length: numDays }).map((_, index) => {
                              if (index + prefixDays < 0) {
                                dates = index + 1;
                                return (
                                  <TableCell
                                    sx={{
                                      p: 0.6,
                                      width: "150px",
                                      height: 100,
                                      border: "1px solid rgba(224,224,224,1)",
                                      position: "relative",
                                    }}
                                  >
                                    {/* <Typography sx={isCurrentDate&&currentMonth ? {display:"flex",justifyContent:"center",alignItems:"center", color: "white", background: "#1B5DB6", width: "23px", height: "23px",mt:1, borderRadius: "5px", fontSize: 14,position:"absolute",top:0 } : {fontWeight:700, mt:1,position:"absolute",top:0,fontSize: 14 }}>{dates}</Typography> */}
                                    <Box
                                      sx={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: " 1px solid #E9E9E9;",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <AiOutlinePlus style={{ fontSize: 12 }} />
                                      <Typography
                                        sx={{
                                          p: 0.5,
                                          ml: 0.5,
                                          color: "#9B9B9B",
                                          fontSize: 12,
                                        }}
                                      >
                                        Add Job
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                );
                              }
                            })}
                          {prefixDays !== -1 &&
                            Array.from({ length: numDays }).map((_, index) => {
                              if (index + prefixDays < 7) {
                                dates = index + 1;
                                const isCurrentDate = dates === value.getDate();
                                const currentMonth =
                                  moment(month).format("MMMM") ===
                                  moment()
                                    .month(value.getMonth())
                                    .format("MMMM");
                                let totaljob = 0;
                                return (
                                  <TableCell
                                    sx={{
                                      p: 0.6,
                                      width: "150px",
                                      height: 100,
                                      border: "1px solid rgba(224,224,224,1)",
                                      position: "relative",
                                    }}
                                  >
                                    <Typography
                                      sx={
                                        isCurrentDate && currentMonth
                                          ? {
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              color: "white",
                                              background: "#1B5DB6",
                                              width: "23px",
                                              height: "23px",
                                              mt: 1,
                                              borderRadius: "5px",
                                              fontSize: 14,
                                              position: "absolute",
                                              top: 0,
                                            }
                                          : {
                                              fontWeight: 700,
                                              mt: 1,
                                              position: "absolute",
                                              top: 0,
                                              fontSize: 14,
                                            }
                                      }
                                    >
                                      {dates}
                                    </Typography>

                                    {jobs.map((job, index) => {
                                      if (
                                        moment(job.startDate).format("DD") ==
                                          dates &&
                                        totaljob < 1
                                      ) {
                                        ++totaljob;
                                        return (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              mt: 3.5,
                                            }}
                                          >
                                            <Chip
                                              sx={{
                                                mb: 0.5,
                                                width: "100%",
                                                borderRadius: "5px",
                                                fontWeight: 600,

                                                "& .MuiChip-label": {
                                                  width: "100%",
                                                },
                                              }}
                                              label={
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontWeight: 600,
                                                      fontSize: 14,
                                                    }}
                                                  >
                                                    {job.name.substring(0, 10)}
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      background: "#E0E0E0",
                                                      py: 0.4,
                                                      px: 0.8,
                                                      color: "#4F4F4F",
                                                      fontSize: 11,
                                                      borderRadius: "2px",
                                                    }}
                                                  >
                                                    {job.totalHrs}h
                                                  </Typography>
                                                </Box>
                                              }
                                              onClick={() => {
                                                setSelectedJob(job._id);
                                                setShowEditJob(true);
                                              }}
                                            />
                                          </Box>
                                        );
                                      }
                                    })}

                                    {totaljob < 1 ? (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: " 1px solid #E9E9E9;",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {" "}
                                        <AiOutlinePlus
                                          style={{ fontSize: 12 }}
                                        />
                                        <Typography
                                          sx={{
                                            p: 0.5,
                                            ml: 0.5,
                                            color: "#9B9B9B",
                                            fontSize: 12,
                                          }}
                                          onClick={() => {
                                            setShowAddJob(!showAddJob);
                                            setAnchorElOptionAddJob(null);
                                          }}
                                        >
                                          Add Job
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            border: " 1px solid #E9E9E9;",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <AiOutlinePlus
                                            style={{ fontSize: 12 }}
                                          />
                                          <Typography
                                            sx={{
                                              p: 0.5,
                                              ml: 0.5,
                                              color: "#9B9B9B",
                                              fontSize: 12,
                                            }}
                                            onClick={() => {
                                              setShowAddJob(!showAddJob);
                                              setAnchorElOptionAddJob(null);
                                            }}
                                          >
                                            Add Job
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            mt: 0.5,
                                            width: "100%",
                                            borderRadius: "5px",
                                            border: " 1px solid #E9E9E9;",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <AiOutlineFolderView
                                            style={{ fontSize: 12 }}
                                          />
                                          <Typography
                                            sx={{
                                              p: 0.5,
                                              ml: 0.5,
                                              color: "#9B9B9B",
                                              fontSize: 12,
                                            }}
                                            onClick={() => {
                                              alert(month)
                                            }}
                                          >
                                            View All
                                          </Typography>
                                        </Box>
                                      </>
                                    )}
                                  </TableCell>
                                );
                              }
                            })}
                        </TableRow>
                      )}
                      {index >= 1 && (
                        <TableRow>
                          {Array.from({ length: numDays }).map((_, index) => {
                            if (index < 7 && dates !== numDays) {
                              dates += 1;
                              let totaljob = 0;
                              const isCurrentDate = dates === value.getDate();
                              const currentMonth =
                                moment(month).format("MMMM") ===
                                moment().month(value.getMonth()).format("MMMM");
                              return (
                                <TableCell
                                  sx={{
                                    p: 0.6,
                                    width: "150px",
                                    height: 100,
                                    border: "1px solid rgba(224,224,224,1)",
                                    position: "relative",
                                  }}
                                >
                                  <Typography
                                    sx={
                                      isCurrentDate && currentMonth
                                        ? {
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "white",
                                            background: "#1B5DB6",
                                            width: "23px",
                                            height: "23px",
                                            mt: 1,
                                            borderRadius: "5px",
                                            fontSize: 14,
                                            position: "absolute",
                                            top: 0,
                                          }
                                        : {
                                            fontWeight: 700,
                                            mt: 1,
                                            position: "absolute",
                                            top: 0,
                                            fontSize: 14,
                                          }
                                    }
                                  >
                                    {dates}
                                  </Typography>
                                  <Box
                                  sx={{
                                    mt: 3.5,
                                  }}
                                >
                                  {jobs.map((job, index) => {
                                    if (
                                      moment(job.startDate).format("DD") ==
                                        dates &&
                                      totaljob <= 1
                                    ) {
                                      totaljob+= 1;
                                      return (
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",

                                            }}
                                          >
                                            <Chip
                                              sx={{
                                                mb: 0.5,
                                                width: "100%",
                                                borderRadius: "5px",
                                                fontWeight: 600,
                                                backgroundColor: `${colors.chip.lightBlue}`,
                                                color: `${colors.chip.Blue}`,
                                                "& .MuiChip-label": {
                                                  width: "100%",
                                                },
                                              }}
                                              label={
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontWeight: 600,
                                                      fontSize: 14,
                                                    }}
                                                  >
                                                    {job.name.substring(0, 10)}
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      background: "#E0E0E0",
                                                      py: 0.4,
                                                      px: 0.8,
                                                      color: "#4F4F4F",
                                                      fontSize: 11,
                                                      borderRadius: "2px",
                                                    }}
                                                  >
                                                    {job.totalHrs}h
                                                  </Typography>
                                                </Box>
                                              }
                                              onClick={() => {
                                                setSelectedJob(job._id);
                                                setShowEditJob(true);
                                              }}
                                            />
                                          </Box>
                                        </>
                                      );
                                    }
                                  })}
                                  </Box>
                                  {totaljob > 1 ? (
                                    <Box
                                    sx={{
                                      mt: 0.5,
                                      width: "100%",
                                      borderRadius: "5px",
                                      border: " 1px solid #E9E9E9;",
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    <BsBoxArrowUpRight color="#9B9B9B" />
                                    <Typography
                                      sx={{
                                        p: 0.5,
                                        ml: 0.5,
                                        color: "#9B9B9B",
                                        fontSize: 12,
                                      }}
                                    >
                                      View All
                                    </Typography>
                                  </Box>
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: " 1px solid #E9E9E9;",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {" "}
                                        <AiOutlinePlus
                                          style={{ fontSize: 12 }}
                                        />
                                        <Typography
                                          sx={{
                                            p: 0.5,
                                            ml: 0.5,
                                            color: "#9B9B9B",
                                            fontSize: 12,
                                          }}
                                          onClick={() => {
                                            setShowAddJob(!showAddJob);
                                            setAnchorElOptionAddJob(null);
                                          }}
                                        >
                                          Add Job
                                        </Typography>
                                      </Box>
                                 
                                    </>
                                  )}
                            
                                </TableCell>
                              );
                            } else if (index < 7) {
                              return (
                                <TableCell
                                  sx={{
                                    width: "150px",
                                    height: 100,
                                    border: "1px solid rgba(224,224,224,1)",
                                  }}
                                ></TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      )}
                    </>
                  );
                })}

                {/* <TableRow >
                {Array.from({ length: numDays }).map((_, index) => {
                 const count=index+1
                 // const date = index + 1;
                 // const isCurrentDate = date === value.getDate();
                 // return (
                   //   index < 7 &&
                   //   <TableCell>{dates}</TableCell>
                   // )
                   if (index < 7) {
                    dates+=1
                    // dates = dates+index + 1;
                    return (
                      <TableCell>{dates}</TableCell>
                    )
                  }
                })}
              </TableRow>
              <TableRow >
                {Array.from({ length: numDays }).map((_, index) => {
                 const count=index+1
                 // const date = index + 1;
                 // const isCurrentDate = date === value.getDate();
                 // return (
                   //   index < 7 &&
                   //   <TableCell>{dates}</TableCell>
                   // )
                   if (index < 7) {
                    dates+=1
                    // dates = dates+index + 1;
                    return (
                      <TableCell>{dates}</TableCell>
                    )
                  }
                })}
              </TableRow>
              <TableRow >
                {Array.from({ length: numDays }).map((_, index) => {
                 const count=index+1
                 // const date = index + 1;
                 // const isCurrentDate = date === value.getDate();
                 // return (
                   //   index < 7 &&
                   //   <TableCell>{dates}</TableCell>
                   // )
                   if (index < 7) {
                    dates+=1
                    // dates = dates+index + 1;
                    return (
                      <TableCell>{dates}</TableCell>
                    )
                  }
                })}
              </TableRow>
              <TableRow >
                {Array.from({ length: numDays }).map((_, index) => {
                 const count=index+1
                 // const date = index + 1;
                 // const isCurrentDate = date === value.getDate();
                 // return (
                   //   index < 7 &&
                   //   <TableCell>{dates}</TableCell>
                   // )
                   if (index < 7) {
                    dates+=1
                    // dates = dates+index + 1;
                    return (
                      <TableCell>{dates}</TableCell>
                    )
                  }
                })}
              </TableRow> */}
              </TableBody>
            </Table>
          )}
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "0px 0px 10px 10px",
              color: `${colors.textSecondary}`,
              fontWeight: 600,
            }}
          >
            <Chip
              size="small"
              sx={{
                mr: 1,
                fontWeight: 600,
                backgroundColor: `${colors.chip.lightBlue}`,
                color: `${colors.chip.Blue}`,
                fontSize: 11,
              }}
              label="PENDING"
            />

            <Chip
              size="small"
              sx={{
                mr: 1,
                fontWeight: 600,
                backgroundColor: `${colors.chip.lightGray}`,
                color: `${colors.chip.gray}`,
                fontSize: 11,
              }}
              label="NOT STARTED"
            />

            <Chip
              size="small"
              sx={{
                mr: 1,
                fontWeight: 600,
                backgroundColor: `${colors.chip.lightYellow}`,
                color: `${colors.chip.yellow}`,
                fontSize: 11,
              }}
              label="IN PROGRESS"
            />

            <Chip
              size="small"
              sx={{
                mr: 1,
                fontWeight: 600,
                backgroundColor: `${colors.chip.lightGreen}`,
                color: `${colors.chip.green}`,
                fontSize: 11,
              }}
              label="DONE"
            />

            <Chip
              size="small"
              sx={{
                mr: 1,
                fontWeight: 600,
                backgroundColor: `${colors.chip.lightRed}`,
                color: `${colors.chip.red}`,
                fontSize: 11,
              }}
              label="DECLINED"
            />
          </Typography>
        </TableContainer>
      </Box>
    </>
  );
};

export default Calendar;
