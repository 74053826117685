import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AddToDo from '../AddToDo/AddToDo';
import AddEmployee from '../Dialog/AddEmployee';
import AddJob from '../Dialog/AddJob';

export default function AddToDoDrawer({ handleAddToDo, open, getTasks }) {

  // toggle dialogs
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [openJobDialog, setOpenJobDialog] = useState(false);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleAddToDo}
    >
      <Box
        sx={{ width: 300, pt: 10 }}
        role="presentation"
      >
        {/* Employee Dialog */}
        <AddEmployee open={openEmployeeDialog} handleClose={() => setOpenEmployeeDialog(false)} />

        {/* Job Dialog */}
        <AddJob open={openJobDialog} handleClose={() => setOpenJobDialog(false)} />


        <AddToDo
          handleJobDialog={setOpenJobDialog}
          jobDialog={openJobDialog}
          handleEmployeeDialog={setOpenEmployeeDialog}
          employeeDialog={openEmployeeDialog}
          handleAddToDo={handleAddToDo}
          getTasks={getTasks}
        />

      </Box>
    </Drawer>
  );
}
