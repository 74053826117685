import React, { useState } from "react";
import { LoadingBtn, PrimaryButton } from "../UI/Buttons";
import {
  Box,
  IconButton,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { makeStyles } from "@mui/styles";
import FieldLabel from "../UI/FieldLabel";
import { LoadingButton } from "@mui/lab";
import colors from "app/style/colors";
import { Service } from "app/config/service";
import { SuccessToaster } from "../UI/Toaster";
import { useForm } from "react-hook-form";
const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
});

export default function AddCategory({ open, handleClose,jobCategory }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [name, setName] = useState("");
 

  
  const createNewJobCategory = async (e) => {
    e.preventDefault();
    try {
      const obj = {
        name: name,
        slug: uuidv4()
      };
      const { status, responseCode } = await Service.createJobCategory(obj);
      if (status === true && responseCode === 200) {
        handleClose();
        SuccessToaster("Category Added");
        jobCategory();
      }
    } catch (error) {
      console.log("file: AddJob.js => line 93 => createNewJob => error", error);
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Add a job category
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <MdClose size={24} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography
          component="form"
          onSubmit={createNewJobCategory}
        >
          <FormControl variant="standard" fullWidth>
            <FieldLabel>Category Name*</FieldLabel>
            <TextField
              hiddenLabel
              placeholder="Enter job category name"
              variant="outlined"
              className={classes.outlineInputBorder}
              size="small"
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormControl>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <PrimaryButton
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </PrimaryButton>
            &nbsp;
            <LoadingButton
              sx={{
                my: 2,
                py: 1,
                borderRadius: "8px",
                textTransform: "capitalize",
                boxShadow: "none",
                bgcolor: colors.darkBlue,
                ":hover": {
                  boxShadow: "none",
                  bgcolor: colors.skyBlue,
                },
              }}
              loading={loading}
              type="submit"
              variant="contained"
              style={{ px: 6 }}
              disabled={name === ""}
            >
              Add Job Category
            </LoadingButton>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
