import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  FaCheckCircle,
  FaFileMedical,
  FaPlusCircle,
  FaRegCalendarAlt,
  FaRegSmile,
  FaTimesCircle,
} from "react-icons/fa";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import colors from "app/style/colors";
import { Service } from "app/config/service";
import { getDate } from "app/utils";
import enLocale from "date-fns/locale/en-GB";
// import components
import FieldLabel from "../UI/FieldLabel";
import { LoadingBtn, PrimaryButton } from "../UI/Buttons";
import { ErrorToaster, SuccessToaster } from "../UI/Toaster";
import Menudropdown from "../Dropdown/Menudropdown";
import { ChipDelete, ChipSolid, ChipStatus, ChipTag } from "../UI/Chip";
import { MdImage, MdLink } from "react-icons/md";
import { BsPaperclip, BsThreeDots, BsTrash } from "react-icons/bs";
import Picker from "emoji-picker-react";
import { Error } from "../UI/Error";
import CloseIcon from "@mui/icons-material/Close";
import Images from "assets/images";
import { LocalizationProvider } from "@mui/x-date-pickers";
const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});

function AddToDo({
  handleJobDialog,
  jobDialog,
  handleEmployeeDialog,
  employeeDialog,
  handleAddToDo,
  setTasks,
  getTasks,
}) {
  const DATE_SIZE = 28;
  const dateAdd = new Date();

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);

  const open = Boolean(anchorEl); // for emoji comment box
  const openDate = Boolean(anchorElDate); // for duedate box

  // states for holding data
  const [selectedJobName, setSelectedJobName] = useState("none");
  const [selectedEmployee, setSelectedEmployee] = useState("none");
  const [selectedPriority, setSelectedPriority] = useState("none");
  const [selectedStatus, setSelectedStatus] = useState("none");
  const [selectedTags, setSelectedTags] = useState([]);

  const [comment, setComment] = useState("");
  const [Addedcomments, setAddedComments] = useState([]);
  const [commentPosition, setCommentPosition] = useState();
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [selectedDate, setSelectedDate] = useState([]);
  const [openIndex, setOpenIndex] = useState(-1);
  // state for holding subtask length
  const [dateInputs, setDateInputs] = useState(2);
  const [subtaskLength, setSubtaskLength] = useState(1);

  const [jobs, setJobs] = useState([]);
  const [tags, setTags] = useState([]);
  const [employees, setEmployees] = useState([]);

  const tagInput = useRef();
  const addTag = async () => {
    let obj = {
      title: tagInput.current.value,
    };
    const { status, responseCode, data } = await Service.addTag(obj);
    if (status === true && responseCode === 200) {
      getTags();
      setAnchorEl(null);
      tagInput.current.value = "";
    }
  };

  // state for handling menu popover
  const [coordinate, setCoordinate] = useState({
    x: "",
    y: "",
  });

  // customize tag dropdown
  const menuProps = {
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };

  // function for handling main date
  const handleDateClick = (event, index) => {
    setAnchorElDate(event.currentTarget);
    setCoordinate({
      x: event.clientX,
      y: event.pageY,
    });
    setOpenIndex(index);
  };

  // handle main due date
  const changeDate = (newValue) => {
    let allDate = [...selectedDate];
    allDate[openIndex] = newValue;
    setSelectedDate(allDate);
  };

  // get data from api
  const getJobs = async () => {
    try {
      const { status, responseCode, data } = await Service.getJobs();
      if (status === true && responseCode === 200) {
        setJobs(data?.jobs);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };
  const getTags = async () => {
    try {
      const { status, responseCode, data } = await Service.getTags("");
      if (status === true && responseCode === 200) {
        setTags(data?.tags);
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };
  const getRegisteredEmployee = async () => {
    try {
      const { status, responseCode, data } =
        await Service.getRegisteredEmployee();
      if (status === true && responseCode === 200) {
        setEmployees(data?.users);
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };

  // handle tags add and delete
  const handleAddTag = (item) => {
    const sortedTags = [...selectedTags];
    sortedTags.push(item);
    setSelectedTags(sortedTags);
    const tagsAfterSelect = tags.filter((e) => e._id !== item._id);
    setTags(tagsAfterSelect);
  };

  const deleteTag = (item) => {
    const tagsAfterDelete = selectedTags.filter((e) => e._id !== item._id);
    setSelectedTags(tagsAfterDelete);
    const mainTags = [...tags];
    mainTags.push(item);
    setTags(mainTags);
  };

  // handle add another subtask
  const addAnotherSubTask = () => {
    setSubtaskLength(subtaskLength + 1);
    setDateInputs(dateInputs + 1);
  };

  // *For add todo
  const addToDo = async (userData) => {
    setLoading(true);

    let finalTags = [];
    let allSubtask = [];

    for (let index = 1; index <= subtaskLength; index++) {
      const element = {
        name: userData[`subTask${index}`],
        status: userData[`subTaskStatus${index}`],
        assigned_to: userData[`subTaskAssign${index}`],
        due_date: userData[`dueDate${index}`],
      };
      allSubtask.push(element);
    }

    try {
      let obj = {
        name: userData.todo,
        job: selectedJobName,
        assigned_to: selectedEmployee?._id,
        priority: selectedPriority,
        status: selectedStatus,
        due_date: userData.dueDate0,
        tags: selectedTags,
        subTasks: allSubtask,
        comments: Addedcomments,
      };
      const { status, responseCode, data } = await Service.createTask(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster("Todo Added");
        getTasks();
        handleAddToDo(false);
        setSelectedJobName("none");
        setSelectedEmployee("none");
        setSelectedPriority("none");
        setSelectedStatus("none");
        selectedTags([]);
        setTasks(data);
        reset();
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  // function for handling marketing menu
  const handleEmojiClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEmojiClose = () => {
    setAnchorEl(null);
  };
  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject?.emoji) {
      setAnchorEl(null);
      let text = [...comment];
      text.splice(commentPosition, 0, emojiObject?.emoji);
      setValue("taskComments", text.join(""));
      setComment(text.join(""));
    }
  };
  const hiddenImageInput = useRef(null);
  const handleImageClick = (event) => {
    hiddenImageInput.current.click();
  };

  const handleImage = async (e) => {
    try {
      let formData = new FormData();
      formData.append("document_type", "1");
      formData.append("document", e.target.files[0]);
      const { responseCode, status, message, data } = await Service.upload(
        formData
      );
      if (status === true && responseCode === 200) {
        let image = [...images];
        image.push(data);
        setImages(image);
        e.target.value = null;
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };
  const deleteCommentImage = (i) => {
    try {
      let image = [...images];
      image.splice(i, 1);
      setImages(image);
    } catch (error) {
      console.log(error);
    }
  };

  const hiddenFileInput = useRef(null);
  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = async (e) => {
    try {
      let formData = new FormData();
      formData.append("document_type", "2");
      formData.append("document", e.target.files[0]);
      const { responseCode, status, message, data } = await Service.upload(
        formData
      );
      if (status === true && responseCode === 200) {
        let filesName = [...files];
        filesName.push(data);
        setFiles(filesName);
        e.target.value = null;
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };

  const deleteCommentFile = (i) => {
    try {
      let filesName = [...files];
      filesName.splice(i, 1);
      setFiles(filesName);
    } catch (error) {
      console.log(error);
    }
  };
  const clearComment = () => {
    setComment("");
    setImages([]);
    setFiles([]);
  };
  const handleAddedComments = () => {
    let addComment = [...Addedcomments];
    addComment.push({
      content: comment,
      attachments: [...files, ...images],
      created_on: new Date(),
    });
    setAddedComments(addComment);
    clearComment();
  };
  const handleDeleteAddedComments = (index) => {
    let addComment = [...Addedcomments];
    addComment.splice(index, 1);
    setAddedComments(addComment);
    clearComment();
  };

  useEffect(() => {
    getJobs();
    getTags();
    getRegisteredEmployee();
  }, [jobDialog, employeeDialog]);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <Box
      sx={{
        bgcolor: colors.white,
        width: "100%",
        height: "93vh",
        overflow: "scroll",
        p: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: colors.textSecondary, fontWeight: "bold" }}
      >
        Add a to do
      </Typography>
      <Typography
        component="form"
        sx={{ my: 5 }}
        onSubmit={handleSubmit(addToDo)}
      >
        {/* To do */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>To do*</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Enter to do"
            variant="outlined"
            className={classes.outlineInputBorder}
            size="small"
            {...register("todo", {
              required: "To do is Required",
            })}
          />
          {errors.todo && <Error message={errors.todo.message} />}
        </FormControl>

        {/* Job Name */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>Job Name*</FieldLabel>
          <Grid
            container
            alignItems="center"
            className={classes.boxSelectField}
          >
            <Grid item xs={10}>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  PaperProps: {
                    sx: { ml: 2.5, maxHeight: "30vh", width: "30vh" },
                  },
                }}
                variant="standard"
                fullWidth
                defaultValue=""
                {...register("jobName", {
                  required: "Job Name is Required",
                })}
                onChange={(e) => {
                  setSelectedJobName(e.target.value);
                  clearErrors("jobName");
                }}
                sx={{
                  color: colors.secondaryLight,
                  "::before": {
                    display: "none",
                  },
                  "::after": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={"none"} disabled>
                  Select Job Name
                </MenuItem>
                {jobs.map((item) => (
                  <MenuItem key={uuidv4()} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={0.5}>
              <Divider orientation="vertical" sx={{ height: 20 }} />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleJobDialog(!jobDialog)}>
                <FaPlusCircle size={16} color="black" />
              </IconButton>
            </Grid>
          </Grid>
          {errors.jobName && <Error message={errors.jobName.message} />}
        </FormControl>

        {/* Assign to */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>Assign to*</FieldLabel>
          <Grid
            container
            alignItems="center"
            className={classes.boxSelectField}
          >
            <Grid item xs={2}>
              <Avatar
                alt=""
                src=""
                sx={{
                  width: "30px",
                  height: "30px",
                  bgcolor: colors.skyBlue,
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
              >
                {selectedEmployee.name?.split(" ").length > 1
                  ? selectedEmployee.name?.charAt(0) +
                    selectedEmployee.name?.split(" ")[1].charAt(0)
                  : selectedEmployee.name?.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item xs={8}>
              <Select
                variant="standard"
                fullWidth
                MenuProps={{
                  PaperProps: { sx: { maxHeight: "30vh", width: "30vh" } },
                }}
                defaultValue=""
                {...register("assignTo", {
                  required: "Assign To is Required",
                })}
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  clearErrors("assignTo");
                }}
                sx={{
                  color: colors.secondaryLight,
                  "::before": {
                    display: "none",
                  },
                  "::after": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={"none"} disabled>
                  Select employee
                </MenuItem>
                {employees.map((item) => (
                  <MenuItem key={uuidv4()} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={0.5}>
              <Divider orientation="vertical" sx={{ height: 20 }} />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleEmployeeDialog(!employeeDialog)}>
                <FaPlusCircle size={16} color="black" />
              </IconButton>
            </Grid>
          </Grid>
          {errors.assignTo && <Error message={errors.assignTo.message} />}
        </FormControl>

        {/* Priority */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>Priority*</FieldLabel>
          <Select
            variant="standard"
            fullWidth
            className={classes.boxSelectField}
            defaultValue=""
            {...register("priority", {
              required: "Priority is Required",
            })}
            onChange={(e) => {
              setSelectedPriority(e.target.value);
              clearErrors("priority");
            }}
            sx={{
              color: colors.secondaryLight,
              "::before": {
                display: "none",
              },
              "::after": {
                display: "none",
              },
            }}
          >
            <MenuItem value={"none"} disabled>
              {/* Select priority */}
              <ChipSolid label={"Select priority"} />
            </MenuItem>
            <MenuItem value={"High"}>
              <ChipSolid label={"High"} />
            </MenuItem>
            <MenuItem value={"Medium"}>
              <ChipSolid label={"Medium"} />
            </MenuItem>
            <MenuItem value={"Low"}>
              <ChipSolid label={"Low"} />
            </MenuItem>
          </Select>
          {errors.priority && <Error message={errors.priority.message} />}
        </FormControl>

        {/* Due Date */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>Due Date*</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Select date"
            variant="outlined"
            className={classes.outlineInputBorder}
            // error={errors?.dueDate?.message && (true)}
            onClick={(e) => handleDateClick(e, 0)}
            onKeyDown={(e) => e.preventDefault()}
            {...register(
              "dueDate0"
              // {
              //   required:"Due Date is Required"
              // }
            )}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      bgcolor: "#F3F7F9",
                      p: 1,
                      ml: -1.6,
                      height: 43,
                      width: 30,
                      borderRadius: "8px 0px 0px 8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaRegCalendarAlt color="gray" />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          {/*  {errors.dueDate0 && <Error message={errors.dueDate0.message} />}  */}
        </FormControl>

        {/* To do status */}
        <FormControl
          variant="standard"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <FieldLabel>To do status*</FieldLabel>
          <Select
            variant="standard"
            fullWidth
            className={classes.boxSelectField}
            defaultValue=""
            {...register("status", {
              required: "Status is Required",
            })}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              clearErrors("status");
            }}
            sx={{
              color: colors.secondaryLight,
              "::before": {
                display: "none",
              },
              "::after": {
                display: "none",
              },
            }}
          >
            <MenuItem value={"none"} disabled>
              <ChipStatus label="Select status" />
            </MenuItem>
            <MenuItem value={"Done"}>
              <ChipStatus label="Done" />
            </MenuItem>
            <MenuItem value={"In progress"}>
              <ChipStatus label="In progress" />
            </MenuItem>
            <MenuItem value={"Overdue"}>
              <ChipStatus label="Overdue" />
            </MenuItem>
            <MenuItem value={"Not started"}>
              <ChipStatus label="Not started" />
            </MenuItem>
          </Select>
          {errors.status && <Error message={errors.status.message} />}
        </FormControl>

        {/* dynamic subtask fields */}
        {[...Array(Number(subtaskLength))].map((item, index) => {
          return (
            <Fragment key={index}>
              {/* Sub-tasks */}
              <FormControl
                variant="standard"
                fullWidth
                style={{ marginBottom: "15px" }}
              >
                <FieldLabel>Sub-tasks</FieldLabel>
                <TextField
                  hiddenLabel
                  placeholder="Enter Sub-task"
                  variant="outlined"
                  className={classes.outlineInputBorder}
                  error={errors?.subTask?.message && true}
                  size="small"
                  {...register(
                    `subTask${index + 1}`
                    // {
                    //   required:
                    // }
                  )}
                />
              </FormControl>

              {/* Sub-task status */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task status</FieldLabel>
                <Controller
                  name={`subTaskStatus${index + 1}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      MenuProps={{
                        PaperProps: {
                          sx: { ml: 2.5, maxHeight: "30vh", width: "30vh" },
                        },
                      }}
                      label="Select User Type"
                      value={value ? value : "none"}
                      variant="standard"
                      fullWidth
                      defaultValue={"none"}
                      className={classes.boxSelectField}
                      sx={{
                        color: colors.secondaryLight,
                        "::before": {
                          display: "none",
                        },
                        "::after": {
                          display: "none",
                        },
                      }}
                      {...register(`subTaskStatus${index + 1}`, {
                        // required: 'User type is required',
                      })}
                      onChange={onChange}
                    >
                      <MenuItem value={"none"} disabled>
                        <ChipStatus label="Select status" />
                      </MenuItem>
                      <MenuItem value={"Done"}>
                        <ChipStatus label="Done" />
                      </MenuItem>
                      <MenuItem value={"In progress"}>
                        <ChipStatus label="In progress" />
                      </MenuItem>
                      <MenuItem value={"Overdue"}>
                        <ChipStatus label="Overdue" />
                      </MenuItem>
                      <MenuItem value={"Not started"}>
                        <ChipStatus label="Not started" />
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              {/* Sub-task assigned to */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task assigned to</FieldLabel>
                <Grid
                  container
                  alignItems="center"
                  className={classes.boxSelectField}
                >
                  <Grid item xs={2}>
                    <Avatar
                      alt=""
                      src=""
                      sx={{
                        width: "30px",
                        height: "30px",
                        bgcolor: colors.skyBlue,
                        fontSize: "12px",
                        textTransform: "uppercase",
                      }}
                    >
                      {/* Ab */}
                    </Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name={`subTaskAssign${index + 1}`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  ml: 2.5,
                                  maxHeight: "30vh",
                                  width: "30vh",
                                },
                              },
                            }}
                            variant="standard"
                            fullWidth
                            value={value ? value : "none"}
                            sx={{
                              color: colors.secondaryLight,
                              "::before": {
                                display: "none",
                              },
                              "::after": {
                                display: "none",
                              },
                            }}
                            {...register(`subTaskAssign${index + 1}`, {
                              // required: 'User type is required',
                            })}
                            onChange={onChange}
                          >
                            <MenuItem value={"none"} disabled>
                              Select employee
                            </MenuItem>
                            {employees.map((item) => (
                              <MenuItem key={uuidv4()} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Divider orientation="vertical" sx={{ height: 20 }} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => handleEmployeeDialog(!employeeDialog)}
                    >
                      <FaPlusCircle size={16} color="black" />
                    </IconButton>
                  </Grid>
                </Grid>
              </FormControl>

              {/* Sub-task due date */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task due date </FieldLabel>
                <TextField
                  hiddenLabel
                  placeholder="Select due date"
                  variant="outlined"
                  className={classes.outlineInputBorder}
                  error={errors?.dueDate?.message && true}
                  onClick={(e) => handleDateClick(e, index + 1)}
                  onKeyDown={(e) => e.preventDefault()}
                  {...register(
                    `dueDate${index + 1}`
                    // {
                    //   required:
                    // }
                  )}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            bgcolor: "#F3F7F9",
                            p: 1,
                            ml: -1.6,
                            height: 43,
                            width: 30,
                            borderRadius: "8px 0px 0px 8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FaRegCalendarAlt color="gray" />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Fragment>
          );
        })}

        {/* menu dropdown for due date */}
        {[...Array(Number(dateInputs))].map((item, index) => {
          return (
            <Menudropdown
              key={uuidv4()}
              open={openDate}
              anchorEl={anchorElDate}
              setAnchor={() => setAnchorElDate(null)}
              id={index}
              clickedItemId={openIndex}
              width={"auto"}
              coordinate={coordinate}
              transformOrigin={{
                vertical: -10,
                horizontal: 0,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                // adapterLocale={enLocale}
              >
                <Box
                  sx={{
                    "& > div": {
                      minWidth: 270,
                    },
                    "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                      {
                        width: 270,
                      },
                    "& .MuiTypography-caption": {
                      width: DATE_SIZE,
                      margin: 0,
                    },
                    "& .PrivatePickersSlideTransition-root": {
                      minHeight: DATE_SIZE * 6,
                    },
                    '& .PrivatePickersSlideTransition-root [role="row"]': {
                      margin: 0,
                    },
                    "& .MuiPickersDay-dayWithMargin": {
                      margin: 0,
                    },
                    "& .MuiPickersDay-root": {
                      width: DATE_SIZE,
                      height: DATE_SIZE,
                    },
                  }}
                >
                  <StaticDatePicker
                    value={selectedDate[index] ? selectedDate[index] : ""}
                    minDate={dateAdd.setDate(dateAdd.getDate() + 1)}
                    onChange={(e) => {
                      setValue(`dueDate${index}`, getDate(e));
                      setAnchorElDate(null);
                      changeDate(e);
                    }}
                    displayStaticWrapperAs="desktop"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Menudropdown>
          );
        })}

        <PrimaryButton
          onClick={addAnotherSubTask}
          sx={{
            my: 2,
            bgcolor: colors.grayShade,
            textTransform: "capitalize",
            fontWeight: 600,
          }}
        >
          <FaFileMedical style={{ margin: "0px 10px" }} />
          Add Another sub-task
        </PrimaryButton>

        {/* Tags (max 3) */}
        <FormControl variant="standard" fullWidth>
          <FieldLabel>Tags (max 3)</FieldLabel>
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {selectedTags.map((item) => (
              <ChipDelete
                key={item}
                onDelete={() => deleteTag(item)}
                item={item}
              />
            ))}
          </Box>

          {selectedTags.length < 3 && (
            <Grid
              container
              alignItems="center"
              className={classes.boxSelectField}
            >
              <Grid item xs={12}>
                <Select
                  variant="standard"
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      sx: { ml: 2.5, maxHeight: "30vh", width: "30vh" },
                    },
                  }}
                  // MenuProps={menuProps}
                  sx={{
                    color: colors.secondaryLight,
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <TextField
                      hiddenLabel
                      placeholder="Add Tag"
                      inputRef={tagInput}
                      variant="outlined"
                      className={classes.outlineInputBorder}
                      size="small"
                      sx={{ mb: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => (tagInput.current.value = "")}
                            >
                              <FaTimesCircle
                                size={16}
                                color={colors.grayShade}
                              />
                            </IconButton>
                            <IconButton onClick={addTag}>
                              <FaCheckCircle size={16} color={colors.skyBlue} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {tags.map((item) => (
                      <ChipTag
                        margin={1}
                        key={item}
                        item={item}
                        handleClick={() => handleAddTag(item)}
                      />
                    ))}
                  </Box>
                </Select>
              </Grid>

              {/* <Grid item xs={0.5}>
                <Divider orientation="vertical" sx={{ height: 20, }} />
              </Grid> */}
              {/* <Grid item xs={1}>
                <IconButton>
                  <FaPlusCircle size={16} color="black" />
                </IconButton>
              </Grid> */}
            </Grid>
          )}
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FieldLabel>Comment (optional)</FieldLabel>

          {Addedcomments.map((item, index) => (
            // item.content.length > 35 ?
            //   <Typography key={index} sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content.slice(0, 35)}......</Typography>
            //   : <Typography key={index} sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content}</Typography>
            <React.Fragment>
              <Box
                key={index}
                sx={{
                  backgroundColor: colors.darkgrayShade,
                  border: `1px solid ${colors.borderColor}`,
                  p: 1,
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Fragment>
                  <Box sx={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                      size="small"
                      sx={{ py: 0 }}
                      onClick={() => handleDeleteAddedComments(index)}
                    >
                      <BsTrash />
                    </IconButton>
                  </Box>
                  {/* Attachments */}
                  {item?.attachments.length > 0 && (
                    <Typography variant="body2" fontWeight="bold" mt={2}>
                      Attachments
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    {item?.attachments.map((attachment, i) => {
                      return (
                        <Box key={i}>
                          {attachment.document_type === "1" && (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${attachment?.document}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          )}

                          {attachment.document_type === "2" &&
                          attachment.document.slice(-4) === ".txt" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.doc}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-4) === ".doc" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.doc}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-5) === ".docx" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.doc}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-4) === ".pdf" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.pdf}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-4) === ".csv" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.excel}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-4) === ".xls" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.excel}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-5) === ".xlsx" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.excel}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-5) === ".pptx" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.ppt}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : attachment.document.slice(-5) === ".ppt" ? (
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link
                                href={`${attachment?.document}`}
                                target="_blank"
                              >
                                <img
                                  src={`${Images.ppt}`}
                                  width="60px"
                                  height={"60px"}
                                  alt="file"
                                  style={{ margin: "4px" }}
                                />
                              </Link>
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {attachment?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            // <Box sx={{mr:2,mb:2,width:"70px"}}>
                            // <Link href={`${attachment?.document}`} target="_blank">
                            //   <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                            // </Link>
                            // <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                            // {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                            // </Typography>
                            // </Box>
                            ""
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                  {item?.content && (
                    <>
                      <Divider />
                      {/* Comments text */}
                      <Typography variant="body2" color="initial" my={2}>
                        {item?.content}
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                    </>
                  )}
                </Fragment>
              </Box>
            </React.Fragment>
          ))}

          <Box sx={{ mt: 1 }} className={classes.boxSelectField}>
            <Grid container alignItems="center">
              <Grid item xs={1.5}>
                <IconButton onClick={handleEmojiClick}>
                  <FaRegSmile size={18} />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleEmojiClose}
                  transformOrigin={{
                    vertical: 50,
                    horizontal: 0,
                  }}
                  PaperProps={{
                    style: {
                      // width: 120,
                      borderRadius: "10px",
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Picker
                    disableSearchBar
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                  />
                </Menu>
              </Grid>
              <Grid item xs={1.5}>
                <IconButton onClick={handleImageClick}>
                  <MdImage size={20} />
                </IconButton>
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenImageInput}
                  onChange={handleImage}
                  style={{ display: "none" }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <IconButton onClick={handleFileClick}>
                  <BsPaperclip size={18} />
                </IconButton>
                <input
                  type="file"
                  accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  ref={hiddenFileInput}
                  onChange={handleFile}
                  style={{ display: "none" }}
                />
              </Grid>
              {/* <Grid item xs={1.5}>
                <IconButton>
                  <MdLink size={20} />
                </IconButton>
              </Grid> */}
            </Grid>

            <Divider />
            <Box sx={{ p: 1 }}>
              {images.length > 0 && (
                <>
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      Images:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {images.map((item, i) => (
                        <Box>
                          <IconButton
                            size="small"
                            sx={{
                              backgroundColor: "#ECECEC",
                              width: "20px",
                              height: "12px ",
                              position: "relative",
                              left: "50px",
                              top: 10,
                            }}
                            onClick={() => deleteCommentImage(i)}
                          >
                            <CloseIcon fontSize="10px" />
                          </IconButton>
                          <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                            <img
                              src={item?.document}
                              width="70px"
                              height="70px"
                              style={{ margin: "5px" }}
                              alt="file"
                            />
                            <Typography
                              noWrap
                              sx={{
                                textAlign: "center",
                                fontSize: "13px",
                                color: "#007AFF",
                              }}
                            >
                              {item?.document?.replace(
                                "https://consultya.mangotech-api.com/media/comments/",
                                ""
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {files.length > 0 && (
                <>
                  <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      Files:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {files.map((attachment, i) => {
                        return (
                          <Box sx={{ mr: 2, mb: 2 }}>
                            {attachment.document?.slice(-4) === ".txt" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.doc}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".txt", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-4) === ".doc" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.doc}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".doc", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-5) === ".docx" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.doc}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".docx", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-4) === ".pdf" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.pdf}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".pdf", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-4) === ".csv" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.excel}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".csv", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-5) === ".xlsx" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.excel}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".xlsx", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-4) === ".xls" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.excel}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "13px",
                                      color: "#007AFF",
                                    }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".xls", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-4) === ".ppt" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.ppt}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{ fontSize: "13px", color: "#007AFF" }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".ppt", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : attachment.document.slice(-5) === ".pptx" ? (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.ppt}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />

                                  <Typography
                                    noWrap
                                    sx={{ fontSize: "13px", color: "#007AFF" }}
                                  >
                                    {attachment?.document
                                      ?.replace(
                                        "https://consultya.mangotech-api.com/media/comments/",
                                        ""
                                      )
                                      .replace(".pptx", "")}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <IconButton
                                  size="small"
                                  sx={{
                                    backgroundColor: "#ECECEC",
                                    width: "20px",
                                    height: "12px ",
                                    position: "relative",
                                    left: "50px",
                                    top: 10,
                                  }}
                                  onClick={() => deleteCommentFile(i)}
                                >
                                  <CloseIcon fontSize="10px" />
                                </IconButton>
                                <Box sx={{ width: "70px" }}>
                                  <img
                                    src={`${Images.word}`}
                                    width="60px"
                                    height={"60px"}
                                    alt="file"
                                    style={{ margin: "4px" }}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
            </Box>
            <TextField
              hiddenLabel
              multiline
              placeholder="Add your comment..."
              variant="outlined"
              fullWidth
              value={comment}
              className={classes.outlineInputBorderNone}
              sx={{ minHeight: "100px" }}
              error={errors?.taskComments?.message && true}
              size="small"
              onClick={(e) => {
                setCommentPosition(e.target.selectionStart);
              }}
              onKeyPress={(e) => {
                setCommentPosition(e.target.selectionStart + 1);
              }}
              {...register("taskComments", {
                onChange: (e) => {
                  setComment(e.target.value);
                },
              })}
            />
            <Box sx={{ float: "right" }}>
              <IconButton onClick={clearComment}>
                <FaTimesCircle
                  size={16}
                  color={comment.length >= 1 ? colors.red : colors.redShade}
                />
              </IconButton>
              <IconButton onClick={handleAddedComments}>
                <FaCheckCircle size={16} color={colors.skyBlue} />
              </IconButton>
            </Box>
          </Box>
        </FormControl>

        <LoadingBtn
          fullWidth
          loading={loading}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingBtn>
      </Typography>
    </Box>
  );
}

export default AddToDo;
