import { Typography } from "@mui/material"
import colors from "app/style/colors";
import { AiFillWarning } from "react-icons/ai"

export const Error = (props) => {
  return (
    <Typography sx={{ fontSize: 12, mt: 1, color: colors.blackShade }}>
      <AiFillWarning color="red" style={{ margin: "0px 5px" }} />
      {props.message}
    </Typography>
  );
}