import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Service } from "app/config/service";
import colors from "app/style/colors";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import { FaPlusCircle } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { LoadingBtn } from "../UI/Buttons";
import { Error } from "../UI/Error";
import FieldLabel from "../UI/FieldLabel";
import { SuccessToaster } from "../UI/Toaster";

const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },

  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});

const AddJob = ({ setScheduleView ,setToggleTodos}) => {
  const classes = useStyles();
  // Form Handling
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  // Google Map
  const [showMap, setShowMap] = useState(true);
  const [libraries] = useState(["places"]);
  const [autoComplete, setAutoComplete] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 25.193,
    longitude: 67.155,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro",
    libraries: libraries,
  });
  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const changeLocation = (val) => {
    const latitude = val.lat();
    const longitude = val.lng();
    setCurrentLocation({ latitude, longitude });
  };

  const center = {
    lat: currentLocation.latitude,
    lng: currentLocation.longitude,
  };

  const containerStyle = {
    width: "100%",
    height: "200px",
  };
  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const test = autoComplete.getPlace();
      changeLocation(test.geometry.location);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const addJob = async (formData) => {
    try {
      let obj = {
        budget: formData.budget,
        // category: formData.category,
        client_name: formData.client_name,
        location: formData.location,
        details: formData.details,
        name: formData.name,
        status: "pending",
        latitude: Number(currentLocation.latitude.toFixed(3)),
        longitude: Number(currentLocation.longitude.toFixed(3)),
      };
      const { status, responseCode, data } = await Service.createJobs(obj);
      if (status === true && responseCode === 200) {
        reset();
        SuccessToaster("Job Added");
        setToggleTodos(false);
        setScheduleView(1);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddJobDrawer.js ~ line 166 ~ addJob ~ error",
        error
      );
    }
  };

  return (
    <Box
      sx={{
        bgcolor: colors.white,
        width: "100%",
        height: "93vh",
        overflow: "scroll",
        py: 2,
        px: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: colors.textSecondary, fontWeight: "bold" }}
      >
        Add a job
      </Typography>
      <Box component="form" sx={{ my: 3 }} onSubmit={handleSubmit(addJob)}>
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FieldLabel>Job Name*</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Enter Job name"
            variant="outlined"
            className={classes.outlineInputBorder}
            size="small"
            {...register("name", {
              required: "Job Name is Required",
            })}
          />
          {errors.name && <Error message={errors.name.message} />}
        </FormControl>
        {/* Job Description */}
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FieldLabel>Job Description</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Enter job description"
            variant="outlined"
            className={classes.outlineInputBorder}
            size="small"
            {...register("details")}
          />
          {errors.details && <Error message={errors.details.message} />}
        </FormControl>

        {/* Client Name */}
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FieldLabel>Client Name</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Enter Client Name"
            variant="outlined"
            className={classes.outlineInputBorder}
            size="small"
            {...register("client_name")}
          />
          {errors.client_name && <Error message={errors.client_name.message} />}
        </FormControl>

        {/* Job Budget */}
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FieldLabel>Job Budget</FieldLabel>
          <TextField
            hiddenLabel
            placeholder="Enter Job Budget"
            variant="outlined"
            className={classes.outlineInputBorder}
            size="small"
            {...register("budget")}
          />
          {errors.clientName && <Error message={errors.clientName.message} />}
        </FormControl>
        {/* Job Status */}
        <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
          <FieldLabel>Job Status</FieldLabel>
          <TextField
            disabled
            hiddenLabel
            placeholder="Enter Job Status"
            variant="outlined"
            value="Pending"
            className={classes.outlineInputBorder}
            size="small"
            {...register("status")}
          />
          {errors.status && <Error message={errors.status.message} />}
        </FormControl>

        {/* Job Location */}
        {isLoaded && (
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <FormControl variant="standard" fullWidth>
              <FieldLabel>Job Location</FieldLabel>
              <TextField
                hiddenLabel
                placeholder="Search location"
                variant="outlined"
                className={classes.outlineInputBorder}
                error={errors?.location?.message && true}
                size="small"
                {...register(
                  "location"
                  // {
                  //   required: "location is required."
                  // }
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          bgcolor: "#F3F7F9",
                          p: 1,
                          ml: -1.6,
                          height: 43,
                          width: 30,
                          borderRadius: "8px 0px 0px 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MdLocationOn />
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Typography
                      onClick={() => setShowMap(!showMap)}
                      variant="body2"
                      sx={{
                        fontSize: 12,
                        width: "20%",
                        cursor: "pointer",
                        color: colors.skyBlue,
                      }}
                    >
                      Hide map
                    </Typography>
                  ),
                }}
              />
              {errors?.location?.message && (
                <Error message={errors?.location?.message} />
              )}
            </FormControl>
          </Autocomplete>
        )}

        {showMap
          ? isLoaded && (
              <Box mt={1.5}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                >
                  <Marker position={center} />
                </GoogleMap>
              </Box>
            )
          : null}
        <LoadingBtn
          fullWidth
          loading={loading}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingBtn>
      </Box>
    </Box>
  );
};

export default AddJob;
