import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import colors from 'app/style/colors'
import React from 'react'

export function PrimaryButton(props) {
  return (
    <Button
      {...props}
    >
      {props.children}
    </Button>
  )
}

export function LoadingBtn(props) {

  return (
    <LoadingButton
      sx={{
        my: 2,
        py: 1,
        borderRadius: "8px",
        textTransform: "capitalize",
        boxShadow: "none",
        bgcolor: colors.darkBlue,
        ":hover": {
          boxShadow: "none",
          bgcolor: colors.skyBlue,
        },
        ...props.style
      }}
      {...props}
    >
      {props.children}
    </LoadingButton>
  )
}

