import React from 'react'
import { Chip, Typography } from '@mui/material'

import colors from 'app/style/colors'
import { MdAlarm } from 'react-icons/md'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'



const useStyles = makeStyles({
    yellowChip: {
        color: `${colors.yellow} !important`,
        border: `1px solid ${colors.yellow} !important`,
        backgroundColor: `${colors.yellowShade} !important`
      },
      greenChip: {
        color: `${colors.green} !important`,
        border: `1px solid ${colors.green} !important`,
        backgroundColor: `${colors.greenShade} !important`
      },
      grayChip: {
        color: `${colors.darkGray} !important`,
        border: `1px solid ${colors.darkGray} !important`,
        backgroundColor: `${colors.darkgrayShade} !important`
      },
      redChip: {
        color: `${colors.red} !important`,
        border: `1px solid ${colors.red} !important`,
        backgroundColor: `${colors.redShade} !important`
      },
      priorityChip: {
        display: "block !important",
        color: `${colors.white} !important`,
        border: "none",
        lineHeight: "25px",
        textAlign: "center",
      },
      purpleBox: {
        backgroundColor: `${colors.purple} !important`
      },
      greenBox: {
        backgroundColor: `${colors.green} !important`
      },
      yellowBox: {
        backgroundColor: `${colors.yellow} !important`
      },
})

function ChipDelete({item,onDelete}) {
  return (
    <Chip onDelete={onDelete}
              sx={{
                width: "fit-content",
                m: 0.4,
                color: colors.skyBlue,
                border: `1px solid ${colors.skyBlue}`,
                '& .MuiChip-deleteIcon': {
                  color: colors.skyBlue,
                },
                '& .MuiChip-deleteIcon:hover': {
                  color: colors.primary,
                },
              }}
              size="small"
              label={`#${item.title}`}
              variant="outlined" />
  )
}

function ChipTag({item,handleClick,margin}) {
  return (
    <Chip 
    onClick={handleClick}
              sx={{
                overflow:'hidden',
                display:"block",
                width: "fit-content",
                color: colors.skyBlue,
                mt:margin,
                border: `1px solid ${colors.skyBlue}`,
                '& .MuiChip-deleteIcon': {
                  color: colors.skyBlue,
                },
                '& .MuiChip-deleteIcon:hover': {
                  color: colors.primary,
                },
              }}
              size="small"
              label={`#${item?.title}`}
              variant="outlined" />
  )
}

function ChipStatus({label,handleClick,margin}) {
  return (
    <Chip
    onClick={handleClick}
    sx={{
      color: label === "Done" ? colors.green :label === "In progress" ? colors.yellow : label === "Overdue" ? colors.red : colors.darkGray,
      bgcolor: label === "Done" ? colors.greenShade :label === "In progress" ? colors.yellowShade : label === "Overdue" ? colors.redShade : colors.darkgrayShade,
      display: "block", 
      border: "none",
      lineHeight: "25px",
      textAlign: "center",
      cursor: "pointer",
      mt:margin
    }}
    size="small" label={label}
  />
  )
}

function ChipSolid({label,handleClick,margin}) {
    const classes = useStyles()
  return (
    <Chip
    onClick={handleClick}
    sx={{mt:margin}}
    className={clsx(classes.priorityChip,
        label == "High" &&   classes.purpleBox,
        label == "Medium" &&   classes.greenBox,
        label == "Low" &&   classes.yellowBox,
        )}
    size="small" label={label}
  />
  )
}

function ChipStatusOutline({item,handleClick}) {

    const classes = useStyles()
  return (
    <Chip 
    onClick={handleClick}
    sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: 120,
        textAlign: "center",
      }}
        className={item.status === "In progress" ? classes.yellowChip :
          item.status === "Overdue" ? classes.redChip :
            item.status === "Done" ? classes.greenChip : classes.grayChip}
        size="small"
        label={
          <span 
            style={{ display: "inline-flex", alignItems: "center", fontWeight: "bold", fontSize: "12px" }}>
            {item.status === "In progress" && (
              <Typography variant="body2" sx={{ fontSize: "12px", display: "flex", alignItems: "center" }}>
                In Progress &nbsp;<MdAlarm />
              </Typography>
            )}
            {item.status === "Overdue" && (
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                overdue
              </Typography>
            )}
            {item.status === "Done" && (
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Done
              </Typography>
            )}
            {item.status === "Not started" && (
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Not started
              </Typography>
            )}

          </span>}

      />
  )
}

export {ChipDelete,ChipStatusOutline,ChipStatus,ChipSolid,ChipTag}