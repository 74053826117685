import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

import colors from "app/style/colors";
import { makeStyles } from "@mui/styles";
import { FaCheckCircle } from "react-icons/fa";
import { PrimaryButton } from "../UI/Buttons";
import { Service } from "app/config/service";
import { ChipSolid, ChipStatus, ChipTag } from "../UI/Chip";
import moment from "moment";
import { el } from "date-fns/locale";
import {
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Menudropdown from "../Dropdown/Menudropdown";
import { v4 as uuidv4 } from "uuid";
import { DateRangePicker, DateRange } from "react-date-range";

const useStyles = makeStyles({
  itemHeading: {
    fontSize: "14px !important",
    color: `${colors.textSecondary} !important`,
    fontWeight: "bold !important",
  },
  itemText: {
    fontSize: "14px !important",
    color: `${colors.darkGray} !important`,
  },
  filterBoxes: {
    height: 165,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "1px",
    },
    "&:hover": {
      "&::-webkit-scrollbar": {
        width: "2.5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B3B6BE",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#E5E6EB",
      },
    },
  },
});

const dueDate = ["Next 7 days", "Next 30 days", "Next 90 days", "Custom"];

const dateCreated = ["Last 7 days", "Last 30 days", "Last 90 days", "Custom"];

const status = ["In progress", "Done", "Overdue", "Not started"];

const priority = ["High", "Medium", "Low"];

function Filters({ setShowFilter, getTasks }) {
  const classes = useStyles();
  const DATE_SIZE = 28;
  const [openIndex, setOpenIndex] = useState(-1);
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [tags, setTags] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [customDueDate, setCustomDueDate] = useState(Date.now());
  const [customCreateDate, setCustomCreateDate] = useState(Date.now());

  const [anchorElDate, setAnchorElDate] = useState(null);
  const openDate = Boolean(anchorElDate); // for duedate box

  const [anchorElDateCreated, setAnchorElDateCreated] = useState(null);
  const openCreatedDate = Boolean(anchorElDateCreated); // for created box

  const [customDueDateChecker, setCustomDueDateChecker] = useState(true);

  const [dueDateRange, setDueDateRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const [createdDateRange, setCreatedDateRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const [selectedFilters, setSelectedFilters] = useState({
    jobs: [],
    assigned_to: [],
    status: [],
    tags: [],
    priority: [],
    dueDate: "",
    dueDateChecker: "",
    createdDate: "",
    createdDateChecker: "",
    dueDatePop: "block",
    createdDatePop: "block",
  });
  // handle filters
  const handleFilter = (name, item) => {
    let spreadFilters = [...selectedFilters[name]];
    let getIndex;
    if (item._id) {
      getIndex = spreadFilters.findIndex((e) => e === item._id);
    } else {
      getIndex = spreadFilters.findIndex((e) => e === item);
    }

    if (getIndex === -1) {
      if (name !== "dueDate" && name !== "createdDate") {
        spreadFilters.push(item._id ? item._id : item);
      } else {
        spreadFilters = item;
      }

      setSelectedFilters({ ...selectedFilters, [name]: spreadFilters });
    } else {
      let removeItem;
      if (item._id) {
        removeItem = spreadFilters.filter((e) => e !== item._id);
      } else {
        removeItem = spreadFilters.filter((e) => e !== item);
      }
      setSelectedFilters({ ...selectedFilters, [name]: removeItem });
    }
  };

  const applyFilter = () => {
    let jobs = "";
    let assigned = "";
    let status = "";
    let tags = "";
    let priority = "";
    let dueDate = "";
    let createdDate = "";
    let customDueDateStart = "";
    let customDueDateEnd = "";
    let customCreatedStartDate = "";
    let customCreatedEndDate = "";
    selectedFilters.jobs.map((item, index) => {
      jobs += `jobs[${index}]=${item}&`;
    });
    selectedFilters.assigned_to.map((item, index) => {
      assigned += `assigned_to[${index}]=${item}&`;
    });
    selectedFilters.status.map((item, index) => {
      status += `status[${index}]=${item}&`;
    });
    selectedFilters.tags.map((item, index) => {
      tags += `tags[${index}]=${item}&`;
    });
    selectedFilters.priority.map((item, index) => {
      priority += `priority[${index}]=${item}&`;
    });
    dueDate = `due_date=${selectedFilters.dueDate}&`;
    createdDate = `created_date=${selectedFilters.createdDate}&`;
    customDueDateStart = `customDueDateStart=${dueDateRange[0].startDate}&`
    customDueDateEnd = `customDueDateEnd=${dueDateRange[0].endDate}&`
    customCreatedStartDate = `customCreatedStartDate=${createdDateRange[0].startDate}&`
    customCreatedEndDate = `customCreatedEndDate=${createdDateRange[0].endDate}&`
    getTasks(
      jobs +
      assigned +
      status +
      tags +
      priority +
      dueDate +
      createdDate +
      customDueDateStart +
      customDueDateEnd +
      customCreatedStartDate +
      customCreatedEndDate
    );
    setShowFilter();
  };

  const clearFilter = () => {
    getTasks();
    setSelectedFilters({
      jobs: [],
      assigned_to: [],
      status: [],
      tags: [],
      priority: [],
      dueDate: "",
      createdDate: "",
      dueDateChecker: "",
      createdDateChecker: "",
    });
    setDueDateRange(() => [{ startDate: "", endDate: "" }]);
    setCustomCreateDate(() => [{ startDate: "", endDate: "" }]);
    setShowFilter();
  };
  const daysFilter = (name, item) => {
    let intStr = parseInt(item.replace(/[A-Za-z$-]/g, ""));
    if (name === "dueDate") {
      if (selectedFilters.dueDateChecker !== item) {
        let date = moment()
          .startOf(Date.now())
          .add(intStr, "days")
          .format("YYYY-MM-DD");
        selectedFilters.dueDateChecker = item;
        handleFilter(name, date);
      } else {
        selectedFilters.dueDateChecker = "";
        handleFilter(name, "");
      }
    } else if (name === "createdDate") {
      if (selectedFilters.createdDateChecker !== item) {
        let date = moment()
          .startOf(Date.now())
          .add(-intStr, "days")
          .format("YYYY-MM-DD");
        selectedFilters.createdDateChecker = item;
        handleFilter(name, date);
      } else {
        selectedFilters.createdDateChecker = "";
        handleFilter(name, "");
      }
    }
  };

  const getFilterTask = async () => {
    try {
      const { status, responseCode, data } = await Service.getFilterTask();
      if (status === true && responseCode === 200) {
        setJobs(data.jobs);
        setTags(data.tags);
        setAssignedTo(data.assigned_to);
      }
    } catch (error) {
      console.log("file: Filters.js => line 54 => getTasks => error", error);
    }
  };

  const handleCustomDueDate = (newValue) => {
    setAnchorElDate(null);
    setCustomDueDate(newValue);
    let date = moment(newValue).format("YYYY-MM-DD");
    handleFilter("dueDate", date);
  };
  const handleCustomCreateDate = (newValue) => {
    setCustomCreateDate(newValue);
    let date = moment(newValue).format("YYYY-MM-DD");
    handleFilter("createdDate", date);
  };

  // state for handling menu popover
  const [coordinate, setCoordinate] = useState({
    x: "",
    y: "",
  });

  useEffect(() => {
    getFilterTask();
  }, [getTasks]);

  return (
    <Box
      sx={{
        boxShadow: "0px 5px 25px rgba(112, 115, 114, 0.4)",
        p: 1.5,
        borderRadius: "8px",
        mb: 2,
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          color: colors.textSecondary,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Filters
        <IconButton onClick={setShowFilter}>
          <MdClose size={24} />
        </IconButton>
      </Box>

      <Grid container sx={{ height: 220 }} columnSpacing={3}>
        {/* Job Name */}
        <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>
            Job Name
          </Typography>
          <Box className={classes.filterBoxes}>
            {jobs.map((item) => (
              <Box
                onClick={() => handleFilter("jobs", item)}
                key={item._id}
                sx={{
                  my: 0.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body2" noWrap>
                  {item.name}
                </Typography>
                {selectedFilters.jobs?.map(
                  (jobItem) =>
                    jobItem === item._id && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Assigned to */}
        <Grid item xs={2}>
          <Typography variant="body1" className={classes.itemHeading}>
            Assigned to
          </Typography>
          <Box className={classes.filterBoxes}>
            {assignedTo.map((item) => (
              <Box
                key={item._id}
                onClick={() => handleFilter("assigned_to", item)}
                sx={{
                  my: 0.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <Box variant="body2" sx={{ fontSize: "12px" }}>
                  <Avatar
                    alt=""
                    src=""
                    sx={{
                      display: "inline-flex",
                      mr: 1,
                      width: "21px",
                      height: "21px",
                      bgcolor: item.colorCode,
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.name?.split(" ").length > 1
                      ? item.name?.charAt(0) +
                      item.name?.split(" ")[1].charAt(0)
                      : item.name?.charAt(0)}
                  </Avatar>
                  {item.name}
                </Box>
                {selectedFilters.assigned_to?.map(
                  (jobItem) =>
                    jobItem === item._id && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Status */}
        <Grid item xs={1.7}>
          <Typography variant="body1" className={classes.itemHeading}>
            Status
          </Typography>
          <Box className={classes.filterBoxes}>
            {status.map((item, index) => (
              <Box
                key={item + index}
                onClick={() => handleFilter("status", item)}
                sx={{
                  my: 0.5,
                  px: 1,
                  py: 0.7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <ChipStatus label={item} />
                {selectedFilters.status?.map(
                  (jobItem) =>
                    jobItem === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Due date */}
        <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>
            Due date
          </Typography>
          <Box className={classes.filterBoxes}>
            {dueDate.map((item) =>
              item === "Custom" ? (
                <Box
                  onClick={(event) => {
                    setAnchorElDate(event.currentTarget);
                  }}
                  sx={{
                    my: 0.5,
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: `1px solid ${colors.borderColor}`,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="body2">
                    {anchorElDate
                      ? `${moment(dueDateRange.startDate).format(
                        "DD MMM YYYY"
                      )}-${moment(dueDateRange.endDate).format(
                        "DD MMM YYYY"
                      )}`
                      : "Custom"}
                  </Typography>
                </Box>
              ) : (
                <React.Fragment key={item}>
                  <Box
                    onClick={() => daysFilter("dueDate", item)}
                    sx={{
                      my: 0.5,
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: `1px solid ${colors.borderColor}`,
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="body2">{item}</Typography>
                    {selectedFilters.dueDateChecker === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )}
                  </Box>
                </React.Fragment>
              )
            )}
          </Box>
          <Menu
            anchorEl={anchorElDate}
            open={openDate}
            onClose={() => setAnchorElDate(null)}
          >
            <Box sx={{ position: "relative", zIndex: "99" }}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDueDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dueDateRange}
              />
              <Box
                sx={{ textAlign: "right", position: "absoulute", zIndex: "99" }}
              >
                <Button onClick={() => setAnchorElDate(null)}>Done</Button>
              </Box>
            </Box>
          </Menu>
        </Grid>

        {/* Date created */}
        <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>
            Date created
          </Typography>
          <Box className={classes.filterBoxes}>
            {dateCreated.map((item) =>
              item === "Custom" ? (
                <Box
                  onClick={(event) => {
                    setAnchorElDateCreated(event.currentTarget);
                  }}
                  sx={{
                    my: 0.5,
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: `1px solid ${colors.borderColor}`,
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="body2">
                    {anchorElDateCreated
                      ? `${moment(dueDateRange.startDate).format(
                        "DD MMM YYYY"
                      )}-${moment(dueDateRange.endDate).format(
                        "DD MMM YYYY"
                      )}`
                      : "Custom"}
                  </Typography>
                </Box>
              ) : (
                <React.Fragment key={item}>
                  <Box
                    onClick={() => daysFilter("createdDate", item)}
                    sx={{
                      my: 0.5,
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: `1px solid ${colors.borderColor}`,
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="body2">{item}</Typography>
                    {selectedFilters.createdDateChecker === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )}
                  </Box>
                </React.Fragment>
              )
            )}
          </Box>
          <Menu
            anchorEl={anchorElDateCreated}
            open={openCreatedDate}
            onClose={() => setAnchorElDateCreated(null)}
          >
            <Box sx={{ position: "relative", zIndex: "99" }}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setCreatedDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={createdDateRange}
              />
              <Box
                sx={{ textAlign: "right", position: "absoulute", zIndex: "99" }}
              >
                <Button onClick={() => setAnchorElDateCreated(null)}>
                  Done
                </Button>
              </Box>
            </Box>
          </Menu>
        </Grid>

        {/* Tags */}
        <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>
            Tags
          </Typography>
          <Box className={classes.filterBoxes}>
            {tags.map((item) => (
              <Box
                onClick={() => handleFilter("tags", item)}
                key={item._id}
                sx={{
                  my: 0.5,
                  px: 1,
                  py: 0.7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <ChipTag item={item} />

                {selectedFilters.tags?.map(
                  (jobItem) =>
                    jobItem === item._id && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Priority */}
        <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>
            Priority
          </Typography>
          <Box className={classes.filterBoxes}>
            {priority.map((item, index) => (
              <Box
                onClick={() => handleFilter("priority", item)}
                key={item + index}
                sx={{
                  my: 0.5,
                  px: 1,
                  py: 0.7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <ChipSolid label={item} />
                {selectedFilters.priority?.map(
                  (jobItem) =>
                    jobItem === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right" }}>
        <Typography
          onClick={clearFilter}
          variant="body2"
          color="initial"
          sx={{
            display: "inline",
            mr: 2,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Clear All
        </Typography>

        <PrimaryButton
          onClick={applyFilter}
          className="customBtnFilter"
          sx={{
            px: 3,
            border: `1px solid ${colors.darkBlue}`,
            bgcolor: colors.darkBlue,
            mr: 1,
            textTransform: "capitalize",
            color: colors.white,
            ":hover": {
              boxShadow: "none",
              bgcolor: colors.skyBlue,
            },
          }}
        >
          Show results
        </PrimaryButton>
      </Box>
    </Box>
  );
}

export default Filters;
