import React from 'react'
import { Typography } from '@mui/material'

import colors from 'app/style/colors'

function FieldLabel({ children }) {
  return (
    <Typography variant="body2" sx={{ color: colors.textSecondary, fontWeight: 600, py: 1 }}>{children}</Typography>
  )
}

export default FieldLabel