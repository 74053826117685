import React, { useState } from 'react'
import { Box, Container, FormControl, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from "@mui/styles";
import { ErrorToaster } from 'app/Components/UI/Toaster'


import useAuth from 'app/hooks/useAuth'
import { emailRegex } from 'app/utils';

// *import Components
import { LoadingBtn, PrimaryButton } from 'app/Components/UI/Buttons';
import { Error } from 'app/Components/UI/Error';
import { useNavigate } from 'react-router-dom';
import { Wave } from 'assets/images';
import { Service } from 'app/config/service';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  outlineInputBorder: {

    "& * > fieldset": {
      borderRadius: "10px"
    },
  },
})

function Register() {

  const classes = useStyles()

  const { signIn } = useAuth()

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, } = useForm();

  const [loading, setLoading] = useState(false);

  // *For User SignIn
  const userSignUp = async (userData) => {
    setLoading(true)
    try {
      let obj = {
        name: userData.name,
        password: userData.password,
        email: userData.email,
        phone: userData.phone,
        company: userData.companyName,
        role:"A"
      }
      const { status, responseCode, data } = await Service.register(obj)
      if (status === true && responseCode === 200) {
        toast.success('Register Successfully!', {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        signIn(data.token, data.name)
      }
      console.log('file: Register.js => line 42 => userSignUp => data', data);
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };


  return (

    <Container maxWidth="xxl" sx={{ height: "100vh", position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>

      <Box sx={{
        p: 4,
        my: 8,
        borderRadius: "10px",
        width: "40%",
        boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
      }}>
        <Typography sx={{ fontSize: "30px", fontWeight: "bold", my: 1, textAlign: "center" }}>Sign Up</Typography>
        <Typography sx={{ textAlign: "center" }} >Fill in the fields below to sign up.</Typography>
        <Typography component='form' sx={{ my: 4 }} onSubmit={handleSubmit(userSignUp)}>

          <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }} >
            <TextField
              hiddenLabel
              placeholder='Name'
              variant="outlined"
              size="small"
              className={classes.outlineInputBorder}
              error={errors?.name?.message && (true)}
              {...register("name", {
                required: 'Name is required',
              })}
            />
            {errors?.name?.message && (
              <Error message={errors?.name?.message} />
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }} >
            <TextField
              hiddenLabel
              placeholder='Company'
              variant="outlined"
              size="small"
              className={classes.outlineInputBorder}
              error={errors?.companyName?.message && (true)}
              {...register("companyName", {
                required: 'Company name is required',
              })}
            />
            {errors?.companyName?.message && (
              <Error message={errors?.companyName?.message} />
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }} >
            <TextField
              hiddenLabel
              placeholder='Phone Number'
              variant="outlined"
              type="number"
              size="small"
              className={classes.outlineInputBorder}
              error={errors?.phone?.message && (true)}
              {...register("phone", {
                required: 'Phone is required',
              })}
            />
            {errors?.phone?.message && (
              <Error message={errors?.phone?.message} />
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }} >
            <TextField
              hiddenLabel
              placeholder='Email'
              variant="outlined"
              type="email"
              className={classes.outlineInputBorder}
              error={errors?.email?.message && (true)}
              size="small"
              {...register("email", {
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address',
                }
              })}
            />
            {errors?.email?.message && (
              <Error message={errors?.email?.message} />
            )}
          </FormControl>

          <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }} >
            <TextField
              hiddenLabel
              placeholder='Password'
              variant="outlined"
              type="password"
              size="small"
              className={classes.outlineInputBorder}
              error={errors?.password?.message && (true)}
              {...register("password", {
                required: 'Password is required',
              })}
            />
            {errors?.password?.message && (
              <Error message={errors?.password?.message} />
            )}
          </FormControl>

          <LoadingBtn
            fullWidth
            loading={loading}
            type="submit"
            variant="contained"
          >
            Register
          </LoadingBtn>

          <PrimaryButton fullWidth sx={{ my: 1 }} onClick={() => navigate("/auth/sign-in")} >
            Sign In
          </PrimaryButton>
        </Typography>
      </Box>

      <Box sx={{ position: "absolute", bottom: -100, width: "100%", zIndex: -999 }}>
        <Wave />
      </Box>
    </Container>
  )
}

export default Register