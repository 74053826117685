import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import AddEmployee from "app/Components/Dialog/AddEmployee";
import EditJobDrawer from "app/Components/Drawer/EditJobDrawer";
import AssignResourcesDrawer from "app/Components/Drawer/TimeSheet/AssignResources";
import Menudropdown from "app/Components/Dropdown/Menudropdown";
import { ErrorToaster, SuccessToaster } from "app/Components/UI/Toaster";
import { TableHeadTypo } from "app/Components/UI/Typo";
import { Service } from "app/config/service";
import colors from "app/style/colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import {
  BsFillPersonFill,
  BsPencilFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Resizable } from "react-resizable";
const useStyles = makeStyles({
  spanStyle: {
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: "8px 0px 0px 8px",
    height: "100%",
    width: "8px",
    backgroundColor: "#0769FC",
  },
});
const UnSchedule = () => {
  const classes = useStyles();

  // *For Column Resize
  const [width, setWidth] = useState(200);

  const onResize = (event, { element, size, handle }) => {
    try {
      setWidth(size.width);
    } catch (error) {
      console.log(
        "file: TodosTable.js => line 302 => onResize => error",
        error
      );
    }
  };
  const [unScheduleJobs, setUnScheduleJobs] = useState([]);
  const [selectedUnScheduleJob, setSelectedUnScheduleJob] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [assignResourcesDrawer, setAssignResourcesDrawer] = useState(false);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);


  const [showEditJob, setShowEditJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUnschedule = async () => {
    try {
      const { data, responseCode, message, status } =
        await Service.getUnscheduled();
      if (responseCode === 200 && status == true) {
        setUnScheduleJobs(data.result);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: UnSchedule.js ~ line 55 ~ getUnschedule ~ error",
        error
      );
    }
  };
  const deleteJob = async () => {
    try {
      const { data, responseCode, message, status } = await Service.deleteJob(
        selectedUnScheduleJob._id
      );
      if (responseCode === 200 && status == true) {
        SuccessToaster(message);
        getUnschedule();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: UnSchedule.js ~ line 55 ~ getUnschedule ~ error",
        error
      );
    }
  };

  useEffect(() => {
    getUnschedule();
  }, []);

  return (
    <>

      {selectedJob && (
        <EditJobDrawer
          handleEditJob={() => setShowEditJob(!showEditJob)}
          open={showEditJob}
          selectedJobId={selectedJob}
          setSelectedJobId={setSelectedJob}
          getAssignJob={getUnschedule}
         
        />
      )}

      <AssignResourcesDrawer
        handleAddTimeSheet={() =>
          setAssignResourcesDrawer(!assignResourcesDrawer)
        }
        open={assignResourcesDrawer}
        job={selectedUnScheduleJob}
        getUnschedule={getUnschedule}
        handleEmployeeDialog={setOpenEmployeeDialog}
        employeeDialog={openEmployeeDialog}
      />

      <AddEmployee
        open={openEmployeeDialog}
        handleClose={() => setOpenEmployeeDialog(false)}
      />

      {/* table head  */}
      <Box
        sx={{
          p: 1.5,
          m: 2,
          borderRadius: "8px",
          bgcolor: "#F3F7F9",
          minWidth: 450,
          display: "flex",
          border: `1px solid ${colors.borderColor}`,
        }}
      >
        <Resizable
          height={"auto"}
          width={width}
          minConstraints={[100, 100]}
          maxConstraints={[450, 450]}
          onResize={onResize}
          axis={"x"}
          resizeHandles={["se resize-cursor"]}
        >
          <div className="box" style={{ width: width, height: "auto" }}>
            <TableHeadTypo style={{ marginLeft: "15px" }}>
              Job Name
            </TableHeadTypo>
          </div>
        </Resizable>

        <Grid container sx={{ minWidth: "500px" }}>
          <Grid item xs={2.5}>
            <TableHeadTypo>Category</TableHeadTypo>
          </Grid>
          <Grid item xs={3.5}>
            <TableHeadTypo>Description</TableHeadTypo>
          </Grid>
          <Grid item xs={2}>
            <TableHeadTypo>Client</TableHeadTypo>
          </Grid>
          <Grid item xs={3}>
            <TableHeadTypo>Location</TableHeadTypo>
          </Grid>
        </Grid>
      </Box>
      {unScheduleJobs.length === 0 &&
        <Box sx={{ textAlign: "center", width: "100%", my: 20 }}>
          <Typography
            sx={{ color: "#4F4F4F", fontSize: 25, fontWeight: 600 }}
          >
            Scheduler
          </Typography>
          <Typography
            sx={{ color: "#4F4F4F", fontSize: 15, fontWeight: 400 }}
          >
            No jobs are unscheduled
          </Typography>
        </Box>
      }
      {unScheduleJobs.length !== 0 &&
        unScheduleJobs.map((jobList, index) => {
          return (
            <Box key={index}>
              <Box
                sx={{
                  position: "relative",
                  m: 2,
                  p: 1.5,
                  borderRadius: "8px",
                  minWidth: 650,
                  minHeight: 70,
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${colors.borderColor}`,
                }}
              >
                <span className={classes.spanStyle} />

                <Box sx={{ width: `${width - 25 + "px"}`, overflow: "hidden" }}>
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{ height: "100%", pl: 1, mr: 1 }}
                  >
                    {jobList?.name}
                  </Typography>
                </Box>
                <Grid container sx={{ minWidth: "500px", alignItems: "center" }}>
                  <Grid item xs={2.5}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        pl: 1,
                        color: `${colors.textSecondary}`,
                      }}
                    >
                      {jobList?.category?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        pl: 1,
                        color: `${colors.textSecondary}`,
                      }}
                    >
                      {jobList?.details}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        pl: 1,
                        color: `${colors.textSecondary}`,
                      }}
                    >
                      {jobList?.client_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        pl: 1,
                        color: `${colors.textSecondary}`,
                      }}
                    >
                      {jobList?.location}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <span
                      style={{ cursor: "pointer", float: "right" }}
                      onClick={(event) => {
                        handleClick(event);
                        setSelectedUnScheduleJob(jobList);
                        setSelectedJob(jobList._id);
                      }}
                    >
                      <BsThreeDotsVertical size={24} />
                    </span>
                  </Grid>
                  <Menu
                    PaperProps={{
                      style: {
                        width: 180,
                        borderRadius: 8,
                        marginTop: 18,
                      },
                    }}
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={!anchorEl}
                  >
                    <MenuItem
                      sx={{ fontSize: "14px", color: colors.textSecondary }}
                      onClick={() => {
                        handleClose();
                        setAssignResourcesDrawer(!assignResourcesDrawer);
                      }}
                    >
                      <BsFillPersonFill />
                      &nbsp; Assign Resources
                    </MenuItem>
                    <MenuItem
                      sx={{ fontSize: "14px", color: colors.textSecondary }}
                      // onClick={() => {
                      //   handleClose();
                      //   setAssignResourcesDrawer(!assignResourcesDrawer);
                      // }}
                      onClick={() => {
                        handleClose();
                        setShowEditJob(true);
                      }}
                    >
                      <BsPencilFill />
                      &nbsp; Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => deleteJob()}
                      sx={{ fontSize: "15px", color: colors.textSecondary }}
                    >
                      <MdDelete />
                      &nbsp; Delete
                    </MenuItem>
                  </Menu>
                </Grid>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default UnSchedule;
