import {
  Avatar,
  Box,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Service } from "app/config/service";
import colors from "app/style/colors";
import Images from "assets/images";
import React from "react";
import { Fragment } from "react";
import { useState, useRef, useEffect } from "react";
import { BsChat, BsPaperclip, BsThreeDots } from "react-icons/bs";
import { FaCheckCircle, FaRegSmile, FaTimesCircle } from "react-icons/fa";
import { PrimaryButton } from "../../UI/Buttons";
import Picker from "emoji-picker-react";
import moment from "moment";
import { SuccessToaster } from "../../UI/Toaster";
import { MdClose, MdImage, MdLink } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";

const useStyles = makeStyles({
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});

const ToDoComments = ({ handleComments, open, timeSheet,getTasks }) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();
  const commentsRef = useRef(0);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [anchorElCommentPop, setAnchorElCommentPop] = useState(null);
  const openCommentPop = Boolean(anchorElCommentPop);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [displayAddComment, setDisplayAddComment] = useState(true);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPicker = Boolean(anchorEl); // for emoji comment box
  const [commentPosition, setCommentPosition] = useState();
  const [addedComments, setAddedComments] = useState([]);
// console.log(commentPosition)
//   useEffect(() => {
//     console.log(commentPosition, "COMMENT_POSITION");
//   }, [comment]);

  const getComment = async () => {
    try {
      const { data, message, responseCode, status } = await Service.getComment(
        timeSheet?._id
      );
      if (responseCode === 200 && status === true) {
        setComments(data.comments);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Comments.js ~ line 49 ~ getComment ~ error",
        error
      );
    }
  };
  const handleMouseUp = (event) => {
    const selection = window.getSelection();
    setCommentPosition(selection.focusOffset);
  };
  const handleCloseCommentPop = async (event) => {
    try {
      setAnchorElCommentPop(null);
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error",
        error
      );
    }
  };

  const handleDeleteComment = async () => {
    try {
      const { status, responseCode, message, data } =
        await Service.deleteComment(selectedCommentId);
      if (status === true && responseCode === 200) {
        getComment();
        SuccessToaster(message);
        getTasks();
        setAnchorElCommentPop(null);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error",
        error
      );
    }
  };

  const handleClickCommentPop = async (event, _id) => {
    try {
      setSelectedCommentId(_id);
      setAnchorElCommentPop(event.currentTarget);
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 308 ~ handleClickCommentPop ~ error",
        error
      );
    }
  };
  // function for handling marketing menu
  const handleEmojiClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEmojiClose = () => {
    setAnchorEl(null);
  };

  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject?.emoji) {
      setAnchorEl(null);
      let text = [...comment];
      text.splice(commentPosition, 0, emojiObject?.emoji);
      // console.log(text)
      // let text = comment.slice(0, commentPosition) + emojiObject?.emoji + comment.slice(commentPosition);
      setValue("taskComments", text.join(""));
      setComment(text.join(""));
    }
  };
  const hiddenImageInput = useRef(null);
  const handleImageClick = (event) => {
    hiddenImageInput.current.click();
  };

  const handleImage = async (e) => {
    try {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("document_type", "1");
        formData.append("document", e.target.files[0]);
        const { responseCode, status, message, data } = await Service.upload(
          formData
        );
        if (status === true && responseCode === 200) {
          let image = [...images];
          image.push(data);
          setImages(image);
          e.target.value = null;
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };

  const deleteCommentImage = (i) => {
    try {
      let image = [...images];
      image.splice(i, 1);
      setImages(image);
    } catch (error) {
      console.log(error);
    }
  };

  const hiddenFileInput = useRef(null);
  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = async (e) => {
    try {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("document_type", "2");
        formData.append("document", e.target.files[0]);
        const { responseCode, status, message, data } = await Service.upload(
          formData
        );
        if (status === true && responseCode === 200) {
          let filesName = [...files];
          filesName.push(data);
          setFiles(filesName);
          e.target.value = null;
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };

  const deleteCommentFile = (i) => {
    try {
      let filesName = [...files];
      filesName.splice(i, 1);
      setFiles(filesName);
    } catch (error) {
      console.log(error);
    }
  };
  const clearComment = () => {
    setComment("");
    setImages([]);
    setFiles([]);
  };
  const handleAddedComments = async () => {
    try {
      let addComment = [...addedComments];
      if (comment || files.length > 0 || images.length > 0) {
        addComment.push({
          content: comment,
          attachments: [...files, ...images],
          created_on: new Date(),
        });
        // setAddedComments(addComment)
        await Service.createComment({
          task: timeSheet._id,
          content: comment,
          attachments: [...files, ...images],
          created_on: new Date(),
        });
      }
      getComment();
      getTasks();
      clearComment();
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 243 ~ handleAddedComments ~ error",
        error
      );
    }
  };
  useEffect(() => {
    getComment();
  }, [timeSheet]);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleComments}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box sx={{ width: 340, pt: 10 }} role="presentation">
        <Box
          sx={{
            bgcolor: colors.white,
            width: "100%",
            height: "93vh",
            overflow: "scroll",
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              px: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: colors.textSecondary, fontWeight: "bold" }}
            >
              {timeSheet?.job}
            </Typography>
            <IconButton
              onClick={handleComments}
              sx={{ px: 0 }}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
              }}
            >
              <MdClose size={24} />
            </IconButton>
          </Box>
          <Typography
            sx={{ p: 2, color: "#4F4F4F", fontSize: 16, fontWeight: 600 }}
          >
            {" "}
            Comments
          </Typography>
          {comments.length > 0 ? (
            <React.Fragment>
              {comments.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    backgroundColor: colors.darkgrayShade,
                    border: `1px solid ${colors.borderColor}`,
                    p: 1,
                    borderRadius: "8px",
                    mb: 1,
                  }}
                >
                  <Fragment>
                    <Grid container>
                      <Grid item xs={10}>
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                            alt=""
                            src=""
                            sx={{
                              display: "inline-flex",
                              mr: 1,
                              width: "25px",
                              height: "25px",
                              bgcolor: colors.skyBlue,
                              fontSize: "12px",
                              textTransform: "uppercase",
                            }}
                          >
                            {item?.created_by?.split(" ").length > 1
                              ? item?.created_by?.charAt(0) +
                                item?.created_by?.split(" ")[1].charAt(0)
                              : item?.created_by?.charAt(0)}
                          </Avatar>
                          <Box>
                            <Typography variant="body1">
                              {item?.created_by}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.itemText}
                            >
                              {moment(item.created_on).fromNow()}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          sx={{ py: 0 }}
                          onClick={(event) =>
                            handleClickCommentPop(event, item._id)
                          }
                        >
                          <BsThreeDots />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Menu
                      id="basic-menu"
                      PaperProps={{
                        sx: {
                          boxShadow: "none",
                          borderRadius: "10px",
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      anchorEl={anchorElCommentPop}
                      open={openCommentPop}
                      onClose={handleCloseCommentPop}
                    >
                      <MenuItem onClick={handleDeleteComment}>Delete</MenuItem>
                    </Menu>
                    {/* Attachments */}
                    {item?.attachments.length > 0 && (
                      <Typography variant="body2" fontWeight="bold" mt={2}>
                        Attachments
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {item?.attachments.map((attachment, i) => {
                        return (
                          <Box key={i}>
                            {attachment.document_type === "1" && (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${attachment?.document}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            )}

                            {attachment.document_type === "2" &&
                            attachment.document.slice(-4) === ".txt" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".doc" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".docx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".pdf" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.pdf}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".csv" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".xls" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".xlsx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".pptx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".ppt" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : (
                              // <Box sx={{mr:2,mb:2,width:"70px"}}>
                              // <Link href={`${attachment?.document}`} target="_blank">
                              //   <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                              // </Link>
                              // <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                              // {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                              // </Typography>
                              // </Box>
                              ""
                            )}
                          </Box>
                        );
                      })}
                    </Box>

                    {/* <Grid item xs={3.4}>
                    <img
                      width="100%"
                      height="80px"
                      src="https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMGludGVyaW9yfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                      alt=""
                    />
                  </Grid> */}
                    {item?.content && (
                      <>
                        <Divider />
                        {/* Comments text */}
                        <Typography variant="body2" color="initial" my={2}>
                          {item?.content}
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                      </>
                    )}
                  </Fragment>
                </Box>
              ))}

              {/* Add comments button */}
              <PrimaryButton
                sx={{
                  minWidth: 180,
                  my: 2,
                  bgcolor: colors.grayShade,
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
                onClick={() => setDisplayAddComment(!displayAddComment)}
              >
                <BsChat style={{ margin: "0px 10px" }} />
                Add comment
              </PrimaryButton>
            </React.Fragment>
          ) : (
            ""
          )}
          <FormControl
            variant="standard"
            fullWidth
            sx={{ display: displayAddComment ? "block" : "none" }}
          >
            <Box sx={{ mt: 1 }} className={classes.boxSelectField}>
              <Grid container alignItems="center">
                <Grid item xs={1.5}>
                  <IconButton onClick={handleEmojiClick}>
                    <FaRegSmile size={18} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openPicker}
                    onClose={handleEmojiClose}
                    transformOrigin={{
                      vertical: 50,
                      horizontal: 0,
                    }}
                    PaperProps={{
                      style: {
                        // width: 120,
                        borderRadius: "10px",
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Picker
                      disableSearchBar
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                    />
                  </Menu>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleImageClick}>
                    <MdImage size={20} />
                  </IconButton>
                  <input
                    type="file"
                    accept="image/*"
                    ref={hiddenImageInput}
                    onChange={handleImage}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleFileClick}>
                    <BsPaperclip size={18} />
                  </IconButton>
                  <input
                    type="file"
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    ref={hiddenFileInput}
                    onChange={handleFile}
                    style={{ display: "none" }}
                  />
                </Grid>
                {/* <Grid item xs={1.5}>
                  <IconButton>
                    <MdLink size={20} />
                  </IconButton>
                </Grid> */}
              </Grid>

              <Divider />
              <Box sx={{ p: 1 }}>
                {images.length > 0 && (
                  <>
                    <Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Images:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {images.map((item, i) => (
                          <Box>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: "#ECECEC",
                                width: "20px",
                                height: "12px ",
                                position: "relative",
                                left: "50px",
                                top: 10,
                              }}
                              onClick={() => deleteCommentImage(i)}
                            >
                              <CloseIcon fontSize="10px" />
                            </IconButton>
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <img
                                src={item?.document}
                                width="70px"
                                height="70px"
                                style={{ margin: "5px" }}
                                alt="file"
                              />
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {item?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                {files.length > 0 && (
                  <>
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Files:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {files.map((attachment, i) => {
                          return (
                            <Box sx={{ mr: 2, mb: 2 }}>
                              {attachment.document?.slice(-4) === ".txt" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".txt", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".doc" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".doc", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".docx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".docx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".pdf" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.pdf}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".pdf", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".csv" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".csv", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".xlsx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".xlsx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".xls" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".xls", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".ppt" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".ppt", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".pptx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".pptx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.word}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
              </Box>
              <TextField
                ref={commentsRef}
                hiddenLabel
                multiline
                placeholder="Add your comment..."
                variant="outlined"
                fullWidth
                value={comment}
                className={classes.outlineInputBorderNone}
                sx={{ minHeight: "100px" }}
                error={errors?.taskComments?.message && true}
                size="small"
                // onMouseUp={handleMouseUp}
                onClick={(e) => {
                  setCommentPosition(e.target.selectionStart);
                }}
                onKeyPress={(e) => {
                  setCommentPosition(e.target.selectionStart + 1);
                }}
                {...register("taskComments", {
                  onChange: (e) => {
                    setComment(e.target.value);
                  },
                })}
              />
              <Box sx={{ mt: 1.5, float: "right" }}>
                <IconButton onClick={clearComment}>
                  <FaTimesCircle 
                  size={16} 
                  color={comment.length >= 1 ? colors.red : colors.redShade} />
                </IconButton>
                <IconButton onClick={handleAddedComments}>
                  <FaCheckCircle size={16} color={colors.skyBlue} />
                </IconButton>
              </Box>
            </Box>
          </FormControl>
        </Box>
        {/* Comments div  */}
      </Box>
    </Drawer>
  );
};

export default ToDoComments;
