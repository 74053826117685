import { Avatar, Box, Chip, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from 'app/style/colors';
import React from 'react'
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import { BsChat, BsThreeDotsVertical } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { Resizable } from 'react-resizable';
import ViewMore from '../Drawer/TimeSheet/ViewMore';
import { TableHeadTypo } from '../UI/Typo';
import moment from 'moment'
import Comments from '../Drawer/TimeSheet/Comments';
import { Service } from 'app/config/service';

const groupByToMap = require('array.prototype.groupbytomap');



const useStyles = makeStyles({
    spanStyle: {
        position: "absolute",
        left: 0, top: 0,
        borderRadius: "8px 0px 0px 8px",
        height: "100%", width: "8px",
        backgroundColor: colors.skyBlue
    },
    boxStyle: {
        padding: 4.5,
        width: 90,
        height: 59,
        borderRadius: "5px",
        boxSizing: "border-box",
        border: "1px solid #E9EBED",
        background: "rgba(255, 255, 255, 0.1)",
        margin: 5
    },
    dotStyle: {
        position: "absolute",
        right: 0, top: 0,
        height: "100%",
        display: "flex", alignItems: "center", justifyItems: "center"
    },
})

const TimeSheetTable = ({ timeSheetsData, getTimeSheet, startDate, endDate, employee, job }) => {


    const classes = useStyles()
    const [timeSheets, setTimeSheets] = useState([]);
    console.log("🚀 ~ file: TimeSheetTable.js ~ line 54 ~ TimeSheetTable ~ timeSheets", timeSheets)
    // *For Column Resize
    const [width, setWidth] = useState(200);

    const [anchorElStatus, setAnchorElStatus] = useState(null);
    const openStatus = Boolean(anchorElStatus);

    const [anchorElDate, setAnchorElDate] = useState(null);
    const openDate = Boolean(anchorElDate);
    const [date, setDate] = useState(Date.now());
    console.log("🚀 ~ file: TimeSheetTable.js ~ line 64 ~ TimeSheetTable ~ date", date)

    const [showViewMore, setShowViewMore] = useState(false);
    const [anchorElOptionViewMore, setAnchorElOptionViewMore] = useState(false);

    const [anchorElOption, setAnchorElOption] = useState(null);
    const openOption = Boolean(anchorElOption);
    const [anchorElComments, setAnchorElComments] = useState(null);
    const openComments = Boolean(anchorElComments);
    const [selectedTimeSheet, setSelectedTimeSheet] = useState({});



    const onResize = (event, { element, size, handle }) => {
        try {

            setWidth(size.width)
        } catch (error) {
            console.log('file: TodosTable.js => line 302 => onResize => error', error)
        }
    }
    const getTimeDetails = () => {
        if (timeSheetsData) {
            for (let index = 0; index < timeSheetsData.length; index++) {
                const timeSheets = groupByToMap(timeSheetsData[index].time_details, (sheet) => {
                    return sheet.type;
                });

                for (let [key, value] of timeSheets) {

                    let totalTime = 0
                    value.map((time) => {
                        totalTime += time.timeDiff
                    })
                    timeSheetsData[index] = { ...timeSheetsData[index], [`${key}Hrs`]: totalTime }
                    if (key === "onjob") {
                        timeSheetsData[index] = { ...timeSheetsData[index], ['jobStartTime']: value[0].startTime }
                        timeSheetsData[index] = { ...timeSheetsData[index], ['jobEndTime']: value[value.length - 1].endTime }
                    }
                }
            }

            setTimeSheets(timeSheetsData)
            // console.log("🚀 ~ file: TimeSheetTable.js ~ line 42 ~ TimeSheetTable ~ timeSheetsData", timeSheetsData)


        }
    }
    const updateTimeSheet = async (obj) => {
        try {
            // console.log(obj)

            const { data, responseCode, status } = await Service.updateTimeSheet(obj)
            if (status === true && responseCode == 200) {
                getTimeSheet(startDate, endDate, employee, job)
            }

        } catch (error) {
            console.log("🚀 ~ file: TimeSheetTable.js ~ line 114 ~ updateTimeSheet ~ error", error)

        }
    }
    useEffect(() => {
        getTimeDetails()
    }, [timeSheetsData]);

    return (
        <>
            {selectedTimeSheet.userDetails &&
                <ViewMore handleViewMore={() => setShowViewMore(!showViewMore)} open={showViewMore} timeSheet={selectedTimeSheet} setTimeSheet={setSelectedTimeSheet} getTimeSheet={getTimeSheet} startDate={startDate} endDate={endDate} />

            }
            {/* table head  */}
            {selectedTimeSheet.userDetails&&
            <Comments handleComments={() => setAnchorElComments(!openComments)} open={openComments} timeSheet={selectedTimeSheet} />
        }
            <Box sx={{
                p: 1.5,
                m: 2,
                borderRadius: "8px",
                bgcolor: '#F3F7F9',
                minWidth: 450,
                display: "flex",
                border: `1px solid ${colors.borderColor}`
            }}>
                <Resizable
                    height={'auto'}
                    width={width}
                    minConstraints={[100, 100]}
                    maxConstraints={[450, 450]}
                    onResize={onResize}
                    axis={'x'}
                    resizeHandles={["se resize-cursor"]}

                >
                    <div className="box" style={{ width: width, height: 'auto' }}>
                        <TableHeadTypo style={{ marginLeft: '15px', }}>Job Name</TableHeadTypo>
                    </div>
                </Resizable>

                <Grid container columns={20} sx={{ minWidth: '500px' }}>
                    <Grid item xs={3} >
                        <TableHeadTypo >Employee Name </TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.8}  >
                        <TableHeadTypo>Status</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2} >
                        <TableHeadTypo >Date</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Job Start</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Job End</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Travel</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Break</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Delay</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1.8}  >
                        <TableHeadTypo >Total hours</TableHeadTypo>
                    </Grid>
                    <Grid item xs={1}  >
                        <TableHeadTypo >Comments</TableHeadTypo>
                    </Grid>

                </Grid>

            </Box>
            <Box >

                {timeSheets.map((item, index) => {
                    return (
                        <Box key={index}
                            sx={{
                                position: "relative",
                                m: 2, p: 1.5,
                                borderRadius: "8px",
                                minWidth: 650,
                                minHeight: 70,
                                display: "flex", alignItems: "center", border: `1px solid ${colors.borderColor}`
                            }}>

                            <span className={classes.spanStyle} />

                            <Box sx={{ width: `${width - 25 + 'px'}`, overflow: 'hidden' }}>
                                <Typography variant="body2" noWrap
                                    sx={{ height: "100%", pl: 1, mr: 1, }}
                                >
                                    {item?.jobDetail?.name}
                                </Typography>


                            </Box>
                            <Grid container columns={20} sx={{ minWidth: '500px', alignItems: 'center' }}>
                                <Grid item xs={3} alignSelf="center">
                                    <Box variant="body2" sx={{ ml: 1, cursor: "pointer", display: 'flex', alignItems: 'center' }}>
                                        <Avatar alt="" src=''
                                            sx={{
                                                display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                                fontSize: "13px", textTransform: "uppercase",
                                                bgcolor: colors.skyBlue,
                                            }}>

                                            {item?.userDetails?.name?.split(" ").length > 1 ?
                                                item?.userDetails?.name?.charAt(0) + item?.userDetails?.name?.split(" ")[1].charAt(0) :
                                                item?.userDetails?.name?.charAt(0)
                                            }
                                        </Avatar>
                                        <Typography noWrap sx={{ fontSize: '13px' }}>{item?.userDetails?.name}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2.8}  >
                                    {item.status === "timesubmitted"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, mr: 1, fontWeight: 600, backgroundColor: `#FFBA7D`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Submitted" onClick={(event) => setAnchorElStatus(event.currentTarget)} />
                                    }
                                    {item.status === "onjob"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, mr: 1, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" onClick={(event) => setAnchorElStatus(event.currentTarget)} />

                                    }
                                    {item.status === "delay"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, mr: 1, fontWeight: 600, backgroundColor: `#EB5757`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delayed" onClick={(event) => setAnchorElStatus(event.currentTarget)} />
                                    }
                                    {item.status === "break"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, mr: 1, fontWeight: 600, backgroundColor: `#98A2B3`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Break" onClick={(event) => setAnchorElStatus(event.currentTarget)} />
                                    }
                                    {item.status === "travel"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, mr: 1, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" onClick={(event) => setAnchorElStatus(event.currentTarget)} />
                                    }
                                    {item.status === "timeapproved"
                                        &&
                                        <Chip size="small" sx={{width:'100%', cursor: "pointer", p: 1, fontWeight: 600, backgroundColor: `#49BE7B`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Approved" onClick={(event) => setAnchorElStatus(event.currentTarget)} />
                                    }
                                

                                    <Menu
                                        anchorEl={anchorElStatus}
                                        id="account-menu"
                                        open={openStatus}
                                        onClose={() => setAnchorElStatus(null)}
                                        onClick={() => { setAnchorElStatus(null); updateTimeSheet() }}

                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.32))',
                                                borderRadius: '8px',
                                                mt: 1.5,
                                                ml: 3,
                                                textAlign: "center",
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 60,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,

                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >

                                        <MenuItem value={"onjob"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "onjob" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" />
                                        </MenuItem>
                                        <MenuItem value={"delay"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "delay" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#EB5757`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delayed" />
                                        </MenuItem>
                                        <MenuItem value={"break"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "break" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#98A2B3`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Break" />
                                        </MenuItem>
                                        <MenuItem value={"travel"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "travel" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                                        </MenuItem>
                                        <MenuItem value={"timesubmitted"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "timesubmitted" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#FFA000`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Submitted" />
                                        </MenuItem>
                                        <MenuItem value={"timeapproved"} onClick={() => updateTimeSheet({ 'id': item._id, 'status': "timeapproved" })}>
                                            <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#49BE7B`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Approved" />
                                        </MenuItem>

                                    </Menu>

                                </Grid>
                                <Grid item xs={2} >
                                    <Typography sx={{ cursor: "pointer", fontSize: 14, pl: 1, color: `${colors.textSecondary}` }} onClick={(event) => setAnchorElDate(event.currentTarget)}>{moment(item.date).format("DD/MM/YYYY")}</Typography>
                                    <Menu
                                        anchorEl={anchorElDate}
                                        id="account-menu"
                                        open={openDate}
                                        onClose={() => setAnchorElDate(null)}
                                        // onClick={() => setAnchorElDate(null)}
                                        onClick={() => updateTimeSheet({ 'id': item._id, 'date': moment(date).format("YYYY-MM-DD") })}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.32))',
                                                borderRadius: '8px',
                                                mt: 1.5,
                                                textAlign: "center",

                                            },

                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <Calendar
                                            onChange={d => { setDate(d); setAnchorElDate(null); }} date={date} />
                                    </Menu>
                                </Grid>
                                <Grid item xs={1.8}  >
                                    <Typography sx={{ fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{item.jobStartTime?moment(item.jobStartTime).format("hh:mm a"):'--'}</Typography>
                                </Grid>
                                <Grid item xs={1.8}  >
                                    <Typography sx={{ fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{item.jobEndTime?moment(item.jobEndTime).format("hh:mm a"):"--"}</Typography>
                                </Grid>
                                <Grid item xs={1.8}  >
                                    <Typography sx={{ fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{moment().startOf('day').add(item.travelHrs, 'minutes').format('HH:mm')} hrs</Typography>
                                </Grid>
                                <Grid item xs={1.8}  >
                                    <Typography sx={{ fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{moment().startOf('day').add(item.breakHrs, 'minutes').format('HH:mm')} hrs</Typography>
                                </Grid>
                                <Grid item xs={1.8}  >
                                    <Typography sx={{ fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{moment().startOf('day').add(item.delayHrs, 'minutes').format('HH:mm')} hrs</Typography>
                                </Grid>
                                <Grid item xs={1.8}   >
                                    <Typography sx={{ fontWeight: 600, fontSize: 14, pl: 1, color: `${colors.textSecondary}` }}>{moment().startOf('day').add(item.totalHrs, 'minutes').format('HH:mm')} hrs</Typography>
                                </Grid>
                                <Grid item xs={1} textAlign="center">
                                    <BsChat size={26} onClick={() => { setSelectedTimeSheet(item); setAnchorElComments(true) }} />
                                    <span className={classes.dotStyle} onClick={(event) => { setSelectedTimeSheet(item); setAnchorElOption(event.currentTarget) }}>
                                        <BsThreeDotsVertical size={24} />
                                    </span>
                                    <Menu
                                        anchorEl={anchorElOption}
                                        id="account-menu"
                                        open={openOption}
                                        onClose={() => setAnchorElOption(null)}
                                        onClick={() => setAnchorElOption(null)}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.32))',
                                                borderRadius: '8px',
                                                mt: 1.5,
                                                textAlign: "center",

                                            },

                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={() => {
                                            setShowViewMore(!showViewMore)
                                            setAnchorElOptionViewMore(null)

                                        }} sx={{ fontSize: "14px", color: colors.textSecondary }}>
                                            <FiExternalLink />
                                            &nbsp;
                                            View more
                                        </MenuItem>
                                        {/* <MenuItem sx={{ fontSize: "14px", color: colors.textSecondary }}>
                                                                <MdDelete />
                                                                &nbsp;
                                                                Delete
                                                            </MenuItem> */}


                                    </Menu>
                                </Grid>


                            </Grid>
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}

export default TimeSheetTable