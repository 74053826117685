import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Propose from 'app/Components/Drawer/Propose'
import { TableHeadTypo } from 'app/Components/UI/Typo'
import { Service } from 'app/config/service'
import colors from 'app/style/colors'
import moment from 'moment'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { Resizable } from 'react-resizable'

const useStyles = makeStyles({
    spanStyle: {
        position: "absolute",
        left: 0, top: 0,
        borderRadius: "8px 0px 0px 8px",
        height: "100%", width: "8px",
        backgroundColor: '#0769FC'
    }
})
const JobChanges = () => {
    const classes = useStyles()
    const [jobChangesList, setJobChangesList] = useState([]);
    const [showPropose, setShowPropose] = useState(false);
    const [selectRequest, setSelectRequest] = useState({});
    // *For Column Resize
    const [width, setWidth] = useState(200);


    // *For Column Resize
    const onResize = (event, { element, size, handle }) => {
        try {

            setWidth(size.width)
        } catch (error) {
            console.log('file: TodosTable.js => line 302 => onResize => error', error)
        }
    }
    const getJobChange=async()=>{
        try {
            const {status,responseCode,data,message}=await Service.getJobChange()
            if(status===true&responseCode===200){
                setJobChangesList(data.result)
            }

        } catch (error) {
            console.log("🚀 ~ file: JobChanges.js ~ line 39 ~ getJobChange ~ error", error)
            
        }
    }
    const updateJobChangeStatus=async(obj)=>{
        try {
            const {status,responseCode,}=await Service.updateJobChange(obj)
            if(status===true&responseCode===200){
                getJobChange()
            }
        } catch (error) {
            console.log("🚀 ~ file: JobChanges.js ~ line 55 ~ updateJobChangeStatus ~ error", error)
            
        }
    }
    useEffect(() => {
        getJobChange()
    }, []);

    return (
        <>
        <Propose handlePropose={() => setShowPropose(!showPropose)} open={showPropose} getJobChange={getJobChange} selectRequest={selectRequest}/>
            {/* table head  */}
            <Box sx={{
                p: 1.5,
                m: 2,
                borderRadius: "8px",
                bgcolor: '#F3F7F9',
                minWidth: 450,
                display: "flex",
                border: `1px solid ${colors.borderColor}`
            }}>
                <Resizable
                    height={'auto'}
                    width={width}
                    minConstraints={[100, 100]}
                    maxConstraints={[450, 450]}
                    onResize={onResize}
                    axis={'x'}
                    resizeHandles={["se resize-cursor"]}

                >
                    <div className="box" style={{ width: width, height: 'auto' }}>
                        <TableHeadTypo style={{ marginLeft: '15px', }}>Job Name</TableHeadTypo>
                    </div>
                </Resizable>
                <Grid container sx={{ minWidth: '500px' }}>

                    <Grid item xs={2} >
                        <TableHeadTypo>Employee Name</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.3}  >
                        <TableHeadTypo>Original date/time</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.3} >
                        <TableHeadTypo >Requested date/time</TableHeadTypo>
                    </Grid>
                    <Grid item xs={4}  >
                        <TableHeadTypo >Accept or Reject</TableHeadTypo>
                    </Grid>

                </Grid>
            </Box>
            {jobChangesList.map((list,index)=>{
                return(
            <Box >

                <Box sx={{
                    position: "relative",
                    m: 2, p: 1.5,
                    borderRadius: "8px",
                    minWidth: 650,
                    minHeight: 70,
                    display: "flex", alignItems: "center", border: `1px solid ${colors.borderColor}`
                }}>

                    <span className={classes.spanStyle} />
                
                    
                        
                        <Box sx={{ width: `${width - 25 + 'px'}`, overflow: 'hidden' }}>
                        <Typography variant="body2" noWrap
                            sx={{color:`${colors.textSecondary}`, height: "100%", pl: 1, mr: 1, }}
                        >
                           {list?.job?.name}
                        </Typography>
                    </Box>
                    <Grid container sx={{ minWidth: '500px', alignItems: 'center' }}>
                        <Grid item xs={2} >
                        <Box variant="body2" sx={{width: 120, ml:1, display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="" src=''
                                    sx={{
                                        display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                        fontSize: "13px", textTransform: "uppercase",
                                        bgcolor: colors.skyBlue,
                                    }}>

                                    {list?.worker?.name?.split(" ").length > 1 ?
                                        list?.worker?.name?.charAt(0) + list?.worker?.name?.split(" ")[1].charAt(0) :
                                        list?.worker?.name?.charAt(0)
                                    }
                                </Avatar>
                                <Typography noWrap sx={{color:`${colors.textSecondary}`, fontSize: '13px' }}>{list?.worker?.name}</Typography>
                            </Box>                       
                             </Grid>
                             <Grid item xs={2.3}   >
                                <Box >
                                <Typography sx={{color:`${colors.textSecondary}`, fontSize: '13px' }}>
                                    {moment(list?.orgStartDate).format('D MMM')} - {moment(list?.orgEndDate).format('D MMM YYYY')}
                                     </Typography>
                                <Typography sx={{color:`${colors.textSecondary}`, fontSize: '13px' }}>
                                {moment(list?.orgStartTime).format('h:mm a')} - {moment(list?.orgEndTime).format('h:mm a')}
                                </Typography>
                                </Box>
                             </Grid>
                             <Grid item xs={2.3}  >
                                <Box >
                                <Typography sx={{color:`${colors.textSecondary}`, fontSize: '13px',fontWeight:700 }}>
                                {moment(list?.reqStartDate).format('D MMM')} - {moment(list?.reqEndDate).format('D MMM YYYY')}
                                </Typography>
                                <Typography sx={{color:`${colors.textSecondary}`, fontSize: '13px' }}>
                                {moment(list?.reqStartTime).format('h:mm a')} - {moment(list?.reqEndTime).format('h:mm a')}

                                    </Typography>
                                </Box>
                             </Grid>
                             <Grid item xs={5} alignSelf="center">
                                    {list.status==="request"&&
                                     <Box sx={{ display: "flex", ml: 0.5 }}>
                                     <Button
                                         sx={{
                                             width:88,height:28, fontSize: '13px', background: '#219653', borderRadius: '5px', mr: 1, '&:hover': {
                                                 backgroundColor: '#1eb05c'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateJobChangeStatus({status:"accept",id:list._id})}>Accept</Button>
                                     <Button
                                         sx={{
                                             width:88,height:28, fontSize: '13px', background: '#EB5757', borderRadius: '5px',
                                             '&:hover': {
                                                 backgroundColor: '#f76060'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateJobChangeStatus({status:"reject",id:list._id})}
                                     >Reject 
                                     </Button>
                                     <Button
                                         sx={{
                                             ml:1,
                                             height:28, fontSize: '13px', background: `${colors.darkBlue}`, borderRadius: '5px',
                                             '&:hover': {
                                                 backgroundColor: '#408df5'
                                             }
                                         }} variant="contained"
                                         onClick={() =>{ setShowPropose(!showPropose);setSelectRequest(list)}}
                                     >Propose New</Button>
                                 </Box>
                                    }
                                    {list.status==="reject"&&
                                         <Typography sx={{color:'#EB5757',fontWeight:600,textAlign:'left',fontSize:13}}>Request Rejected</Typography>

                                    }
                                    {list.status==="accept"&&
                                         <Typography sx={{color:"#219653",fontWeight:600,textAlign:'left',fontSize:13}}>Request Accepted</Typography>

                                    }
                                    {list.status==="propose"&&
                                         <Typography sx={{color:"#2F80ED",fontWeight:600,textAlign:'left',fontSize:13}}>New date/time Proposed</Typography>

                                    }
                        </Grid>
                        </Grid>
                        
                    
                
                    
                </Box>
            </Box>
            )})}

        </>
    )
}

export default JobChanges