import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AddJob from "app/Components/AddJob/AddJob";
import colors from "app/style/colors";
import { Loading } from "assets/images";
import React, { useState, useEffect, Fragment } from "react";
import DailyTable from "./shared/Schedule/DailyTable";
import UnSchedule from "./shared/UnSchedule/UnSchedule";
import WeeklyTable from "./shared/Schedule/WeeklyTable";
import TimeOff from "./shared/Request/TimeOff";
import JobChanges from "./shared/Request/JobChanges";
import { makeStyles } from "@mui/styles";
import Calendar from "app/Components/Calendar/Calendar";
import { AiOutlineDown } from "react-icons/ai";
import { RiArrowDownSLine } from "react-icons/ri";
import { FaFileMedical, FaFilter } from "react-icons/fa";
import Filters from "./shared/Filter/Filters";
import { PrimaryButton } from "app/Components/UI/Buttons";
import AddJobDrawer from "app/Components/Drawer/AddJobDrawer";
import JobDailyTable from "./shared/Schedule/JobDailyTable";
import JobWeeklyTable from "./shared/Schedule/JobWeeklyTable";
import { Service } from "app/config/service";

const useStyles = makeStyles({
  scheduleSelectedButton: {
    backgroundColor: `${colors.navyBlue} !important`,
    borderRadius: "5px !important",
    marginRight: "5px !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
  },
  scheduleButton: {
    background: `${colors.gray} !important`,
    color: `${colors.textSecondary} !important`,
    borderRadius: "5px !important",
    marginRight: "5px !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
  },
  viewSelectedButton: {
    border: `1px solid ${colors.navyBlue} !important`,
    color: `${colors.navyBlue} !important`,
    backgroundColor: "#FFFFFF !important",
    marginRight: "5px !important",
    textTransform: "capitalize !important",
    borderRadius: "5px !important",
  },
  viewButton: {
    border: `1px solid #E0E0E0 !important`,
    color: `#1B5DB6 !important`,
    backgroundColor: "#FFFFFF !important",
    marginRight: "5px !important",
    textTransform: "capitalize !important",
    borderRadius: "5px !important",
  },
  newviewButton: {
    border: `1px solid #E0E0E0 !important`,
    color: `#4F4F4F !important`,
    backgroundColor: "#FFFFFF !important",
    marginRight: "5px !important",
    textTransform: "capitalize !important",
    borderRadius: "5px !important",
  },
});
const Schedule = ({ getPageName }) => {
  const classes = useStyles();

  const [loader, setLoader] = useState(true);
  const [toggleSchedule, setToggleTodos] = useState(true);

  const [scheduleView, setScheduleView] = useState(0);
  const [anchorEmployeeView, setAnchorEmployeeView] = useState(null);
  const openEmployeeView = Boolean(anchorEmployeeView);
  const [employeeView, setEmployeeView] = useState("Employee view");
  const [filterCount, setFilterCount] = useState(0);

  // handling job dialog
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [showAddJob, setShowAddJob] = useState(false);

  const [dayView, setDayView] = useState("Week view");
  const [requestView, setRequestView] = useState(0);

  const [anchorElDayView, setAnchorElDayView] = useState(null);
  const openDayView = Boolean(anchorElDayView);

  const [showFilter, setShowFilter] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const getJobs = async (value) => {
    try {
      const { status, responseCode, data } = await Service.getFilteredJobs(
        value
      );
      if (status === true && responseCode === 200) {
        setLoader(false);
        if (data.count > 0) {
          setToggleTodos(false);
          const allJobs = [];
          for (let index = 0; index < data?.tasks.length; index++) {
            const element = data?.tasks[index];
            const updatedElement = {
              ...element,
              color: `#${Math.floor(Math.random() * 16347215).toString(16)}`,
            };
            allJobs.push(updatedElement);
          }
          setFilteredJobs(allJobs);
        }
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };
  const getUnschedule = async () => {
    try {
      const { data, responseCode, message, status } =
        await Service.getUnscheduled();
      if (responseCode === 200 && status == true && data) {
        setLoader(false);
        if (data.count > 0) {
          setToggleTodos(false);
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: UnSchedule.js ~ line 55 ~ getUnschedule ~ error",
        error
      );
    }
  };
  useEffect(() => {
    getJobs();
    getUnschedule();
    getPageName("Scheduler");
    document.title = "Scheduler";
  }, []);
  return (
    <Grid container columnSpacing={1}>
      <AddJobDrawer
        handleAddJob={() => setShowAddJob(!showAddJob)}
        open={showAddJob}
        setScheduleView={setScheduleView}
      />
      {loader ? (
        <Box
          sx={{
            width: "100%",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Fragment>
          <Grid item xs={toggleSchedule ? 8.5 : 12}>
            {toggleSchedule ? (
              <Box
                sx={{
                  m: 1.5,
                  border: `1px solid ${colors.borderColor}`,
                  minHeight: "90vh",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: colors.textSecondary,
                      fontWeight: "bold",
                      mb: 1,
                    }}
                  >
                    {" "}
                    Scheduler
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: colors.textSecondary }}
                  >
                    Start by creating a job on the right.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    m: 3,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      className={
                        scheduleView === 0
                          ? classes.scheduleSelectedButton
                          : classes.scheduleButton
                      }
                      onClick={() => {
                        setScheduleView(0);
                        setShowFilter(false);
                      }}
                    >
                      Scheduled
                    </Button>
                    <Button
                      variant="contained"
                      className={
                        scheduleView === 1
                          ? classes.scheduleSelectedButton
                          : classes.scheduleButton
                      }
                      onClick={() => {
                        setScheduleView(1);
                        setShowFilter(false);
                      }}
                    >
                      Unscheduled
                    </Button>
                    <Button
                      variant="contained"
                      className={
                        scheduleView === 2
                          ? classes.scheduleSelectedButton
                          : classes.scheduleButton
                      }
                      onClick={() => {
                        setScheduleView(2);
                        setShowFilter(false);
                      }}
                    >
                      Requests
                    </Button>
                  </Box>
                  <Box>
                    {scheduleView === 0 ? (
                      <>
                        {(dayView === "Week view" ||
                          dayView === "Daily view") && (
                          <PrimaryButton
                            variant="contained"
                            onClick={() => setShowAddJob(!showAddJob)}
                            sx={{
                              border: `1px solid ${colors.blurBorder}`,
                              bgcolor: colors.skyBlue,
                              mr: 1,
                              textTransform: "capitalize",
                              color: colors.white,
                              ":hover": {
                                bgcolor: colors.darkBlue,
                              },
                            }}
                          >
                            <FaFileMedical style={{ marginRight: "8px" }} />
                            Add New Job
                          </PrimaryButton>
                        )}

                        <Button
                          variant="contained"
                          className={classes.viewButton}
                          sx={{ border: `1px solid #1B5DB6 !important ` }}
                          onClick={(event) =>
                            setAnchorEmployeeView(event.currentTarget)
                          }
                        >
                          {employeeView}{" "}
                          <RiArrowDownSLine
                            style={{
                              paddingLeft: 5,
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          />
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.viewButton}
                          sx={{ border: `1px solid #1B5DB6 !important ` }}
                          onClick={(event) =>
                            setAnchorElDayView(event.currentTarget)
                          }
                        >
                          {dayView}{" "}
                          <RiArrowDownSLine
                            style={{
                              paddingLeft: 5,
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          />{" "}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.viewButton}
                          sx={{ border: `1px solid #1B5DB6 !important ` }}
                          onClick={() => {
                            setShowFilter(!showFilter);
                          }}
                        >
                          <FaFilter
                            style={{
                              marginRight: 10,
                              fontSize: 12,
                              color: "#1B5DB6",
                            }}
                          />
                          Filters
                          <span
                            style={{
                              marginLeft: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              color: `${colors.white}`,
                              backgroundColor: "#1B5DB6",
                            }}
                          >
                            {filterCount}
                          </span>
                        </Button>

                        <Menu
                          anchorEl={anchorElDayView}
                          id="account-menu"
                          open={openDayView}
                          onClose={() => setAnchorElDayView(null)}
                          onClick={() => setAnchorElDayView(null)}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem onClick={() => setDayView("Daily view")}>
                            Daily View
                          </MenuItem>
                          <MenuItem onClick={() => setDayView("Week view")}>
                            Week View
                          </MenuItem>
                          {employeeView === "Job view" && (
                            <MenuItem onClick={() => setDayView("Month view")}>
                              Month View
                            </MenuItem>
                          )}
                        </Menu>
                        <Menu
                          anchorEl={anchorEmployeeView}
                          id="account-menu"
                          open={openEmployeeView}
                          onClose={() => setAnchorEmployeeView(null)}
                          onClick={() => setAnchorEmployeeView(null)}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setEmployeeView("Employee view");
                              dayView === "Month view"
                                ? setDayView("Daily view")
                                : setDayView(dayView);
                            }}
                          >
                            Employee View
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setEmployeeView("Job view");
                              setDayView(dayView);
                            }}
                          >
                            Job View
                          </MenuItem>
                        </Menu>
                      </>
                    ) : scheduleView === 2 ? (
                      <>
                        <Button
                          variant="contained"
                          className={
                            requestView === 0
                              ? classes.viewSelectedButton
                              : classes.newviewButton
                          }
                          onClick={() => setRequestView(0)}
                        >
                          Time-off
                          <span
                            style={{
                              marginLeft: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              color: `${colors.white}`,
                              backgroundColor:
                                requestView === 0 ? "#1B5DB6" : "#4F4F4F",
                            }}
                          >
                            0
                          </span>
                        </Button>
                        <Button
                          variant="contained"
                          className={
                            requestView === 1
                              ? classes.viewSelectedButton
                              : classes.newviewButton
                          }
                          onClick={() => setRequestView(1)}
                        >
                          Job Changes
                          <span
                            style={{
                              marginLeft: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              color: `${colors.white}`,
                              backgroundColor:
                                requestView === 1 ? "#1B5DB6" : "#4F4F4F",
                            }}
                          >
                            0
                          </span>
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box sx={{ px: 3 }}>
                  {/* Filters component */}

                  <Collapse in={showFilter}>
                    <Filters
                      setShowFilter={() => setShowFilter(!showFilter)}
                      setFilterCount={setFilterCount}
                      getJobs={getJobs}
                      employeeView={employeeView}
                      setIsFilter={setIsFilter}
                      setEmployeeView={setEmployeeView}
                      setDayView={setDayView}
                    />
                  </Collapse>
                </Box>
                {scheduleView === 0 &&
                  employeeView === "Employee view" &&
                  dayView === "Week view" && <WeeklyTable />}
                {scheduleView === 0 &&
                  employeeView === "Employee view" &&
                  dayView === "Daily view" && <DailyTable />}
                {scheduleView === 0 &&
                  employeeView === "Job view" &&
                  dayView === "Daily view" && (
                    <JobDailyTable
                      isFilter={isFilter}
                      filteredJobs={filteredJobs}
                    />
                  )}
                {scheduleView === 0 &&
                  employeeView === "Job view" &&
                  dayView === "Week view" && (
                    <JobWeeklyTable
                      isFilter={isFilter}
                      filteredJobs={filteredJobs}
                    />
                  )}
                {scheduleView === 0 &&
                  employeeView === "Job view" &&
                  dayView === "Month view" && <Calendar />}
                {scheduleView === 1 && <UnSchedule />}
                {scheduleView === 2 && requestView === 0 && <TimeOff />}
                {scheduleView === 2 && requestView === 1 && <JobChanges />}
              </>
            )}
          </Grid>
          {toggleSchedule && (
            <Grid item xs={3.5}>
              <AddJob setScheduleView={setScheduleView} setToggleTodos={setToggleTodos}/>
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};

export default Schedule;
