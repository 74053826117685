const colors = {
  primary: '#333333',
  primaryDark: '#212630',
  secondary: '#98A2B3',
  secondaryLight: '#7a7a7a',
  textPrimary: '#333',
  textSecondary: '#4F4F4F',
  white: '#fff',
  black: '#000',
  gray: '#F8F8F8',
  lightGray: '#F5F5F5',
  primaryGray: "#98A2B3",
  grayShade: "#EEEEEE",
  blackShade: "rgba(0,0,0,0.4)",
  shadowColor: "rgba(112, 115, 114, 0.2)",
  borderColor: "rgba(224, 224, 224, 0.5)",
  skyBlue: "#00A3FF",
  blurBorder: "(0, 122, 255, 0.6)",
  yellowShade: "rgba(242, 180, 70, 0.15)",
  yellow: "#E9B04B",
  orange: "#F3A560",
  purple: "#6967CEE5",
  green: "#59C36A",
  greenShade: "#E6F6E9",
  red: "#E26767",
  redShade: "#F3E1E2",
  darkGray: "#888888",
  darkgrayShade: "#ECECEC",
  darkgrayShade2: "#828282",
  darkBlue: "#2F80ED",
  navyBlue:"#1B5DB6",
  chip:{
    Blue: "#007AFF",
    lightBlue: "rgba(234, 241, 247, 0.7)",
    lightGreen:'#E6F6E9',
    green:'#59C36A',
    lightYellow:'#F2B44626',
    yellow:'#F2B446',
    lightGray:'#E0E0E0',
    gray:'#5F5959',
    lightRed:'#F3E1E2',
    red:'#E26767',

  }

};

export default colors;