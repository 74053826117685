import { Box, Button, Dialog, DialogContent, IconButton, Slide, Typography } from '@mui/material'
import React from 'react'
import Images from 'assets/images'
import { MdClose } from 'react-icons/md'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const Response = ({ open, handleClose, resCode,getTimeSheet }) => {
    
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="sm"
            TransitionComponent={Transition}
        keepMounted

            PaperProps={{
                style: { borderRadius: 10 }
            }}
        >
            <DialogContent>
                <Box sx={{ mb: 5 }}>
                    <IconButton onClick={handleClose} sx={{ px: 0, float: 'right' }} disableRipple={true}>
                        <MdClose size={24} />
                    </IconButton>
                </Box>
                {resCode === 200 ?
               <>
                <Box sx={{margin:'auto',background:"#F2F4F7",width:90,height:90,borderRadius:'50px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img src={Images.accept} width={'50'} alt="success"/>
                </Box>
                <Typography sx={{mt:1,textAlign:"center",fontWeight:700,fontSize:18}}>Timesheet submitted!</Typography>
                <Typography sx={{mt:1,textAlign:"center",fontSize:14,color:'#000000'}}>You have successfully submitted the timesheet for this employee. </Typography>
                <Typography sx={{mt:1,textAlign:"center",fontSize:14,color:'#98A2B3'}}>Click <span style={{cursor:"pointer",color:'#2F80ED'}}>here </span>to view timesheet. </Typography>
                <Box sx={{textAlign:'center'}}>
                <Button variant="contained" sx={{mt:3,width:120,background:'#2F80ED',borderRadius:'8px',padding: '8px 12px;' }}
                onClick={()=>{handleClose();getTimeSheet();}}
                >OK</Button>
                </Box>
               
               </>
                    :
                <>
                <Box sx={{margin:'auto',background:"#F2F4F7",width:90,height:90,borderRadius:'50px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img src={Images.caution} width={'50'} alt="warning"/>
                </Box>
                <Typography sx={{mt:1,textAlign:"center",fontWeight:700,fontSize:18}}>Attention!</Typography>
                <Typography sx={{mt:1,textAlign:"center",fontSize:14,color:'#000000'}}>Employee has already submitted timesheet for this date.  </Typography>
                <Typography sx={{mt:1,textAlign:"center",fontSize:14,color:'#98A2B3'}}>Click <span style={{cursor:"pointer",color:'#2F80ED'}}>here </span>to view submitted timesheet. </Typography>
                <Box sx={{textAlign:'center'}}>
                <Button variant="contained" sx={{mt:3,width:120,background:'#2F80ED',borderRadius:'8px',padding: '8px 12px;' }} 
                onClick={()=>{handleClose();getTimeSheet();}} >OK</Button>
                </Box>
                </>
                }
            </DialogContent>
        </Dialog>
    )
}

export default Response