import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import colors from "app/style/colors";
import React, { Fragment } from "react";
import { BsBoxArrowUpRight, BsPlusLg } from "react-icons/bs";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import moment from "moment-timezone";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useEffect } from "react";
import { getWeekDate } from "app/utils";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { Service } from "app/config/service";
import AddJobDrawer from "app/Components/Drawer/AddJobDrawer";
import EditJobDrawer from "app/Components/Drawer/EditJobDrawer";
import { MdClose } from "react-icons/md";
const useStyles = makeStyles({
  timeOffBoxTitle: {
    width: "90%",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: 600,
    padding: 5,
  },
  approvedTimeOffBox: {
    display: "flex",
    alignItems: "center",
    border: `2px solid ${colors.purple}`,
    borderRadius: 3,
    color: `${colors.purple}`,
    background: `${colors.gray}`,
    width: "100%",
  },
  reqestedTimeOffBox: {
    display: "flex",
    alignItems: "center",
    border: `2px solid ${colors.orange}`,
    borderRadius: 3,
    color: `${colors.orange}`,
    background: `${colors.gray}`,
    width: "100%",
  },
  chip: {
    "& .MuiChip-root": {
      borderRadius: "10px",
    },
  },
});
const JobWeeklyTable = ({ isFilter, filteredJobs }) => {
  const classes = useStyles();
  const [weeklyDate, setWeeklyDate] = useState();

  const [dateList, setDateList] = useState([]);

  // View More Job

  const [anchorElViewMore, setAnchorElViewMore] = useState(null);
  const openViewMoreJob = Boolean(anchorElViewMore);

  const [showAddJob, setShowAddJob] = useState(false);
  const [anchorElOptionAddJob, setAnchorElOptionAddJob] = useState(false);
  const [showEditJob, setShowEditJob] = useState(false);
  const [anchorElOptionEditJob, setAnchorElOptionEditJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const [jobs, setJobs] = useState([]);
  const [stats, setStats] = useState({});

  const [modelList, setModelList] = useState({});
  const [openViewAllJobsDialog, setOpenViewALlJobsDialog] = useState(false);
  const [totalHrsDay, setTotalHrsDay] = useState([]);
  const handleCalender = (action, val) => {
    setWeeklyDate(getWeekDate(action, val));
    createDateList(getWeekDate(action, val));
    getAssignJob(getWeekDate(action, val));
  };
  const createDateList = (date) => {
    let startTime = moment(date?.startTimeStamp);
    // let endTime = moment(date?.endTimeStamp)
    let dateList = [];
    for (let index = 1; index <= 7; index++) {
      // console.log(moment().weekday(index).format("ddd D"),'WEEKDAY');
      dateList.push(moment(startTime).weekday(index).format("dddd D"));
      setDateList(dateList);
    }
  };

  const handleViewMoreMenu = (event, value, date) => {
    try {
      setAnchorElViewMore(event.currentTarget);
      const list = value.assignDetails.filter(
        (val) => moment(val.startDate).format("dddd D") === date
      );
      setModelList({
        id: value._id,
        name: value.name,
        totalHrs: Math.ceil(value.totalHrs),
        date: list[0].startDate,
        assignWork: list,
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: WeeklyTable.js ~ line 77 ~ handleViewMoreModal ~ error",
        error
      );
    }
  };

  const getAssignJob = async (val) => {
    try {
      let date = getWeekDate();
      if (val) {
        date = val;
      }
      let startDate = moment(date?.startTimeStamp).format("YYYY-MM-DD");

      let endDate = moment(date?.endTimeStamp).format("YYYY-MM-DD");

      const { status, responseCode, message, data } =
        await Service.getJobWeekly(startDate, endDate);
      if (status === true && responseCode === 200) {
        // if (data.result.length < 1) {
        //   setShowAddJob(true);
        // }
        setJobs(data.result);
        setStats(data.stats ? data.stats : {});
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: WeeklyTable.js ~ line 75 ~ getAssignJob ~ error",
        error
      );
    }
  };

  const totalWeeklyHr = async () => {
    try {
      let arr = [...totalHrsDay];
      jobs.map((job) => {
        job.assignDetails.map((assign) => {
          let formatDate = moment(assign.startDate).format("dddd");
          let index = arr.findIndex((x) => x.formatDate == formatDate);
          if (index == -1) {
            arr.push({ [formatDate]: assign.difference });
          } else {
            arr[index].formatDate += assign.difference;
          }
        });
      });
      setTotalHrsDay(arr);
    } catch (error) {
      console.log(
        "🚀 ~ file: WeeklyTable.js ~ line 118 ~ totalWeeklyHr ~ error",
        error
      );
    }
  };

  const calculateTotalHours = (job,dateList) => {
    let totalHours = 0;

    job.assignDetails.forEach((work) => {
      const startDate = moment(work.startDate).format("dddd D");
      const dIndex = dateList.indexOf(startDate); 
      if (dIndex !== -1 && work.difference) {
        totalHours += Math.ceil(work.difference);
      }
    });

    return totalHours;
  };

  const [setTotalhrs,setSetTotalHrs] = useState(0);
  useEffect(() => {
    let totalHours = 0;
    for (let i = 0; i < dateList.length; i++) {
      const date = dateList[i];
      if(stats[date]){
        totalHours += stats[date];
      }
    }
    setSetTotalHrs(totalHours);
  },[dateList,stats])

  useEffect(() => {
    setWeeklyDate(getWeekDate());
    createDateList(getWeekDate());
    totalWeeklyHr();
    getAssignJob();
  }, []);

  
  return (
    <Fragment>
      <AddJobDrawer
        handleAddJob={() => setShowAddJob(!showAddJob)}
        open={showAddJob}
      />
      {selectedJob && (
        <EditJobDrawer
          handleEditJob={() => setShowEditJob(!showEditJob)}
          open={showEditJob}
          selectedJobId={selectedJob}
          setSelectedJobId={setSelectedJob}
          getAssignJob={getAssignJob}
          date={weeklyDate}
        />
      )}
      <Box sx={{ p: 3, borderRadius: "10px" }}>
        <TableContainer component={Paper} sx={{ borderBottomLeftRadius: 15 }}>
          {/* Weekly Date */}
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "10px 10px 0 0",
              color: `${colors.textSecondary}`,
              fontWeight: 600,
            }}
          >
            <RiArrowLeftSFill
              fontSize={22}
              cursor="pointer"
              onClick={() =>
                handleCalender("previous", weeklyDate?.startTimeStamp)
              }
            />
            {moment(weeklyDate?.startTimeStamp).format("MMM Do, YYYY")} &nbsp;-
            &nbsp;{moment(weeklyDate?.endTimeStamp).format("MMM Do, YYYY")}
            <RiArrowRightSFill
              fontSize={22}
              cursor="pointer"
              onClick={() => handleCalender("next", weeklyDate?.endTimeStamp)}
            />
          </Typography>

          {/* Table */}
          {jobs.length === 0 && (
            <Box sx={{ textAlign: "center", width: "100%", my: 20 }}>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 25, fontWeight: 600 }}
              >
                Scheduler
              </Typography>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 15, fontWeight: 400 }}
              >
                No jobs scheduled for this week
              </Typography>
            </Box>
          )}
          {jobs.length !== 0 && (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {/* Header */}
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Job
                  </TableCell>
                  {dateList.map((item, i) => {
                    return (
                      <TableCell key={i} align="center" sx={{ p: 0,minWidth:150 }}>
                        {item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              {jobs?.map((job, index) => {
                return (
                  <TableBody key={index}>
                    <TableCell
                      align="left"
                      sx={{
                        p: 0.7,
                        position: "relative",
                        minWidth: 150,
                        borderRight: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      <Box sx={{ position: "absolute", top: 10 }}>
                        <Typography variant="subtitle2" component={"span"}>
                          {job.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            m: 0.5,
                            color: "#4F4F4F",
                            textAlign: "center",
                            p: 0.5,
                            width: "40px",
                            background: "#E9E9E9",
                            fontSize: "14px",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        >
                          {calculateTotalHours(job,dateList)}h
                        </Box>
                      </Box>
                    </TableCell>
                    {dateList.map((date, dIndex) => {
                      let jobCount = 0;
                      return (
                        <TableCell
                          key={dIndex}
                          align="left"
                          sx={{
                            height: 100,
                            maxWidth: 100,
                            p: 0.7,
                            borderRight: "1px solid rgba(244,244,244,1)",
                          }}
                        >
                          {job?.assignDetails?.map((assign, index) => {
                            if (
                              moment(assign.startDate).format("dddd D") ===
                                dateList[dIndex] &&
                              jobCount <= 1
                            ) {
                              jobCount += 1;
                              return (
                                <Box key={index}>
                                  {(assign.status === "pending" ||
                                    assign.status === "request") && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 2,
                                        backgroundColor: `${colors.chip.lightBlue}`,
                                        color: `${colors.chip.Blue}`,
                                        borderRadius: "4px",
                                        mb: 1,
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                          }}
                                        >
                                          <span>
                                            {moment(assign.startTime)
                                              .format("h:mma")
                                              .replace("m", "")}{" "}
                                            -{" "}
                                            {moment(assign.endTime)
                                              .format("h:mma")
                                              .replace("m", "")}
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: 12,
                                              color: "#4F4F4F",
                                              background: "white",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {Math.ceil(assign.difference)}h
                                          </span>
                                        </Box>
                                      }
                                      onClick={() => {
                                        setSelectedJob(job._id);
                                        setShowEditJob(true);
                                      }}
                                    />
                                  )}
                                  {assign.status === "decline" && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 2,
                                        backgroundColor: `${colors.chip.lightRed}`,
                                        color: `${colors.chip.red}`,
                                        borderRadius: "4px",
                                        mb: 1,
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                          }}
                                        >
                                          <span>
                                            {moment(assign.startTime)
                                              .format("h:mma")
                                              .replace("m", "")}{" "}
                                            -{" "}
                                            {moment(assign.endTime)
                                              .format("h:mma")
                                              .replace("m", "")}
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: 12,
                                              color: "#4F4F4F",
                                              background: "white",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {Math.ceil(assign.difference)}h
                                          </span>
                                        </Box>
                                      }
                                      onClick={() => {
                                        setSelectedJob(job._id);
                                        setShowEditJob(true);
                                      }}
                                    />
                                  )}
                                  {assign.status === "inprogress" && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 2,
                                        backgroundColor: `${colors.chip.lightYellow}`,
                                        color: `${colors.chip.yellow}`,
                                        borderRadius: "4px",
                                        mb: 1,
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                          }}
                                        >
                                          <span>
                                            {moment(assign.startTime)
                                              .format("h:mma")
                                              .replace("m", "")}{" "}
                                            -{" "}
                                            {moment(assign.endTime)
                                              .format("h:mma")
                                              .replace("m", "")}
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: 12,
                                              color: "#4F4F4F",
                                              background: "white",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {Math.ceil(assign.difference)}h
                                          </span>
                                        </Box>
                                      }
                                      onClick={() => {
                                        setSelectedJob(job._id);
                                        setShowEditJob(true);
                                      }}
                                    />
                                  )}
                                  {assign.status === "notstarted" && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 2,
                                        backgroundColor: `${colors.chip.lightGray}`,
                                        color: `${colors.chip.gray}`,
                                        borderRadius: "4px",
                                        mb: 1,
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                          }}
                                        >
                                          <span>
                                            {moment(assign.startTime)
                                              .format("h:mma")
                                              .replace("m", "")}{" "}
                                            -{" "}
                                            {moment(assign.endTime)
                                              .format("h:mma")
                                              .replace("m", "")}
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: 12,
                                              color: "#4F4F4F",
                                              background: "white",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {Math.ceil(assign.difference)}h
                                          </span>
                                        </Box>
                                      }
                                      onClick={() => {
                                        setSelectedJob(job._id);
                                        setShowEditJob(true);
                                      }}
                                    />
                                  )}
                                  {assign.status === "done" && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 2,
                                        backgroundColor: `${colors.chip.lightGreen}`,
                                        color: `${colors.chip.green}`,
                                        borderRadius: "4px",
                                        mb: 1,
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                          }}
                                        >
                                          <span>
                                            {moment(assign.startTime)
                                              .format("h:mma")
                                              .replace("m", "")}{" "}
                                            -{" "}
                                            {moment(assign.endTime)
                                              .format("h:mma")
                                              .replace("m", "")}
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              fontSize: 12,
                                              color: "#4F4F4F",
                                              background: "white",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            {Math.ceil(assign.difference)}h
                                          </span>
                                        </Box>
                                      }
                                      onClick={() => {
                                        setSelectedJob(job._id);
                                        setShowEditJob(true);
                                      }}
                                    />
                                  )}
                                  {jobCount > 1 && (
                                    <Chip
                                      key={index}
                                      size="small"
                                      sx={{
                                        ".MuiChip-label": { width: "100%" },
                                        width: "100%",
                                        py: 0.3,
                                        backgroundColor: "white",
                                        borderRadius: "4px",
                                        border: "1px solid #9B9B9B",
                                      }}
                                      label={
                                        <Box
                                          sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            width: 120,
                                            cursor: "pointer",
                                          }}
                                          onClick={(event) =>
                                            handleViewMoreMenu(
                                              event,
                                              job,
                                              dateList[dIndex]
                                            )
                                          }
                                        >
                                          <BsBoxArrowUpRight color="#9B9B9B" />
                                          <span
                                            style={{
                                              fontSize: 12,
                                              color: "#9B9B9B",
                                              padding: "0px 5px",
                                            }}
                                          >
                                            View more
                                          </span>
                                        </Box>
                                      }
                                    />
                                  )}
                                </Box>
                              );
                            }
                          })}
                        </TableCell>
                      );
                    })}
                  </TableBody>
                );
              })}

              <TableBody sx={{ height: "35rem" }}>
                <TableRow>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224,224,224,1)" }}
                  ></TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow>
                  <TableCell
                    rowSpan={3}
                    sx={{
                      borderBottomLeftRadius: 15,
                      fontSize: 12,
                      textAlign: "center",
                      color: `${colors.white}`,
                      backgroundColor: `${colors.darkgrayShade2}`,
                    }}
                  >
                    Total hours per week:
                    <br />
                    {setTotalhrs} h
                  </TableCell>
                </TableRow>
                <TableRow>
                  {dateList.map((item, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          p: 0.5,
                          backgroundColor: `${colors.chip.lightGray}`,
                          fontWeight: 600,
                          color: `${colors.textSecondary}`,
                          textAlign: "center",
                        }}
                      >
                        {stats[item] ? stats[item] : 0}
                        {/* 87 */}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={7} sx={{ p: 0.5, textAlign: "center" }}>
                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightBlue}`,
                        color: `${colors.chip.Blue}`,
                        fontSize: 11,
                      }}
                      label="PENDING"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightGray}`,
                        color: `${colors.chip.gray}`,
                        fontSize: 11,
                      }}
                      label="NOT STARTED"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightYellow}`,
                        color: `${colors.chip.yellow}`,
                        fontSize: 11,
                      }}
                      label="IN PROGRESS"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightGreen}`,
                        color: `${colors.chip.green}`,
                        fontSize: 11,
                      }}
                      label="DONE"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightRed}`,
                        color: `${colors.chip.red}`,
                        fontSize: 11,
                      }}
                      label="DECLINED"
                    />
                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        background:
                          "repeating-linear-gradient(-58deg,#F8F8F8,#F8F8F8 8px,#ECB22E 10px,#ECB22E 10px)",
                        color: "rgb(153, 103, 15)",
                        fontSize: 11,
                      }}
                      label="Requested Time Off"
                    />
                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        border: "1px solid",
                        background:
                          "repeating-linear-gradient(-58deg,#F8F8F8,#F8F8F8 5px,#908eda 5px,#908eda 4px);",
                        color: "#7876D3",
                        fontSize: 11,
                      }}
                      label="Approved Time Off"
                    />
                  </TableCell>
                </TableRow>
                <Menu
                  anchorEl={anchorElViewMore}
                  id="account-menu"
                  open={openViewMoreJob}
                  onClose={() => setAnchorElViewMore(null)}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width: 320,
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                    },
                  }}
                >
                  <Box sx={{ p: 1.5 }}>
                    <Box
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        {modelList.name} ({modelList.totalHrs} hrs)
                      </Typography>
                      <AiOutlineClose
                        style={{ cursor: "pointer" }}
                        onClick={() => setAnchorElViewMore(null)}
                      />
                    </Box>

                    <Box sx={{ mt: 1 }}>
                      <Typography>
                        {moment(modelList.date).format("YYYY/MM/DD")}
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 1, maxHeight: "150px", overflowY: "auto" }}>
                      {modelList?.assignWork?.map((assign, index) => {
                        return (
                          <Box key={index}>
                            {(assign.status === "pending" ||
                              assign.status === "request") && (
                              <Chip
                                key={index}
                                size="small"
                                sx={{
                                  ".MuiChip-label": { width: "100%" },
                                  width: "100%",
                                  py: 2,
                                  backgroundColor: `${colors.chip.lightBlue}`,
                                  color: `${colors.chip.Blue}`,
                                  borderRadius: "4px",
                                  mb: 1,
                                }}
                                label={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <span>
                                      {moment(assign.startTime)
                                        .format("h:MMa")
                                        .replace("m", "")}{" "}
                                      -{" "}
                                      {moment(assign.endTime)
                                        .format("h:MMa")
                                        .replace("m", "")}
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "#4F4F4F",
                                        background: "white",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      {Math.ceil(assign.difference)}h
                                    </span>
                                  </Box>
                                }
                                onClick={() => {
                                  setSelectedJob(modelList.id);
                                  setAnchorElViewMore(null);
                                  setShowEditJob(true);
                                }}
                              />
                            )}
                            {assign.status === "decline" && (
                              <Chip
                                key={index}
                                size="small"
                                sx={{
                                  ".MuiChip-label": { width: "100%" },
                                  width: "100%",
                                  py: 2,
                                  backgroundColor: `${colors.chip.lightRed}`,
                                  color: `${colors.chip.red}`,
                                  borderRadius: "4px",
                                  mb: 1,
                                }}
                                label={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <span>
                                      {moment(assign.startTime)
                                        .format("h:MMa")
                                        .replace("m", "")}{" "}
                                      -{" "}
                                      {moment(assign.endTime)
                                        .format("h:MMa")
                                        .replace("m", "")}
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "#4F4F4F",
                                        background: "white",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      {Math.ceil(assign.difference)}h
                                    </span>
                                  </Box>
                                }
                                onClick={() => {
                                  setSelectedJob(modelList.id);
                                  setAnchorElViewMore(null);
                                  setShowEditJob(true);
                                }}
                              />
                            )}
                            {assign.status === "inprogress" && (
                              <Chip
                                key={index}
                                size="small"
                                sx={{
                                  ".MuiChip-label": { width: "100%" },
                                  width: "100%",
                                  py: 2,
                                  backgroundColor: `${colors.chip.lightYellow}`,
                                  color: `${colors.chip.yellow}`,
                                  borderRadius: "4px",
                                  mb: 1,
                                }}
                                label={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <span>
                                      {moment(assign.startTime)
                                        .format("h:MMa")
                                        .replace("m", "")}{" "}
                                      -{" "}
                                      {moment(assign.endTime)
                                        .format("h:MMa")
                                        .replace("m", "")}
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "#4F4F4F",
                                        background: "white",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      {Math.ceil(assign.difference)}h
                                    </span>
                                  </Box>
                                }
                                onClick={() => {
                                  setSelectedJob(modelList.id);
                                  setAnchorElViewMore(null);
                                  setShowEditJob(true);
                                }}
                              />
                            )}
                            {assign.status === "notstarted" && (
                              <Chip
                                key={index}
                                size="small"
                                sx={{
                                  ".MuiChip-label": { width: "100%" },
                                  width: "100%",
                                  py: 2,
                                  backgroundColor: `${colors.chip.lightGray}`,
                                  color: `${colors.chip.gray}`,
                                  borderRadius: "4px",
                                  mb: 1,
                                }}
                                label={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <span>
                                      {moment(assign.startTime)
                                        .format("h:MMa")
                                        .replace("m", "")}{" "}
                                      -{" "}
                                      {moment(assign.endTime)
                                        .format("h:MMa")
                                        .replace("m", "")}
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "#4F4F4F",
                                        background: "white",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      {Math.ceil(assign.difference)}h
                                    </span>
                                  </Box>
                                }
                                onClick={() => {
                                  setSelectedJob(modelList.id);
                                  setAnchorElViewMore(null);
                                  setShowEditJob(true);
                                }}
                              />
                            )}
                            {assign.status === "done" && (
                              <Chip
                                key={index}
                                size="small"
                                sx={{
                                  ".MuiChip-label": { width: "100%" },
                                  width: "100%",
                                  py: 2,
                                  backgroundColor: `${colors.chip.lightGreen}`,
                                  color: `${colors.chip.green}`,
                                  borderRadius: "4px",
                                  mb: 1,
                                }}
                                label={
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <span>
                                      {moment(assign.startTime)
                                        .format("h:MMa")
                                        .replace("m", "")}{" "}
                                      -{" "}
                                      {moment(assign.endTime)
                                        .format("h:MMa")
                                        .replace("m", "")}
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        color: "#4F4F4F",
                                        background: "white",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      {Math.ceil(assign.difference)}h
                                    </span>
                                  </Box>
                                }
                                onClick={() => {
                                  setSelectedJob(modelList.id);
                                  setAnchorElViewMore(null);
                                  setShowEditJob(true);
                                }}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>

                    <Button
                      size="small"
                      fullWidth
                      sx={{
                        mt: 1,
                        border: "1px solid #E9E9E9;",
                        textAlign: "center",
                        color: "#9B9B9B",
                      }}
                      onClick={() => {
                        setAnchorElViewMore(null);
                        setShowAddJob(!showAddJob);
                        anchorElOptionAddJob(null);
                      }}
                    >
                      <AiOutlinePlus style={{ marginRight: 6 }} />
                      <Typography sx={{ fontSize: 14 }}>Add job</Typography>
                    </Button>
                  </Box>
                </Menu>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default JobWeeklyTable;
