import { Box, Button, Chip, Divider, Drawer, FormControl, Link,Grid, IconButton, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Error } from 'app/Components/UI/Error'
import FieldLabel from 'app/Components/UI/FieldLabel'
import { Service } from 'app/config/service'
import colors from 'app/style/colors'
import React, { useState, useEffect ,useRef} from 'react'
import { Calendar } from 'react-date-range'
import { useForm } from 'react-hook-form'
import { MdClose, MdDateRange, MdOutlineDateRange } from 'react-icons/md'
import moment from 'moment'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BiPlus } from 'react-icons/bi'
import { LoadingBtn } from 'app/Components/UI/Buttons'
import AddJob from 'app/Components/Dialog/AddJob'
import { Fragment } from 'react'
import AddEmployee from 'app/Components/Dialog/AddEmployee'
import Picker from 'emoji-picker-react'
import CloseIcon from '@mui/icons-material/Close';
import Images from 'assets/images';
import { BsPaperclip, BsThreeDots, BsTrash } from 'react-icons/bs';
import { FaCheckCircle, FaFileMedical, FaPlusCircle, FaRegCalendarAlt, FaRegSmile, FaTimesCircle } from 'react-icons/fa'
import { MdImage, MdLink } from 'react-icons/md';
import Response from 'app/Components/Dialog/Response'
import dayjs from 'dayjs'
import { ErrorToaster } from 'app/Components/UI/Toaster'
import { AiFillPlusCircle } from 'react-icons/ai'




const useStyles = makeStyles({
    drawerPaper: {
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            overflowY: "auto",
            width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: colors.primaryGray,
            borderRadius: "10px",
        },
    },
    outlineInputBorder: {
        "& * > fieldset": {
            padding: "18px 12px",
            borderRadius: "10px",
        },
    },
    outlineInputBorderNone: {
        "& * > fieldset": {
            border: "none"
        },
    },
    boxSelectField: {
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: "5px 12px",
    },
    paper: {
        borderRadius: 12,
        marginTop: 8
    },
    boxStyle: {
        padding: 4.5,
        width: 90,
        height: 59,
        borderRadius: "5px",
        boxSizing: "border-box",
        border: "1px solid #E9EBED",
        background: "rgba(255, 255, 255, 0.1)",
        margin: 5
    },
})
const AddTimeSheet = ({ handleAddTimeSheet, open,getTimeSheet,startDate,endDate,employee,job }) => {
    const classes = useStyles();
    const { register, handleSubmit, formState: { errors }, clearErrors, setValue, control, reset } = useForm();
    const [employees, setEmployees] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);

    const [anchorElDate, setAnchorElDate] = useState(null);
    const openDate = Boolean(anchorElDate);
    const [date, setDate] = useState(Date.now());
    const [assignTimeList, setAssignTimeList] = useState([{ type: "", startTime:null, endTime: null }]);
    
    const [openJobDialog, setOpenJobDialog] = useState(false);
    const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);

    const [comment, setComment] = useState("");
    const [commentAnchorEl, setCommentAnchorEl] = useState(null);
    const [openComment, setOpenComment] = useState(commentAnchorEl);
    const [Addedcomments, setAddedComments] = useState([]);
    const [commentPosition, setCommentPosition] = useState();
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [openResponseDialog, setOpenResponseDialog] = useState(false);
    const [responseCodeDialog, setResponseCodeDialog] = useState(200);


    const handleAssignTimeList = () => {
        setAssignTimeList([...assignTimeList, { type: "", startTime:null, endTime:null }])
    };
    const handleAssignTimeListChange = (e, index, action) => {
        if (action) {
            const list = [...assignTimeList];
            list[index][action] = e
            setAssignTimeList(list);
            setValue(action+[index],e)
            clearErrors(action+[index]);
        }
        else {
            
            let { name, value } = e.target;
            if("type"+index===name){
               name= name.replace(index,"")
            }
            const list = [...assignTimeList];
            list[index][name] = value;
            setAssignTimeList(list);
        }
    };


    // get employees
    const getJob = async () => {
        try {
            const { status, responseCode, data } = await Service.getJobs()
            if (status === true && responseCode === 200) {
                setJobs(data?.jobs)
            }
        } catch (error) {
            console.log('file: Todos.js => line 164 => getTags => error', error);
        }
    }
    // get employees
    const getEmployee = async () => {
        try {
            const { status, responseCode, data } = await Service.getRegisteredEmployee()
            if (status === true && responseCode === 200) {
                setEmployees(data?.users)
            }
        } catch (error) {
            console.log('file: Todos.js => line 164 => getTags => error', error);
        }
    }
    const handleEmojiClick = (event) => {
        setCommentAnchorEl(event.currentTarget);
        setOpenComment(!openComment)
      };
      const handleEmojiClose = () => {
        setCommentAnchorEl(null);
        setOpenComment(!openComment)
      };
      const onEmojiClick = (event, emojiObject) => {
        if (emojiObject?.emoji) {
          setCommentAnchorEl(null)
          setOpenComment(false)
          let text = [...comment]
          text.splice(commentPosition, 0, emojiObject?.emoji)
          setValue("taskComments", text.join(''))
          setComment(text.join(''))
        }
      };
      const hiddenImageInput = useRef(null);
      const handleImageClick = event => {
        hiddenImageInput.current.click();
      };
    
      const handleImage = async (e) => {
        try {
          let formData = new FormData()
          formData.append("document_type", '1')
          formData.append("document", e.target.files[0])
          const { responseCode, status, message, data } = await Service.upload(formData)
          if (status === true && responseCode === 200) {
            let image = [...images]
            image.push(data)
            setImages(image)
            e.target.value = null
          }
    
        } catch (error) {
          console.log("🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error", error)
        }
      }
      const deleteCommentImage=(i) => {
        try {
          let image = [...images]
          image.splice(i,1)
            setImages(image)
        } catch (error) {
          console.log(error)
        }
    
      }
    
    
      const hiddenFileInput = useRef(null);
      const handleFileClick = event => {
        hiddenFileInput.current.click();
      };
    
      const handleFile = async (e) => {
        try {
          let formData = new FormData()
          formData.append("document_type", '2')
          formData.append("document", e.target.files[0])
          const { responseCode, status, message, data } = await Service.upload(formData)
          if (status === true && responseCode === 200) {
            let filesName = [...files]
            filesName.push(data)
            setFiles(filesName)
            e.target.value = null
          }
    
        } catch (error) {
          console.log("🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error", error)
        }
      }
    
      const deleteCommentFile=(i) => {
        try {
          let filesName = [...files]
          filesName.splice(i,1)
          setFiles(filesName)
        } catch (error) {
          console.log(error)
        }
    
      }
      const clearComment = () => {
        setComment("")
        setImages([])
        setFiles([])
      }
      const handleAddedComments = () => {
    
        let addComment = [...Addedcomments]
        addComment.push({ content: comment, attachments: [...files, ...images],created_on:new Date() })
        setAddedComments(addComment)
        clearComment()
      }
      const handleDeleteAddedComments = (index) => {
    
        let addComment = [...Addedcomments]
        addComment.splice(index,1)
        setAddedComments(addComment)
        clearComment()
      }



    const addTimeSheet = async (formData) => {
        try {
              let dateFormat=moment(date).format("YYYY-MM-DD")

              for (let index = 0; index < assignTimeList.length; index++) {
                let startTime=moment(assignTimeList[index].startTime['$d']).format("hh:mm:ssZZ")
                let endTime=moment(assignTimeList[index].endTime['$d']).format("hh:mm:ssZZ")
                
                if(endTime<startTime){
                    ErrorToaster("Endtime is not less than starttime")
                    return 0
                }
                else{
                    assignTimeList[index].startTime=dateFormat+'T'+startTime;
                     assignTimeList[index].endTime=dateFormat+'T'+endTime;
                }
              }
            let obj={
                date,
                employee:formData.assignTo,
                job:formData.job,
                time_details:assignTimeList,
                comments: Addedcomments
            }   
            
            const { status, responseCode, data } = await Service.createTimeSheet(obj)
            if (status === true && responseCode === 200) {
                handleAddTimeSheet()
                getTimeSheet(startDate,endDate,employee,job)
                setResponseCodeDialog(200)
                setOpenResponseDialog(true)
                reset({ date: '',assignTo:"",job:"" });
                setAssignTimeList([ { type: "", startTime:null, endTime:null }])
                setAddedComments([])
                setFiles([])
                setImages([])

            }
        } 
        catch (error) {
            console.log('file: Todos.js => line 164 => getTags => error', error);
            setResponseCodeDialog(400)
            setOpenResponseDialog(true)
            handleAddTimeSheet()
        }
    }

    useEffect(() => {

        getEmployee()
        getJob()
    }, []);
    return (
        <Fragment>
        <AddJob open={openJobDialog} handleClose={() => setOpenJobDialog(false)} getJobs={getJob} />
        <AddEmployee open={openEmployeeDialog} handleClose={() => setOpenEmployeeDialog(false)}  getEmployees={getEmployee}/>
        <Response open={openResponseDialog}  handleClose={() => setOpenResponseDialog(false)} resCode={responseCodeDialog} />
        <Drawer
            anchor="right"
            open={open}
            onClose={handleAddTimeSheet}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {/* <TimeSheetComponent/> */}
            <Box
                sx={{ width: 340, pt: 10 }}
                role="presentation"
            >
                <Box sx={{ bgcolor: colors.white, width: "100%", height: "93vh", overflow: "scroll", p: 1.5 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                            px: 2
                        }}
                    >
                        <Typography variant="h6" sx={{ color: colors.textSecondary, fontWeight: "bold" }}>Add Time Manually</Typography>
                        <IconButton onClick={handleAddTimeSheet} sx={{ px: 0 }}>
                            <MdClose size={24} />
                        </IconButton>

                    </Box>
                    <Typography component='form' sx={{ my: 2.5 }} onSubmit={handleSubmit(addTimeSheet)}>
                        {/*Job Name */}
                        <FormControl variant="standard" fullWidth sx={{ mb: 1 }} >
                            <FieldLabel>Job Name*</FieldLabel>
                            <Grid container alignItems="center" className={classes.boxSelectField}>
                                <Grid item xs={10} >
                                    <Select
                                        variant="standard"
                                        fullWidth
                                        defaultValue="none"
                                        name="job"
                                        MenuProps={{
                                          PaperProps: {
                                            sx: {
                                              ".MuiList-root": {
                                                maxHeight:250
                                              }
                                            }
                                          }}}

                                        {...register("job",
                                            {
                                                required: "Please select job .",
                                                validate: value => value !== 'none' || 'error message'
                                            }
                                        )}
                                        
                                        sx={{
                                            borderColor: errors?.assginTo?.message && "red",
                                            color: colors.secondaryLight,
                                            "::before": {
                                                display: "none",
                                            },
                                            "::after": {
                                                display: "none",
                                            },
                                        }}
                                    >
                                        <MenuItem value={"none"} disabled sx={{display:"none"}}>
                                            Select Job Name
                                        </MenuItem>
                                        {jobs.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Divider orientation="vertical" sx={{ height: 20, }} />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={()=>setOpenJobDialog(true)} >
                                        <FaPlusCircle size={16} color="black" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {errors?.job?.message && (
                                <Error message={"Please select a job"} />
                            )}
                        </FormControl>
                        {/*Employee Name */}
                        <FormControl variant="standard" fullWidth sx={{ mb: 1 }} >
                            <FieldLabel>Employee*</FieldLabel>
                            <Grid container alignItems="center" className={classes.boxSelectField}>
                                <Grid item xs={10} >
                                    <Select
                                        variant="standard"
                                        fullWidth
                                        defaultValue="none"
                                        name="assignTo"
                                        {...register("assignTo",
                                            {
                                                required: "Please select employee .",
                                                validate: value => value !== 'none' || 'error message'
                                            }
                                        )}
                                        // onChange={(e)=>handleServiceChange(e,index)}
                                        sx={{
                                            borderColor: errors?.assignTo?.message && "red",
                                            color: colors.secondaryLight,
                                            
                                            "::before": {
                                                display: "none",
                                            },
                                            "::after": {
                                                display: "none",
                                            },
                                        }}
                                        MenuProps={{
                                          PaperProps: {
                                            sx: {
                                              ".MuiList-root": {
                                                maxHeight:250
                                              }
                                            }
                                          }}}
                                    >
                                        <MenuItem value={"none"} disabled sx={{display:"none"}}>
                                            Select Employee
                                        </MenuItem>
                                        {employees.map((item) => (
                                            <MenuItem key={item._id} value={item._id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={0.5}>
                                    <Divider orientation="vertical" sx={{ height: 20, }} />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={()=> setOpenEmployeeDialog(true)}>
                                        <FaPlusCircle size={16} color="black" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {errors?.assignTo?.message && (
                                <Error message={"Please select a employee"} />
                            )}
                        </FormControl>
                        {/* Date */}
                        <FormControl variant="standard" fullWidth >
                            <FieldLabel>Date</FieldLabel>
                            <TextField
                                disabled
                                hiddenLabel
                                placeholder='Select date'
                                variant="outlined"
                                className={classes.outlineInputBorder}
                                value={moment(date).format("DD MMMM YYYY")}
                                onClick={(event) => { setAnchorElDate(event.currentTarget) }}
                                size="small"
                                // {...register("date"
                                // )}
                                InputProps={{
                                  startAdornment:
                                  <IconButton disableRipple sx={{borderRadius:0,backgroundColor:'#F3F7F9',marginLeft:'-13px',mr:1,mt:0.2}} ><MdOutlineDateRange/></IconButton>,
                                  
                                  }}
                            />
                            <Menu
                                anchorEl={anchorElDate}
                                open={openDate}
                                onClose={() => setAnchorElDate(null)}
                            >
                                <Calendar
                                  weekStartsOn={1}
                                    onChange={item => { setDate(item); setAnchorElDate(null) }}
                                    date={date}
                                />

                            </Menu>
                            {errors?.date?.message && (
                                <Error message={"Please select a date"} />
                            )}
                        </FormControl>
                        {assignTimeList.map((list, index) => {
                            return (
                                <Fragment key={index}>
                                    {/* Type */}
                                    <FormControl variant="standard" fullWidth >
                                        <FieldLabel>Type</FieldLabel>
                                        <Select
                                            className={classes.boxSelectField}
                                            sx={{
                                                borderColor: errors?.type?.message && "red",
                                                color: colors.secondaryLight,
                                                "::before": {
                                                    display: "none",
                                                },
                                                "::after": {
                                                    display: "none",
                                                },
                                            }}
                                            variant="standard"
                                            fullWidth
                                            value={assignTimeList[index].type===""?"none":assignTimeList[index].type}
                                            
                                            {...register(`type${index}`,
                                                {
                                                    required: "Please select type",
                                                    validate: value => value !== "none" || 'error message'
                                                }
                                            )}
                                            
                                            onChange={(e) => handleAssignTimeListChange(e, index)}

                                        >
                                            <MenuItem value={"none"} disabled sx={{display:"none"}}>
                                            Select time type
                                            </MenuItem>
                                            <MenuItem value={"onjob"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" />
                                            </MenuItem>
                                            <MenuItem value={"delay"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#EB5757`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delayed" />
                                            </MenuItem>
                                            <MenuItem value={"break"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#98A2B3`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On break" />
                                            </MenuItem>
                                            <MenuItem value={"travel"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                                            </MenuItem>
                                            <MenuItem value={"timesubmitted"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#FFA000`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Submitted" />
                                            </MenuItem>
                                            <MenuItem value={"timeapproved"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#49BE7B`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Approved" />
                                            </MenuItem>
                                        </Select>
                                        {errors?.[`type${index}`]?.message && (
                                <Error message={"Please select a type"} />
                            )}
                                    </FormControl>

                                    {/* Start Time */}
                                    <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                                        <FieldLabel>Start Time</FieldLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className={classes.outlineInputBorder}
                                                value={assignTimeList[index]?.startTime}
                                                name="startTime"
                                                onChange={(e) => handleAssignTimeListChange(e, index, "startTime")}
                                                // maxTime={dayjs().set('hour', assignTimeList[index].endTime[`$H`]).set('minute', assignTimeList[index].endTime[`$m`])}

                                                InputAdornmentProps={{ position: 'start' }}
                                                renderInput={(params) =>
                                                    <TextField size="small" {...params}
                                                    sx={{
                                                      button: { backgroundColor: '#F3F7F9',borderRadius:0,mt:'2px',height:40},

                                                    }}
                                                        {...register(`startTime${index}`,
                                                            {
                                                                required: "Please enter start Time",
                                                            }
                                                        )}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                        {errors?.[`startTime${index}`]?.message && (
                                            <Error message={"Please enter start time"} />
                                        )}
                                    </FormControl>

                                    {/* End Time */}
                                    <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                                        <FieldLabel>End Time</FieldLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className={classes.outlineInputBorder}
                                                // label="End Time"
                                                value={assignTimeList[index]?.endTime}
                                                name="endTime"
                                                minTime={assignTimeList[index]?.startTime!=null&&dayjs().set('hour', assignTimeList[index]?.startTime[`$H`]).set('minute', assignTimeList[index]?.startTime[`$m`])}
                                                // maxTime={dayjs().set('hour', 18)}
                                                InputAdornmentProps={{ position: 'start' }}
                                                onChange={(e) => handleAssignTimeListChange(e, index, "endTime")}
                                                renderInput={(params) =>
                                                    <TextField size="small" {...params}
                                                    sx={{
                                                      button: { backgroundColor: '#F3F7F9',borderRadius:0,mt:'2px',height:40},

                                                    }}
                                                        {...register(`endTime${index}`,
                                                            {
                                                                required: "Please enter end time",
                                                            }
                                                        )}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                        {errors?.[`endTime${index}`]?.message && (
                                            <Error message={"Please enter end time"} />
                                        )}
                                    </FormControl>
                                </Fragment>
                            )
                        })}
                        <Box sx={{ width: "100%", my: 1,textAlign:"center" }} >
                            <Button
                            disableRipple disableFocusRipple disableElevation
                                variant="contained"
                                sx={{ px: 6, backgroundColor: "transparent", color: "#333333",boxShadow:0,"&:hover": {backgroundColor: "transparent"} }}
                                onClick={handleAssignTimeList}
                            >
                                <AiFillPlusCircle style={{ marginRight: 5, fontSize: 17 }} />
                                <Typography sx={{fontWeight: 500, fontSize: 13 }}>Add time</Typography>
                            </Button>
                        </Box>

                        <FormControl variant="standard" fullWidth >
          <FieldLabel>Comment (Optional)</FieldLabel>
          
          {Addedcomments.map((item, index) =>

            // item.content.length > 35 ?
            //   <Typography key={index} sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content.slice(0, 35)}......</Typography>
            //   : <Typography key={index} sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content}</Typography>
            <React.Fragment>
              
              <Box
                key={index}
                sx={{
                  backgroundColor: colors.darkgrayShade,
                  border: `1px solid ${colors.borderColor}`,
                  p: 1,
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <Fragment>
                  <Box sx={{width:"100%", textAlign:"right"}}>
                  <IconButton size="small" sx={{ py: 0,}} onClick={()=>handleDeleteAddedComments(index)} >
                          <BsTrash  />
                        </IconButton>
                        
                  </Box>
                  {/* Attachments */}
                  {item?.attachments.length > 0
                    &&
                    <Typography variant="body2" fontWeight="bold" mt={2}>
                      Attachments
                    </Typography>
                  }
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>
                    {item?.attachments.map((attachment, i) => {
                      return (
                        <Box key={i}>
                          {attachment.document_type === '1' &&
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <Link href={`${attachment?.document}`} target="_blank">
                                <img src={`${attachment?.document}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                              </Link>
                              <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                              </Typography>
                            </Box>
                          }

                          {
                            attachment.document_type === '2'
                              &&
                              attachment.document.slice(-4) === ('.txt')
                              ?
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link href={`${attachment?.document}`} target="_blank">
                                  <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                </Link>
                                <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                  {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                </Typography>
                              </Box>
                              :
                              attachment.document.slice(-4) === ('.doc')
                                ?
                                <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                  <Link href={`${attachment?.document}`} target="_blank">
                                    <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                  </Link>
                                  <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                  </Typography>
                                </Box>
                                :
                                attachment.document.slice(-5) === ('.docx')
                                  ?
                                  <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                    <Link href={`${attachment?.document}`} target="_blank">
                                      <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                    </Link>
                                    <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                      {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                    </Typography>
                                  </Box>
                                  :
                                  attachment.document.slice(-4) === ('.pdf')
                                    ?
                                    <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                      <Link href={`${attachment?.document}`} target="_blank">
                                        <img src={`${Images.pdf}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                      </Link>
                                      <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                        {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                      </Typography>
                                    </Box>
                                    :
                                    attachment.document.slice(-4) === ('.csv')
                                      ?
                                      <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                        <Link href={`${attachment?.document}`} target="_blank">
                                          <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                        </Link>
                                        <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                          {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                        </Typography>
                                      </Box>
                                      :
                                      attachment.document.slice(-4) === ('.xls')
                                        ?
                                        <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                          <Link href={`${attachment?.document}`} target="_blank">
                                            <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                          </Link>
                                          <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                          </Typography>
                                        </Box>
                                        :
                                        attachment.document.slice(-5) === ('.xlsx')
                                          ?
                                          <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                            <Link href={`${attachment?.document}`} target="_blank">
                                              <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                            </Link>
                                            <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                              {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                            </Typography>
                                          </Box>
                                          :
                                          attachment.document.slice(-5) === ('.pptx')
                                            ?
                                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                              <Link href={`${attachment?.document}`} target="_blank">
                                                <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                              </Link>
                                              <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                              </Typography>
                                            </Box>
                                            :
                                            attachment.document.slice(-5) === ('.ppt')
                                              ?
                                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                                <Link href={`${attachment?.document}`} target="_blank">
                                                  <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                </Link>
                                                <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                  {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                                </Typography>
                                              </Box>
                                              :
                                              // <Box sx={{mr:2,mb:2,width:"70px"}}>
                                              // <Link href={`${attachment?.document}`} target="_blank">
                                              //   <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                              // </Link>
                                              // <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                              // {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                              // </Typography>
                                              // </Box>
                                              ""
                          }

                        </Box>
                      )
                    })
                    }

                  </Box>
                {item?.content &&
                 <>
                  <Divider />
                   {/* Comments text */}
                  <Typography variant="body2" color="initial" my={2}>
                    {item?.content}
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                 </>
              }
                </Fragment>
              </Box>
              </React.Fragment>
            )
            }

          <Box sx={{ mt: 1 }} className={classes.boxSelectField}>
            <Grid container alignItems="center">
              <Grid item xs={1.5} onClick={handleEmojiClick}>
                <IconButton >
                  <FaRegSmile size={18} />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={commentAnchorEl}
                  open={openComment}
                  onClose={handleEmojiClose}
                  transformOrigin={{
                    vertical: 50,
                    horizontal: 0,
                  }}
                  PaperProps={{
                    style: {
                      // width: 120,
                      borderRadius: "10px"
                    },
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >

                  <Picker
                    disableSearchBar
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                  />
                </Menu>

              </Grid>
              <Grid item xs={1.5}>
                <IconButton onClick={handleImageClick}>
                  <MdImage size={20} />
                </IconButton>
                <input type="file"
                  accept="image/*"
                  ref={hiddenImageInput}
                  onChange={handleImage}
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid item xs={1.5}>
                <IconButton onClick={handleFileClick}>
                  <BsPaperclip size={18} />
                </IconButton>
                <input type="file"
                  accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  ref={hiddenFileInput}
                  onChange={handleFile}
                  style={{ display: 'none' }}
                />
              </Grid>
              {/* <Grid item xs={1.5}>
                <IconButton>
                  <MdLink size={20} />
                </IconButton>
              </Grid> */}
            </Grid>

            <Divider />
            <Box sx={{ p: 1 }} >
              {images.length > 0 &&
                <>
                  <Box >
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Images:</Typography>
                    <Box sx={{display:'flex',flexWrap:'wrap', alignItems:"center"}}>
                    {images.map((item, i) => (
                      <Box >
                      <IconButton size ='small' 
                      sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                      onClick={()=>deleteCommentImage(i)}
                      >
                        <CloseIcon fontSize="10px" />
                       </IconButton>
                     <Box sx={{mr:2,mb:2,width:"70px"}}>
                       <img src={item?.document} width='70px' height='70px' style={{ margin: '5px' }} alt='file' />
                       <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                             {item?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                             </Typography>
                     </Box>
                     </Box>
                    )
                    )}
                    </Box>
                  </Box>
                  <Divider />

                </>
              }
              {files.length > 0 &&
                <>
                  <Box sx={{ mt: 1 }} >
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Files:</Typography>
                    <Box sx={{display:'flex',flexWrap:'wrap', alignItems:"center"}}>
                    {files.map((attachment, i) => {
                      return (
                        <Box sx={{mr:2,mb:2}}>
                        {
                          attachment.document?.slice(-4) === '.txt' ?
                          <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                            <Box sx={{width:'70px'}}>
                              <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                              
                            <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.txt','')}
                            </Typography>

                            </Box>
                            </Box>
                            : attachment.document.slice(-4) === '.doc' ?
                            <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                            <Box sx={{width:'70px'}}>
                              
                                <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                              
                              <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.doc','')}
                                    </Typography>
                              </Box>
                              </Box>
                              : attachment.document.slice(-5) === '.docx' ?
                              <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                <Box sx={{width:'70px'}}>
                                  
                                    <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                  
                                  <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.docx','')}
                                    </Typography>
                                </Box>
</Box>
                                :
                                
                                attachment.document.slice(-4) === ('.pdf')
                                  ?
                                  <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                  <Box sx={{ width: '70px' }}>
                                    
                                      <img src={`${Images.pdf}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                    
                                    <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.pdf','')}
                                    </Typography>
                                  </Box>
</Box>
                                  :
                                  
                                  attachment.document.slice(-4) === '.csv'
                                    ?
                                    <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                    <Box sx={{width:'70px'}}>
                                      
                                        <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                      
                                      <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.csv','')}
                                    </Typography>
                                    </Box>
                                    </Box>
                                    :
                                    attachment.document.slice(-5) === '.xlsx'
                                      ?
                                      <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                      <Box sx={{width:'70px'}}>
                                        
                                          <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                        
                                        <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.xlsx','')}
                                    </Typography>
                                      </Box>
                                      </Box>
                                      :
                                      attachment.document.slice(-4) === '.xls'
                                        ?
                                        <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                        <Box sx={{width:'70px'}}>
                                          
                                            <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                          
                                          <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.xls','')}
                                    </Typography>
                                        </Box>
                                        </Box>
                                        :
                                        attachment.document.slice(-4) === '.ppt'
                                          ?
                                          <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                          <Box sx={{width:'70px'}}>
                                            
                                              <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                            
                                            <Typography noWrap  sx={{ fontSize: '13px', color: '#007AFF' }}>
                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.ppt','')}
                            </Typography>
                                          </Box>
</Box>
                                          :
                                          attachment.document.slice(-5) === '.pptx'
                                            ?
                                            <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                            <Box sx={{width:'70px'}}>
                                              
                                                <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                              
                                              <Typography noWrap  sx={{ fontSize: '13px', color: '#007AFF' }}>
                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.pptx','')}
                            </Typography>
                                            </Box>
                                            </Box>
                                            :
                                            <Box>
                          <IconButton size ='small' 
                          sx={{backgroundColor:'#ECECEC',width:'20px',height:'12px ',position:'relative',left:'50px',top:10}}
                          onClick={()=>deleteCommentFile(i)}
                          >
                              <CloseIcon fontSize="10px" />
                              </IconButton>
                                            <Box sx={{width:'70px'}}>
                                              
                                                <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                              
                                            </Box>
                                            </Box>
                        }

                      </Box>

                      )
                    })}
                    </Box>
                  </Box>
                  <Divider />

                </>
              }
            </Box>
            <TextField
              hiddenLabel
              multiline
              placeholder='Add your comment...'
              variant="outlined"
              fullWidth
              value={comment}
              className={classes.outlineInputBorderNone}
              sx={{ minHeight: "100px" }}
              error={errors?.taskComments?.message && (true)}
              size="small"
              onClick={(e) => {
                setCommentPosition(e.target.selectionStart)
              }}
              {...register("taskComments", {
                onChange: (e) => {
                  setComment(e.target.value)
                }
              }
              )}
            />
            <Box sx={{ float: "right" }}>
              <IconButton onClick={clearComment} >
                <FaTimesCircle size={16} color={colors.grayShade} />
              </IconButton>
              <IconButton onClick={handleAddedComments}>
                <FaCheckCircle size={16} color={colors.skyBlue} />
              </IconButton>
            </Box>
          </Box>
        </FormControl>

                        <Box sx={{ width: "100%" }} >
                            <LoadingBtn
                                fullWidth
                                loading={loading}
                                type="submit"
                                variant="contained"
                                style={{ px: 6 }}
                            >
                                Save
                            </LoadingBtn>
                        </Box>
                    </Typography>
                </Box>
            </Box>
        </Drawer >
        </Fragment>
    )
}

export default AddTimeSheet