import {
  Avatar,
  Box,
  Chip,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  tooltipClasses
} from "@mui/material";
import colors from "app/style/colors";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import { getDailyDate } from "app/utils";
import { Service } from "app/config/service";
import { Fragment } from "react";
import EditJobDrawer from "app/Components/Drawer/EditJobDrawer";
import {  styled} from "@mui/styles";

const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <span style={{ width: "320px" }}>{children}</span>
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C",
      },
    },
  },
});


const DailyTable = () => {
  const [value, setValue] = React.useState(0);
  const [dailyDate, setDailyDate] = useState(Date.now());
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // let time = [
  //   "09:00 AM",
  //   "10:00 AM",
  //   "11:00 AM",
  //   "12:00 PM",
  //   "01:00 PM",
  //   "02:00 PM",
  //   "03:00 PM",
  //   "04:00 PM",
  //   "05:00 PM",
  //   "06:00 PM"
  // ];

  let time = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  let temparr = [];
  const elRef = useRef([]);
  const elTableRef = useRef([]);

  const [workers, setWorkers] = useState([]);
  // console.log(
  //   "🚀 ~ file: DailyTable.js ~ line 51 ~ DailyTable ~ workers",
  //   workers
  // );

  const boxRef = useRef(null);
  const boxRef2 = useRef([]);


  const addToRef = (el) => {
    boxRef2.current = [];
    if (boxRef2 && !boxRef2.current.includes(el)) {
      boxRef2.current.push(el);
    }
  };
  const previous = () => {
    boxRef.current.scrollLeft += -100;
    boxRef2.current.forEach((el) => {
      el.scrollLeft += -100;
    });
  };
  const next = () => {
    // console.log(boxRef2.current.length);
    boxRef.current.scrollLeft += 100;
    boxRef2.current.forEach((el) => {
      el.scrollLeft += 100;
    });
  };

  const handleDailyCalender = (action, val) => {
    setDailyDate(getDailyDate(action, val));
    let startDate = getDailyDate(action, val);
    getScheduleWorker(moment(startDate.startTimeStamp).format("YYYY-MM-DD"));
  };

  const getScheduleWorker = async (startDate) => {
    try {
      const { status, responseCode, message, data } =
        await Service.getJobAssignByDaily(startDate, startDate);
      if (status === true && responseCode === 200) {
        setWorkers(data.result);
        if (data.result.length) {
          boxRef2.current = [];
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: DailyTable.js ~ line 73 ~ getScheduleWorker ~ error",
        error
      );
    }
  };

  const [showEditJob, setShowEditJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  useEffect(() => {
    setDailyDate(getDailyDate());
    getScheduleWorker(moment(Date.now()).format("YYYY-MM-DD"));
  }, []);
  return (
    <Fragment>
      {selectedJob && (
        <EditJobDrawer
          handleEditJob={() => setShowEditJob(!showEditJob)}
          open={showEditJob}
          selectedJobId={selectedJob}
          setSelectedJobId={setSelectedJob}
          getAssignJob={getScheduleWorker}
          date={dailyDate}
        />
      )}
      <Box sx={{ p: 3, borderRadius: "10px" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "calc(100% - 20px)",
            overflow: "hidden",
            borderBottomLeftRadius: 15,
            bottom: 0,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "10px 10px 0 0",
              color: `${colors.textSecondary}`,
              fontWeight: 600,
            }}
          >
            <RiArrowLeftSFill
              fontSize={22}
              cursor="pointer"
              onClick={() =>
                handleDailyCalender("previous", dailyDate?.startTimeStamp)
              }
            />
            {moment(dailyDate?.startTimeStamp).format("dddd,MMM Do, YYYY")}
            <RiArrowRightSFill
              fontSize={22}
              cursor="pointer"
              onClick={() =>
                handleDailyCalender("next", dailyDate?.startTimeStamp)
              }
            />
          </Typography>
          {workers.length === 0 && (
            <Box sx={{ textAlign: "center", width: "100%", my: 20 }}>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 25, fontWeight: 600 }}
              >
                Scheduler
              </Typography>
              <Typography
                sx={{ color: "#4F4F4F", fontSize: 15, fontWeight: 400 }}
              >
                No jobs are scheduled on this day
              </Typography>
            </Box>
          )}
          {workers.length !== 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 700 }}
                  >
                    Worker
                  </TableCell>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <Box
                      ref={boxRef}
                      sx={{ width: "100%", overflow: "hidden" }}
                    >
                      {time.map((item, index) => {
                        return (
                          <TableCell
                            key={index}
                            id={index}
                            sx={{ minWidth: 20, borderBottom: "none" }}
                          >
                            {item}
                          </TableCell>
                        );
                      })}
                    </Box>
                  </Box>
                </TableRow>
              </TableHead>
              {workers.map((item, index) => {
            
                return (
                  <TableBody>
                    <TableCell
                      align="left"
                      sx={{
                        p: 0.7,
                        minWidth: 150,
                        borderRight: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      <Box>
                        <Avatar
                          alt=""
                          src=""
                          sx={{
                            display: "inline-flex",
                            mr: 1,
                            width: "30px",
                            height: "30px",
                            bgcolor: item.colorCode,
                            fontSize: "12px",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.name?.split(" ").length > 1
                            ? item.name?.charAt(0) +
                            item.name?.split(" ")[1].charAt(0)
                            : item.name?.charAt(0)}
                        </Avatar>
                        <Typography variant="subtitle2" component={"span"}>
                          {item.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            m: 0.5,
                            color: "#888888",
                            textAlign: "center",
                            p: 0.5,
                            width: "40px",
                            background: "#E9E9E9",
                            fontSize: "14px",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        >
                          {item.totalHrs}h
                        </Box>
                      </Box>
                    </TableCell>

                    <Box
                      ref={addToRef}
                      sx={{
                        width: "calc(100% - 50px)",
                      overflow: "auto",
                      position: "relative",
                      top: 1
                    }}
                    >
                      {time.map((timeV, index) => {
                        return (
                          <TableCell
                            key={index}
                            id={index}
                            align="left"
                            sx={{
                              verticalAlign: "middle",
                              height: 77,
                              minWidth: 100,
                              p: 0.7,
                              borderRight: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            {item.assignWorks.map((work, index) => {
                              var mStart = moment(work.assignDetails.startTime);
                              var roundUpStartTime =
                                mStart.minute() ||
                                  mStart.second() ||
                                  mStart.millisecond()
                                  ? mStart.add(0, "hour").startOf("hour")
                                  : mStart.startOf("hour");
                              var width =
                                work.assignDetails.timeDiff == 0
                                  ? 106
                                  : work.assignDetails.timeDiff * 106;
                              let count = 0;
                              if (
                                roundUpStartTime.format("hh:mm A") === timeV
                              ) {
                                count += 1;
                                return (
                                  <Box 
                                  sx={{
                                    display:'flex',
                                  }}
                                  key={index}
                                  >
                                    {(work.assignDetails.status === "pending" ||
                                      work.assignDetails.status ===
                                      "request") && (
                                        <CustomTooltip title={work.name} arrow>
                                        <Chip
                                          onClick={() => {
                                            setSelectedJob(work._id);
                                            setShowEditJob(true);
                                          }}
                                          label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.assignDetails.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.assignDetails.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.assignDetails.timeDiff)}h
                                                </Typography>
                                              </Box>
                                            }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightBlue}`,
                                            color: `${colors.chip.Blue}`,
                                            width: width - 12,
                                          }}
                                        />
                                        </CustomTooltip>
                                      )}
                                    {work.assignDetails.status ===
                                      "decline" && (
                                        <CustomTooltip title={work.name} arrow>
                                        <Chip
                                          onClick={() => {
                                            setSelectedJob(work._id);
                                            setShowEditJob(true);
                                          }}
                                                label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.assignDetails.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.assignDetails.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.assignDetails.timeDiff)}h
                                                </Typography>
                                              </Box>
                                            }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightRed}`,
                                            color: `${colors.chip.red}`,
                                            width: width - 12,
                                          }}
                                        />
                                        </CustomTooltip>
                                      )}
                                    {work.assignDetails.status ===
                                      "inprogress" && (
                                        <CustomTooltip title={work.name} arrow>
                                        <Chip
                                          onClick={() => {
                                            setSelectedJob(work._id);
                                            setShowEditJob(true);
                                          }}
                                                label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.assignDetails.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.assignDetails.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.assignDetails.timeDiff)}h
                                                </Typography>
                                              </Box>
                                            }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightYellow}`,
                                            width: width - 12,
                                          }}
                                        />
                                        </CustomTooltip>
                                      )}
                                    {work.assignDetails.status ===
                                      "notstarted" && (
                                        <CustomTooltip title={work.name} arrow>
                                        <Chip
                                          onClick={() => {
                                            setSelectedJob(work._id);
                                            setShowEditJob(true);
                                          }}
                                                label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.assignDetails.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.assignDetails.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.assignDetails.timeDiff)}h
                                                </Typography>
                                              </Box>
                                            }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightGray}`,
                                            color: `${colors.chip.gray}`,
                                            width: width - 12,
                                          }}
                                        />
                                        </CustomTooltip>
                                      )}
                                    {work.assignDetails.status === "done" && (
                                      <CustomTooltip title={work.name} arrow>
                                      <Chip
                                        onClick={() => {
                                          setSelectedJob(work._id);
                                          setShowEditJob(true);
                                        }}
                                              label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.assignDetails.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.assignDetails.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.assignDetails.timeDiff)}h
                                                </Typography>
                                              </Box>
                                            }
                                        sx={{
                                          mb: 2,
                                          borderRadius: "5px",
                                          fontWeight: 600,
                                          backgroundColor: `${colors.chip.lightGreen}`,
                                          color: `${colors.chip.green}`,
                                          width: width - 12,
                                        }}
                                      />
                                      </CustomTooltip>
                                    )}
                                    {count >= 2 && (
                                      <Chip
                                        label={"View More"}
                                        sx={{
                                          borderRadius: "5px",
                                          fontWeight: 500,
                                          backgroundColor: `${colors.white}`,
                                          border: `1px solid ${colors.chip.lightGray}`,
                                          color: `#9B9B9B`,
                                          width: width - 12,
                                        }}
                                      />
                                    )}
                                  </Box>
                                );
                              }
                            })}
                          </TableCell>
                        );
                      })}
                    </Box>
                  </TableBody>
                );
              })}

              <TableRow>
                <TableCell colSpan={7} sx={{ p: 0.5, textAlign: "center" }}>
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightBlue}`,
                      color: `${colors.chip.Blue}`,
                      fontSize: 11,
                    }}
                    label="PENDING"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGray}`,
                      color: `${colors.chip.gray}`,
                      fontSize: 11,
                    }}
                    label="NOT STARTED"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightYellow}`,
                      color: `${colors.chip.yellow}`,
                      fontSize: 11,
                    }}
                    label="IN PROGRESS"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGreen}`,
                      color: `${colors.chip.green}`,
                      fontSize: 11,
                    }}
                    label="DONE"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightRed}`,
                      color: `${colors.chip.red}`,
                      fontSize: 11,
                    }}
                    label="DECLINED"
                  />
                </TableCell>
              </TableRow>
            </Table>
          )}
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default DailyTable;




// use the concat method to add the new element to the existing boxRef2.current array.