const Images = {
doc:require('./extension/doc.png'),
pdf:require('./extension/pdf.png'),
excel:require('./extension/xls.png'),
word:require('./extension/word.png'),
ppt:require('./extension/ppt.png'),
accept:require('./accept.png'),
caution:require('./caution.png'),
}

export const Loading = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="0" fill="none" stroke="#212630" strokeWidth="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s" />
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s" />
      </circle><circle cx="50" cy="50" r="0" fill="none" stroke="#00a3ff" strokeWidth="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s" />
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s" />
      </circle>
    </svg>
  )
}
export const Wave = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#333333" fillOpacity="1" d="M0,64L80,90.7C160,117,320,171,480,197.3C640,224,800,224,960,192C1120,160,1280,96,1360,64L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
  )
}

export default Images;