import { Calendar, DateRange, } from 'react-date-range';
import moment from 'moment';
import { Box, Button, Grid, Menu, MenuItem, Radio, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from 'app/style/colors';
import { Loading } from 'assets/images';
import React, { useEffect, useState, Fragment } from 'react'
import { AiFillFileAdd } from 'react-icons/ai';
import { BsChat, BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete, MdKeyboardArrowDown } from 'react-icons/md';
import { RiArrowLeftSFill, RiArrowRightSFill } from 'react-icons/ri';
import { getDailyDate, getWeekDate } from 'app/utils';
import AddTimeSheet from 'app/Components/Drawer/TimeSheet/AddTimeSheet';
import { Service } from 'app/config/service';
import CountUp from 'react-countup';
import TimeSheetTable from 'app/Components/TimeSheet/TimeSheetTable';
import Request from './Request';


const useStyles = makeStyles({
    spanStyle: {
        position: "absolute",
        left: 0, top: 0,
        borderRadius: "8px 0px 0px 8px",
        height: "100%", width: "8px",
        backgroundColor: colors.skyBlue
    },
    boxStyle: {
        padding: 4.5,
        width: 90,
        height: 59,
        borderRadius: "5px",
        boxSizing: "border-box",
        border: "1px solid #E9EBED",
        background: "rgba(255, 255, 255, 0.1)",
        margin: 5
    },
    dotStyle: {
        position: "absolute",
        right: 0, top: 0,
        height: "100%",
        display: "flex", alignItems: "center", justifyItems: "center"
    },
})
const TimeSheet = ({ getPageName }) => {
    const classes = useStyles()

    const [loader, setLoader] = useState(false);
    const [timeSheet, setTimeSheet] = useState(false);
    const [timeSheetsData, setTimeSheetsData] = useState([]);
    const [requestCount, setRequestCount] = useState(0);
    const [onJob, setOnJob] = useState(0);
    const [breaks, setBreaks] = useState(0);
    const [travel, setTravel] = useState(0);
    const [delay, setDelay] = useState(0);

    const [view, setView] = useState(0);

    const [anchorElViewBy, setAnchorElViewBy] = useState(null);
    const openViewBy = Boolean(anchorElViewBy);

    const [selectedValueViewBY, setSelectedValueViewBY] = useState(null);
    const [viewBY, setViewBY] = useState("View By");
    const [filterEmployee, setFilterEmployee] = useState({ _id: "", name: "All Employee" });
    const [filterJob, setFilterJob] = useState({ _id: "", name: "All Job" });
    const [filter, setFilter] = useState([]);
    const [anchorElEmployeeFilter, setAnchorElEmployeeFilter] = useState(null);
    const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
    const [anchorElJobFilter, setAnchorElJobFilter] = useState(null);
    const [openJobFilter, setOpenJobFilter] = useState(false);

    const [addTimeSheet, setAddTimeSheet] = useState();
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [weeklyDate, setWeeklyDate] = useState();

    const [dailyDate, setDailyDate] = useState(Date.now());


    const handleWeeklyCalender = (action, val) => {
        setWeeklyDate(getWeekDate(action, val))
        let startDate = moment(getWeekDate(action, val).startTimeStamp).format("YYYY-MM-DD")
        let endDate = getWeekDate(action, val).endTimeStamp.format("YYYY-MM-DD")
        setStartDateFilter(startDate)
        setEndDateFilter(endDate)
        getTimeSheet(startDate, endDate, filterEmployee._id, filterJob._id)
    }

    const handleDailyCalender = (action, val) => {
        setDailyDate(getDailyDate(action, val))
        let date = getDailyDate(action, val)
        let startDate = moment(date.startTimeStamp).format("YYYY-MM-DD")
        getTimeSheet(startDate, startDate, filterEmployee._id, filterJob._id)
        setStartDateFilter(startDate)
        setEndDateFilter(startDate)
    }
    const handleViewBY = () => {
        setViewBY(selectedValueViewBY);
        setAnchorElViewBy(null);
        if (selectedValueViewBY === "Week View") {
            let startDate = moment(weeklyDate.startTimeStamp).format("YYYY-MM-DD")
            let endDate = moment(weeklyDate.endTimeStamp).format("YYYY-MM-DD")
            getTimeSheet(startDate, endDate, filterEmployee._id, filterJob._id)
            setStartDateFilter(startDate)
            setEndDateFilter(endDate)
        }
        else if (selectedValueViewBY === "Date Range") {
            let startDate = moment(dateRange[0].startDate).format("YYYY-MM-DD")
            let endDate = moment(dateRange[0].endDate).format("YYYY-MM-DD")
            getTimeSheet(startDate, endDate, filterEmployee._id, filterJob._id)
            setStartDateFilter(startDate)
            setEndDateFilter(endDate)
        }
    }


    const getTimeSheet = async (startDate, endDate, employee, job) => {
        try {
            setLoader(true)
            const { data, responseCode, message, status } = await Service.getTimeSheet(startDate, endDate, employee, job)
            if (responseCode === 200 && status == true) {
                setTimeSheetsData(data.result)
                if (data.result.length ===0) {
                    setTimeSheet(true)
                }
                else{
                    setTimeSheet(false)
                }
                setRequestCount(data.requestCount)
                if (data.typeHrs.length > 0) {
                    for (let index = 0; index < data.typeHrs.length; index++) {
                        if ("onjob" === data.typeHrs[index]._id) {
                            setOnJob(data.typeHrs[index].totalHrs)
                        }
                        else if ("break" === data.typeHrs[index]._id) {
                            setBreaks(data.typeHrs[index].totalHrs)
                        }
                        else if ("travel" === data.typeHrs[index]._id) {
                            setTravel(data.typeHrs[index].totalHrs)
                        }
                        else if ("delay" === data.typeHrs[index]._id) {
                            setDelay(data.typeHrs[index].totalHrs)
                        }
                    }
                }
                else {
                    setOnJob(0)
                    setBreaks(0)
                    setTravel(0)
                    setDelay(0)
                }

            }
        } catch (error) {
            console.log("🚀 ~ file: TimeSheet.js ~ line 116 ~ getTimeSheet ~ error", error)

        } finally {
            setLoader(false)
        }
    }
    const getFilter = async () => {
        try {
            const { data, status, responseCode } = await Service.getFilterTask()
            if (status === true && responseCode === 200) {
                setFilter(data)
            }
        } catch (error) {
            console.log("🚀 ~ file: TimeSheet.js ~ line 156 ~ getFilter ~ error", error)

        }
    }
    const afterRequestChangeReset=()=>{
        setViewBY("View By")
        setFilterJob({ _id: "", name: "All Job" })
        setFilterEmployee({ _id: "", name: "All Employee" })
        let startDate = moment(getDailyDate()).format("YYYY-MM-DD")
        getTimeSheet(startDate, startDate, "", "")
    }

    useEffect(() => {
        getPageName("TimeSheet")
        setWeeklyDate(getWeekDate())
        setDailyDate(getDailyDate())
        let startDate = moment(getDailyDate()).format("YYYY-MM-DD")
        getTimeSheet(startDate, startDate, "", "")
        setStartDateFilter(startDate)
        setEndDateFilter(startDate)
        getFilter()
    }, [])
    return (
        <>
        <Grid container columnSpacing={1}>

            <Fragment>
                <AddTimeSheet handleAddTimeSheet={() => setAddTimeSheet(!addTimeSheet)} open={addTimeSheet }
                    getTimeSheet={getTimeSheet}
                    startDate={startDateFilter}
                    endDate={endDateFilter}
                    employee={filterEmployee._id}
                    job={filterJob._id} />
                <Box sx={{ m: 3, width: '100%', display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Button sx={{ background: "#F8F8F8", border: " 1px solid #E0E0E0;", mr: 1, fontSize: 14 }}
                            onClick={(event) => { setAnchorElEmployeeFilter(event.currentTarget); setOpenEmployeeFilter(true) }}>{filterEmployee?.name} <MdKeyboardArrowDown size={"18px"} /></Button>
                        <Menu
                            anchorEl={anchorElEmployeeFilter}
                            id="account-menu"
                            open={openEmployeeFilter}
                            onClose={() => { setAnchorElEmployeeFilter(null); setOpenEmployeeFilter(false) }}
                            onClick={() => { setAnchorElEmployeeFilter(null); setOpenEmployeeFilter(false) }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1,
                                    width: 200,
                                    maxHeight: 200,
                                    overflow: 'auto',
                                    p: 1

                                },
                            }}
                        >
                            <MenuItem onClick={() => {
                                setFilterEmployee({ _id: "", name: "All Employee" });
                                getTimeSheet(startDateFilter, endDateFilter, "", filterJob._id)
                            }}>All Employee</MenuItem>
                            {

                                filter?.assigned_to?.map((employee, index) => {
                                    return (
                                        <MenuItem value={employee._id} key={index} onClick={() => {
                                            setFilterEmployee(employee);
                                            getTimeSheet(startDateFilter, endDateFilter, employee._id, filterJob._id)
                                        }
                                        }>
                                            {employee.name}
                                        </MenuItem>
                                    )
                                })}


                        </Menu>


                        <Button sx={{ background: "#F8F8F8", border: " 1px solid #E0E0E0;", mr: 1, fontSize: 14 }}
                            onClick={(event) => { setAnchorElJobFilter(event.currentTarget); setOpenJobFilter(true) }}
                        >{filterJob?.name} <MdKeyboardArrowDown /></Button>
                        <Menu
                            anchorEl={anchorElJobFilter}
                            id="account-menu"
                            open={openJobFilter}
                            onClose={() => { setAnchorElJobFilter(null); setOpenJobFilter(false) }}
                            onClick={() => {
                                setAnchorElJobFilter(null);
                                setOpenJobFilter(false);
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1,
                                    width: 200,
                                    maxHeight: 200,
                                    overflow: 'auto',
                                    p: 1

                                },
                            }}
                        >
                            <MenuItem onClick={() => {
                                setFilterJob({ _id: "", name: "All Job" });
                                getTimeSheet(startDateFilter, endDateFilter, filterEmployee._id, "")
                            }}>All Job</MenuItem>
                            {

                                filter?.jobs?.map((job, index) => {
                                    return (
                                        <MenuItem value={job._id} key={index}
                                            onClick={() => {
                                                setFilterJob(job);
                                                getTimeSheet(startDateFilter, endDateFilter, filterEmployee._id, job._id);
                                            }}>
                                            {job.name}
                                        </MenuItem>
                                    )
                                })}


                        </Menu>

                        <Button sx={{ background: "#F8F8F8", border: " 1px solid #E0E0E0;", mr: 1, fontSize: 14 }} onClick={(event) => setAnchorElViewBy(event.currentTarget)}>{viewBY} <MdKeyboardArrowDown /></Button>
                        <Menu
                            anchorEl={anchorElViewBy}
                            id="account-menu"
                            open={openViewBy}
                            onClose={() => setAnchorElViewBy(null)}
                            // onClick={()=>setAnchorElViewBy(null)}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    width: 312,
                                    p: 2

                                },
                            }}
                        // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >

                            {/* Day View */}
                            <Box sx={{ mb: 1, fontSize: 14, border: "0.5px solid #CCCCCC;", borderRadius: "8px", py: 0.2, px: 0.8, width: '100%', display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <Typography>Day View </Typography>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#2F80ED',
                                        },
                                    }}
                                    checked={selectedValueViewBY === 'Day View'}
                                    onChange={(event) => setSelectedValueViewBY(event.target.value)}
                                    value="Day View"
                                    name="radio-day-view"
                                />
                            </Box>
                            {/* Week View  */}
                            <Box sx={{ mb: 1, fontSize: 14, border: "0.5px solid #CCCCCC;", borderRadius: "8px", py: 0.2, px: 0.8, width: '100%', display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <Typography>Week View </Typography>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#2F80ED',
                                        },
                                    }}
                                    checked={selectedValueViewBY === 'Week View'}
                                    onChange={(event) => setSelectedValueViewBY(event.target.value)}
                                    value="Week View"
                                    name="radio-week-view"
                                />
                            </Box>
                            {/* Date Range View */}
                            <Box sx={{ mb: 1, fontSize: 14, border: "0.5px solid #CCCCCC;", borderRadius: "8px", py: 0.2, px: 0.8, width: '100%', display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                <Typography>Date Range </Typography>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#2F80ED',
                                        },
                                    }}
                                    checked={selectedValueViewBY === 'Date Range'}
                                    onChange={(event) => setSelectedValueViewBY(event.target.value)}
                                    value="Date Range"
                                    name="radio-date-range-view"
                                />
                            </Box>
                            {selectedValueViewBY === 'Date Range' &&
                                <Box >
                                    <DateRange
                                        weekStartsOn={1}
                                        style={{ width: '280px' }}
                                        editableDateInputs={true}
                                        onChange={item => setDateRange([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateRange}
                                    />
                                </Box>
                            }
                            <Button variant="contained" sx={{ background: "#2F80ED", width: '100%' }} onClick={() => { handleViewBY() }}>View</Button>
                        </Menu>

                    </Box>
                    {view==0&&
                    <Box>
                        <Typography variant='subtitle2' sx={{ display: 'flex', justifyContent: 'center', p: 1, color: `${colors.textSecondary}`, fontWeight: 600 }}>
                            {viewBY === "Week View" ?
                                <>
                                    <RiArrowLeftSFill fontSize={22} cursor='pointer' onClick={() => handleWeeklyCalender("previous", weeklyDate?.startTimeStamp)} />
                                    {moment(weeklyDate?.startTimeStamp).format('MMM Do, YYYY')} &nbsp;- &nbsp;{moment(weeklyDate?.endTimeStamp).format('MMM Do, YYYY')}
                                    <RiArrowRightSFill fontSize={22} cursor='pointer' onClick={() => handleWeeklyCalender("next", weeklyDate?.endTimeStamp)} />

                                </>

                                : viewBY === "Date Range" ?
                                    <>
                                        {moment(dateRange[0]?.startDate).format('MMM Do, YYYY')} &nbsp;- &nbsp;{moment(dateRange[0]?.endDate).format('MMM Do, YYYY')}
                                    </>

                                    :
                                    <>
                                        <RiArrowLeftSFill fontSize={22} cursor='pointer' onClick={() => handleDailyCalender("previous", dailyDate?.startTimeStamp)} />
                                        {moment(dailyDate?.startTimeStamp).format('dddd,MMMM Do, YYYY')}
                                        <RiArrowRightSFill fontSize={22} cursor='pointer' onClick={() => handleDailyCalender("next", dailyDate?.startTimeStamp)} />

                                    </>

                            }
                        </Typography>
                    </Box>
                    }
                   
                    <Box >
                        <Button sx={{ mr: 2, background: "#007AFF99", color: "#FFFFFF", fontSize: 14 }} onClick={() => {
                            setAddTimeSheet(!addTimeSheet); setView(0)
                        }}> <AiFillFileAdd size={"18px"} /><span style={{ marginLeft: '10px' }}

                        >Add timesheet</span></Button>
                        <Button sx={{
                            background: view == 0 ? "#FFFFFF" : "#1B5DB6", border: " 1px solid #1B5DB6;", color: view == 0 ? "#1B5DB6" : "#FFFFFF", fontSize: 14,
                            '&:hover': {
                                background: view == 0 ? "#FFFFFF" : "#1B5DB6",
                            }
                        }}
                            onClick={() => setView(1)}
                        >Requests 
            <span style={{marginLeft:10,display:"flex",alignItems:"center",justifyContent:"center", height: "20px",width: "20px",borderRadius:"50%",color:view==0?`${colors.white}`:'#1B5DB6',backgroundColor:view==0?'#1B5DB6':'#FFFFFF'}}>{requestCount}</span>
                        
                        
                            {/* <Avatar sx={{ ml: 1, width: 18, height: 18, background: "#1B5DB6", fontSize: 12 }}>2</Avatar> */}
                        </Button>
                    </Box>
                </Box>
                {loader && view == 0 ?
                    <Box sx={{
                        width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"
                    }}>
                        <Loading />
                    </Box>
                    :
                    view == 0 &&
                    <Grid item xs={timeSheet ? 12 : 12}>
                        {timeSheet===true ? (
                          <Box sx={{ m: 1.5, border: `1px solid ${colors.borderColor}`, minHeight: "90vh", position: "relative" }}>
                          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", textAlign: "center" }}>
                              <Typography variant="h5" sx={{ color: colors.textSecondary, fontWeight: "bold", mb: 1 }}> NO TIMESHEETS</Typography>
                              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                                  You have no timesheets to view on this date.
                                  Please select an alternative date to view or add a new time on the right.
                              </Typography>
                          </Box>
                      </Box>
                        ) : (
                            <>

                                <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>

                                    <>
                                        <Box className={classes.boxStyle}>

                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 600, fontSize: 18, textAlign: 'center', lineHeight: 1.5 }}><CountUp end={onJob>0?moment().startOf('day').add(onJob, 'minutes').format('HH'):onJob} duration={0.75} /> hrs</Typography>
                                            <Typography sx={{ color: "#007AFF", fontWeight: 700, fontSize: 12, textAlign: 'center', }}>ON THE JOB</Typography>
                                        </Box>
                                        <Box className={classes.boxStyle}>
                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 600, fontSize: 18, textAlign: 'center', lineHeight: 1.5 }}><CountUp end={travel>0?moment().startOf('day').add(travel, 'minutes').format('HH'):travel} duration={0.75} /> hrs</Typography>
                                            <Typography sx={{ color: "#F0913D", fontWeight: 700, fontSize: 12, textAlign: 'center', }}>TRAVEL</Typography>
                                        </Box>
                                        <Box className={classes.boxStyle}>
                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 600, fontSize: 18, textAlign: 'center', lineHeight: 1.5 }}><CountUp end={breaks>0?moment().startOf('day').add(breaks, 'minutes').format('HH'):breaks} duration={0.75} /> hrs</Typography>
                                            <Typography sx={{ color: "#98A2B3", fontWeight: 700, fontSize: 12, textAlign: 'center', }}>BREAK</Typography>
                                        </Box>
                                        <Box className={classes.boxStyle}>
                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 600, fontSize: 18, textAlign: 'center', lineHeight: 1.5 }}><CountUp end={delay>0?moment().startOf('day').add(delay, 'minutes').format('HH'):delay} duration={0.75} /> hrs</Typography>
                                            <Typography sx={{ color: "#EB5757", fontWeight: 700, fontSize: 12, textAlign: 'center', }}>DELAY</Typography>
                                        </Box>
                                        <Box className={classes.boxStyle} sx={{ background: "#F3F7F9 !important" }}>
                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 600, fontSize: 18, textAlign: 'center', lineHeight: 1.5 }}><CountUp end={moment().startOf('day').add(onJob + travel + breaks + delay, 'minutes').format('HH')} duration={0.75} /> hrs</Typography>
                                            <Typography sx={{ color: "#4F4F4F", fontWeight: 700, fontSize: 12, textAlign: 'center', }}>TOTAL</Typography>
                                        </Box>
                                    </>


                                </Box>

                                <TimeSheetTable
                                    timeSheetsData={timeSheetsData}
                                    getTimeSheet={getTimeSheet}
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                    employee={filterEmployee._id}
                                    job={filterJob._id}
                                />

                            </>

                        )}


                    </Grid>

                }

            </Fragment>


        </Grid>
        { view==1 &&
            <Request afterRequestChangeReset={afterRequestChangeReset} setDailyDate={setDailyDate}/>
        }
        </>
    )
}

export default TimeSheet