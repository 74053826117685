import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Propose from 'app/Components/Drawer/Propose'
import { TableHeadTypo } from 'app/Components/UI/Typo'
import { Service } from 'app/config/service'
import colors from 'app/style/colors'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { FaUndo } from 'react-icons/fa'
import { Resizable } from 'react-resizable'
const groupByToMap = require('array.prototype.groupbytomap');


const useStyles = makeStyles({
    spanStyle: {
        position: "absolute",
        left: 0, top: 0,
        borderRadius: "8px 0px 0px 8px",
        height: "100%", width: "8px",
        backgroundColor: '#0769FC'
    }
})
const Request = ({afterRequestChangeReset,setDailyDate}) => {
    const classes = useStyles()
    const [requestList, setRequestList] = useState([]);
    const [requestListModified, setRequestListModified] = useState([]);
    
    // *For Column Resize
    const [width, setWidth] = useState(200);


    // *For Column Resize
    const onResize = (event, { element, size, handle }) => {
        try {

            setWidth(size.width)
        } catch (error) {
            console.log('file: TodosTable.js => line 302 => onResize => error', error)
        }
    }
    const getRequest = async () => {
        try {
            const { status, responseCode, data, message } = await Service.getTimeSheetRequest()
            if (status === true & responseCode === 200) {
                setRequestList(data.result)

            }

        } catch (error) {
            console.log("🚀 ~ file: JobChanges.js ~ line 39 ~ getJobChange ~ error", error)

        }
    }
    const getTimeDetails = () => {
        if (requestList.length > 0) {
            for (let index = 0; index < requestList.length; index++) {
                const timeSheets = groupByToMap(requestList[index].time_details, (sheet) => {
                    return sheet.type;
                });
                console.log("🚀 ~ file: Request.js ~ line 61 ~ timeSheets ~ timeSheets", timeSheets)

                for (let [key, value] of timeSheets) {

                    let totalTime = 0
                    value.map((time) => {
                        totalTime += time.timeDiff
                    })
                    requestList[index] = { ...requestList[index], [`${key}Hrs`]: totalTime }
                    if (key === "onjob") {
                        requestList[index] = { ...requestList[index], ['jobStartTime']: value[0].startTime }
                        requestList[index] = { ...requestList[index], ['jobEndTime']: value[value.length - 1].endTime }
                    }
                }
            }
            
            setRequestListModified(requestList)

            // console.log("🚀 ~ file: TimeSheetTable.js ~ line 42 ~ TimeSheetTable ~ timeSheetsData", timeSheetsData)


        }
    }
    const updateTimeSheetStatus = async (obj) => {
        try {
            const { status, responseCode, } = await Service.updateTimeSheet(obj)
            if (status === true & responseCode === 200) {
                getRequest()
                afterRequestChangeReset()
                setDailyDate(Date.now())
            }
        } catch (error) {
            console.log("🚀 ~ file: JobChanges.js ~ line 55 ~ updateJobChangeStatus ~ error", error)

        }
    }
    useEffect(() => {
        getRequest()
    }, []);
    useEffect(() => {
        getTimeDetails()

    }, [requestList]);

    return (
        <>
            {/* table head  */}
            <Box sx={{
                p: 1.5,
                m: 2,
                borderRadius: "8px",
                bgcolor: '#F3F7F9',
                minWidth: 450,
                display: "flex",
                border: `1px solid ${colors.borderColor}`
            }}>
                <Resizable
                    height={'auto'}
                    width={width}
                    minConstraints={[100, 100]}
                    maxConstraints={[450, 450]}
                    onResize={onResize}
                    axis={'x'}
                    resizeHandles={["se resize-cursor"]}

                >
                    <div className="box" style={{ width: width, height: 'auto' }}>
                        <TableHeadTypo style={{ marginLeft: '15px', }}>Job Name</TableHeadTypo>
                    </div>
                </Resizable>

                <Grid container columns={20} sx={{ minWidth: '500px' }}>
                    <Grid item xs={3} >
                        <TableHeadTypo >Worker Name </TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >On Job</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >Travel</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >Break</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >Delay</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >Total hours</TableHeadTypo>
                    </Grid>
                    <Grid item xs={7}>
                        <TableHeadTypo >Accept or Reject</TableHeadTypo>

                    </Grid>


                </Grid>

            </Box>

            {requestListModified.length>0?
            requestListModified.map((list, index) => {
                return (
                    <Box >

                        <Box sx={{
                            position: "relative",
                            m: 2, p: 1.5,
                            borderRadius: "8px",
                            minWidth: 650,
                            minHeight: 70,
                            display: "flex", alignItems: "center", border: `1px solid ${colors.borderColor}`
                        }}>

                            <span className={classes.spanStyle} />



                            <Box sx={{ width: `${width - 25 + 'px'}`, overflow: 'hidden' }}>
                                <Typography variant="body2" noWrap
                                    sx={{ color: `${colors.textSecondary}`, height: "100%", pl: 1, mr: 1, }}
                                >
                                    {list?.jobDetail?.name}
                                </Typography>
                            </Box>
                            <Grid container columns={20} sx={{ minWidth: '500px', alignItems: 'center' }}>
                                <Grid item xs={3} >
                                    <Box variant="body2" sx={{ width: 120, ml: 1, display: 'flex', alignItems: 'center' }}>
                                        <Avatar alt="" src=''
                                            sx={{
                                                display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                                fontSize: "13px", textTransform: "uppercase",
                                                bgcolor: colors.skyBlue,
                                            }}>

                                            {list?.userDetails?.name?.split(" ").length > 1 ?
                                                list?.userDetails?.name?.charAt(0) + list?.userDetails?.name?.split(" ")[1].charAt(0) :
                                                list?.userDetails?.name?.charAt(0)
                                            }
                                        </Avatar>
                                        <Typography noWrap sx={{ color: `${colors.textSecondary}`, fontSize: '13px' }}>{list?.userDetails?.name}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} >
                                    <Box >
                                        <Typography sx={{ color: `${colors.textSecondary}`, fontSize: '13px' }}>
                                            {list.onjobHrs ? moment().startOf('day').add(list.onjobHrs, 'minutes').format('hh:mm') +' hrs' : '00:00 hrs'}
                                        </Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Box >
                                        <Typography sx={{color: `${colors.textSecondary}`, fontSize: '13px' }}>
                                            {list.travelHrs ? moment().startOf('day').add(list.travelHrs, 'minutes').format('hh:mm') +' hrs' : '00:00 hrs'}
                                        </Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Box >
                                        <Typography sx={{ color: `${colors.textSecondary}`, fontSize: '13px' }}>
                                            {list.breakHrs ? moment().startOf('day').add(list.breakHrs, 'minutes').format('hh:mm')+' hrs' : '00:00 hrs'}
                                        </Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Box >
                                        <Typography sx={{ color: `${colors.textSecondary}`, fontSize: '13px' }}>
                                            {list.delayHrs ? moment().startOf('day').add(list.delayHrs, 'minutes').format('hh:mm')+' hrs' : '00:00 hrs'}
                                        </Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Box >
                                        <Typography sx={{ color: `${colors.textSecondary}`, fontSize: '13px' }}>
                                            {list.totalHrs ? moment().startOf('day').add(list.totalHrs, 'minutes').format('hh:mm') +' hrs' : '00:00 hrs'}
                                        </Typography>

                                    </Box>
                                </Grid>
                                <Grid item xs={7}  >
                                {list.status==="request"&&
                                     <Box sx={{ display: "flex", ml: 0.5 }}>
                                     <Button
                                         sx={{
                                             width:88,height:28, fontSize: '13px', background: '#219653', borderRadius: '5px', mr: 1, '&:hover': {
                                                 backgroundColor: '#1eb05c'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateTimeSheetStatus({status:"timeapproved",id:list._id})}>Accept</Button>
                                     <Button
                                         sx={{
                                             width:88,height:28, fontSize: '13px', background: '#EB5757', borderRadius: '5px',
                                             '&:hover': {
                                                 backgroundColor: '#f76060'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateTimeSheetStatus({status:"reject",id:list._id})}
                                     >Reject 
                                     </Button>
                                     
                                 </Box>
                                    }
                                    {list.status==="reject"&&
                                         <Typography sx={{color:'#EB5757',fontWeight:600,textAlign:'left',fontSize:13}}>Timesheet  rejected
                                         <Button
                                         sx={{
                                             ml:1,
                                             height:28, fontSize: '13px', background: `${colors.darkBlue}`, borderRadius: '5px',
                                             '&:hover': {
                                                 backgroundColor: '#408df5'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateTimeSheetStatus({status:"request",id:list._id})}
                                     ><FaUndo style={{marginRight:10}}/> Undo</Button>
                                         </Typography>

                                    }
                                    {list.status==="timeapproved"&&
                                         <Typography sx={{color:"#219653",fontWeight:600,textAlign:'left',fontSize:13}}>Request Accepted
                                         <Button
                                         sx={{
                                             ml:1,
                                             height:28, fontSize: '13px', background: `${colors.darkBlue}`, borderRadius: '5px',
                                             '&:hover': {
                                                 backgroundColor: '#408df5'
                                             }
                                         }} variant="contained"
                                         onClick={() =>updateTimeSheetStatus({status:"request",id:list._id})}
                                     ><FaUndo style={{marginRight:10}}/>Undo</Button>
                                         </Typography>

                                    }
                                    
                                </Grid>

                            </Grid>




                        </Box>
                    </Box>
                )
            })
        :
        <Box sx={{ textAlign: 'center', width: '100%', my: 25 }}>
                <Typography sx={{ color: '#4F4F4F', fontSize: 30, fontWeight: 600 }}>NO REQUESTS</Typography>
                <Typography sx={{ color: '#4F4F4F', fontSize: 14, fontWeight: 400 }}>You have no timesheet submissions to view on this date.
                </Typography>
            </Box>
            
        }
           
            
        </>
    )
}

export default Request