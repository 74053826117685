import { Apis, post, get, deleted, patch } from '.';

export const Service = {
    login: async (obj) => {
        let result = await post(Apis.login, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    register: async (obj) => {
        let result = await post(Apis.register, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobs: async () => {
        let result = await get(Apis.getJobs);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getRoles: async () => {
        let result = await get(Apis.getRoles);
        if (result.status === 200) return result.data;
        else throw result;
    },
    jobCategory: async () => {
        let result = await get(Apis.jobCategory);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createJobCategory: async (obj) => {
        let result = await post(Apis.createJobCategory, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createJobs: async (obj) => {
        let result = await post(Apis.createJobs, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createTask: async (obj) => {
        let result = await post(Apis.createTask, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    addNewEmployee: async (obj) => {
        let result = await post(Apis.addNewEmployee, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createSubTask: async (obj) => {
        let result = await post(Apis.createSubTask, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTags: async (keyword) => {
        let result = await get(Apis.getTags + '/?search=' + keyword);
        if (result.status === 200) return result.data;
        else throw result;
    },
    addTag: async (obj) => {
        let result = await post(Apis.addTag, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTasks: async (keyword) => {
        let result = await get(Apis.getTasks + '/?' + keyword);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getFilteredJobs: async (keyword) => {
        let result = await get(Apis.getFilteredJobs + '/?' + keyword);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getRegisteredEmployee: async () => {
        let result = await get(Apis.getRegisteredEmployee,);
        if (result.status === 200) return result.data
        else throw result;
    },
    getFilterTask: async () => {
        let result = await get(Apis.getFilterTask);
        if (result.status === 200) return result.data;
        else throw result;
    },
    deleteTask: async (id,) => {
        let result = await deleted(Apis.deleteTask + '?id=' + id);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateTask: async (obj,) => {
        let result = await patch(Apis.updateTask, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateSubTask: async (obj,) => {
        let result = await patch(Apis.updateSubTask, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    upload: async (obj) => {
        let result = await post(Apis.upload, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getComment: async (id, timeSheet) => {
        let isTimeSheet = timeSheet ? '?timeSheet_id=' : '?task_id='
        let result = await get(Apis.getComment + isTimeSheet + id);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createComment: async (obj) => {
        let result = await post(Apis.createComment, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    deleteComment: async (id) => {
        let result = await deleted(Apis.deleteComment + '?id=' + id);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobAssignBy: async (startDate, endDate) => {
        let result = await get(Apis.getJobAssignBy + `?startDate=${startDate}&endDate=${endDate}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobWeekly: async (startDate, endDate) => {
        let result = await get(Apis.getJobWeekly + `?startDate=${startDate}&endDate=${endDate}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobAssignByDaily: async (startDate, endDate) => {
        let result = await get(Apis.getJobAssignByDaily + `?startDate=${startDate}&endDate=${endDate}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobByDaily: async (startDate, endDate) => {
        let result = await get(Apis.getJobByDaily + `?startDate=${startDate}&endDate=${endDate}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getUnscheduled: async () => {
        let result = await get(Apis.getUnscheduled);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTimeOff: async () => {
        let result = await get(Apis.getTimeOff);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateTimeOff: async (obj) => {
        let result = await patch(Apis.updateTimeOff, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateJob: async (obj) => {
        let result = await patch(Apis.updateJob, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobById: async (_id) => {
        console.log("🚀 ~ file: service.js ~ line 126 ~ getJobById: ~ _id", _id)

        let result = await get(Apis.getJobById + `?_id=${_id}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    deleteJob: async (id) => {
        let result = await patch(Apis.deleteJob + `?id=${id}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTimeSheet: async (startDate, endDate, employee, job) => {
        let result = await get(Apis.getTimeSheet + `?startDate=${startDate}&endDate=${endDate}&employee=${employee}&job=${job}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTimeSheetRequest: async () => {
        let result = await get(Apis.GettimesheetRequest);
        if (result.status === 200) return result.data;
        else throw result;
    },
    createTimeSheet: async (obj) => {
        let result = await post(Apis.createTimeSheet, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateTimeSheet: async (obj) => {
        let result = await patch(Apis.updateTimeSheet, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },

    getJobChange: async () => {
        let result = await get(Apis.getJobChange);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateJobChange: async (obj) => {
        let result = await patch(Apis.updateJobChange, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getJobByMonth: async (startDate, endDate) => {
        let result = await get(Apis.getJobByMonth + `?toDate=${startDate}&fromDate=${endDate}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateTimeSheetTime: async (obj) => {
        let result = await patch(Apis.updateTimeSheetTime, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
}