import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

import colors from "app/style/colors";
import { makeStyles } from "@mui/styles";
import { FaCheckCircle } from "react-icons/fa";
import { Service } from "app/config/service";
import moment from "moment";
import { PrimaryButton } from "app/Components/UI/Buttons";

const useStyles = makeStyles({
  itemHeading: {
    fontSize: "14px !important",
    color: `${colors.textSecondary} !important`,
    fontWeight: "bold !important",
  },
  itemText: {
    fontSize: "14px !important",
    color: `${colors.darkGray} !important`,
  },
  filterBoxes: {
    height: 165,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "1px",
    },
    "&:hover": {
      "&::-webkit-scrollbar": {
        width: "2.5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B3B6BE",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#E5E6EB",
      },
    },
  },
});
const status = ["pending", "inprogess", "done", "notstarted", "declined"];
const timeoff = ["request", "approve"];

function Filters({
  setShowFilter,
  getJobs,
  setFilterCount,
  employeeView,
  setIsFilter,
  setEmployeeView,
  setDayView
}) {
  const classes = useStyles();
  const DATE_SIZE = 28;
  const [openIndex, setOpenIndex] = useState(-1);
  const [isForcePickerOpen, setIsOpen] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [tags, setTags] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [customDueDate, setCustomDueDate] = useState(Date.now());
  const [customCreateDate, setCustomCreateDate] = useState(Date.now());

  const [anchorElDate, setAnchorElDate] = useState(null);
  const openDate = Boolean(anchorElDate); // for duedate box

  const [selectedFilters, setSelectedFilters] = useState({
    jobs: [],
    assigned_to: [],
    status: [],
    timeoff: [],
  });
  const [filter, setFilter] = useState([]);
  // handle filters
  const handleFilter = (name, item) => {
    let arr = [...filter];
    let spreadFilters = [...selectedFilters[name]];
    let getIndex;
    if (item._id) {
      getIndex = spreadFilters.findIndex((e) => e === item._id);
    } else {
      getIndex = spreadFilters.findIndex((e) => e === item);
    }

    if (getIndex === -1) {
      if (name !== "dueDate" && name !== "createdDate") {
        arr.push(name);
        setFilter(arr);
        spreadFilters.push(item._id ? item._id : item);
      } else {
        spreadFilters = item;
      }

      setSelectedFilters({ ...selectedFilters, [name]: spreadFilters });
    } else {
      let removeItem;
      if (item._id) {
        removeItem = spreadFilters.filter((e) => e !== item._id);
      } else {
        removeItem = spreadFilters.filter((e) => e !== item);
      }
      setSelectedFilters({ ...selectedFilters, [name]: removeItem });
      const index = arr.indexOf(name);
      arr.splice(index, 1);
      setFilter(arr);
    }

    if (arr) {
      let unique = [...new Set(arr)];
      setFilterCount(unique.length);
    }
  };

  const applyFilter = () => {
    let jobs = "";
    let assigned = "";
    let status = "";

    selectedFilters.jobs.map((item, index) => {
      jobs += `jobs[${index}]=${item}&`;
    });
    selectedFilters.assigned_to.map((item, index) => {
      assigned += `assigned_to[${index}]=${item}&`;
    });
    selectedFilters.status.map((item, index) => {
      status += `status[${index}]=${item}&`;
    });
    setEmployeeView('Job view');
    setDayView('Daily view')
    getJobs(jobs + assigned + status);
    setIsFilter(true)
    setShowFilter();
  };

  const clearFilter = () => {
    // getTasks()
    setSelectedFilters({
      jobs: [],
      assigned_to: [],
      status: [],
      timeoff: [],
    });
    setFilter([]);
    setFilterCount(0);
    setIsFilter(false)
    setShowFilter();
  };
  const daysFilter = (name, item) => {
    let intStr = parseInt(item.replace(/[A-Za-z$-]/g, ""));
    if (name === "dueDate") {
      if (selectedFilters.dueDateChecker !== item) {
        let date = moment()
          .startOf(Date.now())
          .add(intStr, "days")
          .format("YYYY-MM-DD");
        selectedFilters.dueDateChecker = item;
        handleFilter(name, date);
      } else {
        selectedFilters.dueDateChecker = "";
        handleFilter(name, "");
      }
    } else if (name === "createdDate") {
      if (selectedFilters.createdDateChecker !== item) {
        let date = moment()
          .startOf(Date.now())
          .add(-intStr, "days")
          .format("YYYY-MM-DD");
        selectedFilters.createdDateChecker = item;
        handleFilter(name, date);
      } else {
        selectedFilters.createdDateChecker = "";
        handleFilter(name, "");
      }
    }
  };

  const getFilterTask = async () => {
    try {
      const { status, responseCode, data } = await Service.getFilterTask();
      if (status === true && responseCode === 200) {
        setJobs(data.jobs);
        setTags(data.tags);
        setAssignedTo(data.assigned_to);
      }
    } catch (error) {
      console.log("file: Filters.js => line 54 => getTasks => error", error);
    }
  };

  // const handleCustomDueDate = (newValue) => {
  //   setAnchorElDate(null)
  //   setCustomDueDate(newValue);
  //   let date=moment(newValue).format('YYYY-MM-DD')
  //   handleFilter("dueDate", date)

  // };
  // const handleCustomCreateDate = (newValue) => {
  //   setCustomCreateDate(newValue);
  //   let date=moment(newValue).format('YYYY-MM-DD')
  //   handleFilter("createdDate", date)
  // };

  // state for handling menu popover
  const [coordinate, setCoordinate] = useState({
    x: "",
    y: "",
  });

  useEffect(() => {
    getFilterTask();
  }, []);

  return (
    <Box
      sx={{
        boxShadow: "0px 5px 25px rgba(112, 115, 114, 0.4)",
        p: 1.5,
        borderRadius: "8px",
        mb: 2,
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          color: colors.textSecondary,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Filters
        <IconButton onClick={setShowFilter}>
          <MdClose size={24} />
        </IconButton>
      </Box>
      <Grid container sx={{ height: 220 }} columnSpacing={3}>
        {/* Job Name */}
        <Grid item xs={2.5}>
          <Typography variant="body1" className={classes.itemHeading}>
            Job Name
          </Typography>
          <Box className={classes.filterBoxes}>
            {jobs.map((item) => (
              <Box
                onClick={() => handleFilter("jobs", item)}
                // onClick={() => alert(item.name)}
                key={item._id}
                sx={{
                  my: 0.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body2" noWrap>
                  {item.name}
                </Typography>
                {selectedFilters.jobs?.map(
                  (jobItem) =>
                    jobItem === item._id && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Assigned to */}
        <Grid item xs={2.5}>
          <Typography variant="body1" className={classes.itemHeading}>
            Assigned to
          </Typography>
          <Box className={classes.filterBoxes}>
            {assignedTo.map((item) => (
              <Box
                key={item._id}
                onClick={() => handleFilter("assigned_to", item)}
                sx={{
                  my: 0.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                <Box variant="body2" sx={{ fontSize: "12px" }}>
                  <Avatar
                    alt=""
                    src=""
                    sx={{
                      display: "inline-flex",
                      mr: 1,
                      width: "21px",
                      height: "21px",
                      bgcolor: colors.skyBlue,
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.name?.split(" ").length > 1
                      ? item.name?.charAt(0) +
                        item.name?.split(" ")[1].charAt(0)
                      : item.name?.charAt(0)}
                  </Avatar>
                  {item.name}
                </Box>
                {selectedFilters.assigned_to?.map(
                  (jobItem) =>
                    jobItem === item._id && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Status */}
        <Grid item xs={2.5}>
          <Typography variant="body1" className={classes.itemHeading}>
            Status
          </Typography>
          <Box className={classes.filterBoxes}>
            {status.map((item, index) => (
              <Box
                key={item + index}
                onClick={() => handleFilter("status", item)}
                sx={{
                  my: 0.5,
                  px: 1,
                  py: 0.7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                {item === "pending" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightBlue}`,
                      color: `${colors.chip.Blue}`,
                      fontSize: 11,
                    }}
                    label="PENDING"
                  />
                )}
                {item === "notstarted" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGray}`,
                      color: `${colors.chip.gray}`,
                      fontSize: 11,
                    }}
                    label="NOT STARTED"
                  />
                )}
                {item === "inprogess" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightYellow}`,
                      color: `${colors.chip.yellow}`,
                      fontSize: 11,
                    }}
                    label="IN PROGRESS"
                  />
                )}
                {item === "done" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGreen}`,
                      color: `${colors.chip.green}`,
                      fontSize: 11,
                    }}
                    label="DONE"
                  />
                )}
                {item === "declined" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightRed}`,
                      color: `${colors.chip.red}`,
                      fontSize: 11,
                    }}
                    label="DECLINED"
                  />
                )}
                {selectedFilters.status?.map(
                  (jobItem) =>
                    jobItem === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Tags */}
        {/* <Grid item xs={1.6}>
          <Typography variant="body1" className={classes.itemHeading}>Tags</Typography>
          <Box className={classes.filterBoxes}>
            {tags.map((item) => (
              <Box  onClick={() => handleFilter("tags", item)} key={item._id} sx={{ my: 0.5, px: 1, py: 0.7, display: "flex", alignItems: "center", justifyContent: "space-between", border: `1px solid ${colors.borderColor}`, borderRadius: "10px" }}>
                <ChipTag item={item} />

                {selectedFilters.tags?.map((jobItem) => (
                  jobItem === item._id && <FaCheckCircle color={colors.darkBlue} />
                ))}
              </Box>
            ))}
          </Box>
        </Grid> */}

        {/* Priority */}
        <Grid item xs={2.5}>
          <Typography variant="body1" className={classes.itemHeading}>
            Time - Off
          </Typography>
          <Box className={classes.filterBoxes}>
            {timeoff.map((item, index) => (
              <Box
                onClick={() => handleFilter("timeoff", item)}
                key={item + index}
                sx={{
                  my: 0.5,
                  px: 1,
                  py: 0.7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${colors.borderColor}`,
                  borderRadius: "10px",
                }}
              >
                {item == "request" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      border: "1px solid",
                      background:
                        "repeating-linear-gradient(-58deg,#F8F8F8,#F8F8F8 8px,#ECB22E 10px,#ECB22E 10px)",
                      color: "#F2B446",
                      fontSize: 11,
                    }}
                    label="Requested"
                  />
                )}
                {item == "approve" && (
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      border: "1px solid",
                      background:
                        "repeating-linear-gradient(-58deg,#F8F8F8,#F8F8F8 8px,#908eda 10px,#908eda 10px)",
                      color: "#7876D3",
                      fontSize: 11,
                    }}
                    label="Approved"
                  />
                )}
                {selectedFilters.timeoff?.map(
                  (jobItem) =>
                    jobItem === item && (
                      <FaCheckCircle color={colors.darkBlue} />
                    )
                )}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right" }}>
        <PrimaryButton
          onClick={clearFilter}
          variant="body2"
          color="initial"
          sx={{
            border: `1px solid ${colors.darkBlue}`,
            color: `${colors.darkBlue}`,
            textTransform: "capitalize",
            display: "inline",
            mr: 2,
            cursor: "pointer",
          }}
        >
          Clear All Filters
        </PrimaryButton>

        <PrimaryButton
          onClick={applyFilter}
          sx={{
            px: 3,
            border: `1px solid ${colors.darkBlue}`,
            bgcolor: colors.darkBlue,
            mr: 1,
            textTransform: "capitalize",
            color: colors.white,
            ":hover": {
              boxShadow: "none",
              bgcolor: colors.skyBlue,
            },
          }}
        >
          Show results
        </PrimaryButton>
      </Box>
    </Box>
  );
}

export default Filters;
