import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { MdClose } from "react-icons/md";
import FieldLabel from "../UI/FieldLabel";
import { FaPlusCircle, FaRegCalendarAlt } from "react-icons/fa";
import {
  Avatar,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import colors from "app/style/colors";
import { LoadingBtn } from "../UI/Buttons";
import { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import enLocale from "date-fns/locale/en-GB";
import { getDate } from "app/utils";
import { Service } from "app/config/service";
import { SuccessToaster } from "../UI/Toaster";
import AddEmployee from "../Dialog/AddEmployee";
import { Error } from "../UI/Error";
import { ChipStatus } from "../UI/Chip";

const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});

export default function AddSubTask({
  handleSubTask,
  open,
  employees,
  registeredEmployee,
  taskId,
  getTasks,
}) {
  const DATE_SIZE = 28;
  const dateAdd = new Date();

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const openDate = Boolean(anchorEl); // for due date

  const [selectedEmployee, setSelectedEmployee] = useState("none");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("none");

  // toggle dialog
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);

  // function for handling main date
  const handleDate = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const addSubTask = async (TaskData) => {
    try {
      let obj = {
        name: TaskData.subTask,
        status: selectedStatus,
        assigned_to: selectedEmployee?._id,
        due_date: TaskData.dueDate,
        parent_task: taskId,
      };
      const { status, responseCode } = await Service.createSubTask(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster("Subtask Added");
        getTasks();
        setSelectedStatus("none");
        setSelectedEmployee("none");
        reset();
        handleSubTask(false);
      }
    } catch (error) {
      console.log(
        "file: AddSubTask.js => line 77 => addSubTask => error",
        error
      );
    }
  };

  useEffect(() => {
    registeredEmployee();
  }, [openEmployeeDialog]);

  return (
    <Fragment>
      {/* Employee Dialog */}
      <AddEmployee
        open={openEmployeeDialog}
        handleClose={() => setOpenEmployeeDialog(false)}
      />

      <Drawer anchor="right" open={open} onClose={handleSubTask}>
        <Box sx={{ width: 300, pt: 10, px: 2 }} role="presentation">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            Add a sub-task
            <IconButton
              onClick={() => {
                handleSubTask();
                reset();
                setSelectedEmployee("none");
                setSelectedStatus("none");
              }}
              sx={{ px: 0 }}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
              }}
            >
              <MdClose size={24} />
            </IconButton>
          </Box>

          <Typography
            component="form"
            onSubmit={handleSubmit(addSubTask)}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "85vh",
            }}
          >
            <Box>
              {/* To do status */}
              {/* <FormControl variant="standard" fullWidth >
              <FieldLabel>To do status*</FieldLabel>
              <Select
                variant="standard"
                fullWidth
                defaultValue={'none'}
                className={classes.boxSelectField}
                sx={{
                  color: colors.secondaryLight,
                  "::before": {
                    display: "none",
                  },
                  "::after": {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={"none"} disabled>
                  Select status
                </MenuItem>
                <MenuItem value={"dummy"}>
                  Dummy
                </MenuItem>
              </Select>
            </FormControl> */}

              {/* Sub-tasks */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-tasks*</FieldLabel>
                <TextField
                  hiddenLabel
                  placeholder="Enter Sub-task"
                  variant="outlined"
                  className={classes.outlineInputBorder}
                  error={errors?.subTask?.message && true}
                  size="small"
                  {...register("subTask", {
                    required: "Please Enter Sub Task.",
                  })}
                />
                {errors?.subTask?.message && (
                  <Error message={errors?.subTask?.message} />
                )}
              </FormControl>

              {/* Sub-task status */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task status*</FieldLabel>
                <Select
                  variant="standard"
                  fullWidth
                  defaultValue={"none"}
                  className={classes.boxSelectField}
                  value={selectedStatus ?? ""}
                  {...register("status", {
                    required: "Please select Status.",
                    validate: (value) => value !== "none" || "error message",
                  })}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    clearErrors("status");
                  }}
                  sx={{
                    color: colors.secondaryLight,
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  }}
                >
                  <MenuItem value={"none"} disabled>
                    <ChipStatus label="Select status" />
                  </MenuItem>
                  <MenuItem value={"Done"}>
                    <ChipStatus label="Done" />
                  </MenuItem>
                  <MenuItem value={"In progress"}>
                    <ChipStatus label="In progress" />
                  </MenuItem>
                  <MenuItem value={"Overdue"}>
                    <ChipStatus label="Overdue" />
                  </MenuItem>
                  <MenuItem value={"Not started"}>
                    <ChipStatus label="Not started" />
                  </MenuItem>
                </Select>
                {errors?.status?.message && (
                  <Error message={"Please select status."} />
                )}
              </FormControl>

              {/* Sub-task assigned to */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task assigned to*</FieldLabel>
                <Grid
                  container
                  alignItems="center"
                  className={classes.boxSelectField}
                >
                  <Grid item xs={2}>
                    <Avatar
                      alt=""
                      src=""
                      sx={{
                        width: "30px",
                        height: "30px",
                        bgcolor: colors.skyBlue,
                        fontSize: "12px",
                        textTransform: "uppercase",
                      }}
                    >
                      {selectedEmployee.name?.split(" ").length > 1
                        ? selectedEmployee.name?.charAt(0) +
                          selectedEmployee.name?.split(" ")[1].charAt(0)
                        : selectedEmployee.name?.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: "30vh", width: "30vh" },
                        },
                      }}
                      variant="standard"
                      fullWidth
                      defaultValue={"none"}
                      value={selectedEmployee ?? ""}
                      {...register("assignedTo", {
                        required: "Please select assigned to.",
                        validate: (value) =>
                          value !== "none" || "error message",
                      })}
                      onChange={(e) => {
                        setSelectedEmployee(e.target.value);
                        clearErrors("assignedTo");
                      }}
                      sx={{
                        color: colors.secondaryLight,
                        "::before": {
                          display: "none",
                        },
                        "::after": {
                          display: "none",
                        },
                      }}
                    >
                      <MenuItem value={"none"} disabled>
                        Select employee
                      </MenuItem>
                      {employees.map((item) => (
                        <MenuItem key={item._id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={0.5}>
                    <Divider orientation="vertical" sx={{ height: 20 }} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => setOpenEmployeeDialog(!openEmployeeDialog)}
                    >
                      <FaPlusCircle size={16} color="black" />
                    </IconButton>
                  </Grid>
                </Grid>
                {errors?.assignedTo?.message && (
                  <Error message={"Please select assigned to."} />
                )}
              </FormControl>

              {/* Sub-task due date */}
              <FormControl variant="standard" fullWidth>
                <FieldLabel>Sub-task due date*</FieldLabel>
                <TextField
                  hiddenLabel
                  placeholder="Select due date"
                  variant="outlined"
                  className={classes.outlineInputBorder}
                  onClick={(event) => handleDate(event)}
                  onKeyDown={(e) => e.preventDefault()}
                  error={errors?.dueDate?.message && true}
                  {...register(
                    "dueDate"
                    // {
                    //   required:"Due Date is Required"
                    // }
                  )}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            bgcolor: "#F3F7F9",
                            p: 1,
                            ml: -1.6,
                            height: 43,
                            width: 30,
                            borderRadius: "8px 0px 0px 8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FaRegCalendarAlt color="gray" />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDate}
                  onClose={() => setAnchorEl(null)}
                  transformOrigin={{
                    vertical: -10,
                    horizontal: 0,
                  }}
                  PaperProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      // adapterLocale={enLocale}
                    >
                      <Box
                        sx={{
                          "& > div": {
                            minWidth: 240,
                          },
                          "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                            {
                              width: 240,
                            },
                          "& .MuiTypography-caption": {
                            width: DATE_SIZE,
                            margin: 0,
                          },
                          "& .PrivatePickersSlideTransition-root": {
                            minHeight: DATE_SIZE * 6,
                          },
                          '& .PrivatePickersSlideTransition-root [role="row"]':
                            {
                              margin: 0,
                            },
                          "& .MuiPickersDay-dayWithMargin": {
                            margin: 0,
                          },
                          "& .MuiPickersDay-root": {
                            width: DATE_SIZE,
                            height: DATE_SIZE,
                          },
                        }}
                      >
                        <StaticDatePicker
                          value={selectedDate ? selectedDate : ""}
                          minDate={dateAdd.setDate(dateAdd.getDate() + 1)}
                          onChange={(e) => {
                            setValue(`dueDate`, getDate(e));
                            setAnchorEl(null);
                            setSelectedDate(getDate(e));
                            clearErrors("dueDate");
                          }}
                          displayStaticWrapperAs="desktop"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Menu>
                {errors?.dueDate?.message && (
                  <Error message={"Please select due date."} />
                )}
              </FormControl>
            </Box>

            <LoadingBtn
              fullWidth
              // loading={loading}
              type="submit"
              variant="contained"
            >
              Add sub-task
            </LoadingBtn>
          </Typography>
        </Box>
      </Drawer>
    </Fragment>
  );
}
