import React from 'react'
import { Typography } from '@mui/material'

import colors from 'app/style/colors'

export function TableHeadTypo(props) {
  return (
    <Typography
      {...props}
      variant="body2"
      sx={{ fontWeight: "bold", fontSize: "12px", lineHeight: "12px", color: colors.textSecondary }}
    >
      {props.children}
    </Typography>
  )
}

