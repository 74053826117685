import {
  Avatar,
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FieldLabel from "app/Components/UI/FieldLabel";
import colors from "app/style/colors";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { FaFileMedical, FaRegCalendarAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { Calendar } from "react-date-range";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingBtn, PrimaryButton } from "app/Components/UI/Buttons";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Error } from "app/Components/UI/Error";
import { Service } from "app/config/service";

const useStyles = makeStyles({
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    color: `${colors.primary} !important`,
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
});
const AddTime = ({
  open,
  handleAddTime,
  timeSheet,
  getTimeSheet,
  handleViewMore,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);

  // Date
  const [anchorElDate, setAnchorElDate] = useState(null);
  const openDate = Boolean(anchorElDate);
  const [date, setDate] = useState(Date.now());

  const [addMoreTime, setAddMoreTime] = useState([
    { type: "", startTime: null, endTime: null },
  ]);

  const addMoreTimeField = () => {
    setAddMoreTime([
      ...addMoreTime,
      { type: "", startTime: null, endTime: null },
    ]);
  };
  const handleAddMoreTimeChange = (e, index, action) => {
    if (action) {
      const list = [...addMoreTime];
      list[index][action] = e;
      setAddMoreTime(list);
    } else {
      let { name, value } = e.target;
      console.log(
        "🚀 ~ file: AddTime.js ~ line 81 ~ handleAddMoreTimeChange ~ name",
        name
      );

      if ("type" + index === name) {
        name = name.replace(index, "");
      }
      if (value === "onTheJob") {
        value = "onjob";
      }
      const list = [...addMoreTime];
      list[index][name] = value;
      setAddMoreTime(list);
    }
  };
  const addTime = async (formData) => {
    try {
      for (let index = 0; index < addMoreTime.length; index++) {
        let startTime = moment(addMoreTime[index].startTime["$d"]).format(
          "HH:mm:ssZZ"
        );
        let endTime = moment(addMoreTime[index].endTime["$d"]).format(
          "HH:mm:ssZZ"
        );
        let dateFormat = moment(timeSheet.date).format("YYYY-MM-DD");
        addMoreTime[index]["startTime"] = dateFormat + "T" + startTime;
        addMoreTime[index]["endTime"] = dateFormat + "T" + endTime;
      }
      let obj = {
        id: timeSheet._id,
        time_details: addMoreTime,
      };
      const { status, responseCode, data } = await Service.updateTimeSheet(obj);
      if (status === true && responseCode === 200) {
        handleViewMore();
        handleAddTime();
        getTimeSheet(startDate, endDate, "", "");
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };
  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleAddTime}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box sx={{ width: 350, pt: 10, px: 2, pb: 5 }} role="presentation">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontWeight: "bold",
            }}
          >
            <IconButton onClick={handleAddTime} sx={{ px: 0 }}>
              <MdClose size={24} />
            </IconButton>
          </Box>
          <Typography variant="h5" fontWeight={600}>
            {timeSheet.jobDetail.name}
          </Typography>
          <Box
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt=""
                src=""
                sx={{
                  display: "inline-flex",
                  mr: 1,
                  width: "30px",
                  height: "30px",
                  fontSize: "13px",
                  textTransform: "uppercase",
                  bgcolor: colors.skyBlue,
                }}
              >
                {timeSheet?.userDetails?.name?.split(" ").length > 1
                  ? timeSheet?.userDetails?.name?.charAt(0) +
                    timeSheet?.userDetails?.name?.split(" ")[1].charAt(0)
                  : timeSheet?.userDetails?.name?.charAt(0)}
              </Avatar>
              <Typography noWrap sx={{ fontSize: "14px", lineHeight: "20px" }}>
                {timeSheet?.userDetails?.name}
              </Typography>
            </Box>
            {/* <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#FFBA7D`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Submitted" /> */}
          </Box>
          <Typography sx={{ fontSize: 18, fontWeight: 600, my: 2 }}>
            {moment(timeSheet.date).format("dddd, DD-MM-YYYY")}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#4F4F4F" }}>
            Add Time Manually
          </Typography>
          <Typography
            component="form"
            sx={{ my: 2.5 }}
            onSubmit={handleSubmit(addTime)}
          >
            {addMoreTime.map((item, index) => {
              return (
                <Box key={index}>
                  <FormControl variant="standard" fullWidth>
                    <FieldLabel>Time Type</FieldLabel>
                    <Select
                      variant="standard"
                      fullWidth
                      className={classes.boxSelectField}
                      defaultValue="none"
                      sx={{
                        color: colors.secondaryLight,
                        "::before": {
                          display: "none",
                        },
                        "::after": {
                          display: "none",
                        },
                      }}
                      {...register(`type${index}`, {
                        required: "Please select type",
                        validate: (value) =>
                          value !== "none" || "error message",
                      })}
                      onChange={(e) => handleAddMoreTimeChange(e, index)}
                    >
                      <MenuItem value={"none"} disabled>
                        {/* Select priority */}
                        <Chip
                          size="small"
                          sx={{ fontSize: 12, lineHeight: "18px" }}
                          label="Select Type"
                        />
                      </MenuItem>
                      <MenuItem value={"onTheJob"} sx={{ my: 0.5 }}>
                        <Chip
                          size="small"
                          sx={{
                            cursor: "pointer",
                            fontWeight: 600,
                            backgroundColor: `#2D9CDB`,
                            color: `#FFFFFF`,
                            fontSize: 12,
                            lineHeight: "18px",
                          }}
                          label="On the job"
                        />
                      </MenuItem>
                      <MenuItem value={"travel"} sx={{ my: 0.5 }}>
                        <Chip
                          size="small"
                          sx={{
                            cursor: "pointer",
                            fontWeight: 600,
                            backgroundColor: `#A77DFF`,
                            color: `#FFFFFF`,
                            fontSize: 12,
                            lineHeight: "18px",
                          }}
                          label="Travel"
                        />
                      </MenuItem>
                      <MenuItem value={"break"} sx={{ my: 0.5 }}>
                        <Chip
                          size="small"
                          sx={{
                            cursor: "pointer",
                            fontWeight: 600,
                            backgroundColor: `${colors.secondary}`,
                            color: `#FFFFFF`,
                            fontSize: 12,
                            lineHeight: "18px",
                          }}
                          label="Break"
                        />
                      </MenuItem>
                      <MenuItem value={"delay"} sx={{ my: 0.5 }}>
                        <Chip
                          size="small"
                          sx={{
                            cursor: "pointer",
                            fontWeight: 600,
                            backgroundColor: `${colors.red}`,
                            color: `#FFFFFF`,
                            fontSize: 12,
                            lineHeight: "18px",
                          }}
                          label="Delay"
                        />
                      </MenuItem>
                    </Select>
                    {errors?.[`type${index}`]?.message && (
                      <Error message={"Please select a type"} />
                    )}
                  </FormControl>

                  {/* Set Date */}
                  {/* 
                        <FormControl variant="standard" fullWidth >
                            <FieldLabel>Date</FieldLabel>
                            <TextField
            hiddenLabel
            placeholder='Select date'
            variant="outlined"
            value={moment(date).format("DD-MM-YYYY")}
            className={classes.outlineInputBorder}
            size="small"
            onClick={(event) => setAnchorElDate(event.currentTarget)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box sx={{
                    bgcolor: "#F3F7F9",
                    p: 1,
                    ml: -1.6,
                    height: 40,
                    width: 35,
                    borderRadius: "8px 0px 0px 8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    <FaRegCalendarAlt color="gray" />
                  </Box>
                </InputAdornment>
              ),
            }}
            />
            <Menu
                                                        anchorEl={anchorElDate}
                                                        id="account-menu"
                                                        open={openDate}
                                                        onClose={() => setAnchorElDate(null)}
                                                        // onClick={() => setAnchorElDate(null)}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: 'visible',
                                                                filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,0.32))',
                                                                borderRadius: '8px',
                                                                mt: 1.5,
                                                                textAlign: "center",

                                                            },

                                                        }}
                                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                    >
                                                        <Calendar onChange={item => { setDate(item); setAnchorElDate(null) }} date={date} />
                                                    </Menu>
                           
                        </FormControl>
                        
                        {/* Set Start Time */}
                  <FormControl variant="standard" fullWidth>
                    <FieldLabel>Start Time {index + 1}</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={addMoreTime[index].startTime}
                        name="startTime"
                        onChange={(e) =>
                          handleAddMoreTimeChange(e, index, "startTime")
                        }
                        InputAdornmentProps={{ position: "start" }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            {...register(`startTime${index}`, {
                              required: "Please enter start Time",
                            })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors?.[`startTime${index}`]?.message && (
                      <Error message={"Please enter start time"} />
                    )}
                  </FormControl>
                  {/* Set End Time */}
                  <FormControl variant="standard" fullWidth>
                    <FieldLabel>End Time {index + 1}</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={addMoreTime[index].endTime}
                        name="endTime"
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(e) =>
                          handleAddMoreTimeChange(e, index, "endTime")
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            {...register(`endTime${index}`, {
                              required: "Please enter end time",
                            })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors?.[`endTime${index}`]?.message && (
                      <Error message={"Please enter end time"} />
                    )}
                  </FormControl>
                </Box>
              );
            })}
            <Box sx={{ width: "100%", textAlign: "center", my: 1 }}>
              <Button onClick={addMoreTimeField} sx={{ color: "#333333;" }}>
                {" "}
                <BsFillPlusCircleFill
                  style={{ marginRight: "10px", fontSize: 15 }}
                />
                Add Time
              </Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              <LoadingBtn
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
                style={{ px: 6 }}
              >
                Save
              </LoadingBtn>
            </Box>
          </Typography>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default AddTime;
