import { Box, Button, Divider, Drawer, FormControl, Grid, IconButton, InputAdornment, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Service } from 'app/config/service';
import colors from 'app/style/colors';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { useForm } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { FaPlusCircle } from 'react-icons/fa';
import { MdClose, MdLocationOn } from 'react-icons/md';
import { LoadingBtn, PrimaryButton } from '../UI/Buttons';
import { Error } from '../UI/Error';
import FieldLabel from '../UI/FieldLabel';
import { SuccessToaster } from '../UI/Toaster';

const useStyles = makeStyles({
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none"
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8
  },
})
const EditJobDrawer = ({ handleEditJob, open, selectedJobId, setSelectedJobId, getAssignJob, date }) => {

  const classes = useStyles();
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue, control, reset } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedJobCategory, setSelectedJobCategory] = useState("none");

  const [showMap, setShowMap] = useState(true);
  const [autoComplete, setAutoComplete] = useState(null);
  const [assignWorkList, setAssignWorkList] = useState([{ assignTo: "", startDate: new Date(), endDate: new Date(), startTime: dayjs(Date.now()), endTime: dayjs(Date.now()), note: "" }]);

  // for map
  const [currentLocation, setCurrentLocation] = useState({ latitude: 25.193, longitude: 67.155 })

  const [libraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro",
    libraries: libraries
  })

  // fot job categories
  const [jobCategories, setJobCategories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [job, setJob] = useState({});

  const [anchorElDate, setAnchorElDate] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const openDate = Boolean(anchorElDate);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    },
  ]);
  const handleDate = (item, index) => {
    setDateRange([item.selection])
    const list = [...assignWorkList];
    list[index]["startDate"] = moment(item.selection.startDate).format("YYYY-MM-DD");
    list[index]["endDate"] = moment(item.selection.endDate).format("YYYY-MM-DD");
    setAssignWorkList(list);

  }

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setCurrentLocation({ latitude, longitude })
  }

  const center = {
    lat: currentLocation.latitude,
    lng: currentLocation.longitude
  };

  const containerStyle = {
    width: '100%',
    height: '200px'
  };


  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const test = autoComplete.getPlace()
      changeLocation(test.geometry.location)
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }
  const defaultTime = new Date();
  defaultTime.setHours(8,30,0,0);
  const defaultEnd = new Date();
  defaultEnd.setHours(17,30,0,0);

  const handleAssignWorkerAdd = () => {
    setAssignWorkList([...assignWorkList, { assignTo: "", startDate: dayjs(Date.now()), endDate: dayjs(Date.now()), startTime: defaultTime, endTime: defaultEnd, note: "" }])
  };
  const handleWorkerChange = (e, index, action) => {
    if (action) {
      const list = [...assignWorkList];
      list[index][action] = e;
      setAssignWorkList(list);
    }
    else {
      const { name, value } = e.target;
      const list = [...assignWorkList];
      list[index][name] = value;
      setAssignWorkList(list);
    }

  };
  const updateJob = async (formData) => {
    try {
      let obj = {
        id: selectedJobId,
        budget: job.budget,
        category: selectedJobCategory,
        client_name: job.client_name,
        location: job.location,
        details: job.details,
        name: job.name,
        status: "pending",
        latitude: Number(currentLocation.latitude.toFixed(3)),
        longitude: Number(currentLocation.longitude.toFixed(3)),
        assignDetails: assignWorkList
      }
      const { status, responseCode } = await Service.updateJob(obj)
      if (status === true && responseCode === 200) {
        closeDrawer()
        reset()
        SuccessToaster("Job Edit")
        getAssignJob(date)
      }
    } catch (error) {
      console.log("🚀 ~ file: AddJobDrawer.js ~ line 166 ~ addJob ~ error", error)

    }

  }
  const closeDrawer = () => {
    handleEditJob()
    setSelectedJobCategory("")
    setSelectedJobId('')
    setAssignWorkList([{ assginTo: "", startDate: dayjs(Date.now()), endDate: dayjs(Date.now()), startTime: dayjs(Date.now()), endTime: dayjs(Date.now()), note: "" }])
  }

  // getting job categories
  const jobCategory = async () => {
    try {
      const { status, responseCode, data } = await Service.jobCategory()
      if (status === true && responseCode === 200) {
        setJobCategories(data?.jobCats)
      }
    } catch (error) {
      console.log('file: AddJob.js => line 125 => jobCategory => error', error);
    }
  }
  // get employees
  const getEmployee = async () => {
    try {
      const { status, responseCode, data } = await Service.getRegisteredEmployee()
      if (status === true && responseCode === 200) {
        setEmployees(data?.users)
      }
    } catch (error) {
      console.log('file: Todos.js => line 164 => getTags => error', error);
    }
  }
  // get job
  const getJob = async () => {
    try {
      const { status, responseCode, data } = await Service.getJobById(selectedJobId)
      if (status === true && responseCode === 200) {
        setJob(data)
        setSelectedJobCategory(data?.category)
        setAssignWorkList(data?.assignDetails)
        setCurrentLocation({ latitude: data?.latitude, longitude: data?.longitude })
      }
    } catch (error) {
      console.log('file: Todos.js => line 164 => getTags => error', error);
    }
  }
  const handleJobChange = (event) => {
    setJob({ ...job, [event.target.name]: event.target.value, })
  }
  useEffect(() => {
    jobCategory()
    getEmployee()
    getJob()
  }, [selectedJobId]);


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleEditJob}
      classes={{
        paper: classes.drawerPaper,
      }}
    >

      <Box
        sx={{ width: 340, pt: 10 }}
        role="presentation"
      >
        <Box sx={{ bgcolor: colors.white, width: "100%", height: "93vh", overflow: "scroll", p: 1.5 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              px: 2
            }}
          >
            <Typography variant="h6" sx={{ color: colors.textSecondary, fontWeight: "bold" }}>Edit Job</Typography>
            <IconButton onClick={closeDrawer} sx={{ px: 0 }}>
              <MdClose size={24} />
            </IconButton>
          </Box>
          <Typography component='form' sx={{ my: 2.5 }} onSubmit={handleSubmit(updateJob)}>
            {/*Job Name */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }} >
              <FieldLabel>Job Name*</FieldLabel>
              <TextField
                value={job?.name || ''}
                hiddenLabel
                placeholder='Enter Job name'
                variant="outlined"
                className={classes.outlineInputBorder}

                size="small"
                {...register("name",
                  //   {
                  //     required: "Job Name is Required"
                  //   }
                )}
                onChange={
                  handleJobChange
                }
              />
              {errors.name && <Error message={errors.name.message} />}
            </FormControl>

            <FormControl variant="standard" fullWidth >
              <FieldLabel>Job category*</FieldLabel>
              <Grid container alignItems="center" className={classes.boxSelectField}>
                <Grid item xs={10} >
                  <Select
                    variant="standard"
                    fullWidth
                    value={selectedJobCategory || ""}
                    // className={classes.boxSelectField}
                    {...register("category",
                      //   {
                      //     required: "Please select job category.",
                      //     validate: value => value !== 'none' || 'error message'
                      //   }
                    )}
                    onChange={(e) => {
                      setSelectedJobCategory(e.target.value)
                      clearErrors('category');
                    }}
                    sx={{
                      borderColor: errors?.category?.message && "red",
                      color: colors.secondaryLight,
                      "::before": {
                        display: "none",
                      },
                      "::after": {
                        display: "none",
                      },
                    }}
                  >
                    <MenuItem value={"none"} disabled>
                      Enter job category
                    </MenuItem>
                    {jobCategories.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={0.5}>
                  <Divider orientation="vertical" sx={{ height: 20, }} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton >
                    <FaPlusCircle size={16} color="black" />
                  </IconButton>
                </Grid>
              </Grid>
              {errors?.jobCategory?.message && (
                <Error message={"Please select a job category"} />
              )}
            </FormControl>

            {/*Job Description */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }} >
              <FieldLabel>Job Description</FieldLabel>
              <TextField
                hiddenLabel
                placeholder='Enter job description'
                variant="outlined"
                className={classes.outlineInputBorder}
                value={job?.details}
                size="small"
                {...register("details",

                )}
                onChange={
                  handleJobChange
                }
              />
              {errors.details && <Error message={errors.details.message} />}
            </FormControl>

            {/*Client Name */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>Client Name</FieldLabel>
              <TextField
                hiddenLabel
                placeholder='Enter Client Name'
                variant="outlined"
                className={classes.outlineInputBorder}
                value={job?.client_name}
                size="small"
                {...register("client_name",

                )}
                onChange={
                  handleJobChange
                }
              />
              {errors.client_name && <Error message={errors.client_name.message} />}
            </FormControl>

            {/*Job Budget */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>Job Budget</FieldLabel>
              <TextField
                hiddenLabel
                value={job?.budget}
                placeholder='Enter Job Budget'
                variant="outlined"
                className={classes.outlineInputBorder}
                type="number"
                size="small"
                {...register("budget",

                )}
                onChange={
                  handleJobChange
                }
              />
              {errors.clientName && <Error message={errors.clientName.message} />}
            </FormControl>

            {/*Job Status */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>Job Status</FieldLabel>
              <TextField
                disabled
                hiddenLabel
                placeholder='Enter Job Status'
                variant="outlined"
                value={job?.status}
                className={classes.outlineInputBorder}

                size="small"
                {...register("status",

                )}
                onChange={
                  handleJobChange
                }
              />
              {errors.status && <Error message={errors.status.message} />}
            </FormControl>

            {isLoaded &&
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
              >
                <FormControl variant="standard" fullWidth >
                  <FieldLabel>Job Location</FieldLabel>
                  <TextField
                    hiddenLabel
                    placeholder='Search location'
                    variant="outlined"
                    value={job?.location}
                    className={classes.outlineInputBorder}
                    error={errors?.location?.message && (true)}
                    size="small"
                    {...register("location",
                      // {
                      //   required: "location is required."
                      // }
                    )}
                    onChange={
                      handleJobChange
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{
                            bgcolor: "#F3F7F9",
                            p: 1,
                            ml: -1.6,
                            height: 43,
                            width: 30,
                            borderRadius: "8px 0px 0px 8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}>
                            <MdLocationOn />
                          </Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <Typography onClick={() => setShowMap(!showMap)} variant="body2" sx={{ fontSize: 12, width: "20%", cursor: "pointer", color: colors.skyBlue }}>Hide map</Typography>
                      ),
                    }}
                  />
                  {errors?.location?.message && (
                    <Error message={errors?.location?.message} />
                  )}
                </FormControl>
              </Autocomplete>
            }

            {showMap ? (
              isLoaded &&
              <Box mt={1.5}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                >
                  <Marker
                    position={center}
                  />
                </GoogleMap>
              </Box>
            ) : (null)}

            <Typography sx={{ mt: 1.5, fontSize: 18, color: colors.textSecondary, fontWeight: "bold" }}>Assign Work Hours </Typography>
            {assignWorkList.map((item, index) => {
              return (
                <>
                  <Typography sx={{ my: 1, fontWeight: 600, fontSize: 16 }}>Employee {index + 1}</Typography>
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>Assign to</FieldLabel>
                    <Grid container alignItems="center" className={classes.boxSelectField}>
                      <Grid item xs={10} >
                        <Select
                          variant="standard"
                          fullWidth
                          value={assignWorkList[index].assignTo}
                          name="assignTo"
                          // {...register("assginTo",
                          //   {
                          //     required: "Please select emplooye .",
                          //     validate: value => value !== 'none' || 'error message'
                          //   }
                          // )}
                          onChange={(e) => handleWorkerChange(e, index)}
                          sx={{
                            borderColor: errors?.assginTo?.message && "red",
                            color: colors.secondaryLight,
                            "::before": {
                              display: "none",
                            },
                            "::after": {
                              display: "none",
                            },
                          }}
                        >
                          <MenuItem value={"none"} disabled>
                            Select employee
                          </MenuItem>
                          {employees.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={0.5}>
                        <Divider orientation="vertical" sx={{ height: 20, }} />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton >
                          <FaPlusCircle size={16} color="black" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {errors?.assginTo?.message && (
                      <Error message={"Please select a employee"} />
                    )}
                  </FormControl>

                  {/* Date */}
                  <FormControl variant="standard" fullWidth >
                    <FieldLabel>Date</FieldLabel>
                    <TextField
                      disabled
                      hiddenLabel
                      placeholder='Select date or date range'
                      variant="outlined"
                      className={classes.outlineInputBorder}
                      value={`${moment(assignWorkList[index].startDate).format("DD MMM YYYY")} - ${moment(assignWorkList[index].endDate).format("DD MMM YYYY")}`}
                      onClick={(event) => { setAnchorElDate(event.currentTarget); setSelectedDateIndex(index) }}
                      size="small"
                    // {...register("date"
                    // )}
                    />
                    <Menu
                      anchorEl={anchorElDate}
                      open={openDate}
                      onClose={() => setAnchorElDate(null)}
                    >

                      <DateRange
                        // weekStartsOn={1}
                        style={{ width: 'auto' }}
                        editableDateInputs={true}
                        onChange={item => handleDate(item, selectedDateIndex)}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                      />
                      <Box sx={{ textAlign: 'right' }}>
                        <Button onClick={() => setAnchorElDate(null)} >Done</Button>
                      </Box>
                    </Menu>
                  </FormControl>

                  {/* Start Time */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>Start Time</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // label="Start Time"
                        value={assignWorkList[index].startTime}
                        name="startTime"
                        onChange={(e) => handleWorkerChange(e, index, "startTime")}
                        renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  {/* End Time */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>End Time</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // label="End Time"
                        value={assignWorkList[index].endTime}
                        name="endTime"
                        onChange={(e) => handleWorkerChange(e, index, "endTime")}
                        renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  {/* Notes */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }} >
                    <FieldLabel>Notes</FieldLabel>
                    <TextField size='small' hiddenLabel
                      placeholder='Enter Notes'
                      value={assignWorkList[index].note}
                      variant="outlined"
                      className={classes.outlineInputBorder}
                      name="note"
                      onChange={(e) => handleWorkerChange(e, index)}
                    />
                  </FormControl>

                </>
              )
            })}

            <Box sx={{ width: "100%", my: 1 }} onClick={handleAssignWorkerAdd}>
              <Button
                fullWidth
                variant="contained"
                sx={{ px: 6, backgroundColor: "#F8F8F8", color: "#888888", fontWeight: 600, fontSize: 12 }}

              >
                <BiPlus style={{ marginRight: 2, fontSize: 14 }} />
                Add other employee
              </Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              <LoadingBtn
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
                style={{ px: 6 }}
              >
                Save
              </LoadingBtn>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default EditJobDrawer