import { Avatar, Box, Button, Grid, InputAdornment, TextField, Tooltip, tooltipClasses, Typography } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { TableHeadTypo } from 'app/Components/UI/Typo'
import { Service } from 'app/config/service'
import colors from 'app/style/colors'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import moment from 'moment'
const useStyles = makeStyles({
    spanStyle: {
        position: "absolute",
        left: 0, top: 0,
        borderRadius: "8px 0px 0px 8px",
        height: "100%", width: "8px",
        backgroundColor: '#0769FC'
    },
    inputStyle: {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: '0.5px solid #CCCCCC;'
            },
            borderRadius: 8
        }
    }
})

const CustomTooltip = styled(({ children, className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" >
        <span style={{ width: "320px" }}>{children}</span>
    </Tooltip>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 12,
        maxWidth: 200,
        textAlign: "center",
        background: "#0D253C",
        "&>.MuiTooltip-arrow": {
            "&:before": {
                color: "#0D253C"
            }
        }
    },
});
const TimeOff = () => {

    const classes = useStyles()
    const [requestList, setRequestList] = useState([]);
    const [message, setMessage] = useState([{_id:"",message:""}]);
    
    const handleMessage=(obj)=>{
        try {
            const index = message.findIndex(item => item._id === obj._id);
           if(index===-1){
            let messages=[...message,obj]
            setMessage(messages)
           }
           else{
            
            let messages=[...message]
            messages[index].message=obj.message
            if(obj.message===''){
                messages.splice(index, 1);
            }
            setMessage(messages)
           }
        } catch (error) {
            console.log("🚀 ~ file: TimeOff.js ~ line 56 ~ handleMessage ~ error", error)
            
        }
    }
    const updateMessage=(_id)=>{
        const index = message.findIndex(item => item._id ===_id);
        updateTimeOff({_id,ownerMessage:message[index].message})
    }

    const getTimeOff=async()=>{
        try {
            const { status, responseCode, message, data } = await Service.getTimeOff()
            if (status === true && responseCode === 200) {
                setRequestList(data.result)
              }
        } catch (error) {
            console.log("🚀 ~ file: TimeOff.js ~ line 54 ~ getTimeOff ~ error", error)
            
        }
    }
    const updateTimeOff=async(obj)=>{
        try {
            const { status, responseCode, message, data } = await Service.updateTimeOff(obj)
            if (status === true && responseCode === 200) {
                // setRequestList(data.result)
                getTimeOff()
              }
        } catch (error) {
            console.log("🚀 ~ file: TimeOff.js ~ line 54 ~ getTimeOff ~ error", error)
            
        }
    }
    useEffect(() => {
        getTimeOff()
    }, []);


    return (
        <>
        {/* table head  */}
        <Box sx={{
                p: 1.5,
                m: 2,
                borderRadius: "8px",
                bgcolor: '#F3F7F9',
                minWidth: 450,
                display: "flex",
                border: `1px solid ${colors.borderColor}`
            }}>
                <Grid container sx={{ minWidth: '500px' }}>
                    <Grid item xs={1.8} >
                        <TableHeadTypo style={{marginLeft:'16px'}} >Employee Name</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.2}  >
                        <TableHeadTypo>Requested date(s)</TableHeadTypo>
                    </Grid>
                    <Grid item xs={0.8} >
                        <TableHeadTypo >Days</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.3}  >
                        <TableHeadTypo >Message from worker</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2.5}  >
                        <TableHeadTypo >Your message (Optional)</TableHeadTypo>
                    </Grid>
                    <Grid item xs={2}  >
                        <TableHeadTypo >Accept or Reject</TableHeadTypo>
                    </Grid>
                </Grid>
            </Box>
        {requestList.map((list,index)=>{
            return(
                <Box >

                <Box sx={{
                    position: "relative",
                    m: 2, p: 1.5,
                    borderRadius: "8px",
                    minWidth: 650,
                    minHeight: 70,
                    display: "flex", alignItems: "center", border: `1px solid ${colors.borderColor}`
                }}>

                    <span className={classes.spanStyle} />
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={1.8} alignSelf="center">
                            <Box variant="body2" sx={{ml:1, cursor: "pointer", display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="" src=''
                                    sx={{
                                        display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                        fontSize: "13px", textTransform: "uppercase",
                                        bgcolor: colors.skyBlue,
                                    }}>

                                    {`${list.worker.name}`?.split(" ").length > 1 ?
                                        `${list.worker.name}`?.charAt(0) + `${list.worker.name}`?.split(" ")[1].charAt(0) :
                                        `${list.worker.name}`?.charAt(0)
                                    }
                                </Avatar>
                                <Typography noWrap sx={{ fontSize: '13px' }}>{list.worker.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.2} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>{moment(list.toDate).format("DD MMM YYYY")}- {moment(list.fromDate).format("DD MMM YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={0.8} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>{list.days}</Typography>
                        </Grid>
                        <Grid item xs={2.1} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>
                                <CustomTooltip title={list.workerMessage} arrow >
                                    {list.workerMessage}
                                </CustomTooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} alignSelf="center" sx={{ ml: 0.5 }}>
                           {list?.ownerMessage?
                            <Typography noWrap sx={{ fontSize: '13px' }}>
                            <CustomTooltip title={list.ownerMessage} arrow >
                                {list.ownerMessage}
                            </CustomTooltip>
                        </Typography>
                        :
                        <TextField
                            className={classes.inputStyle}
                            placeholder='Enter Your Message' size='small'
                            onChange={(e) => handleMessage({_id:list._id,message:e.target.value})}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={()=>updateMessage(list._id)}>
                                        {message.findIndex(item => item._id === list._id)!==-1&&
                                            <FaCheckCircle color={colors.skyBlue} />
                                            
                                        }
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{ style: { fontSize: 12 } }}
                        />
                           
                           }
                        </Grid>
                        <Grid item xs={2.4} alignSelf="center">
                            {list.status ==="request" &&
                                <Box sx={{ display: "flex", ml: 0.5 }}>
                                    <Button
                                        sx={{
                                            width:88,height:28, fontSize: '13px', background: '#219653', borderRadius: '5px', mr: 1, '&:hover': {
                                                backgroundColor: '#1eb05c'
                                            }
                                        }} variant="contained"
                                        onClick={() => updateTimeOff({_id:list._id,status:"accept"})}>Accept</Button>
                                    <Button
                                        sx={{
                                            width:88,height:28, fontSize: '13px', background: '#EB5757', borderRadius: '5px',
                                            '&:hover': {
                                                backgroundColor: '#f76060'
                                            }
                                        }} variant="contained"
                                        onClick={() => updateTimeOff({_id:list._id,status:"reject"})}
                                    >Reject</Button>
                                </Box>
                            }
                            {list.status ==="accept"
                                ?
                                <Typography sx={{color:'#219653',fontWeight:600,textAlign:'center',fontSize:13}}>Request accepted</Typography>

                                :
                                list.status ==="reject"&&
                                <Typography sx={{color:'#EB5757',fontWeight:600,textAlign:'center',fontSize:13}}>Request rejected</Typography>
                            }
                        </Grid>

                    </Grid>
                </Box>
            </Box>
            )

        })}
            


            {/* <Box >

                <Box sx={{
                    position: "relative",
                    m: 2, p: 1.5,
                    borderRadius: "8px",
                    minWidth: 650,
                    minHeight: 70,
                    display: "flex", alignItems: "center", border: `1px solid ${colors.borderColor}`
                }}>

                    <span className={classes.spanStyle} />
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={1.8} alignSelf="center">
                            <Box variant="body2" sx={{ml:1, cursor: "pointer", display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="" src=''
                                    sx={{
                                        display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                        fontSize: "13px", textTransform: "uppercase",
                                        bgcolor: colors.skyBlue,
                                    }}>

                                    {"Ahmed baky"?.split(" ").length > 1 ?
                                        "Ahmed baky"?.charAt(0) + "Ahmed baky"?.split(" ")[1].charAt(0) :
                                        "Ahmed baky"?.charAt(0)
                                    }
                                </Avatar>
                                <Typography noWrap sx={{ fontSize: '13px' }}>Ahmed baky</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.2} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>17 Aug 2021- 20 Aug 2021</Typography>
                        </Grid>
                        <Grid item xs={0.8} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>14 days</Typography>
                        </Grid>
                        <Grid item xs={2.1} alignSelf="center">
                            <Typography noWrap sx={{ fontSize: '13px' }}>
                                <CustomTooltip title='Visiting my mum in Timbuktu. Ktnxbye.' arrow >
                                    Visiting my mum in Timbuktu. Ktnxbye.
                                </CustomTooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4} alignSelf="center" sx={{ ml: 0.5 }}>
                            <TextField
                                className={classes.inputStyle}
                                placeholder='Enter Your Message' size='small'
                                onChange={(e) => setMessage(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                            {message ?
                                                <FaCheckCircle color={colors.skyBlue} />
                                                : ""
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ style: { fontSize: 12 } }}
                            />
                        </Grid>
                        <Grid item xs={2.4} alignSelf="center">
                            {requestStatus ?
                                <Typography sx={{color:requestStatus==="rejected"?'#EB5757':"#219653",fontWeight:600,textAlign:'center',fontSize:13}}>Request {requestStatus}</Typography>

                                :
                                <Box sx={{ display: "flex", ml: 0.5 }}>
                                    <Button
                                        sx={{
                                            width:88,height:28, fontSize: '13px', background: '#219653', borderRadius: '5px', mr: 1, '&:hover': {
                                                backgroundColor: '#1eb05c'
                                            }
                                        }} variant="contained"
                                        onClick={() => setRequestStatus("accepted")}>Accept</Button>
                                    <Button
                                        sx={{
                                            width:88,height:28, fontSize: '13px', background: '#EB5757', borderRadius: '5px',
                                            '&:hover': {
                                                backgroundColor: '#f76060'
                                            }
                                        }} variant="contained"
                                        onClick={() => setRequestStatus("rejected")}
                                    >Reject</Button>
                                </Box>
                            }
                        </Grid>

                    </Grid>
                </Box>
            </Box> */}
        </>
    )
}

export default TimeOff