const { FaHammer, FaBell, FaRegCalendarAlt, FaRegClock, FaCalculator, FaCommentDots, FaFileAlt, FaUserAlt, FaUsers, FaCog } = require("react-icons/fa");


const style = { fontSize: "18px", verticalAlign: 'bottom', marginRight: "8px", color: "white" }


export const navList = [
  { label: 'Notifications', path: '/notifications', icon: <FaBell style={style} /> },
  { label: "To Do's", path: '/', icon: <FaHammer style={style} /> },
  { label: 'Scheduler', path: '/scheduler', icon: <FaRegCalendarAlt style={style} /> },
  { label: 'Timesheets', path: '/timesheet', icon: <FaRegClock style={style} /> },
  { label: 'Expenses', path: '/expenses', icon: <FaCalculator style={style} /> },
  { label: 'Chat', path: '/chat', icon: <FaCommentDots style={style} /> },
]

export const settingList = [
  { label: 'Jobs', icon: <FaFileAlt style={style} /> },
  { label: 'Employees', icon: <FaUserAlt style={style} /> },
  { label: 'Customers', icon: <FaUsers style={style} /> },
  { label: 'Settings', icon: <FaCog style={style} /> },
]


