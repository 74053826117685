import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PrivateRoutes } from './routes/routes';
import useAuth from './hooks/useAuth';

import SignIn from './views/Session/Login/Login';
import { Toaster } from './Components/UI/Toaster';
import Register from './views/Session/Register/Register';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createTheme({
  palette: {
    primary: {
      main: '#333333',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'sans-serif',
      'Open Sans'
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 }
      }
    }
  }
});


function App() {

  const { verifyToken, user } = useAuth();

  const token = localStorage.getItem('jwt')

  useEffect(() => {
    verifyToken();
  })


  return (
    <BrowserRouter>
      <Toaster />
      <ThemeProvider theme={theme}>
        {/* ========== App Routes ========== */}
        <Routes>
          <Route element={!user ? <Outlet /> : <Navigate to="/" />}>
            <Route path="auth/sign-in" element={<SignIn />} />
            <Route path="auth/sign-up" element={<Register />} />
          </Route>
          <Route element={user || token ? <Outlet /> : <Navigate to="auth/sign-in" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
