import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingBtn } from "app/Components/UI/Buttons";
import { Error } from "app/Components/UI/Error";
import FieldLabel from "app/Components/UI/FieldLabel";
import { SuccessToaster } from "app/Components/UI/Toaster";
import { Service } from "app/config/service";
import colors from "app/style/colors";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { DateRange } from "react-date-range";
import { useForm } from "react-hook-form";
import { FaPlusCircle } from "react-icons/fa";
import { MdClose, MdLocationOn } from "react-icons/md";

const useStyles = makeStyles({
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});
const AssignResourcesDrawer = ({
  handleAddTimeSheet,
  open,
  job,
  getUnschedule,
  handleEmployeeDialog,
  employeeDialog,
}) => {
 

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const defaultTime = new Date();
  defaultTime.setHours(8,30,0,0);
  const defaultEnd = new Date();
  defaultEnd.setHours(17,30,0,0);
  const [assignWorkList, setAssignWorkList] = useState([
    {
      assignTo: "",
      startDate: new Date(),
      endDate: new Date(),
      startTime: defaultTime,
      endTime: defaultEnd,
      note: "",
    },
  ]);
  console.log(
    "🚀 ~ file: AddJobDrawer.js ~ line 62 ~ AddJobDrawer ~ assignWorkList",
    assignWorkList
  );

  const [employees, setEmployees] = useState([]);

  const [anchorElDate, setAnchorElDate] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const openDate = Boolean(anchorElDate);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDate = (item, index) => {
    setDateRange([item.selection]);
    const list = [...assignWorkList];
    list[index]["startDate"] = moment(item.selection.startDate).format(
      "YYYY-MM-DD"
    );
    list[index]["endDate"] = moment(item.selection.endDate).format(
      "YYYY-MM-DD"
    );
    setAssignWorkList(list);
  };

  const handleAssignWorkerAdd = () => {
    setAssignWorkList([
      ...assignWorkList,
      {
        assignTo: "",
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
        startTime: dayjs(Date.now()),
        endTime: dayjs(Date.now()),
        note: "",
      },
    ]);
  };
  const handleWorkerChange = (e, index, action) => {
    if (action) {
      const list = [...assignWorkList];
      list[index][action] = e;
      setAssignWorkList(list);
    } else {
      const { name, value } = e.target;
      const list = [...assignWorkList];
      list[index][name] = value;
      setAssignWorkList(list);
    }
  };
  const assignJob = async (formData) => {
    try {
      let obj = {
        id: job._id,
        assignDetails: assignWorkList,
      };
      const { status, responseCode } = await Service.updateJob(obj);
      if (status === true && responseCode === 200) {
        closeDrawer();
        reset();
        // setSelectedJob("none")
        SuccessToaster("Job Assign");
        getUnschedule();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddJobDrawer.js ~ line 166 ~ addJob ~ error",
        error
      );
    }
  };
  const closeDrawer = () => {
    handleAddTimeSheet();
    setAssignWorkList([
      {
        assginTo: "",
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
        startTime: dayjs(Date.now()),
        endTime: dayjs(Date.now()),
        note: "",
      },
    ]);
  };

  // get employees
  const getEmployee = async () => {
    try {
      const { status, responseCode, data } =
        await Service.getRegisteredEmployee();
      if (status === true && responseCode === 200) {
        setEmployees(data?.users);
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, [employeeDialog]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleAddTimeSheet}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box sx={{ width: 340, pt: 10 }} role="presentation">
        <Box
          sx={{
            bgcolor: colors.white,
            width: "100%",
            height: "93vh",
            overflow: "scroll",
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              px: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: colors.textSecondary, fontWeight: "bold" }}
            >
              Assign work hours
            </Typography>
            <IconButton
              onClick={closeDrawer}
              sx={{ px: 0 }}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
              }}
            >
              <MdClose size={24} />
            </IconButton>
          </Box>
          <Typography
            component="form"
            sx={{ my: 2.5 }}
            onSubmit={handleSubmit(assignJob)}
          >
            {/*Job Name */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>Job Name</FieldLabel>
              <TextField
                hiddenLabel
                disabled
                // placeholder='Enter Job name'
                value={job.name}
                variant="outlined"
                className={classes.outlineInputBorder}
                size="small"
                // {...register("name",
                //   {
                //     required: "Job Name is Required"
                //   }
                // )}
              />
              {errors.name && <Error message={errors.name.message} />}
            </FormControl>

            {/* <Typography sx={{ mt: 1.5, fontSize: 18, color: colors.textSecondary, fontWeight: "bold" }}>Assign Work Hours </Typography> */}
            {assignWorkList.map((item, index) => {
              return (
                <>
                  {/* <Typography sx={{my:1,fontWeight:600,fontSize:16}}>Employee {index+1}</Typography> */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>Assign to</FieldLabel>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.boxSelectField}
                    >
                      <Grid item xs={10}>
                        <Select
                          variant="standard"
                          fullWidth
                          value={assignWorkList[index].assignTo}
                          name="assignTo"
                          // {...register("assginTo",
                          //   {
                          //     required: "Please select emplooye .",
                          //     validate: value => value !== 'none' || 'error message'
                          //   }
                          // )}
                          onChange={(e) => handleWorkerChange(e, index)}
                          sx={{
                            borderColor: errors?.assignTo?.message && "red",
                            color: colors.secondaryLight,
                            "::before": {
                              display: "none",
                            },
                            "::after": {
                              display: "none",
                            },
                          }}
                        >
                          <MenuItem value={"none"} disabled>
                            Select employee
                          </MenuItem>
                          {employees.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={0.5}>
                        <Divider orientation="vertical" sx={{ height: 20 }} />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={() => handleEmployeeDialog(!employeeDialog)}>
                          <FaPlusCircle size={16} color="black" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {errors?.assginTo?.message && (
                      <Error message={"Please select a employee"} />
                    )}
                  </FormControl>

                  {/* Date */}
                  <FormControl variant="standard" fullWidth>
                    <FieldLabel>Date</FieldLabel>
                    <TextField
                      disabled
                      hiddenLabel
                      placeholder="Select date or date range"
                      variant="outlined"
                      className={classes.outlineInputBorder}
                      value={`${moment(assignWorkList[index].startDate).format(
                        "DD MMM YYYY"
                      )} - ${moment(assignWorkList[index].endDate).format(
                        "DD MMM YYYY"
                      )}`}
                      onClick={(event) => {
                        setAnchorElDate(event.currentTarget);
                        setSelectedDateIndex(index);
                      }}
                      size="small"
                      // {...register("date"
                      // )}
                    />
                    <Menu
                      anchorEl={anchorElDate}
                      open={openDate}
                      onClose={() => setAnchorElDate(null)}
                    >
                      <DateRange
                      weekStartsOn={1}
                        style={{ width: "auto" }}
                        editableDateInputs={true}
                        onChange={(item) => handleDate(item, selectedDateIndex)}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                      />
                      <Box sx={{ textAlign: "right" }}>
                        <Button onClick={() => setAnchorElDate(null)}>
                          Done
                        </Button>
                      </Box>
                    </Menu>
                  </FormControl>

                  {/* Start Time */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>Start Time</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // label="Start Time"
                        value={assignWorkList[index].startTime}
                        name="startTime"
                        onChange={(e) =>
                          handleWorkerChange(e, index, "startTime")
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  {/* End Time */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>End Time</FieldLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        // label="End Time"
                        value={assignWorkList[index].endTime}
                        name="endTime"
                        onChange={(e) =>
                          handleWorkerChange(e, index, "endTime")
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  {/* Notes */}
                  <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                    <FieldLabel>Notes (Optional)</FieldLabel>
                    <TextField
                      size="small"
                      hiddenLabel
                      placeholder="Enter Notes"
                      variant="outlined"
                      className={classes.outlineInputBorder}
                      name="note"
                      onChange={(e) => handleWorkerChange(e, index)}
                    />
                  </FormControl>
                </>
              );
            })}

            {/* <Box sx={{ width: "100%",my:1}} onClick={handleAssignWorkerAdd}>
              <Button
                fullWidth
                variant="contained"
                sx={{ px: 6,backgroundColor:"#F8F8F8",color:"#888888",fontWeight:600,fontSize:12 }}
                
              >
                <BiPlus style={{marginRight:2,fontSize:14}}/>
                Add other employee
              </Button>
            </Box> */}
            <Box sx={{ width: "100%" }}>
              <LoadingBtn
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
                style={{ px: 6 }}
              >
                Save
              </LoadingBtn>
            </Box>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AssignResourcesDrawer;
