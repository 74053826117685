import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { MdClose } from "react-icons/md"
import { Box, IconButton, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button, FormControl, TextField, Select, MenuItem, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import FieldLabel from '../UI/FieldLabel';
import colors from 'app/style/colors';
import { LoadingBtn, PrimaryButton } from '../UI/Buttons';
import { Error } from '../UI/Error';
import { emailRegex } from 'app/utils';
import { Service } from 'app/config/service';
import { SuccessToaster } from '../UI/Toaster';


const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none"
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
})

export default function AddEmployee({ open, handleClose,getEmployees }) {


  const classes = useStyles()

  const { register, handleSubmit, formState: { errors },clearErrors,setError,setValue, reset } = useForm();

  const [loading, setLoading] = useState(false);

  const [userRoles, setUserRoles] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState("none");

  const [jobType, setJobType] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("none");

  // getting user Roles
  const getRoles = async () => {
    try {
      const { status, responseCode, data } = await Service.getRoles()
      if (status === true && responseCode === 200) {
        setUserRoles(data.userTypes)
      }
    } catch (error) {
      console.log('file: AddJob.js => line 125 => jobCategory => error', error);
    }
  }

  const getJobTypes = async () => {
    try {
      const { status, responseCode, data } = await Service.getJobs()
      if (status === true && responseCode === 200) {
        setJobType(data?.jobs)
      }
    } catch (error) {
      console.log('file: AddJob.js => line 125 => jobCategory => error', error);
    }
  }

  const handlePhoneNumber=(phone)=>{

    if (phone !== "" && phone.length > 10 && phone.slice(-10)!=='0000000000' &&phone.slice(-10)!=='1234567890' ) {
      setPhoneNumber(phone)
      setValue('phoneNum', phone, { shouldValidate: true })
      }
      else{
        setError("phoneNum", {
          message: {
            inVaild: "This is required",
          }
        });
      }

  }



  const addEmployee = async (data) => {
    try {
     
      const obj = {
        name: data.fullName,
        email: data.email,
        phone: phoneNumber,
        company: data.companyName,
        hourly_wage: data.hourlyWage,
        role: selectedEmployee
      }
          const { status, responseCode } = await Service.addNewEmployee(obj)
          if (status === true && responseCode === 200) {
            handleClose()
            reset()
            setSelectedEmployee("none")
            setSelectedJobType("none")
            setPhoneNumber("")
            SuccessToaster("Employee Added")
            getEmployees()
          }
    } catch (error) {
      if(error.slice(0,5)==='Email'){
        setError('email', {
          message: "Email is already exists"
          
        })
      }
      else if(error.slice(0,5)==='Phone'){
        console.log(error)
        setError('phoneNum', {
          message: "Phone Number is already exists"
          
        })
      }
     else{
      console.log('file: AddEmployee.js => line 44 => addEmployee => error', error);
     }
    }
  }

  useEffect(() => {
    getRoles()
    getJobTypes()
  }, [])


  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
          Add new employee
          <IconButton onClick={
            ()=>{
              handleClose()
              reset()
              setSelectedEmployee("none")
              setSelectedJobType("none")
              setPhoneNumber("")
            }
            }>
            <MdClose size={24} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>

        <Typography component='form' onSubmit={handleSubmit(addEmployee)}>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Full name*</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='Enter full name'
              variant="outlined"
              className={classes.outlineInputBorder}
              error={errors?.fullName?.message && (true)}
              size="small"
              {...register("fullName",
                {
                  required: "Please type in employee’s full name (first name, last name)."
                }
              )}
            />
            {errors?.fullName?.message && (
              <Error message={errors?.fullName?.message} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Type of employee*</FieldLabel>
            <Select
              variant="standard"
              fullWidth
              value={selectedEmployee ?? ""}
              className={classes.boxSelectField}
              {...register("employee",
                {
                  required: "Please select employee type.",
                  validate:value=>value!=='none' || 'error message'
                }
              )}
              onChange={(e) => {
                setSelectedEmployee(e.target.value)
                clearErrors('employee');
              }}
              sx={{
                borderColor: errors?.employee?.message && "red",
                color: colors.secondaryLight,
                "::before": {
                  display: "none",
                },
                "::after": {
                  display: "none",
                },
              }}
            >
              <MenuItem value={"none"} disabled>
                Employee
              </MenuItem>
              {userRoles.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.employee?.message && (
              <Error message={"Please select employee type."} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Company name</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='Enter company name'
              variant="outlined"
              className={classes.outlineInputBorder}
              error={errors?.companyName?.message && (true)}
              size="small"
              {...register("companyName",
                // {
                //   required: 
                // }
              )}
            />
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Phone number*</FieldLabel>
            
            <PhoneInput
              buttonStyle={{
                borderRadius: "8px 0px 0px 8px",
                borderColor: errors?.phoneNum?.message && "red",
              }}
              inputStyle={{
                borderColor:errors?.phoneNum?.message&& "red",
                width: "100%",
                borderRadius: "8px",
                height: "45px",
              }}
              country={'au'}
              value={phoneNumber}
              {...register("phoneNum",
              {
                required: "Invalid phone number. Please type in a valid phone number.",
                
              }
            )}
              onChange={phone => {
               handlePhoneNumber(phone)
              }}
            />
            {errors?.phoneNum?.message && (
              <Error message={"Invalid phone number. Please type in a valid phone number."} />
            )}
            
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Email*</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='email@email.com'
              variant="outlined"
              className={classes.outlineInputBorder}
              error={errors?.email?.message && (true)}
              size="small"
              {...register("email",
                {
                  required: "Invalid email. Please type in a valid email.",
                  pattern: {
                    value: emailRegex,
                    message: 'Invalid email. Please type in a valid email.',
                  }
                }
              )}
            />
            {errors?.email?.message && (
              <Error message={errors?.email?.message} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Job title*</FieldLabel>
            <Select
              variant="standard"
              fullWidth
              value={selectedJobType ?? ""}
              className={classes.boxSelectField}
              {...register("jobType",
                {
                  required: "Please select job type.",
                  validate:value=>value!=='none' || 'error message'
                }
              )}
              onChange={(e) => {
                setSelectedJobType(e.target.value)
                clearErrors('jobType');
              }}
              sx={{
                borderColor:errors?.jobType?.message && "red",
                color: colors.secondaryLight,
                "::before": {
                  display: "none",
                },
                "::after": {
                  display: "none",
                },
              }}
            >
              
              <MenuItem value={"none"} disabled >
                None
              </MenuItem>
              {jobType.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              
              ))}
            </Select>
            {errors?.jobType?.message && (
              <Error message={"Please select job type."} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Hourly wage</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='Enter hourly wage'
              variant="outlined"
              className={classes.outlineInputBorder}
              // error={errors?.hourlyWage?.message && (true)}
              size="small"
              {...register("hourlyWage",
                // {
                //   required: "Please type in a hourly wage."
                // }
              )}
            />
            
           {errors?.hourlyWage?.message && ( <Error message={errors?.hourlyWage?.message} />)}
          
          </FormControl>


          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

            <PrimaryButton  onClick={
            ()=>{
              handleClose()
              reset()
              setSelectedEmployee("none")
              setSelectedJobType("none")
              setPhoneNumber("")
            }
            }>Cancel</PrimaryButton>
            &nbsp;
            <LoadingBtn
              loading={loading}
              type="submit"
              variant="contained"
              style={{ px: 5 }}
            >
              Add new employee
            </LoadingBtn>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
