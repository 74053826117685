import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";

import colors from "app/style/colors";
import { PrimaryButton } from "../UI/Buttons";

function SortBy({ setshowSortBy, getTasks }) {
  const [selectedValue, setSelectedValue] = useState(0);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const sortTask = () => {
    getTasks(`sortby=${selectedValue}`);
    setshowSortBy();
  };

  return (
    <Box
      onMouseLeave={setshowSortBy}
      sx={{
        boxShadow: "0px 5px 25px rgba(112, 115, 114, 0.3)",
        p: 1.5,
        borderRadius: "8px",
        mb: 2,
        maxWidth: 230,
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          color: colors.textSecondary,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Sort By
        <IconButton onClick={setshowSortBy}>
          <MdClose size={24} />
        </IconButton>
      </Box>

      <FormControl>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          sx={{
            fontSize: "14px",
            mb: 1,
            mt: 2,
            fontWeight: "bold",
            color: colors.textSecondary,
          }}
        >
          Job Name
        </FormLabel>

        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value={1}
            label={
              <Typography variant="body2" color="textSecondary">
                Due Date
              </Typography>
            }
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              mx: 0,
              color: colors.textSecondary,
              width: 210,
              border: `1px solid ${colors.borderColor}`,
              borderRadius: "8px",
              pl: 1,
              my: 0.5,
            }}
            control={
              <Radio
                sx={{
                  color: "transparent",
                  ".css-hyxlzm": {
                    bgcolor: "#D0D5DD",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  },
                  "&.Mui-checked": {
                    color: colors.darkBlue,
                    ".MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    ".css-hyxlzm": {
                      bgcolor: "transparent",
                    },
                  },
                }}
              />
            }
          />
          <FormControlLabel
            value={2}
            label={
              <Typography variant="body2" color="textSecondary">
                Date Added
              </Typography>
            }
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              mx: 0,
              color: colors.textSecondary,
              width: 210,
              border: `1px solid ${colors.borderColor}`,
              borderRadius: "8px",
              pl: 1,
              my: 0.5,
            }}
            control={
              <Radio
                sx={{
                  color: "transparent",
                  ".css-hyxlzm": {
                    bgcolor: "#D0D5DD",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  },
                  "&.Mui-checked": {
                    color: colors.darkBlue,
                    ".MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    ".css-hyxlzm": {
                      bgcolor: "transparent",
                    },
                  },
                }}
              />
            }
          />
          <FormControlLabel
            value={3}
            label={
              <Typography variant="body2" color="textSecondary">
                Low - High Priority
              </Typography>
            }
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              mx: 0,
              color: colors.textSecondary,
              width: 210,
              border: `1px solid ${colors.borderColor}`,
              borderRadius: "8px",
              pl: 1,
              my: 0.5,
            }}
            control={
              <Radio
                sx={{
                  color: "transparent",
                  ".css-hyxlzm": {
                    bgcolor: "#D0D5DD",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  },
                  "&.Mui-checked": {
                    color: colors.darkBlue,
                    ".MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    ".css-hyxlzm": {
                      bgcolor: "transparent",
                    },
                  },
                }}
              />
            }
          />
          <FormControlLabel
            value={4}
            label={
              <Typography variant="body2" color="textSecondary">
                High - Low Priority
              </Typography>
            }
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              mx: 0,
              color: colors.textSecondary,
              width: 210,
              border: `1px solid ${colors.borderColor}`,
              borderRadius: "8px",
              pl: 1,
              my: 0.5,
            }}
            control={
              <Radio
                sx={{
                  color: "transparent",
                  ".css-hyxlzm": {
                    bgcolor: "#D0D5DD",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                  },
                  "&.Mui-checked": {
                    color: colors.darkBlue,
                    ".MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    ".css-hyxlzm": {
                      bgcolor: "transparent",
                    },
                  },
                }}
              />
            }
          />
        </RadioGroup>

        <PrimaryButton
          fullWidth
          onClick={sortTask}
          variant="contained"
          sx={{
            border: `1px solid ${colors.darkBlue}`,
            backgroundColor: colors.darkBlue,
            textTransform: "capitalize",
            color: colors.white,
            px: 3,
            mr: 1,
            my: 2,
            ":hover": {
              backgroundColor: colors.skyBlue,
            },
          }}
        >
          Submit
        </PrimaryButton>
      </FormControl>
    </Box>
  );
}

export default SortBy;
