import axios from 'axios';

export const server = 'https://consultya.mangotech-api.com/api';
// export const server = 'http://localhost:8004/api';

const instance = axios.create({
  baseURL: server,
});

const d = new Date();
let time = d.getTimezoneOffset();
// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {

    const token = localStorage.getItem("jwt")
    config.headers = { 
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
			'Content-Type': 'application/json',
      'time':time
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

export default instance;



