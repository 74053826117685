import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, } from '@mui/material'

import colors from 'app/style/colors'
import { Service } from 'app/config/service';
import { Loading } from 'assets/images';

// *import component
import TodosTable from './shared/TodosTable';
import AddEmployee from 'app/Components/Dialog/AddEmployee';
import AddJob from 'app/Components/Dialog/AddJob';
import AddToDo from 'app/Components/AddToDo/AddToDo'



function Todos({ getPageName }) {

  const [toggleTodo, setToggleTodos] = useState(true);

  // toggle dialogs
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [openJobDialog, setOpenJobDialog] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [tags, setTags] = useState([]);

  const [loader, setLoader] = useState(true);

  const [tasks, setTasks] = useState([]);

  const getTasks = async (value) => {
    try {
      const { status, responseCode, data } = await Service.getTasks(value)
      if (status === true && responseCode === 200) {
        setLoader(false)
        if (data.count > 0) {
          setToggleTodos(false)
          const allTask = []
        for (let index = 0; index < data?.tasks.length; index++) {
          const element = data?.tasks[index];
          const updatedElement ={...element,color:`#${Math.floor(Math.random()*16347215).toString(16)}`} 
          allTask.push(updatedElement)
        }
        setTasks(allTask)
        localStorage.setItem("tasks",JSON.stringify(...allTask))
        } else {
          const tasks = localStorage.getItem("tasks")
          if(JSON.parse(tasks)){
            setToggleTodos(false)
            setTasks([])
          }
          else{
            setToggleTodos(true)
          }
        }

      }
    } catch (error) {
      console.log('file: Todos.js => line 164 => getTags => error', error);
    }
  }

  // get employees
  const getRegisteredEmployee = async () => {
    try {
      const { status, responseCode, data } = await Service.getRegisteredEmployee()
      if (status === true && responseCode === 200) {
        setEmployees(data?.users)
      }
    } catch (error) {
      console.log('file: Todos.js => line 164 => getTags => error', error);
    }
  }

  // get jobs
  const getJobs = async () => {
    try {
      const { status, responseCode, data } = await Service.getJobs()
      if (status === true && responseCode === 200) {
        setJobs(data?.jobs)
      }
    } catch (error) {
      console.log('file: Todos.js => line 120 => getJobs => error', error);
    }
  }

  const getTags = async () => {
    try {
      const { status, responseCode, data } = await Service.getTags("")
      if (status === true && responseCode === 200) {
        setTags(data?.tags)
      }
    } catch (error) {
      console.log('file: Todos.js => line 164 => getTags => error', error);
    }
  }


  useEffect(() => {
    getPageName("To Do’s")
    getTasks()
  }, [])
 
  return (
    <Grid container columnSpacing={1}>

      {/* Employee Dialog */}
      <AddEmployee open={openEmployeeDialog} handleClose={() => setOpenEmployeeDialog(false) } getEmployees={getRegisteredEmployee} />

      {/* Job Dialog */}
      <AddJob open={openJobDialog}  handleClose={() => setOpenJobDialog(false)} getJobs={getJobs}  />

      {loader ?
        (
          <Box sx={{
            width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"
          }}>
            <Loading />
          </Box>
        ) :
        (
          <Fragment>
            <Grid item xs={toggleTodo ? 8.5 : 12}>

              {toggleTodo ? (
                <Box sx={{ m: 1.5, border: `1px solid ${colors.borderColor}`, minHeight: "90vh", position: "relative" }}>
                  <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", textAlign: "center" }}>
                    <Typography variant="h5" sx={{ color: colors.textSecondary, fontWeight: "bold", mb: 1 }}>To Do’s</Typography>
                    <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                      You currently have no to do’s to track.
                      <br />
                      Start by creating a to do on the right.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <TodosTable
                  data={tasks}
                  getTasks={getTasks}
                  getRegisteredEmployee={getRegisteredEmployee}
                  getJobs={getJobs}
                  getTags={getTags}
                  jobs={jobs}
                  employees={employees}
                  tags={tags}
                  handleEmployeeDialog={setOpenEmployeeDialog}
                  employeeDialog={openEmployeeDialog}
                  handleJobDialog={setOpenJobDialog}
                  jobDialog={openJobDialog}
                />
              )}

            </Grid>

            {/* Add a to do */}
            {toggleTodo &&
              <Grid item xs={3.5}>

                <AddToDo
                  handleJobDialog={setOpenJobDialog}
                  jobDialog={openJobDialog}
                  handleEmployeeDialog={setOpenEmployeeDialog}
                  employeeDialog={openEmployeeDialog}
                  getTasks={getTasks}
                />

              </Grid>
            }
          </Fragment>

        )}
      
    </Grid>
  )
}

export default Todos