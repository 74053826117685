/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { MdClose } from "react-icons/md"
import { Box, IconButton, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button, FormControl, TextField, Select, MenuItem, Typography, InputAdornment } from '@mui/material';
import { MdLocationOn } from "react-icons/md"
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from '@react-google-maps/api';


import FieldLabel from '../UI/FieldLabel';
import colors from 'app/style/colors';
import { LoadingBtn, PrimaryButton } from '../UI/Buttons';
import { Error } from '../UI/Error';
import { emailRegex } from 'app/utils';
import { Service } from 'app/config/service';
import useAuth from 'app/hooks/useAuth';
import { SuccessToaster } from '../UI/Toaster';


const useStyles = makeStyles({
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none"
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
})

export default function AddJob({ open, handleClose,jobName,getJobs }) {
  const [name, setName] = useState(jobName);

  const [ libraries ] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBJ3IVlUfKfOT9ra-80Fex_t_hM5mfigro",
    libraries: libraries
  })

  const classes = useStyles()

  const { register, handleSubmit, formState: { errors }, reset,clearErrors,setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const [selectedjob, setSelectedJob] = useState("none");

  const [showMap, setShowMap] = useState(true);
  const [autoComplete, setAutoComplete] = useState(null);

  // for map
  const [currentLocation, setCurrentLocation] = useState({ latitude: 25.193, longitude: 67.155 })

  // fot job categories
  const [jobCategories, setJobCategories] = useState([]);


  // for creating new job
  const createNewJob = async (userData) => {

      try {
        const obj = {
          client_name: userData.clientName,
          details: userData.jobDetails,
          name: userData.jobName,
          location: userData.location,
          category: selectedjob,
          latitude: Number(currentLocation.latitude.toFixed(3)),
          longitude: Number(currentLocation.longitude.toFixed(3)),
        }
        const { status, responseCode } = await Service.createJobs(obj)
        if (status === true && responseCode === 200) {
          handleClose()
          reset()
          setSelectedJob("none")
          SuccessToaster("Job Added")
          getJobs()
          jobCategory();
        }
      } catch (error) {
        console.log('file: AddJob.js => line 93 => createNewJob => error', error);

      }
    
  }

  const changeLocation = (val) => {
    const latitude = val.lat()
    const longitude = val.lng()
    setCurrentLocation({ latitude, longitude })
  }

  const center = {
    lat: currentLocation.latitude,
    lng: currentLocation.longitude
  };

  const containerStyle = {
    width: '100%',
    height: '200px'
  };


  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete)
  }

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const test = autoComplete.getPlace()
      changeLocation(test.geometry.location)
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  // getting job categories
  const jobCategory = async () => {
    try {
      const { status, responseCode, data } = await Service.jobCategory()
      if (status === true && responseCode === 200) {
        setJobCategories(data?.jobCats)
      }
    } catch (error) {
      console.log('file: AddJob.js => line 125 => jobCategory => error', error);
    }
  }

  useEffect(() => {
    setName(jobName)
    if(jobName!==null||jobName!=='none'||jobName!==''){
      setValue("jobName",jobName)
    }

  }, [jobName]);

  useEffect(() => {
    jobCategory()
  }, [])
  const clearField=()=>{
    reset()
    setSelectedJob("none")
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
          Add a job
          <IconButton onClick={()=>{clearField();handleClose()}}>
            <MdClose size={24} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>

        <Typography component='form' onSubmit={handleSubmit(createNewJob)}>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Job Name*</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='Enter a unique job name'
              variant="outlined"
              value={name}
              className={classes.outlineInputBorder}
              error={errors?.jobName?.message && (true)}
              size="small"
              {...register("jobName",
                {
                  required: "Job name not unique. Please type in a job name you haven’t used before."
                }
              )}
              onChange={(event)=>{
                setName(event.target.value)
              }}
            />
            {errors?.jobName?.message && (
              <Error message={errors?.jobName?.message} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Job category*</FieldLabel>
            <Select
              variant="standard"
              fullWidth
              value={selectedjob ?? ""}
              className={classes.boxSelectField}
              {...register("jobCategory",
                {
                  required: "Please select job category.",
                  validate:value=>value!=='none' || 'error message'
                }
              )}
              onChange={(e) => {
                setSelectedJob(e.target.value)
                clearErrors('jobCategory');
              }}
              sx={{
                borderColor: errors?.jobCategory?.message && "red",
                color: colors.secondaryLight,
                "::before": {
                  display: "none",
                },
                "::after": {
                  display: "none",
                },
              }}
            >
              <MenuItem value={"none"} disabled>
                Enter job category
              </MenuItem>
              {jobCategories.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.jobCategory?.message && (
              <Error message={"Please select a job category"} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Job details</FieldLabel>
            <TextField
              hiddenLabel
              multiline
              placeholder='Enter job details'
              variant="outlined"
              className={classes.outlineInputBorder}
              error={errors?.jobDetails?.message && (true)}
              size="small"
              {...register("jobDetails",
                // {
                //   required: "job details is required"
                // }
              )}
            />
            {errors?.jobDetails?.message && (
              <Error message={errors?.jobDetails?.message} />
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth >
            <FieldLabel>Client name</FieldLabel>
            <TextField
              hiddenLabel
              placeholder='Enter client name'
              variant="outlined"
              className={classes.outlineInputBorder}
              error={errors?.clientName?.message && (true)}
              size="small"
              {...register("clientName",
                // {
                //   required: "client name is required",
                // }
              )}
            />
            {errors?.clientName?.message && (
              <Error message={errors?.clientName?.message} />
            )}
          </FormControl>

          {isLoaded &&
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <FormControl variant="standard" fullWidth >
                <FieldLabel>Job Location</FieldLabel>
                <TextField
                  hiddenLabel
                  placeholder='Search location'
                  variant="outlined"
                  className={classes.outlineInputBorder}
                  error={errors?.location?.message && (true)}
                  size="small"
                  {...register("location",
                    // {
                    //   required: "location is required."
                    // }
                  )}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box sx={{
                          bgcolor: "#F3F7F9",
                          p: 1,
                          ml: -1.6,
                          height: 43,
                          width: 30,
                          borderRadius: "8px 0px 0px 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}>
                          <MdLocationOn />
                        </Box>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Typography onClick={() => setShowMap(!showMap)} variant="body2" sx={{ fontSize: 12, width: "20%", cursor: "pointer", color: colors.skyBlue }}>Hide map</Typography>
                    ),
                  }}
                />
                {errors?.location?.message && (
                  <Error message={errors?.location?.message} />
                )}
              </FormControl>
            </Autocomplete>
          }

          {showMap ? (
            isLoaded &&
            <Box mt={1.5}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker
                  position={center}
                />
              </GoogleMap>
            </Box>
          ) : (null)}

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimaryButton onClick={()=>{clearField();handleClose()}}>Cancel</PrimaryButton>
            &nbsp;
            <LoadingBtn
              loading={loading}
              type="submit"
              variant="contained"
              style={{ px: 6 }}
            >
              Add Job
            </LoadingBtn>
          </Box>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
