import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Chip,
  InputAdornment,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  MenuItem,
  Collapse,
  Grid,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  FaFilter,
  FaFileMedical,
  FaChevronDown,
  FaTimesCircle,
  FaCheckCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsChat, BsThreeDotsVertical } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import { MdOutlineAddCircle, MdDelete } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { makeStyles, styled } from "@mui/styles";
import { v4 as uuidv4 } from "uuid";
import enLocale from "date-fns/locale/en-GB";
import colors from "app/style/colors";
import { getDate, getTaskDate } from "app/utils";
import { Service } from "app/config/service";

// import components
import { PrimaryButton } from "app/Components/UI/Buttons";
import { TableHeadTypo } from "app/Components/UI/Typo";
import AddSubTask from "app/Components/Drawer/AddSubTask";
import ViewMore from "app/Components/Drawer/ViewMore";
import Filters from "app/Components/Filter/Filters";
import SortBy from "app/Components/Filter/SortBy";
import AddToDoDrawer from "app/Components/Drawer/AddToDoDrawer";
import Menudropdown from "app/Components/Dropdown/Menudropdown";
import {
  ChipDelete,
  ChipSolid,
  ChipStatus,
  ChipStatusOutline,
  ChipTag,
} from "app/Components/UI/Chip";
import AddJob from "app/Components/Dialog/AddJob";

import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import avatarColors from "app/style/avatarColor";
import Comments from "app/Components/Drawer/TimeSheet/Comments";
import ToDoComments from "app/Components/Drawer/TimeSheet/ToDoComments";
import moment from "moment";

const useStyles = makeStyles({
  spanStyle: {
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: "8px 0px 0px 8px",
    height: "100%",
    width: "8px",
    backgroundColor: colors.skyBlue,
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  dotStyle: {
    position: "absolute",
    right: -5,
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
  },
  chatNotify: {
    position: "absolute",
    bottom: 2,
    right: 10,
    fontSize: "8px",
    width: "15px",
    height: "15px",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.skyBlue,
    color: colors.white,
  },
  chatDisable: {
    position: "absolute",
    bottom: 3,
    right: -2,
    fontSize: "10px",
  },
  purpleBox: {
    backgroundColor: `${colors.purple} !important`,
  },
  greenBox: {
    backgroundColor: `${colors.green} !important`,
  },
  yellowBox: {
    backgroundColor: `${colors.yellow} !important`,
  },
  chipDelete: {
    width: "fit-content",
    margin: "2px 0px !important",
    color: `${colors.skyBlue} !important`,
    border: `1px solid ${colors.skyBlue} !important`,
    "&>.MuiChip-deleteIcon": {
      color: `${colors.skyBlue} !important`,
    },
  },
});

const CustomTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <span style={{ width: "320px" }}>{children}</span>
  </Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
    background: "#0D253C",
    "&>.MuiTooltip-arrow": {
      "&:before": {
        color: "#0D253C",
      },
    },
  },
});

function TodosTable({
  data,
  getTasks,
  getJobs,
  getRegisteredEmployee,
  employees,
  jobs,
  getTags,
  tags,
  handleEmployeeDialog,
  employeeDialog,
  handleJobDialogtitle,
  jobDialog,
}) {
  const classes = useStyles();
  const DATE_SIZE = 28; // value of handle calendar date gap

  const [anchorEl, setAnchorEl] = useState(null);
  const [parentTaskId, setParentTaskId] = useState("");

  const [subTaskToggle, setSubTaskToggle] = useState("");
  const [showSubTask, setShowSubTask] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showAddTodo, setShowAddToDo] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [showSortBy, setshowSortBy] = useState(false);

  const [task, setTask] = useState();
  const [commentRef, setCommentRef] = useState(false);

  const [filterEmployees, setFilterEmployees] = useState(employees);

  const [employee, setEmployee] = useState("");

  const open = Boolean(anchorEl);

  const [showTextFiled, setShowTextFiled] = useState();

  // state for identify menu popover
  const [clickedItemId, setClickedItemId] = useState("");

  // state for handling menu popover
  const [coordinate, setCoordinate] = useState({
    x: "",
    y: "",
  });

  const [checkUpdateOnSubtask, setCheckUpdateOnSubTask] = useState(false);

  const [jobName, setJobName] = useState("");
  const [openJobDialog, setOpenJobDialog] = useState(false);

  // for handling comments
  const [anchorElComments, setAnchorElComments] = useState(null);
  const openComments = Boolean(anchorElComments);
  const [selectedTimeSheet, setSelectedTimeSheet] = useState({});

  const checkIfAllSubTaskAreDone = () => {
    data.map((item) => {
      const subTaskDone = item?.subTasks?.every((st) => st.status === "Done");
      const subTaskInProgress = item?.subTasks?.every(
        (st) => st.status === "In progress"
      );
      const subTaskOverDue = item?.subTasks?.every(
        (st) => st.status === "Overdue"
      );
      const subTaskNotStarted = item?.subTasks?.every(
        (st) => st.status === "Not started"
      );
      const isInProgress = item?.subTasks?.filter(
        (val) => val.status === "In progress"
      );
      if (!(moment().isSameOrAfter(item.due_date)) && isInProgress) {
        updateTask({ id: item._id, status: "In progress" });
      }
      if (item.subTasks && subTaskDone)
        updateTask({ id: item._id, status: "Done" });
      else if (item.subTasks && subTaskInProgress)
        updateTask({ id: item._id, status: "In progress" });
      else if (item.subTasks && subTaskOverDue)
        updateTask({ id: item._id, status: "Overdue" });
      else if (item.subTasks && subTaskNotStarted)
        updateTask({ id: item._id, status: "Not started" });
    });
  };
  const checkIfSubTaskIsOverDue = () => {
    data.map((item) => {
      item.subTasks?.map((subItem) => {
        const dueDate = moment(subItem.due_date);
        if (moment().isSameOrAfter(dueDate) && subItem.status !== "Done") {
          updateSubTask({ id: subItem._id, status: "Overdue" });
          updateTask({
            id: item._id,
            status: "Overdue",
            due_date: getDate(subItem.due_date),
          });
        }
      });
    });
  };

  const handleAnchorClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCoordinate({
      x: event.clientX,
      y: event.pageY,
    });
    setClickedItemId(id);
  };

  const toggleSubTasks = (value) => {
    if (subTaskToggle) {
      setSubTaskToggle("");
    } else {
      setSubTaskToggle(value);
    }
  };

  // for delete task
  const deleteTask = async (id) => {
    try {
      const { status, responseCode } = await Service.deleteTask(id);
      if (status === true && responseCode === 200) {
        getTasks();
        if (data.length === 1) {
          localStorage.removeItem("tasks");
        }
      }
    } catch (error) {
      console.log(
        "file: TodosTable.js => line 269 => deleteTask => error",
        error
      );
    }
  };

  // update jobs
  const updateTask = async (obj) => {
    try {
      const { status, responseCode } = await Service.updateTask(obj);
      if (status === true && responseCode === 200) {
        getTasks();
        setAnchorEl(null);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };

  // updateSubTask
  const updateSubTask = async (obj) => {
    const isDue = new Date(obj.due_date) < new Date();
    try {
      if (obj.due_date > obj.item_due_date) {
        updateTask({
          id: obj.itemId,
          due_date: getDate(obj.due_date),
        });
      }

      if (isDue) {
        updateTask({
          id: obj.itemId,
          due_date: getDate(obj.due_date),
          status: "Overdue",
        });
      }
      const { status, responseCode } = await Service.updateSubTask(obj);
      if (status === true && responseCode === 200) {
        getTasks();
        setAnchorEl(null);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };

  // handle tags add and delete
  const handleAddTag = (tag, oldTags, id) => {
    const sortedTags = [...oldTags];
    sortedTags.push(tag);
    updateTask({ id: id, tags: sortedTags });
  };

  const deleteTag = (item, oldTags, id) => {
    const tagsAfterDelete = oldTags.filter((e) => e._id !== item._id);
    updateTask({ id: id, tags: tagsAfterDelete });
  };
  const addTag = async () => {
    let obj = {
      title: tagInput.current.value,
    };
    const { status, responseCode, data } = await Service.addTag(obj);
    if (status === true && responseCode === 200) {
      getTasks();
      getTags();
      setAnchorEl(null);
    }
  };
  const jobNameInput = useRef();
  const tagInput = useRef();
  const handleJobDialog = (e) => {
    setJobName(jobNameInput.current.value);
    setOpenJobDialog(true);
    setAnchorEl(null);
  };
  const handleTodoUpdate = (e, id) => {
    setShowTextFiled("none");
    updateTask({ id, name: e.target.value });
  };
  const handleSubtaskTodoUpdate = (e, id) => {
    setShowTextFiled("none");
    updateSubTask({ id, name: e.target.value });
  };
  const filterEmployeesData = (event) => {
    const keyword = event.target.value;
    setEmployee(event.target.value);
    if (keyword !== "") {
      const results = employees.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFilterEmployees(results);
    } else {
      setFilterEmployees(employees);
      // If the text field is empty, show all users
    }
  };

  console.log("DATA---->", data);

  useEffect(() => {
    getJobs();
    getRegisteredEmployee();
    getTags();
    checkIfAllSubTaskAreDone();
    checkIfSubTaskIsOverDue();
  }, []);

  useEffect(() => {
    setFilterEmployees(employees);
  }, [employees]);

  // *For Column Resize
  const [width, setWidth] = useState(200);

  const onResize = (event, { element, size, handle }) => {
    try {
      console.log("file: TodosTable.js => line 306 => onResize => size", size);
      // setHeight(size.height)
      setWidth(size.width);
    } catch (error) {
      console.log(
        "file: TodosTable.js => line 302 => onResize => error",
        error
      );
    }
  };

  return (
    <Fragment>
      {selectedTimeSheet.user && (
        <ToDoComments
          handleComments={() => setAnchorElComments(!openComments)}
          open={openComments}
          getTasks={getTasks}
          timeSheet={selectedTimeSheet}
        />
      )}
      {/* add sub task drawer */}
      <AddSubTask
        employees={employees}
        registeredEmployee={getRegisteredEmployee}
        getTasks={getTasks}
        taskId={parentTaskId}
        handleSubTask={() => setShowSubTask(!showSubTask)}
        open={showSubTask}
      />
      <AddJob
        open={openJobDialog}
        handleClose={() => setOpenJobDialog(false)}
        jobName={jobName}
        getJobs={getJobs}
      />
      {/* ViewMore drawer */}
      {task && (
        <ViewMore
          handleViewMore={() => setShowViewMore(!showViewMore)}
          open={showViewMore}
          employees={employees}
          registeredEmployee={getRegisteredEmployee}
          getTasks={getTasks}
          task={task}
          commentRef={commentRef}
          handleJobDialog={setOpenJobDialog}
          jobDialog={openJobDialog}
        />
      )}
      {/* Add Todo drawer */}
      <AddToDoDrawer
        getTasks={getTasks}
        handleAddToDo={() => setShowAddToDo(!showAddTodo)}
        open={showAddTodo}
      />

      <Box
        sx={{ m: 1.5, minHeight: "90vh", position: "relative", width: "98%" }}
      >
        <Box sx={{ mb: 2 }}>
          <PrimaryButton
            onClick={() => setShowAddToDo(!showAddTodo)}
            sx={{
              border: `1px solid ${colors.blurBorder}`,
              bgcolor: colors.skyBlue,
              mr: 1,
              textTransform: "capitalize",
              color: colors.white,
              ":hover": {
                bgcolor: colors.darkBlue,
              },
            }}
          >
            <FaFileMedical style={{ marginRight: "8px" }} />
            Add new to do
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              setShowFilter(!showFilter);
              setshowSortBy(false);
            }}
            sx={{
              border: `1px solid ${colors.borderColor}`,
              bgcolor: colors.gray,
              mr: 1,
              textTransform: "capitalize",
            }}
          >
            <FaFilter
              style={{ marginRight: "8px" }}
              color={colors.primaryGray}
            />
            Filter
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              setshowSortBy(!showSortBy);
              setShowFilter(false);
            }}
            sx={{
              border: `1px solid ${colors.borderColor}`,
              bgcolor: colors.gray,
              mr: 1,
              textTransform: "capitalize",
            }}
          >
            <BiMenuAltLeft
              size={24}
              style={{ marginRight: "8px" }}
              color={colors.primaryGray}
            />
            Sort
          </PrimaryButton>
        </Box>

        {/* Filters component */}

        <Collapse in={showFilter}>
          <Filters
            getTasks={getTasks}
            setShowFilter={() => setShowFilter(false)}
          />
        </Collapse>

        {/* Sort By component */}

        <Collapse in={showSortBy}>
          <SortBy
            getTasks={getTasks}
            setshowSortBy={() => setshowSortBy(false)}
          />
        </Collapse>

        {/* table head */}
        <Box
          sx={{
            p: 1.5,
            borderRadius: "8px",
            bgcolor: colors.darkgrayShade,
            minWidth: 650,
            display: "flex",
            border: `1px solid ${colors.borderColor}`,
          }}
        >
          <Resizable
            height={"auto"}
            width={width}
            minConstraints={[150, 150]}
            maxConstraints={[750, 750]}
            onResize={onResize}
            axis={"x"}
            resizeHandles={["se resize-cursor"]}
          >
            <div className="box" style={{ width: width, height: "auto" }}>
              <TableHeadTypo style={{ textAlign: "Left", flex: 1 }}>
                To Do
              </TableHeadTypo>
            </div>
          </Resizable>

          <Grid
            container
            columns={19}
            columnSpacing={1}
            sx={{ minWidth: "500px" }}
          >
            <Grid item xs={2.5}>
              <TableHeadTypo style={{ textAlign: "left", flex: 1 }}>
                Job Name
              </TableHeadTypo>
            </Grid>
            <Grid item xs={2.5} style={{ textAlign: "left", flex: 1 }}>
              <TableHeadTypo>Sub-tasks</TableHeadTypo>
            </Grid>
            <Grid item xs={2.5} style={{ textAlign: "left", flex: 1 }}>
              <TableHeadTypo>Assigned to</TableHeadTypo>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "left", flex: 1 }}>
              <TableHeadTypo>Due Date</TableHeadTypo>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center", flex: 1 }}>
              <TableHeadTypo>Status</TableHeadTypo>
            </Grid>
            <Grid item xs={1.5} sstyle={{ textAlign: "left", flex: 1 }}>
              <TableHeadTypo>Comments</TableHeadTypo>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center", flex: 1 }}>
              <TableHeadTypo>Tags (max 3)</TableHeadTypo>
            </Grid>
            <Grid item xs={2.4} style={{ textAlign: "center", flex: 1 }}>
              <TableHeadTypo>Priority</TableHeadTypo>
            </Grid>
          </Grid>
        </Box>

        {/* table row start */}
        {data.map((item, i) => {
          return (
            <Box key={uuidv4()}>
              <Box
                sx={{
                  position: "relative",
                  my: 2,
                  px: 1.5,
                  borderRadius: "8px",
                  minWidth: 650,
                  minHeight: 70,
                  display: "flex",
                  alignItems: "stretch",
                  border: `1px solid ${colors.borderColor}`,
                }}
              >
                <span className={classes.spanStyle} />

                <Box sx={{ width: `${width - 16 + "px"}`, overflow: "hidden" }}>
                  <CustomTooltip title={item.name} arrow>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        height: "100%",
                        pl: 1,
                        mr: 1,
                        alignItems: "center",
                        cursor: "pointer",
                        display: showTextFiled !== item.name ? "flex" : "none",
                      }}
                      onDoubleClick={() => setShowTextFiled(item.name)}
                    >
                      {item.name}
                    </Typography>
                    <TextField
                      defaultValue={item.name}
                      variant="standard"
                      sx={{
                        height: "100%",
                        pl: 1,
                        mr: 1,
                        mt: "25px",
                        alignItems: "flexStart",
                        display: showTextFiled === item.name ? "flex" : "none",
                      }}
                      autoFocus
                      onBlur={(e) => handleTodoUpdate(e, item._id)}
                    />
                  </CustomTooltip>
                </Box>

                <Grid
                  container
                  columns={19}
                  columnSpacing={1}
                  sx={{ minWidth: "500px" }}
                >
                  {/* Job Name */}
                  <Grid item xs={2.5} alignSelf="center">
                    <Typography
                      variant="body2"
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => handleAnchorClick(e, "job" + item._id)}
                    >
                      {item.job}
                    </Typography>

                    {/* menu list for job */}
                    <Menudropdown
                      colName={"job"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"job" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={250}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <TextField
                        hiddenLabel
                        placeholder="Add job name"
                        variant="outlined"
                        className={classes.outlineInputBorder}
                        size="small"
                        inputRef={jobNameInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <FaTimesCircle
                                  size={14}
                                  color={colors.grayShade}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  handleJobDialog();
                                }}
                              >
                                <FaCheckCircle
                                  size={14}
                                  color={colors.skyBlue}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{ fontSize: "12px", color: "gray", m: 1 }}
                      >
                        OR select job name
                      </Typography>
                      <Box sx={{ height: "30vh" }}>
                        {jobs.map((jobitem) => (
                          <MenuItem
                            onClick={() =>
                              updateTask({ id: item._id, job: jobitem._id })
                            }
                            key={uuidv4()}
                            value={jobitem._id}
                          >
                            <Typography variant="subheading" noWrap>
                              {jobitem.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Box>
                    </Menudropdown>
                  </Grid>
                  {/* Sub tasks */}
                  <Grid item xs={2.5} alignSelf="center">
                    {item.subTasks.length !== 0 ? (
                      <Typography
                        onClick={() => toggleSubTasks(item._id)}
                        sx={{
                          cursor: "pointer",
                          width: 120,
                          display: "flex",
                          alignItems: "center",
                        }}
                        variant="body2"
                      >
                        {item.subTasks.length} sub-tasks
                        <FaChevronDown
                          size={12}
                          style={{
                            margin: "0px 5px",
                            color: colors.primaryGray,
                          }}
                        />
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() => {
                          setShowSubTask(!showSubTask);
                          setParentTaskId(item._id);
                        }}
                        variant="body2"
                        sx={{
                          cursor: "pointer",
                          width: 120,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        0 sub-tasks
                        <FaPlusCircle
                          size={14}
                          style={{ margin: "0px 5px", color: colors.black }}
                        />
                      </Typography>
                    )}
                  </Grid>
                  {/* Assigned to */}
                  <Grid item xs={2.5} alignSelf="center">
                    <Box
                      variant="body2"
                      sx={{
                        width: 120,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={(e) =>
                        handleAnchorClick(e, "assigned" + item._id)
                      }
                    >
                      <Avatar
                        alt=""
                        src=""
                        sx={{
                          display: "inline-flex",
                          mr: 1,
                          width: "30px",
                          height: "30px",
                          bgcolor: item.assigned_to_colorCode,
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.assigned_to?.split(" ").length > 1
                          ? item.assigned_to?.charAt(0) +
                            item.assigned_to?.split(" ")[1].charAt(0)
                          : item.assigned_to?.charAt(0)}
                      </Avatar>
                      <Typography noWrap sx={{ fontSize: "15px" }}>
                        {item.assigned_to}
                      </Typography>
                    </Box>
                    {/* menu list for assigned to */}
                    <Menudropdown
                      colName={"assigned"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"assigned" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={250}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <TextField
                        hiddenLabel
                        placeholder="Search employee"
                        variant="outlined"
                        className={classes.outlineInputBorder}
                        size="small"
                        sx={{ flex: 1 }}
                        value={employee}
                        onChange={filterEmployeesData}
                        autoFocus
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setFilterEmployees(employees);
                                  setEmployee("");
                                }}
                              >
                                <FaTimesCircle
                                  size={16}
                                  style={{ marginRight: "5px" }}
                                  color={colors.grayShade}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  handleEmployeeDialog(!employeeDialog);
                                  setAnchorEl(null);
                                  setFilterEmployees(employees);
                                  setEmployee("");
                                }}
                              >
                                <FaPlusCircle size={16} color="black" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box sx={{ height: "30vh" }}>
                        {filterEmployees.map((assignedItem) => (
                          <MenuItem
                            key={uuidv4()}
                            onClick={() =>
                              updateTask({
                                id: item._id,
                                assigned_to: assignedItem._id,
                              })
                            }
                            sx={{
                              border: `1px solid ${colors.shadowColor}`,
                              borderRadius: "8px",
                              my: 1,
                              p: 1,
                            }}
                          >
                            <Avatar
                              alt=""
                              src=""
                              sx={{
                                display: "inline-flex",
                                mr: 1,
                                width: "25px",
                                height: "25px",
                                bgcolor: assignedItem.colorCode,
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                            >
                              {assignedItem.name?.split(" ").length > 1
                                ? assignedItem.name?.charAt(0) +
                                  assignedItem.name?.split(" ")[1].charAt(0)
                                : assignedItem.name?.charAt(0)}
                            </Avatar>
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{ flex: 1 }}
                            >
                              {assignedItem.name}
                            </Typography>
                            {assignedItem._id === item.assigned_to_id && (
                              <FaCheckCircle
                                color={colors.skyBlue}
                                style={{ marginLeft: "5px" }}
                              />
                            )}
                          </MenuItem>
                        ))}
                      </Box>
                    </Menudropdown>
                  </Grid>
                  {/* DueDate */}
                  <Grid item xs={2} alignSelf="center">
                    <Typography
                      variant="body2"
                      sx={{ width: 80, textAlign: "center", cursor: "pointer" }}
                      onClick={(e) => handleAnchorClick(e, "date" + item._id)}
                    >
                      {getTaskDate(item.due_date)}
                    </Typography>
                    {/* menu list for date */}

                    <Menudropdown
                      colName={"date"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"date" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={"auto"}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        // adapterLocale={enLocale}
                      >
                        <Box
                          sx={{
                            "& > div": {
                              minWidth: 270,
                            },
                            "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                              {
                                width: 270,
                              },
                            "& .MuiTypography-caption": {
                              width: DATE_SIZE,
                              margin: 0,
                            },
                            "& .PrivatePickersSlideTransition-root": {
                              minHeight: DATE_SIZE * 6,
                            },
                            '& .PrivatePickersSlideTransition-root [role="row"]':
                              {
                                margin: 0,
                              },
                            "& .MuiPickersDay-dayWithMargin": {
                              margin: 0,
                            },
                            "& .MuiPickersDay-root": {
                              width: DATE_SIZE,
                              height: DATE_SIZE,
                            },
                          }}
                        >
                          <StaticDatePicker
                            value={getDate(item.due_date)}
                            onChange={(value) =>
                              updateTask({
                                id: item._id,
                                due_date: getDate(value),
                              })
                            }
                            displayStaticWrapperAs="desktop"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            )}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Menudropdown>
                  </Grid>
                  {/* Status */}
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    alignSelf="center"
                  >
                    <Fragment>
                      <ChipStatusOutline
                        item={item}
                        handleClick={(e) =>
                          handleAnchorClick(e, "status" + item._id)
                        }
                      />
                    </Fragment>
                    {/* menu list for status */}

                    <Menudropdown
                      colName={"status"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"status" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={120}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <ChipStatus
                        margin={1}
                        label="Done"
                        handleClick={() =>
                          updateTask({ id: item._id, status: "Done" })
                        }
                      />
                      <ChipStatus
                        margin={1}
                        label="In progress"
                        handleClick={() =>
                          updateTask({ id: item._id, status: "In progress" })
                        }
                      />
                      <ChipStatus
                        margin={1}
                        label="Overdue"
                        handleClick={() =>
                          updateTask({ id: item._id, status: "Overdue" })
                        }
                      />
                      <ChipStatus
                        margin={1}
                        label="Not Started"
                        handleClick={() =>
                          updateTask({ id: item._id, status: "Not started" })
                        }
                      />
                    </Menudropdown>
                  </Grid>
                  {/* Comments */}
                  <Grid item xs={1.5} alignSelf="center">
                    {item.comments ? (
                      <Box
                        sx={{
                          width: 58,
                          color: colors.skyBlue,
                          cursor: "pointer",
                          position: "relative",
                          textAlign: "center",
                        }}
                        // onClick={() => {
                        //   setTask(item);
                        //   setCommentRef(item._id);
                        //   setShowViewMore(!showViewMore);
                        // }}
                        onClick={() => {
                          setSelectedTimeSheet(item);
                          setAnchorElComments(true);
                        }}
                      >
                        <BsChat size={20} />
                        <span className={classes.chatNotify}>
                          {item.comments}
                        </span>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: 58,
                          color: colors.skyBlue,
                          cursor: "pointer",
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <BsChat size={20} style={{ color: "#DADADA" }} />
                      </Box>
                    )}
                  </Grid>
                  {/* Tags */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    xs={3}
                  >
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        maxWidth: 120,
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {item.tags.map((tags) => (
                        <ChipDelete
                          key={uuidv4()}
                          onDelete={() => deleteTag(tags, item.tags, item._id)}
                          item={tags}
                        />
                      ))}
                      {item.tags.length < 3 && (
                        <MdOutlineAddCircle
                          onClick={(e) =>
                            handleAnchorClick(e, "tag" + item._id)
                          }
                          style={{
                            marginTop: "5px",
                            cursor: "pointer",
                            fontSize: "20px",
                            color: colors.skyBlue,
                          }}
                        />
                      )}
                    </Box>
                    {/* menu list for Tag */}
                    <Menudropdown
                      colName={"tag"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"tag" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={200}
                      height={"30vh"}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <TextField
                        hiddenLabel
                        placeholder="Add Tag"
                        inputRef={tagInput}
                        variant="outlined"
                        className={classes.outlineInputBorder}
                        size="small"
                        sx={{ mb: 1 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => (tagInput.current.value = "")}
                              >
                                <FaTimesCircle
                                  size={16}
                                  color={colors.grayShade}
                                />
                              </IconButton>
                              <IconButton onClick={addTag}>
                                <FaCheckCircle
                                  size={16}
                                  color={colors.skyBlue}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {tags.map((tagItem) => (
                        <ChipTag
                          key={uuidv4()}
                          margin={1}
                          item={tagItem}
                          handleClick={() =>
                            handleAddTag(tagItem, item.tags, item._id)
                          }
                        />
                      ))}
                    </Menudropdown>
                  </Grid>
                  {/* Priority */}
                  <Grid item xs={2}>
                    <Typography
                      onClick={(e) =>
                        handleAnchorClick(e, "priority" + item._id)
                      }
                      variant="body2"
                      color="initial"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 1,
                        height: "100%",
                        color: colors.white,
                      }}
                      className={
                        item.priority === "High"
                          ? classes.purpleBox
                          : item.priority === "Medium"
                          ? classes.greenBox
                          : classes.yellowBox
                      }
                    >
                      {item.priority}
                    </Typography>

                    <Menudropdown
                      colName={"priority"}
                      open={open}
                      anchorEl={anchorEl}
                      setAnchor={() => setAnchorEl(null)}
                      id={"priority" + item._id}
                      clickedItemId={clickedItemId}
                      coordinate={coordinate}
                      width={120}
                      transformOrigin={{
                        vertical: -30,
                        horizontal: 0,
                      }}
                    >
                      <ChipSolid
                        margin={1}
                        label="High"
                        handleClick={() =>
                          updateTask({ id: item._id, priority: "High" })
                        }
                      />
                      <ChipSolid
                        margin={1}
                        label="Medium"
                        handleClick={() =>
                          updateTask({ id: item._id, priority: "Medium" })
                        }
                      />
                      <ChipSolid
                        margin={1}
                        label="Low"
                        handleClick={() =>
                          updateTask({ id: item._id, priority: "Low" })
                        }
                      />
                    </Menudropdown>
                  </Grid>
                </Grid>

                <span
                  onClick={(e) => handleAnchorClick(e, "view" + item._id)}
                  className={classes.dotStyle}
                >
                  <BsThreeDotsVertical size={24} />
                </span>

                <Menudropdown
                  colName={"view"}
                  open={open}
                  anchorEl={anchorEl}
                  setAnchor={() => setAnchorEl(null)}
                  id={"view" + item._id}
                  clickedItemId={clickedItemId}
                  coordinate={coordinate}
                  width={120}
                  transformOrigin={{
                    vertical: -30,
                    horizontal: 0,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setShowViewMore(!showViewMore);
                      setTask(item);
                      setAnchorEl(null);
                    }}
                    sx={{ fontSize: "14px", color: colors.textSecondary }}
                  >
                    <FiExternalLink />
                    &nbsp; View more
                  </MenuItem>
                  <MenuItem
                    onClick={() => deleteTask(item._id)}
                    sx={{ fontSize: "14px", color: colors.textSecondary }}
                  >
                    <MdDelete />
                    &nbsp; Delete
                  </MenuItem>
                </Menudropdown>
              </Box>
              {/* Sub task list */}
              {item.subTasks?.map((subItem, id) => (
                <Collapse key={uuidv4()} in={subTaskToggle === item._id}>
                  <Box
                    key={subItem._id}
                    sx={{
                      position: "relative",
                      my: 1,
                      p: 1.5,
                      ml: 20,
                      borderRadius: "8px",
                      width: {
                        md: "75%",
                        xl: "80%",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: `1px solid ${colors.borderColor}`,
                    }}
                  >
                    <span className={classes.spanStyle} />
                    {/* SUBTASK TODO NAME */}
                    <Box sx={{ overflow: "hidden" }}>
                      <CustomTooltip title={subItem.name} arrow>
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{
                            height: "100%",
                            pl: 1,
                            mr: 1,
                            alignItems: "center",
                            width: 250,
                            ".MuiTooltip-popper": { bgcolor: "red" },
                            display:
                              showTextFiled !== subItem.name ? "flex" : "none",
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => setShowTextFiled(subItem.name)}
                        >
                          {id + 1}.{subItem.name}
                        </Typography>
                        <TextField
                          defaultValue={subItem.name}
                          variant="standard"
                          sx={{
                            height: "100%",
                            pl: 1,
                            mr: 1,
                            mt: "10px",
                            width: 250,
                            alignItems: "flexStart",
                            display:
                              showTextFiled === subItem.name ? "flex" : "none",
                          }}
                          autoFocus
                          onBlur={(e) =>
                            handleSubtaskTodoUpdate(e, subItem._id)
                          }
                        />
                      </CustomTooltip>
                    </Box>
                    {/* SUBTASK AVATAR */}
                    <Box>
                      <Box
                        variant="body2"
                        sx={{
                          width: 150,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={(e) =>
                          handleAnchorClick(e, "assigned" + subItem._id)
                        }
                      >
                        <Avatar
                          alt=""
                          src=""
                          sx={{
                            display: "inline-flex",
                            mr: 1,
                            width: "30px",
                            height: "30px",
                            bgcolor: subItem.assigned_to_colorCode,
                            fontSize: "12px",
                            textTransform: "uppercase",
                          }}
                        >
                          {subItem.assigned_to?.split(" ").length > 1
                            ? subItem.assigned_to?.charAt(0) +
                              subItem.assigned_to?.split(" ")[1].charAt(0)
                            : subItem.assigned_to?.charAt(0)}
                        </Avatar>
                        <Typography noWrap sx={{ fontSize: "15px" }}>
                          {subItem.assigned_to}
                        </Typography>
                      </Box>
                      <Menudropdown
                        colName={"assigned"}
                        open={open}
                        anchorEl={anchorEl}
                        setAnchor={() => setAnchorEl(null)}
                        id={"assigned" + subItem._id}
                        clickedItemId={clickedItemId}
                        coordinate={coordinate}
                        width={250}
                        transformOrigin={{
                          vertical: -30,
                          horizontal: 0,
                        }}
                      >
                        <TextField
                          hiddenLabel
                          placeholder="Search employee"
                          variant="outlined"
                          className={classes.outlineInputBorder}
                          size="small"
                          sx={{ flex: 1 }}
                          value={employee}
                          onChange={filterEmployeesData}
                          autoFocus
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setFilterEmployees(employees);
                                    setEmployee("");
                                  }}
                                >
                                  <FaTimesCircle
                                    size={16}
                                    style={{ marginRight: "5px" }}
                                    color={colors.grayShade}
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    handleEmployeeDialog(!employeeDialog);
                                    setAnchorEl(null);
                                    setFilterEmployees(employees);
                                    setEmployee("");
                                  }}
                                >
                                  <FaPlusCircle size={16} color="black" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box sx={{ height: "30vh" }}>
                          {filterEmployees.map((assignedItem) => (
                            <MenuItem
                              key={uuidv4()}
                              onClick={() =>
                                updateSubTask({
                                  id: subItem._id,
                                  assigned_to: assignedItem._id,
                                })
                              }
                              sx={{
                                border: `1px solid ${colors.shadowColor}`,
                                borderRadius: "8px",
                                my: 1,
                                p: 1,
                              }}
                            >
                              <Avatar
                                alt=""
                                src=""
                                sx={{
                                  display: "inline-flex",
                                  mr: 1,
                                  width: "25px",
                                  height: "25px",
                                  bgcolor: assignedItem.colorCode,
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {assignedItem.name?.split(" ").length > 1
                                  ? assignedItem.name?.charAt(0) +
                                    assignedItem.name?.split(" ")[1].charAt(0)
                                  : assignedItem.name?.charAt(0)}
                              </Avatar>
                              <Typography
                                variant="body2"
                                color="initial"
                                sx={{ flex: 1 }}
                              >
                                {assignedItem.name}
                              </Typography>
                              {assignedItem._id === item.assigned_to_id && (
                                <FaCheckCircle
                                  color={colors.skyBlue}
                                  style={{ marginLeft: "5px" }}
                                />
                              )}
                            </MenuItem>
                          ))}
                        </Box>
                      </Menudropdown>
                    </Box>
                    {/* SUBTASK DUE DATE */}
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          width: 80,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleAnchorClick(e, "date" + subItem._id)
                        }
                      >
                        {getTaskDate(subItem.due_date)}
                      </Typography>
                      <Menudropdown
                        colName={"date"}
                        open={open}
                        anchorEl={anchorEl}
                        setAnchor={() => setAnchorEl(null)}
                        id={"date" + subItem._id}
                        clickedItemId={clickedItemId}
                        coordinate={coordinate}
                        width={"auto"}
                        transformOrigin={{
                          vertical: -30,
                          horizontal: 0,
                        }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          // adapterLocale={enLocale}
                        >
                          <Box
                            sx={{
                              "& > div": {
                                minWidth: 270,
                              },
                              "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                                {
                                  width: 270,
                                },
                              "& .MuiTypography-caption": {
                                width: DATE_SIZE,
                                margin: 0,
                              },
                              "& .PrivatePickersSlideTransition-root": {
                                minHeight: DATE_SIZE * 6,
                              },
                              '& .PrivatePickersSlideTransition-root [role="row"]':
                                {
                                  margin: 0,
                                },
                              "& .MuiPickersDay-dayWithMargin": {
                                margin: 0,
                              },
                              "& .MuiPickersDay-root": {
                                width: DATE_SIZE,
                                height: DATE_SIZE,
                              },
                            }}
                          >
                            <StaticDatePicker
                              value={getDate(subItem.due_date)}
                              // maxDate={item.due_date}
                              onChange={(value) =>
                                updateSubTask({
                                  id: subItem._id,
                                  due_date: getDate(value),
                                  item_due_date: getDate(item.due_date),
                                  itemId: item._id,
                                })
                              }
                              displayStaticWrapperAs="desktop"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Menudropdown>
                    </Box>
                    {/* SUBTASK STATUS */}
                    <Box
                      sx={{
                        width: "100px",
                      }}
                    >
                      <Fragment>
                        <ChipStatusOutline
                          item={subItem}
                          handleClick={(e) =>
                            handleAnchorClick(e, "status" + subItem._id)
                          }
                        />
                      </Fragment>
                      <Menudropdown
                        colName={"status"}
                        open={open}
                        anchorEl={anchorEl}
                        setAnchor={() => setAnchorEl(null)}
                        id={"status" + subItem._id}
                        clickedItemId={clickedItemId}
                        coordinate={coordinate}
                        width={120}
                        transformOrigin={{
                          vertical: -30,
                          horizontal: 0,
                        }}
                      >
                        <ChipStatus
                          margin={1}
                          label="Done"
                          handleClick={() =>
                            updateSubTask({ id: subItem._id, status: "Done" })
                          }
                        />
                        <ChipStatus
                          margin={1}
                          label="In progress"
                          handleClick={() =>
                            updateSubTask({
                              id: subItem._id,
                              status: "In progress",
                            })
                          }
                        />
                        <ChipStatus
                          margin={1}
                          label="Overdue"
                          handleClick={() =>
                            updateSubTask({
                              id: subItem._id,
                              status: "Overdue",
                            })
                          }
                        />
                        <ChipStatus
                          margin={1}
                          label="Not Started"
                          handleClick={() =>
                            updateSubTask({
                              id: subItem._id,
                              status: "Not started",
                            })
                          }
                        />
                      </Menudropdown>
                    </Box>
                  </Box>
                </Collapse>
              ))}
            </Box>
          );
        })}
        {/* table row end */}
      </Box>
    </Fragment>
  );
}

export default TodosTable;
