import {
  Avatar,
  Box,
  Chip,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import colors from "app/style/colors";
import React, { Fragment, useRef } from "react";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";
import { getDailyDate } from "app/utils";
import { Service } from "app/config/service";
import EditJobDrawer from "app/Components/Drawer/EditJobDrawer";

const JobDailyTable = ({ isFilter, filteredJobs }) => {
  const [value, setValue] = React.useState(0);
  console.log(filteredJobs);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // let time = [
  //   "09:00 AM",
  //   "10:00 AM",
  //   "11:00 AM",
  //   "12:00 PM",
  //   "01:00 PM",
  //   "02:00 PM",
  //   "03:00 PM",
  //   "04:00 PM",
  //   "05:00 PM",
  //   "06:00 PM"
  // ];


  let time = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];
  let temparr = [];
  const elRef = useRef([]);
  const elTableRef = useRef([]);

  const [jobs, setJobs] = useState([]);

  const [showEditJob, setShowEditJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");

  const [dailyDate, setDailyDate] = useState(Date.now());
  const boxRef = useRef(null);
  const boxRef2 = useRef([]);
  const addToRef = (el) => {
    boxRef2.current = [];
    if (boxRef2 && !boxRef2.current.includes(el)) {
      boxRef2.current.push(el);
    }
  };
  const previous = () => {
    boxRef.current.scrollLeft += -100;
    for (let index = 0; index < boxRef2.current.length; index++) {
      console.log(boxRef2.current[index]);
      boxRef2.current[index].scrollLeft += -100;
    }
  };
  const next = () => {
    boxRef.current.scrollLeft += 100;
    for (let index = 0; index < boxRef2.current.length; index++) {
      console.log(boxRef2.current[index]);
      boxRef2.current[index].scrollLeft += 100;
    }
  };
  const handleDailyCalender = (action, val) => {
    setDailyDate(getDailyDate(action, val));
    let startDate = getDailyDate(action, val);
    getDailyJobs(moment(startDate.startTimeStamp).format("YYYY-MM-DD"));
  };

  const getDailyJobs = async (startDate) => {
    try {
      const { status, responseCode, message, data } =
        await Service.getJobByDaily(startDate, startDate);
      if (status === true && responseCode === 200) {
        setJobs(data.result);
        if (data.result.length) {
          boxRef2.current = [];
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: JobDailyTable.js ~ line 73 ~ getDailyJobs ~ error",
        error
      );
    }
  };
  useEffect(() => {
    setDailyDate(getDailyDate());
    getDailyJobs(moment(Date.now()).format("YYYY-MM-DD"));
  }, []);

  return (
    <Fragment>
      {selectedJob && (
        <EditJobDrawer
          handleEditJob={() => setShowEditJob(!showEditJob)}
          open={showEditJob}
          selectedJobId={selectedJob}
          setSelectedJobId={setSelectedJob}
          getAssignJob={getDailyJobs}
          date={dailyDate}
        />
      )}
      <Box sx={{ p: 3, borderRadius: "10px" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "calc(100% - 20px)",
            overflow: "hidden",
            borderBottomLeftRadius: 15,
            bottom: 0,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: "10px 10px 0 0",
              color: `${colors.textSecondary}`,
              fontWeight: 600,
            }}
          >
            <RiArrowLeftSFill
              fontSize={22}
              cursor="pointer"
              onClick={() =>
                handleDailyCalender("previous", dailyDate?.startTimeStamp)
              }
            />
            {moment(dailyDate?.startTimeStamp).format("dddd,MMM Do, YYYY")}
            <RiArrowRightSFill
              fontSize={22}
              cursor="pointer"
              onClick={() =>
                handleDailyCalender("next", dailyDate?.startTimeStamp)
              }
            />
          </Typography>
          {(jobs.length === 0 && !isFilter) &&
            (<Box sx={{ textAlign: "center", width: "100%", my: 20 }}>
                <Typography
                  sx={{ color: "#4F4F4F", fontSize: 25, fontWeight: 600 }}
                >
                  Scheduler
                </Typography>
                <Typography
                  sx={{ color: "#4F4F4F", fontSize: 15, fontWeight: 400 }}
                >
                  No jobs are scheduled on this day
                </Typography>
              </Box>
            )}
          {isFilter ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ width: "150px", fontWeight: 700 }}
                  >
                    Job
                  </TableCell>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer", marginTop: 7 }}
                      onClick={previous}
                    >
                      {" "}
                      <FaArrowLeft />
                    </span>
                    <Box
                      ref={boxRef}
                      sx={{ width: "calc(100% - 20px)", overflow: "hidden" }}
                    >
                      {time.map((item, index) => {
                        return (
                          <TableCell
                            key={index}
                            id={index}
                            sx={{ minWidth: 100, borderBottom: "none" }}
                          >
                            {item}
                          </TableCell>
                        );
                      })}
                    </Box>
                    <span
                      style={{
                        marginTop: 7,
                        cursor: "pointer",
                      }}
                      onClick={next}
                    >
                      {" "}
                      <FaArrowRight />
                    </span>
                  </Box>
                </TableRow>
              </TableHead>
              {filteredJobs.map((item, index) => {
               const totalHrs = item.assignDetails.reduce((acc, curr) => {
                  var start = new Date(curr.startTime);
                  var end = new Date(curr.endTime);
                  var hours = end.getUTCHours() - start.getUTCHours();
                  return acc + hours;
                }, 0)
                return (
                  <TableBody>
                    <TableCell
                      align="left"
                      sx={{
                        p: 0.7,
                        minWidth: 150,
                        borderRight: "1px solid rgba(224,224,224,1)",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2" component={"span"}>
                          {item.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box
                          sx={{
                            m: 0.5,
                            color: "#4F4F4F",
                            textAlign: "center",
                            p: 0.5,
                            width: "40px",
                            background: "#E9E9E9",
                            fontSize: "14px",
                            fontWeight: 600,
                            borderRadius: "4px",
                          }}
                        >
                          {Math.ceil(totalHrs)}h
                        </Box>
                      </Box>
                    </TableCell>
                    <Box
                      ref={addToRef}
                      sx={{
                        width: "calc(100% - 20px)",
                        overflow: "hidden",
                        position: "relative",
                        top: 1,
                      }}
                    >
                      {time.map((timeV, index) => {
                        return (
                          <TableCell
                            key={index}
                            id={index}
                            align="left"
                            sx={{
                              verticalAlign: "middle",
                              height: 77,
                              minWidth: 100,
                              p: 0.7,
                              borderRight: "1px solid rgba(224,224,224,1)",
                            }}
                          >
                            {item.assignDetails.map((work, index) => {
                              var start = new Date(work.startTime);
                              var end = new Date(work.endTime);
                              var hoursDiff = end.getUTCHours() - start.getUTCHours();
                              let roundedDiff = Math.ceil(work.difference);
                              var mStart = moment(work.startTime);
                              var roundUpStartTime =
                                mStart.minute() ||
                                mStart.second() ||
                                mStart.millisecond()
                                  ? mStart.add(1, "hour").startOf("hour")
                                  : mStart.startOf("hour");
                              var width =
                                work.difference == 0
                                  ? 106
                                  : Math.ceil(work.difference) * 106;
                              let count = 0;
                              if (
                                roundUpStartTime.format("hh:mm A") === timeV &&
                                count <= 1
                              ) {
                                count += 1;
                                return (
                                  <Fragment>
                                    <Box key={index}>
                                      {(work.status === "pending" ||
                                        work.status === "request") && (
                                        <Chip
                                          onClick={() => {
                                            setSelectedJob(item._id);
                                            setShowEditJob(true);
                                          }}
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {moment(work.startTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                -{" "}
                                                {moment(work.endTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                {work.assignTo.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#E0E0E0",
                                                  py: 0.4,
                                                  px: 0.8,
                                                  color: "#4F4F4F",
                                                  fontSize: 11,
                                                  borderRadius: "2px",
                                                }}
                                              >
                                                {Math.ceil(hoursDiff)}h
                                              </Typography>
                                            </Box>
                                          }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightBlue}`,
                                            color: `${colors.chip.Blue}`,
                                            width: width - 12,
                                          }}
                                        />
                                      )}
                                      {work.status === "decline" && (
                                        <Chip
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {moment(work.startTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                -{" "}
                                                {moment(work.endTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                {work.assignTo.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#E0E0E0",
                                                  py: 0.4,
                                                  px: 0.8,
                                                  color: "#4F4F4F",
                                                  fontSize: 11,
                                                  borderRadius: "2px",
                                                }}
                                              >
                                              {Math.ceil(hoursDiff)}h
                                              </Typography>
                                            </Box>
                                          }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightRed}`,
                                            color: `${colors.chip.red}`,
                                            width: width - 12,
                                          }}
                                        />
                                      )}
                                      {work.status === "inprogress" && (
                                        <Chip
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {moment(work.startTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                -{" "}
                                                {moment(work.endTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                {work.assignTo.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#E0E0E0",
                                                  py: 0.4,
                                                  px: 0.8,
                                                  color: "#4F4F4F",
                                                  fontSize: 11,
                                                  borderRadius: "2px",
                                                }}
                                              >
                                              {Math.ceil(hoursDiff)}h
                                              </Typography>
                                            </Box>
                                          }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightYellow}`,
                                            color: `${colors.chip.yellow}`,
                                            width: width - 12,
                                          }}
                                        />
                                      )}
                                      {work.status === "notstarted" && (
                                        <Chip
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {moment(work.startTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                -{" "}
                                                {moment(work.endTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                {work.assignTo.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#E0E0E0",
                                                  py: 0.4,
                                                  px: 0.8,
                                                  color: "#4F4F4F",
                                                  fontSize: 11,
                                                  borderRadius: "2px",
                                                }}
                                              >
                                              {Math.ceil(hoursDiff)}h
                                              </Typography>
                                            </Box>
                                          }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightGray}`,
                                            color: `${colors.chip.gray}`,
                                            width: width - 12,
                                          }}
                                        />
                                      )}
                                      {work.status === "done" && (
                                        <Chip
                                          label={
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 600,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {moment(work.startTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                -{" "}
                                                {moment(work.endTime)
                                                  .format("h:MMa")
                                                  .replace("m", "")}{" "}
                                                {work.assignTo.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#E0E0E0",
                                                  py: 0.4,
                                                  px: 0.8,
                                                  color: "#4F4F4F",
                                                  fontSize: 11,
                                                  borderRadius: "2px",
                                                }}
                                              >
                                              {Math.ceil(hoursDiff)}h
                                              </Typography>
                                            </Box>
                                          }
                                          sx={{
                                            mb: 2,
                                            borderRadius: "5px",
                                            fontWeight: 600,
                                            backgroundColor: `${colors.chip.lightGreen}`,
                                            color: `${colors.chip.green}`,
                                            width: width - 12,
                                          }}
                                        />
                                      )}
                                    </Box>

                                    {count > 1 && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: " 1px solid #E9E9E9;",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            p: 0.5,
                                            ml: 0.5,
                                            color: "#9B9B9B",
                                            fontSize: 12,
                                          }}
                                        >
                                          View more workers
                                        </Typography>
                                      </Box>
                                    )}
                                  </Fragment>
                                );
                              }
                            })}
                          </TableCell>
                        );
                      })}
                    </Box>
                  </TableBody>
                );
              })}
              <TableRow>
                <TableCell colSpan={7} sx={{ p: 0.5, textAlign: "center" }}>
                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightBlue}`,
                      color: `${colors.chip.Blue}`,
                      fontSize: 11,
                    }}
                    label="PENDING"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGray}`,
                      color: `${colors.chip.gray}`,
                      fontSize: 11,
                    }}
                    label="NOT STARTED"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightYellow}`,
                      color: `${colors.chip.yellow}`,
                      fontSize: 11,
                    }}
                    label="IN PROGRESS"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightGreen}`,
                      color: `${colors.chip.green}`,
                      fontSize: 11,
                    }}
                    label="DONE"
                  />

                  <Chip
                    size="small"
                    sx={{
                      mr: 1,
                      fontWeight: 600,
                      backgroundColor: `${colors.chip.lightRed}`,
                      color: `${colors.chip.red}`,
                      fontSize: 11,
                    }}
                    label="DECLINED"
                  />
                </TableCell>
              </TableRow>
            </Table>
          ) : (
            jobs.length !== 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ width: "150px", fontWeight: 700 }}
                    >
                      Job
                    </TableCell>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <span
                        style={{ cursor: "pointer", marginTop: 7 }}
                        onClick={previous}
                      >
                        {" "}
                        <FaArrowLeft />
                      </span>
                      <Box
                        ref={boxRef}
                        sx={{ width: "calc(100% - 20px)", overflow: "hidden" }}
                      >
                        {time.map((item, index) => {
                          return (
                            <TableCell
                              key={index}
                              id={index}
                              sx={{ minWidth: 100, borderBottom: "none" }}
                            >
                              {item}
                            </TableCell>
                          );
                        })}
                      </Box>
                      <span
                        style={{
                          marginTop: 7,
                          cursor: "pointer",
                        }}
                        onClick={next}
                      >
                        {" "}
                        <FaArrowRight />
                      </span>
                    </Box>
                  </TableRow>
                </TableHead>
                {jobs.map((item, index) => {
                  return (
                    <TableBody>
                      <TableCell
                        align="left"
                        sx={{
                          p: 0.7,
                          minWidth: 150,
                          borderRight: "1px solid rgba(224,224,224,1)",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle2" component={"span"}>
                            {item.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              m: 0.5,
                              color: "#4F4F4F",
                              textAlign: "center",
                              p: 0.5,
                              width: "40px",
                              background: "#E9E9E9",
                              fontSize: "14px",
                              fontWeight: 600,
                              borderRadius: "4px",
                            }}
                          >
                            {Math.ceil(item.totalHrs)}h
                          </Box>
                        </Box>
                      </TableCell>
                      <Box
                        ref={addToRef}
                        sx={{
                          width: "calc(100% - 20px)",
                          overflow: "hidden",
                          position: "relative",
                          top: 1,
                        }}
                      >
                        {time.map((timeV, index) => {
                          return (
                            <TableCell
                              key={index}
                              id={index}
                              align="left"
                              sx={{
                                verticalAlign: "middle",
                                height: 77,
                                minWidth: 100,
                                p: 0.7,
                                borderRight: "1px solid rgba(224,224,224,1)",
                              }}
                            >
                              {item.assignDetails.map((work, index) => {
                                let roundedDiff = Math.ceil(work.difference);
                                var mStart = moment(work.startTime);
                                var roundUpStartTime =
                                  mStart.minute() ||
                                  mStart.second() ||
                                  mStart.millisecond()
                                    ? mStart.add(1, "hour").startOf("hour")
                                    : mStart.startOf("hour");
                                var width =
                                  work.difference == 0
                                    ? 106
                                    : Math.ceil(work.difference) * 106;
                                let count = 0;
                                if (
                                  roundUpStartTime.format("hh:mm A") ===
                                    timeV &&
                                  count <= 1
                                ) {
                                  count += 1;
                                  return (
                                    <Fragment>
                                      <Box key={index}>
                                        {(work.status === "pending" ||
                                          work.status === "request") && (
                                          <Chip
                                            onClick={() => {
                                              setSelectedJob(item._id);
                                              setShowEditJob(true);
                                            }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  {work.assignTo.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.difference)}h
                                                </Typography>
                                              </Box>
                                            }
                                            sx={{
                                              mb: 2,
                                              borderRadius: "5px",
                                              fontWeight: 600,
                                              backgroundColor: `${colors.chip.lightBlue}`,
                                              color: `${colors.chip.Blue}`,
                                              width: width - 12,
                                            }}
                                          />
                                        )}
                                        {work.status === "decline" && (
                                          <Chip
                                          onClick={() => {
                                            setSelectedJob(item._id);
                                            setShowEditJob(true);
                                          }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  {work.assignTo.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.difference)}h
                                                </Typography>
                                              </Box>
                                            }
                                            sx={{
                                              mb: 2,
                                              borderRadius: "5px",
                                              fontWeight: 600,
                                              backgroundColor: `${colors.chip.lightRed}`,
                                              color: `${colors.chip.red}`,
                                              width: width - 12,
                                            }}
                                          />
                                        )}
                                        {work.status === "inprogress" && (
                                          <Chip
                                          onClick={() => {
                                            setSelectedJob(item._id);
                                            setShowEditJob(true);
                                          }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  {work.assignTo.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.difference)}h
                                                </Typography>
                                              </Box>
                                            }
                                            sx={{
                                              mb: 2,
                                              borderRadius: "5px",
                                              fontWeight: 600,
                                              backgroundColor: `${colors.chip.lightYellow}`,
                                              color: `${colors.chip.yellow}`,
                                              width: width - 12,
                                            }}
                                          />
                                        )}
                                        {work.status === "notstarted" && (
                                          <Chip
                                          onClick={() => {
                                            setSelectedJob(item._id);
                                            setShowEditJob(true);
                                          }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  {work.assignTo.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.difference)}h
                                                </Typography>
                                              </Box>
                                            }
                                            sx={{
                                              mb: 2,
                                              borderRadius: "5px",
                                              fontWeight: 600,
                                              backgroundColor: `${colors.chip.lightGray}`,
                                              color: `${colors.chip.gray}`,
                                              width: width - 12,
                                            }}
                                          />
                                        )}
                                        {work.status === "done" && (
                                          <Chip
                                          onClick={() => {
                                            setSelectedJob(item._id);
                                            setShowEditJob(true);
                                          }}
                                            label={
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {moment(work.startTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  -{" "}
                                                  {moment(work.endTime)
                                                    .format("h:mma")
                                                    .replace("m", "")}{" "}
                                                  {work.assignTo.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    background: "#E0E0E0",
                                                    py: 0.4,
                                                    px: 0.8,
                                                    color: "#4F4F4F",
                                                    fontSize: 11,
                                                    borderRadius: "2px",
                                                  }}
                                                >
                                                  {Math.ceil(work.difference)}h
                                                </Typography>
                                              </Box>
                                            }
                                            sx={{
                                              mb: 2,
                                              borderRadius: "5px",
                                              fontWeight: 600,
                                              backgroundColor: `${colors.chip.lightGreen}`,
                                              color: `${colors.chip.green}`,
                                              width: width - 12,
                                            }}
                                          />
                                        )}
                                      </Box>

                                      {count > 1 && (
                                        <Box
                                          sx={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            border: " 1px solid #E9E9E9;",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              p: 0.5,
                                              ml: 0.5,
                                              color: "#9B9B9B",
                                              fontSize: 12,
                                            }}
                                          >
                                            View more workers
                                          </Typography>
                                        </Box>
                                      )}
                                    </Fragment>
                                  );
                                }
                              })}
                            </TableCell>
                          );
                        })}
                      </Box>
                    </TableBody>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={7} sx={{ p: 0.5, textAlign: "center" }}>
                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightBlue}`,
                        color: `${colors.chip.Blue}`,
                        fontSize: 11,
                      }}
                      label="PENDING"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightGray}`,
                        color: `${colors.chip.gray}`,
                        fontSize: 11,
                      }}
                      label="NOT STARTED"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightYellow}`,
                        color: `${colors.chip.yellow}`,
                        fontSize: 11,
                      }}
                      label="IN PROGRESS"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightGreen}`,
                        color: `${colors.chip.green}`,
                        fontSize: 11,
                      }}
                      label="DONE"
                    />

                    <Chip
                      size="small"
                      sx={{
                        mr: 1,
                        fontWeight: 600,
                        backgroundColor: `${colors.chip.lightRed}`,
                        color: `${colors.chip.red}`,
                        fontSize: 11,
                      }}
                      label="DECLINED"
                    />
                  </TableCell>
                </TableRow>
              </Table>
            )
          )}
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default JobDailyTable;
