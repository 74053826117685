import { Avatar, Button, Chip, Divider, Drawer, FormControl, Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import colors from 'app/style/colors'
import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BsChat, BsFillClockFill, BsPaperclip, BsThreeDots } from 'react-icons/bs'
import { MdClose, MdImage, MdModeEditOutline } from 'react-icons/md'
import AddTime from './AddTime'
import moment from 'moment'
import EditTime from './EditTime'
import { Link } from 'react-router-dom'
import { Service } from 'app/config/service'
import { SuccessToaster } from 'app/Components/UI/Toaster'
import { useForm } from 'react-hook-form'
import { useRef } from 'react'
import Images from 'assets/images'
import { PrimaryButton } from 'app/Components/UI/Buttons'
import { FaCheckCircle, FaRegSmile, FaTimesCircle } from 'react-icons/fa'
import Picker from 'emoji-picker-react'
import CloseIcon from '@mui/icons-material/Close';


const groupByToMap = require('array.prototype.groupbytomap');


const useStyles = makeStyles({
    drawerPaper: {
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            overflowY: "auto",
            width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: colors.primaryGray,
            borderRadius: "10px",
        },
    },
    boxSelectField: {
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: "5px 12px",
      },
      paper: {
        borderRadius: 12,
        marginTop: 8
      },
      outlineInputBorder: {
        "& * > fieldset": {
          padding: "18px 12px",
          borderRadius: "10px",
        },
      },
      outlineInputBorderNone: {
        "& * > fieldset": {
          border: "none"
        },
      },
})
const ViewMore = ({ open, handleViewMore,timeSheet,setTimeSheet,getTimeSheet,startDate,endDate }) => {

  const { register, handleSubmit, formState: { errors }, clearErrors, setValue, } = useForm();

    const [onJob, setOnJob] = useState([]);
    const [onJobTotalhrs, setOnJobTotalhrs] = useState(0);
    const [breaks, setBreaks] = useState([]);
    const [breaksTotalhrs, setBreaksTotalhrs] = useState(0);
    const [travel, setTravel] = useState([]);
    const [travelTotalhrs, setTravelTotalhrs] = useState(0);
    const [delay, setDelay] = useState([]);   
    const [delayTotalhrs, setDelayTotalhrs] = useState(0);  
    const [editItem, setEditItem] = useState({}); 


    const classes = useStyles();
    const [showAddTime, setAddTime] = useState(false);
    const [showEditTime, setEditTime] = useState(false);
    const [anchorElOptionShowAddTime, setAnchorElOptionshowAddTime] = useState(false);

    const getTimedetails=()=>{
        if(timeSheet?.time_details){
            const time_details = groupByToMap(timeSheet.time_details,(sheet) => {
                return sheet.type;
              });
            for (let [key, value] of time_details) {
                if(key==="onjob"){
                    setOnJob(value)
                    let totaltime=0
                    value.map(time=>{
                        totaltime+=time.timeDiff
                    })
                    setOnJobTotalhrs(totaltime)
                }
                if(key==="break"){
                    
                    setBreaks(value)
                    let totaltime=0
                    value.map(time=>{
                        totaltime+=time.timeDiff
                    })
                    setBreaksTotalhrs(totaltime)
                }
                if(key==="travel"){
                    setTravel(value)
                    let totaltime=0
                    value.map(time=>{
                        totaltime+=time.timeDiff
                    })
                    setTravelTotalhrs(totaltime)
                }
                if(key==="delay"){
                    setDelay(value)
                    let totaltime=0
                    value.map(time=>{
                        totaltime+=time.timeDiff
                    })
                    setDelayTotalhrs(totaltime)
                }
               
            
                }
                
            }
    }
    const handleClose=()=>{
        handleViewMore()
        setTimeSheet({})
    }

// Comments


const [comments, setComments] = useState([]);
const [comment, setComment] = useState("");
const [anchorElCommentPop, setAnchorElCommentPop] = useState(null);
const openCommentPop = Boolean(anchorElCommentPop);
const [selectedCommentId, setSelectedCommentId] = useState();
const [displayAddComment, setDisplayAddComment] = useState(true);
const [images, setImages] = useState([]);
const [files, setFiles] = useState([]);
const [anchorEl, setAnchorEl] = useState(null);
const openPicker = Boolean(anchorEl); // for emoji comment box
const [commentPosition, setCommentPosition] = useState();
const [addedComments, setAddedComments] = useState([]);

const getComment = async () => {
  try {
    const { data, message, responseCode, status } = await Service.getComment(timeSheet?._id, "timeSheet")
    if (responseCode === 200 && status === true) {
      setComments(data.comments)
    }
  } catch (error) {
    console.log("🚀 ~ file: Comments.js ~ line 49 ~ getComment ~ error", error)

  }
}

const handleCloseCommentPop = async (event) => {
  try {
    setAnchorElCommentPop(null);

  } catch (error) {
    console.log("🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error", error)

  }
}

const handleDeleteComment = async () => {
  try {

    const { status, responseCode, message, data } = await Service.deleteComment(selectedCommentId)
    if (status === true && responseCode === 200) {
      getComment()
      SuccessToaster(message)
      setAnchorElCommentPop(null);
    }

  } catch (error) {
    console.log("🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error", error)

  }
}

const handleClickCommentPop = async (event, _id) => {
  try {
    setSelectedCommentId(_id)
    setAnchorElCommentPop(event.currentTarget);

  } catch (error) {
    console.log("🚀 ~ file: ViewMore.js ~ line 308 ~ handleClickCommentPop ~ error", error)

  }
}
// function for handling marketing menu
const handleEmojiClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleEmojiClose = () => {
  setAnchorEl(null);
};
const onEmojiClick = (event, emojiObject) => {
  if (emojiObject?.emoji) {
    setAnchorEl(null)
    let text = [...comment]
    text.splice(commentPosition, 0, emojiObject?.emoji)
    setValue("taskComments", text.join(''))
    setComment(text.join(''))
  }
};
const hiddenImageInput = useRef(null);
const handleImageClick = event => {
  hiddenImageInput.current.click();
};

const handleImage = async (e) => {
  try {
    if (e.target.files[0]) {
      let formData = new FormData()
      formData.append("document_type", '1')
      formData.append("document", e.target.files[0])
      const { responseCode, status, message, data } = await Service.upload(formData)
      if (status === true && responseCode === 200) {
        let image = [...images]
        image.push(data)
        setImages(image)
        e.target.value = null
      }
    }
  } catch (error) {
    console.log("🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error", error)
  }
}

const deleteCommentImage = (i) => {
  try {
    let image = [...images]
    image.splice(i, 1)
    setImages(image)
  } catch (error) {
    console.log(error)
  }

}


const hiddenFileInput = useRef(null);
const handleFileClick = event => {
  hiddenFileInput.current.click();
};

const handleFile = async (e) => {
  try {
    if (e.target.files[0]) {
      let formData = new FormData()
      formData.append("document_type", '2')
      formData.append("document", e.target.files[0])
      const { responseCode, status, message, data } = await Service.upload(formData)
      if (status === true && responseCode === 200) {
        let filesName = [...files]
        filesName.push(data)
        setFiles(filesName)
        e.target.value = null
      }
    }


  } catch (error) {
    console.log("🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error", error)
  }
}

const deleteCommentFile = (i) => {
  try {
    let filesName = [...files]
    filesName.splice(i, 1)
    setFiles(filesName)
  } catch (error) {
    console.log(error)
  }

}
const clearComment = () => {
  setComment("")
  setImages([])
  setFiles([])
}
const handleAddedComments = async () => {
  try {
    let addComment = [...addedComments]
    if (comment || files.length > 0 || images.length > 0) {
      addComment.push({ content: comment, attachments: [...files, ...images], created_on: new Date() })
      // setAddedComments(addComment)
      await Service.createComment({ timesheet: timeSheet._id, content: comment, attachments: [...files, ...images], created_on: new Date() })
    }
    getComment()
    clearComment()
  } catch (error) {
    console.log("🚀 ~ file: ViewMore.js ~ line 243 ~ handleAddedComments ~ error", error)

  }

}

    
    useEffect(() => {
        getTimedetails()
        getComment()
    },[timeSheet]);
    return (
        <Fragment>
            <AddTime handleAddTime={() => setAddTime(!showAddTime)} open={showAddTime} timeSheet={timeSheet} getTimeSheet={getTimeSheet} startDate={startDate} endDate={endDate} handleViewMore={handleViewMore}/>
           {editItem.type&&
            <EditTime open={showEditTime} handleEditTime={() => setEditTime(!showEditTime)} editItem={editItem} getTimeSheet={getTimeSheet}startDate={startDate} endDate={endDate}  />
        } 
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Box sx={{ width: 350, pt: 10, px: 2, pb: 5 }} role="presentation">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            fontWeight: "bold",
                        }}
                    >
                        <IconButton onClick={handleClose} sx={{ px: 0 }}>
                            <MdClose size={24} />
                        </IconButton>
                    </Box>
                    <Typography variant='h6' fontWeight={600}>{timeSheet.jobDetail.name}</Typography>
                    <Box sx={{ my: 1, display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                        <Box sx={{ display: "flex", alignItems: 'center' }}>
                            <Avatar alt="" src=''
                                sx={{
                                    display: "inline-flex", mr: 1, width: "30px", height: "30px",
                                    fontSize: "13px", textTransform: "uppercase",
                                    bgcolor: colors.skyBlue,
                                }}>

                                {timeSheet?.userDetails?.name?.split(" ").length > 1 ?
                                    timeSheet?.userDetails?.name?.charAt(0) + timeSheet?.userDetails?.name?.split(" ")[1].charAt(0) :
                                    timeSheet?.userDetails?.name?.charAt(0)
                                }
                            </Avatar>
                            <Typography noWrap sx={{ fontSize: '14px', lineHeight: "20px" }}>{timeSheet?.userDetails?.name}</Typography>
                        </Box>
                        {   timeSheet.status==="timesubmitted"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#FFBA7D`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Submitted" />
                        }
                        {   timeSheet.status==="onjob"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" />

                        }
                        {   timeSheet.status==="delay"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#EB5757`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delayed" />
                        }
                        {   timeSheet.status==="break"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#98A2B3`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Break" />
                        }
                        {   timeSheet.status==="travel"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                        }
                        {   timeSheet.status==="timeapproved"
                            &&
                            <Chip size="small" sx={{ width: 140, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#49BE7B`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Time Approved" />
                        }
                    </Box>
                    <Typography sx={{fontSize:17,fontWeight:600}}>{moment(timeSheet.date).format("dddd DD-MM-YYYY")}</Typography>

                    {/* Time Details */}
                    <Box sx={{ flex: 1 }}>
                    {onJob.length>0&&
                    <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                    <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                    <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" />
                            <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>{moment().startOf('day').add(onJobTotalhrs, 'minutes').format('HH:mm')} hours</Typography>
                    </Box>
                    <Divider/>
                    {onJob.map((item,index)=>{
                        return(
                            <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}} key={index}>
                            <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>{moment(item.startTime).format("hh:mm a")} - {moment(item.endTime).format("hh:mm a")}  ({moment().startOf('day').add(item.timeDiff, 'minutes').format('H')} hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:"18px",color:`${colors.darkBlue}`,cursor:"pointer"}} onClick={()=>{setEditItem(item);setEditTime(true);}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                     </Box>
                        )
                    })}
                   
                    </Box>
                    }
                    {travel.length>0&&
                    <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                    <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                    <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                            <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}} >{moment().startOf('day').add(travelTotalhrs, 'minutes').format('HH:mm')} hours</Typography>
                    </Box>
                    <Divider/>
                    {travel.map((item,index)=>{
                        return(
                            <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}} key={index}>
                            <Box sx={{display:"flex",alignItems:'center'}} > <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>{moment(item.startTime).format("hh:mm a")} - {moment(item.endTime).format("hh:mm a")}  ({moment().startOf('day').add(item.timeDiff, 'minutes').format('H')} hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:"18px",color:`${colors.darkBlue}`,cursor:"pointer"}} onClick={()=>{setEditItem(item);setEditTime(true);}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                     </Box>
                        )
                    })}
                   
                    </Box>
                    }
                    {/* <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                        <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                        <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                                <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>06:00 hours</Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}}>
                           <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>9:00 am - 12:00 pm  (3 hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:15,color:`${colors.darkBlue}`,cursor:"pointer"}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                        </Box>
                    </Box> */}
                    {breaks.length>0&&
                    <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                    <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                    <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `${colors.secondary}`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Break" />

                            <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>{moment().startOf('day').add(breaksTotalhrs, 'minutes').format('HH:mm')} hours</Typography>
                    </Box>
                    <Divider/>
                    {breaks.map((item,index)=>{
                        return(
                            <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}} key={index}>
                            <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>{moment(item.startTime).format("hh:mm a")} - {moment(item.endTime).format("hh:mm a")}  ({moment().startOf('day').add(item.timeDiff, 'minutes').format('H')} hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:"18px",color:`${colors.darkBlue}`,cursor:"pointer"}} onClick={()=>{setEditItem(item);setEditTime(true);}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                     </Box>
                        )
                    })}
                   
                    </Box>
                    }
                    {/* <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                        <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                        <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `${colors.secondary}`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Break" />
                                <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>06:00 hours</Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}}>
                           <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>9:00 am - 12:00 pm  (3 hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:15,color:`${colors.darkBlue}`,cursor:"pointer"}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                        </Box>
                    </Box> */}
                    {delay.length>0&&
                    <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                    <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                    <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `${colors.red}`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delay" />
                            <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>{moment().startOf('day').add(delayTotalhrs, 'minutes').format('HH:mm')} hours</Typography>
                    </Box>
                    <Divider/>
                    {delay.map((item,index)=>{
                        return(
                            <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}} key={index}>
                            <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>{moment(item.startTime).format("hh:mm a")} - {moment(item.endTime).format("hh:mm a")}  ({moment().startOf('day').add(item.timeDiff, 'minutes').format('H')} hrs)</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:"18px",color:`${colors.darkBlue}`,cursor:"pointer"}} onClick={()=>{setEditItem(item);setEditTime(true);}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                     </Box>
                        )
                    })}
                   
                    </Box>
                    }
                    {/* <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px"}}>
                        <Box sx={{p:1,display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                        <Chip size="small" sx={{ width: 110, cursor: "pointer", p: 1.6, fontWeight: 600, backgroundColor: `${colors.red}`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delay" />
                                <Typography sx={{mr:0.5,fontSize:15,fontWeight:600,color:`${colors.textSecondary}`}}>00:00 hours</Typography>
                        </Box>
                        <Divider/>
                        <Box sx={{p:1,display:"flex",alignItems:'center',justifyContent:'space-between'}}>
                           <Box sx={{display:"flex",alignItems:'center'}}> <BsFillClockFill/> <Typography sx={{ml:"5px",fontSize:13}}>No delays</Typography></Box>
                           <Box sx={{display:"flex",alignItems:'center',fontSize:15,color:`${colors.darkBlue}`,cursor:"pointer"}}><MdModeEditOutline/> <Typography sx={{fontSize:12,pr:1,ml:"3px",}}>Edit</Typography></Box>
                        </Box>
                    </Box> */}
                    {/* Total */}
                    <Box sx={{my:1,border:"0.5px solid #CCCCCC",borderRadius:"8px",background:"#FAFAFA"}}>
                        <Box sx={{p:1,display:'flex',justifyContent:"flex-end"}}>
                            <Typography sx={{fontSize:18,fontWeight:600}}>Total: {moment().startOf('day').add(timeSheet?.totalHrs, 'minutes').format('HH:mm')} hours </Typography>
                        </Box>
                       
                    </Box>
                    </Box>

                    <Box>
                    <Typography sx={{ p: 2, color: '#4F4F4F', fontSize: 16, fontWeight: 600 }}> Comments</Typography >
          {
            comments.length > 0 ?
              <React.Fragment>
                {comments.map((item, i) =>
                (
                  <Box
                    key={i}
                    sx={{
                      backgroundColor: colors.darkgrayShade,
                      border: `1px solid ${colors.borderColor}`,
                      p: 1,
                      borderRadius: "8px",
                      mb: 1,
                    }}
                  >
                    <Fragment>
                      <Grid container>
                        <Grid item xs={10}>
                          <Box sx={{ display: "flex" }}>
                            <Avatar
                              alt=""
                              src=""
                              sx={{
                                display: "inline-flex",
                                mr: 1,
                                width: "25px",
                                height: "25px",
                                bgcolor: colors.skyBlue,
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                            >
                              {item?.created_by?.split(" ").length > 1 ?
                                item?.created_by?.charAt(0) + item?.created_by?.split(" ")[1].charAt(0) :
                                item?.created_by?.charAt(0)
                              }
                            </Avatar>
                            <Box>
                              <Typography variant="body1">{item?.created_by}</Typography>
                              <Typography
                                variant="body2"
                                className={classes.itemText}
                              >
                                {moment(item.created_on).fromNow()}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton sx={{ py: 0 }} onClick={(event) => handleClickCommentPop(event, item._id)}>
                            <BsThreeDots />
                          </IconButton>

                        </Grid>
                      </Grid>
                      <Menu
                        id="basic-menu"
                        PaperProps={{
                          sx: {
                            boxShadow: 'none',
                            borderRadius: '10px'
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        anchorEl={anchorElCommentPop}
                        open={openCommentPop}
                        onClose={handleCloseCommentPop}
                      >
                        <MenuItem onClick={handleDeleteComment}>Delete</MenuItem>
                      </Menu>
                      {/* Attachments */}
                      {item?.attachments.length > 0
                        &&
                        <Typography variant="body2" fontWeight="bold" mt={2}>
                          Attachments
                        </Typography>
                      }
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>
                        {item?.attachments.map((attachment, i) => {
                          return (
                            <Box key={i}>
                              {attachment.document_type === '1' &&
                                <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                  <Link href={`${attachment?.document}`} target="_blank">
                                    <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                      <img src={`${attachment?.document}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                    </Tooltip>
                                  </Link>
                                  <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                  </Typography>
                                </Box>
                              }

                              {
                                attachment.document_type === '2'
                                  &&
                                  attachment.document.slice(-4) === ('.txt')
                                  ?
                                  <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                    <Link href={`${attachment?.document}`} target="_blank">
                                      <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                        <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                      </Tooltip>
                                    </Link>
                                    <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                      {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                    </Typography>
                                  </Box>
                                  :
                                  attachment.document.slice(-4) === ('.doc')
                                    ?
                                    <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                      <Link href={`${attachment?.document}`} target="_blank">
                                        <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                          <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                        </Tooltip>
                                      </Link>
                                      <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                        {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                      </Typography>
                                    </Box>
                                    :
                                    attachment.document.slice(-5) === ('.docx')
                                      ?
                                      <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                        <Link href={`${attachment?.document}`} target="_blank">
                                          <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                            <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                          </Tooltip>
                                        </Link>
                                        <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>

                                          {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                        </Typography>
                                      </Box>
                                      :
                                      attachment.document.slice(-4) === ('.pdf')
                                        ?
                                        <Box sx={{ mr: 2, mb: 2, width: "70px" }}>

                                          <Link href={`${attachment?.document}`} target="_blank">
                                            <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                              <img src={`${Images.pdf}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                            </Tooltip>
                                          </Link>
                                          <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                          </Typography>

                                        </Box>
                                        :
                                        attachment.document.slice(-4) === ('.csv')
                                          ?
                                          <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                            <Link href={`${attachment?.document}`} target="_blank">
                                              <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                                <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                              </Tooltip>
                                            </Link>
                                            <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                              {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                            </Typography>
                                          </Box>
                                          :
                                          attachment.document.slice(-4) === ('.xls')
                                            ?
                                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                              <Link href={`${attachment?.document}`} target="_blank">
                                                <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                                  <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                </Tooltip>
                                              </Link>
                                              <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                              </Typography>
                                            </Box>
                                            :
                                            attachment.document.slice(-5) === ('.xlsx')
                                              ?
                                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                                <Link href={`${attachment?.document}`} target="_blank">
                                                  <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                                    <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                  </Tooltip>
                                                </Link>
                                                <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                  {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                                </Typography>
                                              </Box>
                                              :
                                              attachment.document.slice(-5) === ('.pptx')
                                                ?
                                                <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                                  <Link href={`${attachment?.document}`} target="_blank">
                                                    <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                                      <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                    </Tooltip>
                                                  </Link>
                                                  <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                                  </Typography>
                                                </Box>
                                                :
                                                attachment.document.slice(-5) === ('.ppt')
                                                  ?
                                                  <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                                    <Link href={`${attachment?.document}`} target="_blank">
                                                      <Tooltip title={attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}>
                                                        <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                      </Tooltip>
                                                    </Link>
                                                    <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                      {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                                    </Typography>
                                                  </Box>
                                                  :
                                                  // <Box sx={{mr:2,mb:2,width:"70px"}}>
                                                  // <Link href={`${attachment?.document}`} target="_blank">
                                                  //   <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                                                  // </Link>
                                                  // <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                                                  // {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                                                  // </Typography>
                                                  // </Box>
                                                  ""
                              }

                            </Box>
                          )
                        })
                        }

                      </Box>

                      {/* <Grid item xs={3.4}>
                    <img
                      width="100%"
                      height="80px"
                      src="https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMGludGVyaW9yfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                      alt=""
                    />
                  </Grid> */}
                      {item?.content &&
                        <>
                          <Divider />
                          {/* Comments text */}
                          <Typography variant="body2" color="initial" my={2}>
                            {item?.content}
                          </Typography>
                          <Divider sx={{ mb: 2 }} />
                        </>
                      }
                    </Fragment>
                  </Box>
                )
                )}


                {/* Add comments button */}
                <PrimaryButton
                  sx={{
                    minWidth: 180,
                    my: 2,
                    bgcolor: colors.grayShade,
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                  onClick={() => setDisplayAddComment(!displayAddComment)}
                >
                  <BsChat style={{ margin: "0px 10px" }} />
                  Add comment
                </PrimaryButton>
              </React.Fragment>
              :
              ""
          }
          <FormControl variant="standard" fullWidth sx={{ display: displayAddComment ? 'block' : 'none' }}>

            <Box sx={{ mt: 1 }} className={classes.boxSelectField}>
              <Grid container alignItems="center">
                <Grid item xs={1.5}>
                  <IconButton onClick={handleEmojiClick}>
                    <FaRegSmile size={18} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openPicker}
                    onClose={handleEmojiClose}
                    transformOrigin={{
                      vertical: 50,
                      horizontal: 0,
                    }}
                    PaperProps={{
                      style: {
                        // width: 120,
                        borderRadius: "10px"
                      },
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >

                    <Picker
                      disableSearchBar
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                    />
                  </Menu>

                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleImageClick}>
                    <MdImage size={20} />
                  </IconButton>
                  <input type="file"
                    accept="image/*"
                    ref={hiddenImageInput}
                    onChange={handleImage}
                    style={{ display: 'none' }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleFileClick}>
                    <BsPaperclip size={18} />
                  </IconButton>
                  <input type="file"
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    ref={hiddenFileInput}
                    onChange={handleFile}
                    style={{ display: 'none' }}
                  />
                </Grid>
                {/* <Grid item xs={1.5}>
                  <IconButton>
                    <MdLink size={20} />
                  </IconButton>
                </Grid> */}
              </Grid>

              <Divider />
              <Box sx={{ p: 1 }} >
                {images.length > 0 &&
                  <>
                    <Box >
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Images:</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>
                        {images.map((item, i) => (
                          <Box>
                            <IconButton size='small'
                              sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                              onClick={() => deleteCommentImage(i)}
                            >
                              <CloseIcon fontSize="10px" />
                            </IconButton>
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <img src={item?.document} width='70px' height='70px' style={{ margin: '5px' }} alt='file' />
                              <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                {item?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                              </Typography>
                            </Box>
                          </Box>
                        )
                        )}
                      </Box>
                    </Box>
                    <Divider />

                  </>
                }
                {files.length > 0 &&
                  <>
                    <Box sx={{ mt: 1 }} >
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Files:</Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>


                        {files.map((attachment, i) => {

                          return (
                            <Box sx={{ mr: 2, mb: 2 }}>
                              {
                                attachment.document?.slice(-4) === '.txt' ?
                                  <Box>
                                    <IconButton size='small'
                                      sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                      onClick={() => deleteCommentFile(i)}
                                    >
                                      <CloseIcon fontSize="10px" />
                                    </IconButton>
                                    <Box sx={{ width: '70px' }}>
                                      <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                      <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                        {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.txt', '')}
                                      </Typography>

                                    </Box>
                                  </Box>
                                  : attachment.document.slice(-4) === '.doc' ?
                                    <Box>
                                      <IconButton size='small'
                                        sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                        onClick={() => deleteCommentFile(i)}
                                      >
                                        <CloseIcon fontSize="10px" />
                                      </IconButton>
                                      <Box sx={{ width: '70px' }}>

                                        <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                        <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                          {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.doc', '')}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    : attachment.document.slice(-5) === '.docx' ?
                                      <Box>
                                        <IconButton size='small'
                                          sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                          onClick={() => deleteCommentFile(i)}
                                        >
                                          <CloseIcon fontSize="10px" />
                                        </IconButton>
                                        <Box sx={{ width: '70px' }}>

                                          <img src={`${Images.doc}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                          <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                            {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.docx', '')}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      :

                                      attachment.document.slice(-4) === ('.pdf')
                                        ?
                                        <Box>
                                          <IconButton size='small'
                                            sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                            onClick={() => deleteCommentFile(i)}
                                          >
                                            <CloseIcon fontSize="10px" />
                                          </IconButton>
                                          <Box sx={{ width: '70px' }}>

                                            <img src={`${Images.pdf}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                            <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                              {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.pdf', '')}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        :

                                        attachment.document.slice(-4) === '.csv'
                                          ?
                                          <Box>
                                            <IconButton size='small'
                                              sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                              onClick={() => deleteCommentFile(i)}
                                            >
                                              <CloseIcon fontSize="10px" />
                                            </IconButton>
                                            <Box sx={{ width: '70px' }}>

                                              <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                              <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.csv', '')}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          :
                                          attachment.document.slice(-5) === '.xlsx'
                                            ?
                                            <Box>
                                              <IconButton size='small'
                                                sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                                onClick={() => deleteCommentFile(i)}
                                              >
                                                <CloseIcon fontSize="10px" />
                                              </IconButton>
                                              <Box sx={{ width: '70px' }}>

                                                <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                                <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                  {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.xlsx', '')}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            :
                                            attachment.document.slice(-4) === '.xls'
                                              ?
                                              <Box>
                                                <IconButton size='small'
                                                  sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                                  onClick={() => deleteCommentFile(i)}
                                                >
                                                  <CloseIcon fontSize="10px" />
                                                </IconButton>
                                                <Box sx={{ width: '70px' }}>

                                                  <img src={`${Images.excel}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                                  <Typography noWrap sx={{ textAlign: 'center', fontSize: '13px', color: '#007AFF' }}>
                                                    {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.xls', '')}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              :
                                              attachment.document.slice(-4) === '.ppt'
                                                ?
                                                <Box>
                                                  <IconButton size='small'
                                                    sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                                    onClick={() => deleteCommentFile(i)}
                                                  >
                                                    <CloseIcon fontSize="10px" />
                                                  </IconButton>
                                                  <Box sx={{ width: '70px' }}>

                                                    <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                                    <Typography noWrap sx={{ fontSize: '13px', color: '#007AFF' }}>
                                                      {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.ppt', '')}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                :
                                                attachment.document.slice(-5) === '.pptx'
                                                  ?
                                                  <Box>
                                                    <IconButton size='small'
                                                      sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                                      onClick={() => deleteCommentFile(i)}
                                                    >
                                                      <CloseIcon fontSize="10px" />
                                                    </IconButton>
                                                    <Box sx={{ width: '70px' }}>

                                                      <img src={`${Images.ppt}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                                      <Typography noWrap sx={{ fontSize: '13px', color: '#007AFF' }}>
                                                        {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '').replace('.pptx', '')}
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                  :
                                                  <Box>
                                                    <IconButton size='small'
                                                      sx={{ backgroundColor: '#ECECEC', width: '20px', height: '12px ', position: 'relative', left: '50px', top: 10 }}
                                                      onClick={() => deleteCommentFile(i)}
                                                    >
                                                      <CloseIcon fontSize="10px" />
                                                    </IconButton>
                                                    <Box sx={{ width: '70px' }}>

                                                      <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />

                                                    </Box>
                                                  </Box>
                              }

                            </Box>

                          )
                        })}
                      </Box>
                    </Box>
                    <Divider />

                  </>
                }
              </Box>
              <TextField
                hiddenLabel
                multiline
                placeholder='Add your comment...'
                variant="outlined"
                fullWidth
                value={comment}
                className={classes.outlineInputBorderNone}
                sx={{ minHeight: "100px" }}
                error={errors?.taskComments?.message && (true)}
                size="small"
                onClick={(e) => {
                  setCommentPosition(e.target.selectionStart)
                }}
                {...register("taskComments", {
                  onChange: (e) => {
                    setComment(e.target.value)
                  }
                }
                )}
              />
              <Box sx={{mt:1.5, float: "right" }}>
                <IconButton onClick={clearComment} >
                  <FaTimesCircle size={16} color={colors.grayShade} />
                </IconButton>
                <IconButton onClick={handleAddedComments}>
                  <FaCheckCircle size={16} color={colors.skyBlue} />
                </IconButton>
              </Box>
            </Box>
          </FormControl>

                    </Box>
                    <Button variant='contained'
                     sx={{ '&:hover': {backgroundColor: '#2F80ED'}, width:"100%",background:"#2F80ED"}}
                     onClick={() => {
                        setAddTime(!showAddTime)
                        anchorElOptionShowAddTime(null)}}
                     >Add Time Manually</Button>
                </Box>
            </Drawer>
        </Fragment>
    )
}

export default ViewMore