import { useState } from "react";
import { toast } from "react-toastify";

function useProvideAuth() {

  const [user, setUser] = useState(null);
  const [userName, setUsername] = useState(null);

  const signIn = (token, name) => {
    localStorage.setItem('jwt', token)
    localStorage.setItem('username', name)
    setUser(token)
    setUsername(name)
  }

  const signOut = () => {
    toast.success('Logout Successfully!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    localStorage.clear()
    setUser(null)
    setUsername(null)
  }

  const verifyToken = () => {
    const token = localStorage.getItem('jwt')
    const userName = localStorage.getItem('username')
    setUser(token)
    setUsername(userName)
  }

  return {
    userName,
    user,
    signIn,
    signOut,
    verifyToken
  };
}

export default useProvideAuth;