
// *Import Components
import Dashboard from 'app/views/Dashboard/Dashboard';
import Schedule from 'app/views/Schedule/Schedule';



export const PrivateRoutes = [
    {
        path: "/",
        component: <Dashboard />
    },
    {
        path: "/scheduler",
        component: <Dashboard/>
    },
    {
        path: "/timesheet",
        component: <Dashboard/>
    },

]
