import React, { Fragment, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { MdClose, MdImage, MdLink } from "react-icons/md";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import colors from "app/style/colors";
import {
  FaCheckCircle,
  FaChevronDown,
  FaFileMedical,
  FaPlusCircle,
  FaRegSmile,
  FaTag,
  FaTimesCircle,
} from "react-icons/fa";
import { LoadingBtn, PrimaryButton } from "../UI/Buttons";
import { BsChat, BsPaperclip, BsThreeDots } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import AddSubTask from "./AddSubTask";
import { Service } from "app/config/service";
import {
  ChipDelete,
  ChipSolid,
  ChipStatus,
  ChipStatusOutline,
  ChipTag,
} from "../UI/Chip";
import { display, fontWeight } from "@mui/system";
import FieldLabel from "../UI/FieldLabel";
import { useForm } from "react-hook-form";
import Picker from "emoji-picker-react";
import { ErrorToaster, SuccessToaster } from "../UI/Toaster";
import Images from "assets/images";
import { v4 as uuidv4 } from "uuid";
import Menudropdown from "../Dropdown/Menudropdown";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import enLocale from "date-fns/locale/en-GB";
import { getDate } from "app/utils";
const useStyles = makeStyles({
  itemHeading: {
    fontSize: "14px !important",
    color: `${colors.textSecondary} !important`,
    fontWeight: "bold !important",
  },
  itemText: {
    fontSize: "14px !important",
    color: `${colors.darkGray} !important`,
    width: "100%",
  },
  itemSubTaskText: {
    fontSize: "14px !important",
    color: `${colors.black} !important`,
  },
  yellowChip: {
    color: `${colors.yellow} !important`,
    backgroundColor: `${colors.yellowShade} !important`,
    lineHeight: "28px",
  },
  purpleBox: {
    backgroundColor: `${colors.purple} !important`,
  },
  greenBox: {
    backgroundColor: `${colors.green} !important`,
  },
  yellowBox: {
    backgroundColor: `${colors.yellow} !important`,
  },
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none",
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
});

export default function ViewMore({
  handleViewMore,
  open,
  task,
  employees,
  registeredEmployee,
  getTasks,
  commentRef,
  handleJobDialog,
  jobDialog,
}) {
  const DATE_SIZE = 28;
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [showSubTask, setShowSubTask] = useState(false);
  const [parentTaskId, setParentTaskId] = useState(task?._id);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState();

  const messagesEndRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEle, setAnchorEle] = useState(null);
  const opener = Boolean(anchorEle);
  const openPicker = Boolean(anchorEl); // for emoji comment box
  const [comment, setComment] = useState("");
  const [addedComments, setAddedComments] = useState([]);
  const [commentPosition, setCommentPosition] = useState();
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [displayAddComment, setDisplayAddComment] = useState(false);
  const [displayAddTag, setDisplayAddTag] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(task?.priority);
  const [anchorElCommentPop, setAnchorElCommentPop] = useState(null);
  const openCommentPop = Boolean(anchorElCommentPop);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [jobs, setJobs] = useState([]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (commentRef === task._id) {
      scrollToBottom();
    }
  }, []);

  const getJobs = async () => {
    try {
      const { status, responseCode, data } = await Service.getJobs();
      if (status === true && responseCode === 200) {
        setJobs(data?.jobs);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };

  useEffect(() => {
    getComment();
    getTags();
    setDisplayAddComment(false);
    setSelectedTags(task?.tags);
  }, [task]);

  useEffect(() => {
    FilterTags(tags);
    getJobs();
  }, []);

  const FilterTags = (tags) => {
    const tagsAfterSelect = tags.filter(
      (ar) => !selectedTags.find((rm) => rm._id === ar._id)
    );
    setTags(tagsAfterSelect);
  };
  const getTags = async () => {
    try {
      const { status, responseCode, data } = await Service.getTags("");
      if (status === true && responseCode === 200) {
        FilterTags(data?.tags);
      }
    } catch (error) {
      console.log("file: Todos.js => line 164 => getTags => error", error);
    }
  };

  const handleAddTag = (item) => {
    const sortedTags = [...selectedTags];

    const getIndex = sortedTags.findIndex((x) => x._id === item._id);
    if (selectedTags.length < 3) {
      if (getIndex === -1) {
        sortedTags.push(item);
        setSelectedTags(sortedTags);
        const tagsAfterSelect = tags.filter((e) => e._id !== item._id);
        setTags(tagsAfterSelect);
      }
    }
  };
  const tagInput = useRef();
  const addTag = async () => {
    let obj = {
      title: tagInput.current.value,
    };
    const { status, responseCode, data } = await Service.addTag(obj);
    if (status === true && responseCode === 200) {
      getTags();
      setAnchorEl(null);
      tagInput.current.value = "";
    }
  };

  const deleteTag = (item, oldTags, id) => {
    const tagsAfterDelete = oldTags.filter((e) => e._id !== item._id);
    const mainTags = [...tags];
    mainTags.push(item);
    setTags(mainTags);
    setSelectedTags(tagsAfterDelete);
  };

  const getComment = async () => {
    try {
      const { status, responseCode, data } = await Service.getComment(
        task?._id
      );
      if (status === true && responseCode === 200) {
        setComments(data?.comments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function for handling marketing menu
  const handleEmojiClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEmojiClose = () => {
    setAnchorEl(null);
  };
  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject?.emoji) {
      setAnchorEl(null);
      let text = [...comment];
      text.splice(commentPosition, 0, emojiObject?.emoji);
      setValue("taskComments", text.join(""));
      setComment(text.join(""));
    }
  };
  const hiddenImageInput = useRef(null);
  const handleImageClick = (event) => {
    hiddenImageInput.current.click();
  };

  const handleImage = async (e) => {
    try {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("document_type", "1");
        formData.append("document", e.target.files[0]);
        const { responseCode, status, message, data } = await Service.upload(
          formData
        );
        if (status === true && responseCode === 200) {
          let image = [...images];
          image.push(data);
          setImages(image);
          e.target.value = null;
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };

  const deleteCommentImage = (i) => {
    try {
      let image = [...images];
      image.splice(i, 1);
      setImages(image);
    } catch (error) {
      console.log(error);
    }
  };

  const hiddenFileInput = useRef(null);
  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = async (e) => {
    try {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("document_type", "2");
        formData.append("document", e.target.files[0]);
        const { responseCode, status, message, data } = await Service.upload(
          formData
        );
        if (status === true && responseCode === 200) {
          let filesName = [...files];
          filesName.push(data);
          setFiles(filesName);
          e.target.value = null;
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddToDo.js ~ line 262 ~ handleImage ~ error",
        error
      );
    }
  };

  const deleteCommentFile = (i) => {
    try {
      let filesName = [...files];
      filesName.splice(i, 1);
      setFiles(filesName);
    } catch (error) {
      console.log(error);
    }
  };
  const clearComment = () => {
    setComment("");
    setImages([]);
    setFiles([]);
  };
  const handleAddedComments = async () => {
    try {
      let addComment = [...addedComments];
      if (comment || files.length > 0 || images.length > 0) {
        addComment.push({
          content: comment,
          attachments: [...files, ...images],
          created_on: new Date(),
        });
        // setAddedComments(addComment)
        await Service.createComment({
          task: task._id,
          content: comment,
          attachments: [...files, ...images],
          created_on: new Date(),
        });
      }
      getComment();
      getTasks();
      clearComment();
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 243 ~ handleAddedComments ~ error",
        error
      );
    }
  };

  const handleClickCommentPop = async (event, _id) => {
    try {
      setSelectedCommentId(_id);
      setAnchorElCommentPop(event.currentTarget);
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 308 ~ handleClickCommentPop ~ error",
        error
      );
    }
  };
  const handleCloseCommentPop = async (event) => {
    try {
      setAnchorElCommentPop(null);
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error",
        error
      );
    }
  };

  const handleDeleteComment = async () => {
    try {
      const { status, responseCode, message, data } =
        await Service.deleteComment(selectedCommentId);
      if (status === true && responseCode === 200) {
        getComment();
        SuccessToaster(message);
        getTasks();
        setAnchorElCommentPop(null);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ViewMore.js ~ line 316 ~ handleCloseCommentPop ~ error",
        error
      );
    }
  };

  const updateTodo = async () => {
    try {
      let obj = {
        id: task._id,
        tags: selectedTags,
        priority: selectedPriority,
      };
      const { status, responseCode, data } = await Service.updateTask(obj);
      if (status === true && responseCode === 200) {
        SuccessToaster("Todo Updated");
        getTasks();
        handleViewMore();
        setAddedComments([]);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTask = async (obj) => {
    try {
      const { status, responseCode } = await Service.updateTask(obj);
      if (status === true && responseCode === 200) {
        getTasks();
        setAnchorEle(null);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };
  // updateSubTask
  const updateSubTask = async (obj) => {
    try {
      const { status, responseCode } = await Service.updateSubTask(obj);
      if (status === true && responseCode === 200) {
        getTasks();
        setAnchorEle(null);
      }
    } catch (error) {
      console.log("file: Todos.js => line 120 => getJobs => error", error);
    }
  };
  const [todoName, setTodoName] = useState("");
  const [subToDoName, setSubTodoName] = useState("");
  const [selectedJobName, setSelectedJobName] = useState(task?.job);
  const [coordinate, setCoordinate] = useState({
    x: "",
    y: "",
  });
  const [clickedItemId, setClickedItemId] = useState("");
  const handleAnchorClick = (event, id) => {
    setAnchorEle(event.currentTarget);
    setCoordinate({
      x: event.clientX,
      y: event.pageY,
    });
    setClickedItemId(id);
  };
  const handleTodoName = (id) => {
    updateTask({ id, name: todoName });
  };
  const handleTodoJob = (e, id) => {
    updateTask({ id, job: e.target.value });
  };
  const handleTodoAssignedTo = (e, id) => {
    updateTask({ id, assigned_to: e.target.value });
  };
  const handleTodoSubTaskAssignedTo = (e, id) => {
    updateSubTask({ id, assigned_to: e.target.value });
  };
  const handleSubTaskUpdate = (id) => {
    updateSubTask({ id, name: subToDoName });
    setSubTodoName("");
  };

  return (
    <Fragment>
      {/* add sub task drawer */}
      <AddSubTask
        employees={employees}
        registeredEmployee={registeredEmployee}
        getTasks={getTasks}
        taskId={parentTaskId}
        handleSubTask={() => setShowSubTask(!showSubTask)}
        open={showSubTask}
      />

      <Drawer
        anchor="right"
        open={open}
        onClose={handleViewMore}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box sx={{ width: 350, pt: 10, px: 2, pb: 5 }} role="presentation">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            {task?.name}
            <IconButton
              onClick={handleViewMore}
              sx={{ px: 0 }}
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
              }}
            >
              <MdClose size={24} />
            </IconButton>
          </Box>

          {/* Details */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              To do
            </Typography>
            <TextField
              hiddenLabel
              placeholder="Add todo name"
              defaultValue={task?.name}
              variant="outlined"
              className={classes.itemText}
              size="small"
              onChange={(e) => setTodoName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        handleTodoName(task._id);
                      }}
                    >
                      <FaCheckCircle size={14} color={colors.skyBlue} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Typography variant="body1" className={classes.itemText}>
              {task?.name}
            </Typography> */}
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Job name
            </Typography>
            <Grid
              container
              alignItems="center"
              className={classes.boxSelectField}
            >
              <Grid item xs={10}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    PaperProps: {
                      sx: { ml: 2.5, maxHeight: "30vh", width: "30vh" },
                    },
                  }}
                  variant="standard"
                  fullWidth
                  defaultValue={task?.job_id}
                  onChange={(e) => {
                    handleTodoJob(e, task._id);
                  }}
                  sx={{
                    color: colors.secondaryLight,
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  }}
                >
                  {jobs.map((item) => (
                    <MenuItem key={uuidv4()} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={0.5}>
                <Divider orientation="vertical" sx={{ height: 20 }} />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleJobDialog(!jobDialog)}>
                  <FaPlusCircle size={16} color="black" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Job ID (Auto-generated)
            </Typography>
            <Typography variant="body1" className={classes.itemText}>
              {task?.jobId}
            </Typography>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Date created
            </Typography>
            <Typography variant="body1" className={classes.itemText}>
              {moment(task?.createdAt).format("DD-MMMM-YYYY")}
            </Typography>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Created by
            </Typography>
            <Typography variant="body1" className={classes.itemText}>
              {task?.created_by}
            </Typography>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Assigned to
            </Typography>
            <Grid
              container
              alignItems="center"
              className={classes.boxSelectField}
            >
              <Grid item xs={10}>
                <Select
                  variant="standard"
                  fullWidth
                  defaultValue={task?.assigned_to_id}
                  onChange={(e) => {
                    handleTodoAssignedTo(e, task._id);
                  }}
                  sx={{
                    borderColor: errors?.assginTo?.message && "red",
                    color: colors.secondaryLight,
                    "::before": {
                      display: "none",
                    },
                    "::after": {
                      display: "none",
                    },
                  }}
                >
                  <MenuItem value={"none"} disabled>
                    Select employee
                  </MenuItem>
                  {employees.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={0.5}>
                <Divider orientation="vertical" sx={{ height: 20 }} />
              </Grid>
              <Grid item xs={1}>
                <IconButton>
                  <FaPlusCircle size={16} color="black" />
                </IconButton>
              </Grid>
            </Grid>
            {/* <Typography variant="body1" className={classes.itemText}>
              <Avatar
                alt=""
                src=""
                sx={{
                  display: "inline-flex",
                  mr: 1,
                  width: "25px",
                  height: "25px",
                  bgcolor: colors.skyBlue,
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
              >
                {task?.assigned_to.slice(0, 2)}
              </Avatar>
              {task?.assigned_to}
            </Typography> */}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Due Date
            </Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={(e) => handleAnchorClick(e, "date" + task._id)}
              variant="body1"
              className={classes.itemText}
            >
              {moment(task?.due_date).format("DD-MMMM-YYYY")}
            </Typography>
            <Menudropdown
              colName={"date"}
              open={opener}
              anchorEl={anchorEle}
              setAnchor={() => setAnchorEle(null)}
              id={"date" + task._id}
              clickedItemId={clickedItemId}
              coordinate={coordinate}
              width={"auto"}
              transformOrigin={{
                vertical: -30,
                horizontal: 0,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                // adapterLocale={enLocale}
              >
                <Box
                  sx={{
                    "& > div": {
                      minWidth: 270,
                    },
                    "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                      {
                        width: 270,
                      },
                    "& .MuiTypography-caption": {
                      width: DATE_SIZE,
                      margin: 0,
                    },
                    "& .PrivatePickersSlideTransition-root": {
                      minHeight: DATE_SIZE * 6,
                    },
                    '& .PrivatePickersSlideTransition-root [role="row"]': {
                      margin: 0,
                    },
                    "& .MuiPickersDay-dayWithMargin": {
                      margin: 0,
                    },
                    "& .MuiPickersDay-root": {
                      width: DATE_SIZE,
                      height: DATE_SIZE,
                    },
                  }}
                >
                  <StaticDatePicker
                    value={getDate(task.due_date)}
                    onChange={(value) =>
                      updateTask({
                        id: task._id,
                        due_date: getDate(value),
                      })
                    }
                    displayStaticWrapperAs="desktop"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Menudropdown>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Status
            </Typography>
            <ChipStatusOutline
              item={task}
              handleClick={(e) => handleAnchorClick(e, "status" + task._id)}
            />
            <Menudropdown
              colName={"status"}
              open={opener}
              anchorEl={anchorEle}
              setAnchor={() => setAnchorEle(null)}
              id={"status" + task._id}
              clickedItemId={clickedItemId}
              coordinate={coordinate}
              width={120}
              transformOrigin={{
                vertical: -30,
                horizontal: 0,
              }}
            >
              <ChipStatus
                margin={1}
                label="Done"
                handleClick={() => updateTask({ id: task._id, status: "Done" })}
              />
              <ChipStatus
                margin={1}
                label="In progress"
                handleClick={() =>
                  updateTask({ id: task._id, status: "In progress" })
                }
              />
              <ChipStatus
                margin={1}
                label="Overdue"
                handleClick={() =>
                  updateTask({ id: task._id, status: "Overdue" })
                }
              />
              <ChipStatus
                margin={1}
                label="Not Started"
                handleClick={() =>
                  updateTask({ id: task._id, status: "Not started" })
                }
              />
            </Menudropdown>
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Sub-tasks
              <FaChevronDown
                size={12}
                style={{ margin: "0px 5px", color: colors.primaryGray }}
              />
            </Typography>
          </Box>

          {/* sub-task div */}
          {task?.subTasks.map((subtask, i) => (
            <Box
              sx={{
                border: `1px solid ${colors.borderColor}`,
                p: 1,
                borderRadius: "8px",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{i + 1}.</p>
                <TextField
                  sx={{
                    marginLeft: "5px",
                  }}
                  hiddenLabel
                  placeholder="Add todo name"
                  defaultValue={subtask.name}
                  variant="outlined"
                  className={classes.itemText}
                  size="small"
                  onChange={(e) => setSubTodoName(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            handleSubTaskUpdate(subtask._id);
                          }}
                        >
                          <FaCheckCircle size={14} color={colors.skyBlue} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Grid container my={2} rowGap={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="body1" className={classes.itemText}>
                    Assigned to:
                  </Typography>
                </Grid>
                <Grid
                  xs={8}
                  container
                  alignItems="center"
                  className={classes.boxSelectField}
                >
                  <Grid item xs={10}>
                    <Select
                      variant="standard"
                      fullWidth
                      defaultValue={subtask?.assigned_to_id}
                      onChange={(e) => {
                        handleTodoSubTaskAssignedTo(e, subtask._id);
                      }}
                      sx={{
                        borderColor: errors?.assginTo?.message && "red",
                        color: colors.secondaryLight,
                        "::before": {
                          display: "none",
                        },
                        "::after": {
                          display: "none",
                        },
                      }}
                    >
                      <MenuItem value={"none"} disabled>
                        Select employee
                      </MenuItem>
                      {employees.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={0.5}>
                    <Divider orientation="vertical" sx={{ height: 20 }} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton>
                      <FaPlusCircle size={16} color="black" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.itemText}>
                    Due date:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => handleAnchorClick(e, "date" + subtask._id)}
                    variant="body1"
                    className={classes.itemText}
                  >
                    {moment(subtask?.due_date).format("DD-MMMM-YYYY")}
                  </Typography>
                  <Menudropdown
                    colName={"date"}
                    open={opener}
                    anchorEl={anchorEle}
                    setAnchor={() => setAnchorEle(null)}
                    id={"date" + subtask._id}
                    clickedItemId={clickedItemId}
                    coordinate={coordinate}
                    width={"auto"}
                    transformOrigin={{
                      vertical: -30,
                      horizontal: 0,
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      // adapterLocale={enLocale}
                    >
                      <Box
                        sx={{
                          "& > div": {
                            minWidth: 270,
                          },
                          "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                            {
                              width: 270,
                            },
                          "& .MuiTypography-caption": {
                            width: DATE_SIZE,
                            margin: 0,
                          },
                          "& .PrivatePickersSlideTransition-root": {
                            minHeight: DATE_SIZE * 6,
                          },
                          '& .PrivatePickersSlideTransition-root [role="row"]':
                            {
                              margin: 0,
                            },
                          "& .MuiPickersDay-dayWithMargin": {
                            margin: 0,
                          },
                          "& .MuiPickersDay-root": {
                            width: DATE_SIZE,
                            height: DATE_SIZE,
                          },
                        }}
                      >
                        <StaticDatePicker
                          value={getDate(subtask.due_date)}
                          onChange={(value) =>
                            updateSubTask({
                              id: subtask._id,
                              due_date: getDate(value),
                            })
                          }
                          displayStaticWrapperAs="desktop"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          )}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Menudropdown>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.itemText}>
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ChipStatusOutline
                    item={subtask}
                    handleClick={(e) =>
                      handleAnchorClick(e, "status" + subtask._id)
                    }
                  />
                  <Menudropdown
                    colName={"status"}
                    open={opener}
                    anchorEl={anchorEle}
                    setAnchor={() => setAnchorEle(null)}
                    id={"status" + subtask._id}
                    clickedItemId={clickedItemId}
                    coordinate={coordinate}
                    width={120}
                    transformOrigin={{
                      vertical: -30,
                      horizontal: 0,
                    }}
                  >
                    <ChipStatus
                      margin={1}
                      label="Done"
                      handleClick={() =>
                        updateSubTask({ id: subtask._id, status: "Done" })
                      }
                    />
                    <ChipStatus
                      margin={1}
                      label="In progress"
                      handleClick={() =>
                        updateSubTask({
                          id: subtask._id,
                          status: "In progress",
                        })
                      }
                    />
                    <ChipStatus
                      margin={1}
                      label="Overdue"
                      handleClick={() =>
                        updateSubTask({ id: subtask._id, status: "Overdue" })
                      }
                    />
                    <ChipStatus
                      margin={1}
                      label="Not Started"
                      handleClick={() =>
                        updateSubTask({
                          id: subtask._id,
                          status: "Not started",
                        })
                      }
                    />
                  </Menudropdown>
                </Grid>
              </Grid>
            </Box>
          ))}

          <PrimaryButton
            sx={{
              minWidth: 180,
              my: 2,
              bgcolor: colors.grayShade,
              textTransform: "capitalize",
              fontWeight: 600,
            }}
            onClick={() => [setShowSubTask(!showSubTask), handleViewMore()]}
          >
            <FaFileMedical style={{ margin: "0px 10px" }} />
            Add sub-task
          </PrimaryButton>

          {/* Comments */}

          <Box sx={{ my: 2 }} ref={messagesEndRef}>
            <Typography variant="body1" className={classes.itemHeading}>
              Comments
              <FaChevronDown
                size={12}
                style={{ margin: "0px 5px", color: colors.primaryGray }}
              />
            </Typography>
          </Box>

          {/* Comments div  */}
          {comments ? (
            <React.Fragment>
              {comments.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    backgroundColor: colors.darkgrayShade,
                    border: `1px solid ${colors.borderColor}`,
                    p: 1,
                    borderRadius: "8px",
                    mb: 1,
                  }}
                >
                  <Fragment>
                    <Grid container>
                      <Grid item xs={10}>
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                            alt=""
                            src=""
                            sx={{
                              display: "inline-flex",
                              mr: 1,
                              width: "25px",
                              height: "25px",
                              bgcolor: colors.skyBlue,
                              fontSize: "12px",
                              textTransform: "uppercase",
                            }}
                          >
                            {item?.created_by?.split(" ").length > 1
                              ? item?.created_by?.charAt(0) +
                                item?.created_by?.split(" ")[1].charAt(0)
                              : item?.created_by?.charAt(0)}
                          </Avatar>
                          <Box>
                            <Typography variant="body1">
                              {item?.created_by}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.itemText}
                            >
                              {moment(item.created_on).fromNow()}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          sx={{ py: 0 }}
                          onClick={(event) =>
                            handleClickCommentPop(event, item._id)
                          }
                        >
                          <BsThreeDots />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Menu
                      id="basic-menu"
                      PaperProps={{
                        sx: {
                          boxShadow: "none",
                          borderRadius: "10px",
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      anchorEl={anchorElCommentPop}
                      open={openCommentPop}
                      onClose={handleCloseCommentPop}
                    >
                      <MenuItem onClick={handleDeleteComment}>Delete</MenuItem>
                    </Menu>
                    {/* Attachments */}
                    {item?.attachments.length > 0 && (
                      <Typography variant="body2" fontWeight="bold" mt={2}>
                        Attachments
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {item?.attachments.map((attachment, i) => {
                        return (
                          <Box key={i}>
                            {attachment.document_type === "1" && (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${attachment?.document}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            )}

                            {attachment.document_type === "2" &&
                            attachment.document.slice(-4) === ".txt" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".doc" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".docx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".pdf" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.pdf}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".csv" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-4) === ".xls" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".xlsx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".pptx" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : attachment.document.slice(-5) === ".ppt" ? (
                              <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                                <Link
                                  href={`${attachment?.document}`}
                                  target="_blank"
                                >
                                  <Tooltip
                                    title={attachment?.document?.replace(
                                      "https://consultya.mangotech-api.com/media/comments/",
                                      ""
                                    )}
                                  >
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Tooltip>
                                </Link>
                                <Typography
                                  noWrap
                                  sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    color: "#007AFF",
                                  }}
                                >
                                  {attachment?.document?.replace(
                                    "https://consultya.mangotech-api.com/media/comments/",
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            ) : (
                              // <Box sx={{mr:2,mb:2,width:"70px"}}>
                              // <Link href={`${attachment?.document}`} target="_blank">
                              //   <img src={`${Images.word}`} width='60px' height={'60px'} alt='file' style={{ margin: '4px' }} />
                              // </Link>
                              // <Typography noWrap  sx={{textAlign:'center', fontSize: '13px', color: '#007AFF' }}>
                              // {attachment?.document?.replace('https://consultya.mangotech-api.com/media/comments/', '')}
                              // </Typography>
                              // </Box>
                              ""
                            )}
                          </Box>
                        );
                      })}
                    </Box>

                    {/* <Grid item xs={3.4}>
                    <img
                      width="100%"
                      height="80px"
                      src="https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMGludGVyaW9yfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                      alt=""
                    />
                  </Grid> */}
                    {item?.content && (
                      <>
                        <Divider />
                        {/* Comments text */}
                        <Typography variant="body2" color="initial" my={2}>
                          {item?.content}
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                      </>
                    )}
                  </Fragment>
                </Box>
              ))}

              {/* Add comments button */}
              <PrimaryButton
                sx={{
                  minWidth: 180,
                  my: 2,
                  bgcolor: colors.grayShade,
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
                onClick={() => setDisplayAddComment(!displayAddComment)}
              >
                <BsChat style={{ margin: "0px 10px" }} />
                Add comment
              </PrimaryButton>
            </React.Fragment>
          ) : (
            ""
          )}
          <FormControl
            variant="standard"
            fullWidth
            sx={{ display: displayAddComment ? "block" : "none" }}
          >
            {/* {addedComments.map((item, index) =>

              item.content.length > 35 ?
                <Typography sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content.slice(0, 35)}......</Typography>
                : <Typography sx={{ fontSize: '14px', color: '#007AFF' }}>{item.content}</Typography>

            )} */}
            <Box sx={{ mt: 1 }} className={classes.boxSelectField}>
              <Grid container alignItems="center">
                <Grid item xs={1.5}>
                  <IconButton onClick={handleEmojiClick}>
                    <FaRegSmile size={18} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openPicker}
                    onClose={handleEmojiClose}
                    transformOrigin={{
                      vertical: 50,
                      horizontal: 0,
                    }}
                    PaperProps={{
                      style: {
                        // width: 120,
                        borderRadius: "10px",
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Picker
                      disableSearchBar
                      onEmojiClick={onEmojiClick}
                      disableAutoFocus={true}
                    />
                  </Menu>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleImageClick}>
                    <MdImage size={20} />
                  </IconButton>
                  <input
                    type="file"
                    accept="image/*"
                    ref={hiddenImageInput}
                    onChange={handleImage}
                    style={{ display: "none" }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleFileClick}>
                    <BsPaperclip size={18} />
                  </IconButton>
                  <input
                    type="file"
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    ref={hiddenFileInput}
                    onChange={handleFile}
                    style={{ display: "none" }}
                  />
                </Grid>
                {/* <Grid item xs={1.5}>
                  <IconButton>
                    <MdLink size={20} />
                  </IconButton>
                </Grid> */}
              </Grid>

              <Divider />
              <Box sx={{ p: 1 }}>
                {images.length > 0 && (
                  <>
                    <Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Images:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {images.map((item, i) => (
                          <Box>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: "#ECECEC",
                                width: "20px",
                                height: "12px ",
                                position: "relative",
                                left: "50px",
                                top: 10,
                              }}
                              onClick={() => deleteCommentImage(i)}
                            >
                              <CloseIcon fontSize="10px" />
                            </IconButton>
                            <Box sx={{ mr: 2, mb: 2, width: "70px" }}>
                              <img
                                src={item?.document}
                                width="70px"
                                height="70px"
                                style={{ margin: "5px" }}
                                alt="file"
                              />
                              <Typography
                                noWrap
                                sx={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                  color: "#007AFF",
                                }}
                              >
                                {item?.document?.replace(
                                  "https://consultya.mangotech-api.com/media/comments/",
                                  ""
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                {files.length > 0 && (
                  <>
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Files:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {files.map((attachment, i) => {
                          return (
                            <Box sx={{ mr: 2, mb: 2 }}>
                              {attachment.document?.slice(-4) === ".txt" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".txt", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".doc" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".doc", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".docx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.doc}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".docx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".pdf" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.pdf}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".pdf", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".csv" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".csv", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".xlsx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".xlsx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".xls" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.excel}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".xls", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-4) === ".ppt" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".ppt", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : attachment.document.slice(-5) === ".pptx" ? (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.ppt}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />

                                    <Typography
                                      noWrap
                                      sx={{
                                        fontSize: "13px",
                                        color: "#007AFF",
                                      }}
                                    >
                                      {attachment?.document
                                        ?.replace(
                                          "https://consultya.mangotech-api.com/media/comments/",
                                          ""
                                        )
                                        .replace(".pptx", "")}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : (
                                <Box>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      backgroundColor: "#ECECEC",
                                      width: "20px",
                                      height: "12px ",
                                      position: "relative",
                                      left: "50px",
                                      top: 10,
                                    }}
                                    onClick={() => deleteCommentFile(i)}
                                  >
                                    <CloseIcon fontSize="10px" />
                                  </IconButton>
                                  <Box sx={{ width: "70px" }}>
                                    <img
                                      src={`${Images.word}`}
                                      width="60px"
                                      height={"60px"}
                                      alt="file"
                                      style={{ margin: "4px" }}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
              </Box>
              <TextField
                hiddenLabel
                multiline
                placeholder="Add your comment..."
                variant="outlined"
                fullWidth
                value={comment}
                className={classes.outlineInputBorderNone}
                sx={{ minHeight: "100px" }}
                error={errors?.taskComments?.message && true}
                size="small"
                onClick={(e) => {
                  setCommentPosition(e.target.selectionStart);
                }}
                onKeyPress={(e) => {
                  setCommentPosition(e.target.selectionStart + 1);
                }}
                {...register("taskComments", {
                  onChange: (e) => {
                    setComment(e.target.value);
                  },
                })}
              />
              <Box sx={{ float: "right" }}>
                <IconButton onClick={clearComment}>
                  <FaTimesCircle
                    size={16}
                    color={comment.length >= 1 ? colors.red : colors.redShade}
                  />
                </IconButton>
                <IconButton onClick={handleAddedComments}>
                  <FaCheckCircle size={16} color={colors.skyBlue} />
                </IconButton>
              </Box>
            </Box>
          </FormControl>

          {/* Tags */}
          <Box sx={{ my: 2 }}>
            <Typography variant="body1" className={classes.itemHeading}>
              Tags (max 3)
            </Typography>
            {selectedTags.map((tag) => (
              <ChipDelete
                onDelete={() => deleteTag(tag, selectedTags, tag._id)}
                item={tag}
              />
            ))}
          </Box>
          {/* Add tags button */}
          {selectedTags.length < 3 && (
            <>
              <PrimaryButton
                sx={{
                  minWidth: 180,
                  my: 1,
                  bgcolor: colors.grayShade,
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
                onClick={() => setDisplayAddTag(!displayAddTag)}
              >
                <FaTag style={{ margin: "0px 10px" }} />
                Add Tag
              </PrimaryButton>

              <Grid
                container
                alignItems="center"
                className={classes.boxSelectField}
                sx={{ display: displayAddTag ? "flex" : "none" }}
              >
                <Grid item xs={12}>
                  <Select
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "30vh", width: "30vh" } },
                    }}
                    variant="standard"
                    fullWidth
                    sx={{
                      color: colors.secondaryLight,
                      "::before": {
                        display: "none",
                      },
                      "::after": {
                        display: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        boxSizing: "border-box",
                      }}
                    >
                      <TextField
                        hiddenLabel
                        placeholder="Add Tag"
                        inputRef={tagInput}
                        variant="outlined"
                        className={classes.outlineInputBorder}
                        size="small"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={() => (tagInput.current.value = "")}
                              >
                                <FaTimesCircle
                                  size={16}
                                  color={colors.grayShade}
                                />
                              </IconButton>
                              <IconButton onClick={addTag}>
                                <FaCheckCircle
                                  size={16}
                                  color={colors.skyBlue}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      {tags.map((item) => (
                        <ChipTag
                          margin={1}
                          key={item}
                          item={item}
                          handleClick={() => handleAddTag(item)}
                        />
                      ))}
                    </Box>
                  </Select>
                </Grid>

                {/* <Grid item xs={0.5}>
                <Divider orientation="vertical" sx={{ height: 20, }} />
              </Grid>
              <Grid item xs={1}>
                <IconButton>
                  <FaPlusCircle size={16} color="black" />
                </IconButton>
              </Grid> */}
              </Grid>
            </>
          )}
          {/* Priority */}
          <Typography variant="body1" mt={2} className={classes.itemHeading}>
            Priority
          </Typography>
          {/* <Box
            sx={{
              textAlign: "center",
              bgcolor: colors.purple,
              color: colors.white,
              textTransform: "uppercase",
              p: 0.8,
              borderRadius: "10px",
              width: 100,
            }}
            className={
              task.priority === "High"
                ? classes.purpleBox
                : task.priority === "Medium"
                ? classes.greenBox
                : classes.yellowBox
            }
          >
            {task?.priority}
          </Box> */}
          <Select
            variant="standard"
            // className={classes.boxSelectField}
            defaultValue={task?.priority}
            {...register("priority", {
              required: "Priority is Required",
            })}
            onChange={(e) => {
              setSelectedPriority(e.target.value);
              // clearErrors('priority')
            }}
            sx={{
              color: colors.secondaryLight,
              width: "50%",
              "::before": {
                display: "none",
              },
              "::after": {
                display: "none",
              },
            }}
          >
            <MenuItem value={"none"} disabled>
              {/* Select priority */}
              <ChipSolid label={"Select priority"} />
            </MenuItem>
            <MenuItem value={"High"}>
              <ChipSolid label={"High"} />
            </MenuItem>
            <MenuItem value={"Medium"}>
              <ChipSolid label={"Medium"} />
            </MenuItem>
            <MenuItem value={"Low"}>
              <ChipSolid label={"Low"} />
            </MenuItem>
          </Select>

          {/* Save button */}
          <LoadingBtn
            fullWidth
            loading={loading}
            type="submit"
            variant="contained"
            sx={{
              bgcolor: colors.darkBlue,
              mt: 5,
              borderRadius: "8px",
              ":hover": {
                boxShadow: "none",
                bgcolor: colors.skyBlue,
              },
            }}
            onClick={updateTodo}
          >
            Save
          </LoadingBtn>
        </Box>
      </Drawer>
    </Fragment>
  );
}
