import Axios from '../../axios';
import { errorHandler } from './errorHandler';

export const Apis = {
    login: '/Users/signin',
    register: '/Users/signup',
    getJobs: '/jobs/getDropdown',
    createJobs: '/jobs/create',
    jobCategory: '/jobCategories/getDropdown',
    getJobByDaily:'/jobs/getJobByDaily',
    getJobWeekly:'/jobs/getJobWeekly',
    createJobCategory: '/jobCategories/create',
    getTags: '/tags',
    addTag:'/tags/create',
    getTasks: '/tasks',
    getFilteredJobs: '/jobs',
    getRoles: '/Roles',
    getRegisteredEmployee: '/users/getDropdown',
    createTask: '/tasks/create',
    addNewEmployee: '/Users/addNewUser',
    deleteTask: '/tasks/delete',
    updateTask: '/tasks/update',
    getFilterTask: '/tasks/getFilters',
    createSubTask: '/subTasks/create',
    updateSubTask:'/subTasks/update',
    upload:'/comments/upload',
    getComment:'/comments',
    createComment:'/comments/create',
    deleteComment:'/comments/delete',
    getJobAssignBy:'/jobs/getJobAssignBy',
    getJobAssignByDaily:'/jobs/getJobAssignByDaily',
    getUnscheduled:'/jobs/getUnscheduled',
    updateJob:'/jobs/update',
    deleteJob:'/jobs/delete',
    getJobById:'/jobs/getById',
    getTimeOff:'/timeoff/get',
    updateTimeOff:'/timeoff/update',
    getTimeSheet:'/timesheet/get',
    createTimeSheet:'/timesheet/create',
    updateTimeSheet:'/timesheet/update',
    GettimesheetRequest:'/timesheet/getRequest',
    getJobChange:'/jobchange',
    updateJobChange:'/jobchange/update',
    getJobByMonth:'/jobs/getJobByMonth',
    updateTimeSheetTime:'/timesheet/updateTime'
};

export const headers = {
    'content-type': 'application/json',
};

export const get = async (endPoint, token) => {
    try {
        const result = await Axios.get(endPoint, { 'headers': { 'Authorization': `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await Axios.post(endPoint, data, { 'headers': { 'Authorization': `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const patch = async (endPoint, data, token) => {
    try {
        const result = await Axios.patch(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const deleted = async (endPoint, token) => {
    try {
        const result = await Axios.delete(endPoint, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};















