import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { Toolbar, List, CssBaseline, Typography, Divider, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPrint, FaChevronDown, FaQuestionCircle, FaBell } from "react-icons/fa";

import Avatar from '@mui/material/Avatar';
import colors from 'app/style/colors';
import { navList, settingList } from 'app/Navigate';
import Todos from '../Todos/Todos';

// *import components
import { PrimaryButton } from 'app/Components/UI/Buttons';
import useAuth from 'app/hooks/useAuth';
import { FiLogOut } from 'react-icons/fi';
import Schedule from '../Schedule/Schedule';
import TimeSheet from '../Timesheets/TimeSheet';


const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8.6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - 73px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function Dashboard() {

  const navigate = useNavigate()

  const { pathname } = useLocation()
  const [pageName, setPageName] = useState();
  const { userName, signOut } = useAuth()

  const getPageName = (val) => {
    setPageName(val)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={true} sx={{ bgcolor: colors.white, boxShadow: `0px 5px 25px ${colors.shadowColor}` }}>
        <Toolbar >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

            <Typography variant="body1" sx={{ color: colors.textSecondary, fontWeight: "bold" }}>{pageName}</Typography>

            <Box>
              <PrimaryButton sx={{ border: `1px solid ${colors.borderColor}`, bgcolor: colors.gray, mr: 1, p: 1.2 }}>
                <FaPrint />
              </PrimaryButton>
              <PrimaryButton sx={{ border: `1px solid ${colors.borderColor}`, bgcolor: colors.gray, mr: 1, textTransform: "capitalize" }}>
                Download
                <FaChevronDown style={{ marginLeft: "8px" }} />
              </PrimaryButton>
              <PrimaryButton sx={{ border: `1px solid ${colors.borderColor}`, bgcolor: colors.gray, mr: 1, textTransform: "capitalize" }}>
                <FaQuestionCircle style={{ marginRight: "8px" }} />
                Support
              </PrimaryButton>
              <PrimaryButton sx={{ border: `1px solid ${colors.borderColor}`, bgcolor: colors.gray, mr: 1, p: 1.2, position: "relative" }}>
                <Box sx={{ position: "absolute", top: -8, right: -8, bgcolor: "red", color: colors.white, width: "20px", height: "20px", lineHeight: "20px", borderRadius: "20px" }}>
                  4
                </Box>
                <FaBell />
              </PrimaryButton>
              <PrimaryButton
                onClick={signOut}
                sx={{ border: `1px solid ${colors.borderColor}`, bgcolor: colors.gray, mr: 1, p: 1.2, position: "relative" }}>
                <FiLogOut />
              </PrimaryButton>
            </Box>

          </Box>

        </Toolbar>
      </AppBar>

      <Drawer
        className='customScroll'
        sx={{
          '&>.MuiPaper-root': {
            bgcolor: colors.primary,
          }
        }} variant="permanent" open={true} >


        <List >
          <ListItem>
            <ListItemIcon >
              <Avatar alt="" src='' sx={{ width: "50px", height: "50px", bgcolor: colors.skyBlue }} >
              {userName?.split(" ").length > 1 ?
                  userName?.charAt(0) + userName?.split(" ")[1].charAt(0) :
                  userName?.charAt(0)
                }
                </Avatar>
            </ListItemIcon>
            <ListItemText sx={{ '&>.MuiTypography-body1': { color: colors.white, fontSize: 17 } }} primary={userName} />
          </ListItem>
        </List>

        <Divider sx={{ bgcolor: colors.gray, opacity: 0.1 }} />

        <List sx={{ flex: 1 }}>
          {navList.map((item1, index) => (
            <ListItemButton key={item1.label}
              onClick={() => {
                navigate(item1.path)
              }}
              selected={pathname === item1.path ? true : false}
              sx={{
                display: "flex",
                alignItems: "center",
                my: 1,
                color: colors.lightGray,
                '.MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
                '&:hover': {
                  bgcolor: colors.primaryDark,
                  '&>.MuiTypography-root': {
                    color: colors.white,
                  },
                  '.MuiSvgIcon-root': {
                    color: colors.secondary,
                  },
                  cursor: 'pointer',
                },
                '&.Mui-selected': {
                  bgcolor: colors.secondary
                },
              }}>
              <ListItemIcon>
                {item1.icon}
              </ListItemIcon>
              <ListItemText primary={<Typography type="body2" sx={{ fontSize: 14 }}>{item1.label}</Typography>} />
            </ListItemButton>
          ))}
        </List>

        <List>
          {settingList.map((item1, index) => (
            <ListItemButton key={item1.label}
              // onClick={() => {
              //   handleDialog(item1.label)
              // }}
              selected={pathname === item1.path ? true : false}
              sx={{
                display: "flex",
                alignItems: "center",
                my: 1,
                color: colors.lightGray,
                '.MuiSvgIcon-root': {
                  color: colors.lightGray,
                },
                '&:hover': {
                  bgcolor: colors.primaryDark,
                  '&>.MuiTypography-root': {
                    color: colors.white,
                  },
                  '.MuiSvgIcon-root': {
                    color: colors.secondary,
                  },
                  cursor: 'pointer',
                },
                '&.Mui-selected': {
                  bgcolor: colors.secondary
                },
              }}>
              <ListItemIcon>
                {item1.icon}
              </ListItemIcon>
              <ListItemText primary={<Typography type="body2" sx={{ fontSize: 14 }}>{item1.label}</Typography>} />
            </ListItemButton>
          ))}
        </List>

      </Drawer>

      <Box component="main" sx={{ minHeight: "100vh", bgcolor: colors.white, width: "98%" }}>

        <DrawerHeader />

        {pathname === "/" &&
          <Todos getPageName={getPageName} />
        }
        {pathname === "/scheduler" &&
          <Schedule getPageName={getPageName} />
        }
        {pathname === "/timesheet" &&
          <TimeSheet getPageName={getPageName} />
        }

      </Box>

    </Box>
  );
}
