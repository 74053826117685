import { server } from "../../axios";
import moment from "moment";

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


// *Remove Space
export const RemoveSpace = (string) => {
  const name = string.replace(/ /g, '-').toLowerCase();
  return name;
}

// *Remove Space
export const SpaceRemove = (string) => {
  const name = string.replace(/ /g, '').toLowerCase();
  return name;
}

// *For Image Url
export const imageURL = (url) => {
  const image = server + url;
  return image;
};

// *Date Format
export const getDate = (date) => {
  const newDate = moment(date).format('MM/DD/YYYY')
  return newDate
}

// *Date Format
export const getTaskDate = (date) => {
  const newDate = moment(date).format('D MMM')
  return newDate
}
// *Date Format
export const getWeekDate =(action,value) => {
  let date={}
  if(!value){
    date.startTimeStamp = moment().day(1)
    date.endTimeStamp = moment().day(7)
  }
  if(action==='next'&&value){
    date.startTimeStamp = moment(value).add('days',1).day(1)
    date.endTimeStamp = moment(value).add('days',1).day(7) 
  }
  else if(action==='previous'&&value){
    date.startTimeStamp = moment(value).subtract('days',7).day(1)
    date.endTimeStamp = moment(value).subtract('days',7).day(7)
  }
  return date
}
export const getDailyDate =(action,value) => {
  let date={}
  if(!value){
    date.startTimeStamp = moment()
  }
  if(action==='next'&&value){
    date.startTimeStamp = moment(value).add('days',1)
  }
  else if(action==='previous'&&value){
    date.startTimeStamp = moment(value).subtract('days',1)
  }
  return date
}

export const getMonth =(action,value)=>{
  let date
  if(!value){
    date=  moment().toDate()
  }
  if(action==='next'&&value){
    date=  moment(value).add(1, "month").toDate()
  }
  else if(action==='previous'&&value){
    date= moment(value).subtract(1, "month").toDate()
  }
  return date
}