import { Box, Menu } from '@mui/material'
import React from 'react'

function Menudropdown({colName,children,open,anchorEl,setAnchor,id,clickedItemId,coordinate,width,height,transformOrigin}) {
  return (
    <Menu
    id="basic-menu2"
    anchorEl={anchorEl}
    open={open && id == clickedItemId}
    onClose={setAnchor}
    anchorReference="anchorPosition"
    anchorPosition={{ top: parseInt(coordinate?.y-window.scrollY), left: parseInt(coordinate?.x) }}
    transformOrigin={transformOrigin}
    PaperProps={{
      style: {
        width: width,
        height:height,
        borderRadius: "10px"
      }
    }}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >
    <Box sx={{
      p: colName !== "view" ? 1: 0, boxSizing: "border-box",
    }}>


    {children}

    </Box>
  </Menu>
  )
}

export default Menudropdown