import { Chip, Drawer, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import FieldLabel from 'app/Components/UI/FieldLabel'
import colors from 'app/style/colors'
import dayjs from 'dayjs'
import React from 'react'
import { useEffect,useState  } from 'react'
import moment from 'moment'
import { MdClose } from 'react-icons/md'
import { LoadingBtn } from 'app/Components/UI/Buttons'
import { Service } from 'app/config/service'
const useStyles = makeStyles({
    drawerPaper: {
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            overflowY: "auto",
            width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: colors.primaryGray,
            borderRadius: "10px",
        },
    },
    outlineInputBorder: {
        "& * > fieldset": {
            padding: "18px 12px",
            borderRadius: "10px",
        },
    },
    outlineInputBorderNone: {
        "& * > fieldset": {
            border: "none"
        },
    },
    boxSelectField: {
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        padding: "5px 12px",
    },
    paper: {
        borderRadius: 12,
        marginTop: 8
    },
    boxStyle: {
        padding: 4.5,
        width: 90,
        height: 59,
        borderRadius: "5px",
        boxSizing: "border-box",
        border: "1px solid #E9EBED",
        background: "rgba(255, 255, 255, 0.1)",
        margin: 5
    },
})
const EditTime = ({open,handleEditTime,editItem,getTimeSheet,startDate,endDate}) => {
    const classes = useStyles();
    const [item, setItem] = useState(editItem);
    const [loading, setLoading] = useState(false);


    const handleChange=(event,iName)=>{
        if(iName){
            setItem({...item,[iName]:event['$d']})
        }
        else{
            const {name,value}=event.target
        setItem({...item,[name]:value})
        }
        
    }
const update=async()=>{
    try {
        
        setLoading(true)
        let obj={
            id:item._id,
            type:item.type,
            startTime:item.startTime,
            endTime:item.endTime
        }
        const {data,message,status,responseCode}=await Service.updateTimeSheetTime(obj)
        if (status === true && responseCode === 200) {
            handleEditTime()
            getTimeSheet(startDate,endDate,"","")
        }
    } catch (error) {
        console.log("🚀 ~ file: EditTime.js ~ line 76 ~ update ~ error", error)
        
    }finally{
        setLoading(false)
    }
}
    useEffect(() => {
        setItem(editItem)
    }, [editItem]);
  return (
    <Drawer
            anchor="right"
            open={open}
            onClose={handleEditTime}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {/* <TimeSheetComponent/> */}
            <Box
                sx={{ width: 340, pt: 10 }}
                role="presentation"
            >
                <Box sx={{ bgcolor: colors.white, width: "100%", height: "93vh", overflow: "scroll", p: 1.5 }}>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                            px: 2
                        }}
                    >
                        <Typography variant="h6" sx={{ color: colors.textSecondary, fontWeight: "bold" }}>Edit Time</Typography>
                        <IconButton onClick={handleEditTime} sx={{ px: 0 }}>
                            <MdClose size={24} />
                        </IconButton>

                    </Box>
                    <Typography component='form' sx={{ my: 2.5 }} >
                         {/* Type */}
                         <FormControl variant="standard" fullWidth >
                                        <FieldLabel>Type</FieldLabel>
                                        <Select
                                            className={classes.boxSelectField}
                                            sx={{
                                                // borderColor: errors?.type?.message && "red",
                                                
                                                "::before": {
                                                    display: "none",
                                                },
                                                "::after": {
                                                    display: "none",
                                                },
                                            }}
                                            variant="standard"
                                            fullWidth
                                            value={item.type}
                                            name="type"
                                            onChange={(e)=>handleChange(e)}
                                        >
                                            <MenuItem value={"onjob"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#2D9CDB`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On the job" />
                                            </MenuItem>
                                            <MenuItem value={"delay"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#EB5757`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Delayed" />
                                            </MenuItem>
                                            <MenuItem value={"break"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#98A2B3`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="On break" />
                                            </MenuItem>
                                            <MenuItem value={"travel"} >
                                                <Chip size="small" sx={{ width: 130, cursor: "pointer", p: 1.5, fontWeight: 600, backgroundColor: `#A77DFF`, color: `#FFFFFF`, fontSize: 12, lineHeight: '18px' }} label="Travel" />
                                            </MenuItem>
                                            
                                        </Select>
                                        
                                    </FormControl>
                                     {/* Start Time */}
                                     <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                                        <FieldLabel>Start Time</FieldLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className={classes.outlineInputBorder}
                                                value={item.startTime}
                                                name="startTime"
                                                onChange={(e) => handleChange(e,"startTime")}
                                                maxTime={dayjs().set('hour', moment(item.endTime).format("HH")).set('minute',moment(item.endTime).format("mm"))}

                                                InputAdornmentProps={{ position: 'start' }}
                                                renderInput={(params) =>
                                                    <TextField size="small" {...params}
                                                        // {...register(`startTime${index}`,
                                                        //     {
                                                        //         required: "Please enter start Time",
                                                        //     }
                                                        // )}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                        {/* {errors?.[`startTime${index}`]?.message && (
                                            <Error message={"Please enter start time"} />
                                        )} */}
                                    </FormControl>

                                    {/* End Time */}
                                    <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                                        <FieldLabel>End Time</FieldLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className={classes.outlineInputBorder}
                                                // label="End Time"
                                                value={item.endTime}
                                                name="endTime"
                                                minTime={dayjs().set('hour', moment(item.startTime).format("HH")).set('minute',moment(item.startTime).format("mm"))}
                                                InputAdornmentProps={{ position: 'start' }}
                                                onChange={(e) => handleChange(e,  "endTime")}
                                                renderInput={(params) =>
                                                    <TextField size="small" {...params}
                                                        // {...register(`endTime${index}`,
                                                        //     {
                                                        //         required: "Please enter end time",
                                                        //     }
                                                        // )}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                        {/* {errors?.[`endTime${index}`]?.message && (
                                            <Error message={"Please enter end time"} />
                                        )} */}
                                    </FormControl>
                                    <Box sx={{ width: "100%" }} >
                            <LoadingBtn
                                fullWidth
                                loading={loading}
                                onClick={update}
                                variant="contained"
                                style={{ px: 6 }}
                            >
                                Update
                            </LoadingBtn>
                        </Box>
                    </Typography>
  </Box>
  </Box>
  </Drawer>
  )
}

export default EditTime