import { Box, Button, Drawer, FormControl, IconButton, Menu, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import colors from 'app/style/colors'
import React from 'react'
import { useState } from 'react'
import { DateRange } from 'react-date-range'
import { MdClose } from 'react-icons/md'
import FieldLabel from '../UI/FieldLabel'
import moment from 'moment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useForm } from 'react-hook-form'
import { TrackChanges } from '@mui/icons-material'
import { LoadingBtn } from '../UI/Buttons'
import { Service } from 'app/config/service'
import JobChanges from 'app/views/Schedule/shared/Request/JobChanges'

const useStyles = makeStyles({
  drawerPaper: {
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      overflowY: "auto",
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.primaryGray,
      borderRadius: "10px",
    },
  },
  outlineInputBorder: {
    "& * > fieldset": {
      padding: "18px 12px",
      borderRadius: "10px",
    },
  },
  outlineInputBorderNone: {
    "& * > fieldset": {
      border: "none"
    },
  },
  boxSelectField: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: "5px 12px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8
  },
})
const Propose = ({ open, handlePropose,getJobChange,selectRequest}) => {
console.log("🚀 ~ file: Propose.js ~ line 52 ~ Propose ~ selectRequest", selectRequest)

  const classes = useStyles();
  const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(Date.now());
  const openDate = Boolean(anchorElDate);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    },
  ]);

  const handleTimeChange = (e, action) => {
    if (action === "startTime") {
      let startTime = moment(e['$d']).format('HH:mm')
      let date = moment(dateRange[0].startDate).format('YYYY-MM-DD')
      setStartTime(date + 'T' + startTime)
    }
    else {
      let endTime = moment(e['$d']).format('HH:mm')
      let date = moment(dateRange[0].endDate).format('YYYY-MM-DD')
      setEndTime(date + 'T' + endTime)
    }

  };
  const handleSubmitForm =async () => {
    try {
      try {
        let obj={
          status:"propose",
          id:selectRequest._id,
          reqStartDate:dateRange[0].startDate,
          reqEndDate:dateRange[0].endDate,
          reqStartTime:startTime,
          reqEndTime:endTime,
        }
        const {status,responseCode}=await Service.updateJobChange(obj)
        if(status===true&responseCode===200){
            getJobChange()
            handlePropose()
        }
    } catch (error) {
        console.log("🚀 ~ file: JobChanges.js ~ line 55 ~ updateJobChangeStatus ~ error", error)
        
    }
    } catch (error) {
      console.log("🚀 ~ file: Propose.js ~ line 83 ~ handleSubmitForm ~ error", error)

    }
  }
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handlePropose}
    >
      <Box
        sx={{ width: 300, pt: 10 }}
        role="presentation"
      >
        <Box sx={{ bgcolor: colors.white, width: "100%", height: "93vh", overflow: "scroll", p: 1.5 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              px: 1
            }}
          >
            <Typography variant="body1" sx={{ color: colors.textSecondary, fontWeight: "bold" }}>Propose New Date/Time</Typography>
            <IconButton onClick={handlePropose} sx={{ px: 0 }}>
              <MdClose size={24} />
            </IconButton>
          </Box>
          <Typography component='form' sx={{ my: 2.5 }} onSubmit={handleSubmit(handleSubmitForm)}>
            {/* Date */}
            <FormControl variant="standard" fullWidth >
              <FieldLabel>Date</FieldLabel>
              <TextField
                disabled
                hiddenLabel
                placeholder='Select date or date range'
                variant="outlined"
                className={classes.outlineInputBorder}
                value={`${moment(dateRange[0].startDate).format("DD MMM YYYY")}-${moment(dateRange[0].endDate).format("DD MMM YYYY")}`}
                onClick={(event) => { setAnchorElDate(event.currentTarget); }}
                size="small"
              // {...register("date"
              // )}
              />
              <Menu
                anchorEl={anchorElDate}
                open={openDate}
                onClose={() => setAnchorElDate(null)}
              >

                <DateRange
                  style={{ width: 'auto' }}
                  editableDateInputs={true}
                  onChange={item => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                />
                {/* <Box sx={{textAlign:'right'}}>
                  <Button onClick={() => setAnchorElDate(null)} >Done</Button>
                </Box> */}
              </Menu>
            </FormControl>
            {/* Start Time */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>Start Time</FieldLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={startTime}
                  name="startTime"
                  onChange={(e) => handleTimeChange(e, "startTime")}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>

            {/* End Time */}
            <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
              <FieldLabel>End Time</FieldLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={endTime}
                  name="endTime"
                  onChange={(e) => handleTimeChange(e, "endTime")}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            <Box sx={{ width: "100%" }}>
            <LoadingBtn
              fullWidth
              loading={loading}
              type="submit"
              variant="contained"
              style={{ px: 6 }}
            >
              Save
            </LoadingBtn>
          </Box>
          </Typography>
          
        </Box>

      </Box>
    </Drawer>
  )
}

export default Propose